<template>
  <div
    class="group"
    :class="{
      'flex items-center justify-between': flex,
    }"
  >
    <t-label v-if="label && !labelIn" :for="uuid" :disabled="disabled">{{
      label
    }}</t-label>
    <div
      ref="outsideNumberInput"
      class="inline-flex overflow-hidden rounded-md transition duration-300 focus-within:outline focus-within:outline-2 focus-within:outline-accent-1"
    >
      <div
        class="flex rounded-md border-x border-y border-solid border-transparent bg-gray-600 px-2.5 font-inter text-xs font-medium leading-[30px] text-gray-50 transition duration-300 group-focus-within:bg-gray-700 group-focus-within:text-gray-10 group-hover:border-gray-400"
        :class="{
          '!pl-2 !pr-1': controls,
          '!border-transparent !bg-gray-600 !text-gray-200': disabled,
        }"
        @click="($refs.thisInput as HTMLInputElement).focus()"
      >
        <div
          v-if="label !== '' && labelIn"
          class="text-[9px] font-medium text-gray-200"
        >
          <span>{{ label }}</span>
        </div>
        <input
          :id="uuid"
          ref="thisInput"
          v-model.number="localValue"
          :pattern="regexPattern"
          type="number"
          :min="min"
          :max="max"
          :step="step"
          class="border-none bg-gray-600 p-0 text-center font-inter text-xs font-medium leading-[30px] text-gray-50 outline-none transition duration-300 disabled:!cursor-not-allowed disabled:bg-gray-600 disabled:text-gray-200 group-focus-within:bg-gray-700 group-focus-within:text-gray-10"
          :style="{
            width: width + 'px',
          }"
          :disabled="disabled"
          @blur="onBlur"
        />
        <div v-if="unit !== ''">
          <span>{{ unit }}</span>
        </div>
        <div v-if="controls" class="my-[2px] ml-2">
          <button
            class="block cursor-pointer border-none bg-gray-600 py-[2.5px] text-gray-100 transition duration-300 hover:bg-gray-400 hover:text-gray-50 disabled:!cursor-not-allowed disabled:!border-transparent disabled:bg-gray-600 disabled:text-gray-300 group-hover:border-gray-400"
            :disabled="disabled"
            @click="(e) => (e.shiftKey ? add(5) : add(Number(step)))"
          >
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.22052 2.72852L2.22052 5.72852C2.00958 5.93945 1.93927 6.26758 2.05645 6.54883C2.17364 6.83008 2.45489 7.01758 2.75958 7.01758H8.75958C9.06427 7.01758 9.32208 6.83008 9.43927 6.54883C9.55645 6.26758 9.48614 5.93945 9.2752 5.72852L6.2752 2.72852C5.99395 2.42383 5.50177 2.42383 5.22052 2.72852Z"
                fill="currentColor"
              />
            </svg>
          </button>
          <button
            class="block cursor-pointer border-none bg-gray-600 py-[2.5px] text-gray-100 transition duration-300 hover:bg-gray-400 hover:text-gray-50 disabled:!cursor-not-allowed disabled:!border-transparent disabled:bg-gray-600 disabled:text-gray-300 group-hover:border-gray-400"
            :disabled="disabled"
            @click="
              (e) => (e.shiftKey ? substract(5) : substract(Number(step)))
            "
          >
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.22052 6.78906L2.22052 3.78906C2.00958 3.57812 1.93927 3.25 2.05645 2.96875C2.17364 2.6875 2.45489 2.5 2.75958 2.5H8.75958C9.06427 2.5 9.32208 2.6875 9.43927 2.96875C9.55645 3.25 9.48614 3.57812 9.2752 3.78906L6.2752 6.78906C5.99395 7.09375 5.50177 7.09375 5.22052 6.78906Z"
                fill="currentColor"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { v4 as uuidv4 } from "uuid";
  import TLabel from "../TLabel/TLabel.vue";
  import { useDebounceFn, onClickOutside } from "@vueuse/core";
  import { ref, computed, onBeforeMount, onBeforeUnmount } from "vue";

  const props = defineProps({
    modelValue: {
      type: Number,
      default: 0,
    },
    controls: {
      type: Boolean,
      default: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 99,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    alt: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    unit: {
      type: String,
      default: "px",
    },
    noBorderLeft: {
      type: Boolean,
      default: true,
    },
    regexPattern: {
      type: String,
      default: "",
    },
    step: {
      type: String,
      default: "1",
    },
    width: {
      type: Number,
      default: 44,
    },
    flex: {
      type: Boolean,
      default: false,
    },
    labelIn: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(["update:modelValue"]);

  const uuid = uuidv4();
  const active = ref(false);
  const outsideNumberInput = ref();

  const localValue = computed({
    get() {
      return props.modelValue;
    },
    set: useDebounceFn(function (val) {
      const checkedValue = checkValue(val);
      emit("update:modelValue", checkedValue);
    }, 600),
  });

  function checkValue(value: number) {
    let validatedNumber = value;

    if (props.min !== undefined) {
      if (value < props.min) {
        validatedNumber = props.min;
      }
    }

    if (props.max !== undefined) {
      if (value > props.max) {
        validatedNumber = props.max;
      }
    }

    return validatedNumber;
  }

  function onBlur(val: any) {
    if (val.target.value !== undefined || val.target.value !== "") {
      const checkedValue = checkValue(val.target.value);
      emit("update:modelValue", checkedValue);
    }
  }

  onClickOutside(outsideNumberInput, () => {
    active.value = false;
  });

  function add(toAdd = 1) {
    if (!props.disabled) {
      active.value = true;

      const newValue = props.modelValue + toAdd;

      if (newValue > props.max) {
        emit("update:modelValue", props.max);
      } else {
        emit("update:modelValue", newValue);
      }
    }
  }

  function substract(toSub = 1) {
    if (!props.disabled) {
      active.value = true;

      const newValue = props.modelValue - toSub;

      if (newValue < props.min) {
        emit("update:modelValue", props.min);
      } else {
        emit("update:modelValue", newValue);
      }
    }
  }

  function focusChanged(event: Event) {
    const el = event.target;

    if (!el) {
      return;
    }

    //@ts-expect-error
    if (el.id === uuid) {
      active.value = true;
    } else {
      active.value = false;
    }
  }

  onBeforeMount(() => {
    document.addEventListener("focusin", focusChanged);
  });

  onBeforeUnmount(() => {
    document.removeEventListener("focusin", focusChanged);
  });
</script>
<style lang="scss">
  // @import "./TInputNumber.scss";
  input[type="number"] {
    -moz-appearance: textfield;
    /* Firefox */
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
  }
</style>
