import { fabric } from "fabric";

import useInitControls from "./useInit/useInitControls";
import useInitBg from "./useInit/useInitBg";
import useInitCanvasMask from "./useInit/useInitCanvasMask";
import useInitCrop from "./useInit/useInitCrop";
import useInitSnap from "./useInit/useInitSnap";
import useInitPreviews from "./useInit/useInitPreviews";
import useInitCanvasOn from "./useInit/useInitCanvasOn";
import useInitCropEmits from "./useInit/useInitCropEmits";
import useInitFilters from "./useInit/useInitFilters";

export default () => {
  const scaleOfCanvas = ref(1);
  const canvas = ref();
  const bgImg = ref();
  const canvasMask = ref();
  const cropRect = ref();
  const cropMask = ref();
  const cropLeftFirstThird = ref();
  const cropLeftSecondThird = ref();
  const cropTopFirstThird = ref();
  const cropTopSecondThird = ref();
  const snapTopLine = ref();
  const snapBottomLine = ref();
  const snapLeftLine = ref();
  const snapRightLine = ref();
  const snapCenterHorizontalLine = ref();
  const snapCenterVerticalLine = ref();
  const vignetteLayer = ref(null);
  const workspaceEl = document.querySelector("#workspace") as HTMLElement;
  if (!workspaceEl) {
    throw new Error("element #workspace is missing, plz check!");
  }
  useInitControls();
  const stateStore = useStateStore();
  stateStore.canvasSize = {
    width: workspaceEl.offsetWidth,
    height: workspaceEl.offsetHeight,
  };

  function setCanvaSize(width: number, height: number) {
    stateStore.canvasSize.width = width;
    stateStore.canvasSize.height = height;
    canvas.value.setWidth(stateStore.canvasSize.width);
    canvas.value.setHeight(stateStore.canvasSize.height);
  }

  function init(url = "./IMG_9732.jpg") {
    //@ts-expect-error
    if (fabric.isWebglSupported()) {
      fabric.textureSize = 4096;
    }
    canvas.value = markRaw(
      new fabric.Canvas("canvas", {
        isDrawingMode: false,
        backgroundColor: undefined,
        // selection: false,
        stateful: true,
        preserveObjectStacking: true,
      })
    );

    const { initBackgroundImage } = useInitBg(canvas);
    const { initCanvasMask } = useInitCanvasMask(canvas);
    const { initCropLines, initCropMask, initCropRect } = useInitCrop(
      canvas,
      scaleOfCanvas
    );
    const { initSnapLines } = useInitSnap(canvas);
    const { setPreviewsForFilters, setPreviewsForShapes, setPreviewsForMasks } =
      useInitPreviews();

    fabric.Image.fromURL(url, function (img) {
      const width = img.width;
      const height = img.height;
      if (width && height) {
        stateStore.originBgImage = img.toDataURL({ format: "jpeg" });
        setCanvaSize(width, height);
        bgImg.value = initBackgroundImage(img);
        cropRect.value = initCropRect(width, height);
        cropMask.value = initCropMask(width, height);
        canvasMask.value = initCanvasMask(width, height);
        const { cropLF, cropLS, cropTF, cropTS } = initCropLines(width, height);
        cropLeftFirstThird.value = cropLF;
        cropLeftSecondThird.value = cropLS;
        cropTopFirstThird.value = cropTF;
        cropTopSecondThird.value = cropTS;
        const {
          snapTop,
          snapBottom,
          snapCenterHorizontal,
          snapLeft,
          snapRight,
          snapCenterVertical,
        } = initSnapLines(width, height);
        snapTopLine.value = snapTop;
        snapBottomLine.value = snapBottom;
        snapLeftLine.value = snapLeft;
        snapRightLine.value = snapRight;
        snapCenterHorizontalLine.value = snapCenterHorizontal;
        snapCenterVerticalLine.value = snapCenterVertical;
        setPreviewsForShapes();
        setPreviewsForFilters();
        setPreviewsForMasks();
        auto();
        snapshotBounced();
      }
    });

    const { recalculateCanvasMask } = useRecalculateCanvasMask(
      canvas,
      canvasMask,
      cropRect,
      bgImg
    );

    const { goBack, goForward, saveStateBounced } = useHistory(
      canvas,
      bgImg,
      cropRect,
      cropMask,
      cropLeftFirstThird,
      cropLeftSecondThird,
      cropTopFirstThird,
      cropTopSecondThird,
      snapTopLine,
      snapBottomLine,
      snapLeftLine,
      snapRightLine,
      snapCenterHorizontalLine,
      snapCenterVerticalLine
    );

    const { addText } = useText(canvas);
    const { addShape } = useElements(canvas);
    const { addPicture, addMaskToPicture, resetMask } = usePictures(
      canvas,
      cropRect
    );

    const props = {
      canvas,
      bgImg,
      cropRect,
      cropMask,
      cropLeftFirstThird,
      cropLeftSecondThird,
      cropTopFirstThird,
      cropTopSecondThird,
      snapTopLine,
      snapBottomLine,
      snapLeftLine,
      snapRightLine,
      snapCenterHorizontalLine,
      snapCenterVerticalLine,
      vignetteLayer,
      auto,
      reorderLayersBounced,
      setCanvaSize,
      setCenterFromObject,
      saveStateBounced,
    };

    useInitCanvasOn(props);
    useInitCropEmits(props);
    useInitFilters(props);

    const globalEmitsHandler = window.$event;
    globalEmitsHandler.on("recalculateCanvasMask", () => {
      recalculateCanvasMask();
    });

    globalEmitsHandler.on("reorderLayers", () => {
      reorderLayersBounced();
    });

    globalEmitsHandler.on("resize", () => {
      auto();
      window.$event.emit("updateToolbar");
    });
    globalEmitsHandler.on("back", () => {
      goBack();
      autoBounced();
      reloadData();
    });
    globalEmitsHandler.on("forward", () => {
      goForward();
      autoBounced();
      reloadData();
    });

    globalEmitsHandler.on("addText", (event: any) => {
      addText(event);
      reorderLayersBounced();
      saveStateBounced();
    });

    globalEmitsHandler.on("addShape", (event: string) => {
      addShape(event);
      reorderLayersBounced();
      saveStateBounced();
    });

    globalEmitsHandler.on(
      "addPicture",
      (event: { file: string | ArrayBuffer; url?: boolean }) => {
        addPicture(event.file, event.url);
        reorderLayersBounced();
        saveStateBounced();
      }
    );

    globalEmitsHandler.on(
      "addMaskToPicture",
      (event: { picture: any; type: string }) => {
        addMaskToPicture(event.picture, event.type);
        reorderLayersBounced();
        saveStateBounced();
      }
    );

    globalEmitsHandler.on("resetPictureMask", (picture: any) => {
      resetMask(picture);
      reorderLayersBounced();
      saveStateBounced();
    });

    globalEmitsHandler.on("centerCanvas", () => {
      setCenterFromObject(cropRect.value);
    });
  }

  function setCenterFromObject(obj: fabric.Rect) {
    const objCenter = obj.getCenterPoint();
    const viewportTransform = canvas.value.viewportTransform;
    if (
      canvas.value.width === undefined ||
      canvas.value.height === undefined ||
      !viewportTransform
    )
      return;
    viewportTransform[4] =
      canvas.value.width / 2 - objCenter.x * viewportTransform[0];
    viewportTransform[5] =
      canvas.value.height / 2 - objCenter.y * viewportTransform[3];
    canvas.value.setViewportTransform(viewportTransform);
    canvas.value.renderAll();
  }

  function setZoomAuto(
    scale: number,
    cb?: (left?: number, top?: number) => void
  ) {
    const width = workspaceEl.offsetWidth;
    const height = workspaceEl.offsetHeight;
    canvas.value.setWidth(width);
    canvas.value.setHeight(height);
    const center = canvas.value.getCenter();
    canvas.value.setViewportTransform(fabric.iMatrix.concat());
    canvas.value.zoomToPoint(new fabric.Point(center.left, center.top), scale);
    scaleOfCanvas.value = scale;
    if (!cropRect.value) return;
    setCenterFromObject(cropRect.value);
    if (cb) cb(cropRect.value.left, cropRect.value.top);
  }

  function getScale() {
    const viewPortWidth = workspaceEl.offsetWidth;
    const viewPortHeight = workspaceEl.offsetHeight;
    if (
      viewPortWidth / viewPortHeight <
      stateStore.canvasSize.width / stateStore.canvasSize.height
    ) {
      return viewPortWidth / stateStore.canvasSize.width;
    }
    return viewPortHeight / stateStore.canvasSize.height;
  }

  function auto() {
    const scale = getScale();
    setZoomAuto(scale - 0.02);
    stateStore.fitZoom = scale - 0.02;
    window.$event.emit("recalculateCanvasMask");
    window.$event.emit("refreshZoom");
  }

  const autoBounced = useDebounceFn(async function () {
    auto();
  }, 50);

  const snapshotBounced = useDebounceFn(async function () {
    stateStore.takeSnapshot(canvas.value);
  }, 50);

  const reorderLayersBounced = useDebounceFn(async function () {
    snapBottomLine.value.sendToBack();
    snapTopLine.value.sendToBack();
    snapLeftLine.value.sendToBack();
    snapRightLine.value.sendToBack();
    snapCenterHorizontalLine.value.sendToBack();
    snapCenterVerticalLine.value.sendToBack();
    cropRect.value.sendToBack();
    bgImg.value.sendToBack();
    cropMask.value.bringToFront();
    cropLeftFirstThird.value.bringToFront();
    cropLeftSecondThird.value.bringToFront();
    cropTopFirstThird.value.bringToFront();
    cropTopSecondThird.value.bringToFront();
    canvasMask.value.bringToFront();
    canvas.value.renderAll();
  }, 10);

  const reloadData = useDebounceFn(async function () {
    window.$event.emit("getDuotone");
    window.$event.emit("getAdjust");
  }, 50);

  return { canvas, init, auto };
};
