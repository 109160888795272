<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 18C7.44772 18 7 17.5523 7 17L7 7C7 6.44772 7.44771 6 8 6H12C12.5523 6 13 6.44772 13 7L13 17C13 17.5523 12.5523 18 12 18H8Z"
      fill="currentColor"
    />
    <path
      d="M2.75 2C2.33579 2 2 2.33579 2 2.75C2 3.16421 2.33579 3.5 2.75 3.5L17.2492 3.5C17.6634 3.5 17.9992 3.16421 17.9992 2.75C17.9992 2.33579 17.6634 2 17.2492 2L2.75 2Z"
      fill="currentColor"
    />
  </svg>
</template>
