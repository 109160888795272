<template>
  <div
    ref="thisTab"
    class="rounded-md border-2 border-solid border-transparent transition duration-300"
    :class="{
      '!border-accent-1': active,
      '!border-gray-500': openDetail && !active,
    }"
    @click="
      active = true;
      acitvateBlock();
    "
  >
    <div
      class="box-border flex items-center justify-between rounded-md bg-gray-600 p-2"
    >
      <div
        class="flex flex-1 items-center gap-2 font-inter text-[13px] font-medium leading-6 text-gray-100"
        :class="{
          '!text-gray-50': openDetail,
        }"
      >
        <div class="flex items-center gap-2">
          <div
            v-if="!noMove"
            class="drag-handle max-w-6 min-h-5 grid cursor-move items-center"
          >
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.8125 6.6875C8.52344 6.6875 9.125 6.11328 9.125 5.375C9.125 4.66406 8.52344 4.0625 7.8125 4.0625C7.07422 4.0625 6.5 4.66406 6.5 5.375C6.5 6.11328 7.07422 6.6875 7.8125 6.6875ZM7.8125 11.0625C8.52344 11.0625 9.125 10.4883 9.125 9.75C9.125 9.03906 8.52344 8.4375 7.8125 8.4375C7.07422 8.4375 6.5 9.03906 6.5 9.75C6.5 10.4883 7.07422 11.0625 7.8125 11.0625ZM9.125 14.125C9.125 13.4141 8.52344 12.8125 7.8125 12.8125C7.07422 12.8125 6.5 13.4141 6.5 14.125C6.5 14.8633 7.07422 15.4375 7.8125 15.4375C8.52344 15.4375 9.125 14.8633 9.125 14.125ZM12.1875 6.6875C12.8984 6.6875 13.5 6.11328 13.5 5.375C13.5 4.66406 12.8984 4.0625 12.1875 4.0625C11.4492 4.0625 10.875 4.66406 10.875 5.375C10.875 6.11328 11.4492 6.6875 12.1875 6.6875ZM13.5 9.75C13.5 9.03906 12.8984 8.4375 12.1875 8.4375C11.4492 8.4375 10.875 9.03906 10.875 9.75C10.875 10.4883 11.4492 11.0625 12.1875 11.0625C12.8984 11.0625 13.5 10.4883 13.5 9.75ZM12.1875 15.4375C12.8984 15.4375 13.5 14.8633 13.5 14.125C13.5 13.4141 12.8984 12.8125 12.1875 12.8125C11.4492 12.8125 10.875 13.4141 10.875 14.125C10.875 14.8633 11.4492 15.4375 12.1875 15.4375Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <div v-if="$slots.icon" class="max-w-6 min-h-5 grid items-center">
            <slot name="icon" />
          </div>
        </div>
        <div v-if="$slots.headerContent">
          <slot name="headerContent" />
        </div>
        <h2
          v-if="header !== '' && !$slots.headerContent"
          class="mr-2 flex-1 cursor-pointer font-inter text-[13px] font-medium leading-6 text-gray-100"
          :class="{
            '!text-gray-50': openDetail,
          }"
          @click="
            openDetail = !openDetail;
            active = openDetail;
            acitvateBlock();
          "
        >
          {{ header }}
        </h2>
      </div>

      <div class="flex gap-2">
        <TButton
          v-if="toggleShow"
          :class="{
            '!outline !outline-2 !outline-accent-1': !show,
          }"
          :disabled="disabled"
          data-cy="t-tab-toggle-hide"
          @click="showHide"
        >
          <template #icon>
            <!-- <font-awesome-icon v-if="show" :icon="['fas', 'eye']" />
            <font-awesome-icon v-if="!show" :icon="['fas', 'eye-slash']" /> -->
          </template>
        </TButton>

        <TButton
          v-if="showRemove"
          :disabled="disabled"
          @click="$emit('removeBlock')"
        >
          <template #icon>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.4688 4.9375C15.8242 4.9375 16.125 5.23828 16.125 5.59375C16.125 5.97656 15.8242 6.25 15.4688 6.25H15.1406L14.4844 15.1367C14.4023 16.0664 13.6641 16.75 12.7344 16.75H7.23828C6.30859 16.75 5.57031 16.0664 5.48828 15.1367L4.83203 6.25H4.53125C4.14844 6.25 3.875 5.97656 3.875 5.59375C3.875 5.23828 4.14844 4.9375 4.53125 4.9375H6.41797L7.42969 3.43359C7.70312 3.02344 8.19531 2.75 8.71484 2.75H11.2578C11.7773 2.75 12.2695 3.02344 12.543 3.43359L13.5547 4.9375H15.4688ZM8.71484 4.0625C8.63281 4.0625 8.55078 4.11719 8.52344 4.17188L8.00391 4.9375H11.9688L11.4492 4.17188C11.4219 4.11719 11.3398 4.0625 11.2578 4.0625H8.71484ZM13.8281 6.25H6.14453L6.80078 15.0547C6.82812 15.2734 7.01953 15.4375 7.23828 15.4375H12.7344C12.9531 15.4375 13.1445 15.2734 13.1719 15.0547L13.8281 6.25Z"
                fill="currentColor"
              />
            </svg>
          </template>
        </TButton>

        <t-input-toggle
          v-if="!hideOpen"
          v-model="openDetail"
          data-cy="t-tab-toggle-open"
          :disabled="disabled"
          @update:model-value="
            active = openDetail;
            acitvateBlock();
          "
        />
      </div>
    </div>

    <transition name="slide-fade-dropdown">
      <div v-if="openDetail">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import TInputToggle from "../TInputToggle/TInputToggle.vue";
import { ref, onMounted } from "vue";
import TButton from "../TButton/TButton.vue";
import { onClickOutside } from "@vueuse/core";

const props = defineProps({
  header: {
    type: String,
    default: "",
  },
  defaultOpen: {
    type: Boolean,
    deafult: false,
  },
  disabled: {
    type: Boolean,
    deafult: false,
  },
  toggleShow: {
    type: Boolean,
    deafult: false,
  },
  showRemove: {
    type: Boolean,
    deafult: false,
  },
  defaultDisplayed: {
    type: Boolean,
    deafult: true,
  },
  noMove: {
    type: Boolean,
    deafult: false,
  },
  hideOpen: {
    type: Boolean,
    deafult: false,
  },
});

const emits = defineEmits([
  "showBlock",
  "hideBlock",
  "removeBlock",
  "acitvateBlock",
]);

const openDetail = ref(false);
const active = ref(false);
const show = ref(true);
const thisTab = ref();

function showHide() {
  show.value = !show.value;
  if (show.value) {
    emits("showBlock");
  } else {
    emits("hideBlock");
  }
}

onClickOutside(thisTab, (event: any) => {
  if (!event.composedPath().includes(thisTab.value)) {
    active.value = false;
  }
});

function acitvateBlock() {
  if (active.value) {
    emits("acitvateBlock");
  }
}

onMounted(() => {
  if (props.defaultOpen) {
    openDetail.value = true;
  }
  show.value = props.defaultDisplayed;
});
</script>
