<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.9414 5.10156L13.5 4.5L14.0742 2.96875C14.1016 2.83203 14.2383 2.75 14.375 2.75C14.4844 2.75 14.6211 2.83203 14.6484 2.96875L15.25 4.5L16.7812 5.10156C16.918 5.12891 17 5.26562 17 5.375C17 5.51172 16.918 5.64844 16.7812 5.67578L15.25 6.25L14.6484 7.80859C14.6211 7.91797 14.4844 8 14.375 8C14.2383 8 14.1016 7.91797 14.0742 7.80859L13.5 6.25L11.9414 5.67578C11.832 5.64844 11.75 5.51172 11.75 5.375C11.75 5.26562 11.832 5.12891 11.9414 5.10156ZM8.60547 4.77344L10.0273 7.89062L13.1445 9.3125C13.3086 9.39453 13.418 9.55859 13.418 9.72266C13.418 9.88672 13.3086 10.0508 13.1445 10.1055L10.0273 11.5547L8.60547 14.6719C8.52344 14.8359 8.35938 14.9453 8.19531 14.9453C8.03125 14.9453 7.86719 14.8359 7.8125 14.6719L6.36328 11.5547L3.24609 10.1328C3.08203 10.0508 3 9.88672 3 9.72266C3 9.55859 3.08203 9.39453 3.24609 9.3125L6.36328 7.89062L7.8125 4.77344C7.86719 4.60938 8.03125 4.5 8.19531 4.5C8.35938 4.5 8.52344 4.60938 8.60547 4.77344ZM13.5 13.25L14.0742 11.7188C14.1016 11.582 14.2383 11.5 14.375 11.5C14.4844 11.5 14.6211 11.582 14.6484 11.7188L15.25 13.25L16.7812 13.8516C16.918 13.8789 17 14.0156 17 14.125C17 14.2617 16.918 14.3984 16.7812 14.4258L15.25 15L14.6484 16.5586C14.6211 16.668 14.4844 16.75 14.375 16.75C14.2383 16.75 14.1016 16.668 14.0742 16.5586L13.5 15L11.9414 14.4258C11.832 14.3984 11.75 14.2617 11.75 14.125C11.75 14.0156 11.832 13.8789 11.9414 13.8516L13.5 13.25Z"
      fill="currentColor"
    />
  </svg>
</template>
