import IPluginOptions from "types/PluginOptions";
// import setTheme from "./ThemeSetter";
import { loadLanguageAsync, i18n } from "./i18n-setup";
// import { loadStyle } from "@/utils/loadStyle";
import { setApi, setLambdaStage } from "../api";

type mode = "production" | "testing" | "development";

const setInitState = async (
  options: IPluginOptions,
  hostname: string,
  mode: mode
) => {
  if (!options.authorize) {
    throw new Error(
      "Object 'authorize' must be defined in options. Please attach your licence information"
    );
  }
  const authorizeStore = useAuthorizeStore();

  authorizeStore.hostname = hostname;
  authorizeStore.apiKey = options.authorize.apiKey;
  authorizeStore.entityId = options.authorize.entityId;

  if (options.lambdaStage) {
    switch (options.lambdaStage) {
      case "develop":
        setLambdaStage("develop");
        break;
      case "local":
        setLambdaStage("local");
        break;
      default:
        setLambdaStage("production");
    }
  }

  // try {
  //   await mjmlStore.authorizePromise({ options: options });
  // } catch (e) {
  //   throw new Error(
  //     `Unable to authorize, please check your licence information. + ${e}`
  //   );
  // }

  // if (options.currentUser) {
  //   userStore.setCurrentUser(options.currentUser);
  // }
  if (options.api) {
    for (const i in options.api) {
      //@ts-expect-error
      setApi(i, options.api[i]);
    }
  }
};

export default setInitState;
