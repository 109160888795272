<template>
  <TModal :header="$t('export.title')" xl @close="close">
    <template #icon>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16 10.5C16 13.8281 13.3047 16.5 10 16.5C6.67188 16.5 4 13.8281 4 10.5C4 7.19531 6.67188 4.5 10 4.5C13.3047 4.5 16 7.19531 16 10.5ZM10 5.625C7.30469 5.625 5.125 7.82812 5.125 10.5C5.125 13.1953 7.30469 15.375 10 15.375C12.6719 15.375 14.875 13.1953 14.875 10.5C14.875 7.82812 12.6719 5.625 10 5.625Z"
          fill="currentColor"
        />
      </svg>
    </template>
    <template #dialog-body>
      <div class="p-5 pt-0">
        <div class="grid grid-cols-2">
          <div class="text-center font-inter text-xs text-gray-200">
            <img :src="preview" class="mb-4" />
            <p>
              {{ $t("export.format") }}: {{ format }}, {{ $t("export.size") }}:
              {{ size }}
            </p>
            <p>{{ Math.round(width) }} x {{ Math.round(height) }}px</p>
          </div>
          <div class="px-5">
            <MenuContentContainer :border-bottom="false">
              <div class="mb-5">
                <TSlider
                  v-model="quality"
                  :label="$t('export.quality')"
                  :min="20"
                  :max="100"
                  unit="%"
                ></TSlider>
              </div>
              <ContentRow block>
                <TInputNumber
                  :key="key"
                  :model-value="Math.round(width)"
                  :label="$t('dimensions.width')"
                  flex
                  :max="maxW"
                  :min="0"
                  :controls="false"
                  @update:model-value="changeWidth"
                />
              </ContentRow>
              <ContentRow block>
                <TInputNumber
                  :key="key"
                  :model-value="Math.round(height)"
                  :label="$t('dimensions.height')"
                  flex
                  :max="maxH"
                  :min="0"
                  :controls="false"
                  @update:model-value="changeHeight"
                />
              </ContentRow>
            </MenuContentContainer>
          </div>
        </div>
        <div class="flex justify-end">
          <TButton success @click="download">
            {{ $t("export.download") }}
          </TButton>
        </div>
      </div>
    </template>
  </TModal>
</template>

<script setup lang="ts">
  import TModal from "@/ui/TModal/TModal.vue";
  import useExport from "./useExport";
  import TSlider from "@/ui/TSlider/TSlider.vue";
  import TInputNumber from "@/ui/TInputNumber/TInputNumber.vue";
  import TButton from "@/ui/TButton/TButton.vue";

  const props = defineProps<{
    canvas: Ref<fabric.Canvas>;
  }>();

  const {
    maxH,
    maxW,
    width,
    height,
    format,
    quality,
    size,
    preview,
    key,
    changeHeight,
    changeWidth,
    download,
    close,
  } = useExport(props.canvas);
</script>
