import formatBytes from "@/utils/formatBytes";

export default (canvas: any) => {
  const cropRect = canvas.value.item(1);
  const maxW = ref(cropRect.width);
  const width = ref(cropRect.width);
  const maxH = ref(cropRect.height);
  const height = ref(cropRect.height);
  const size = ref("0kb");
  const format = ref("jpeg");
  const quality = ref(90);
  const preview = ref(getImage());
  const key = ref(0);

  async function download() {
    // create a new handle
    // const newHandle = await window.showSaveFilePicker();

    // // create a FileSystemWritableFileStream to write to
    // const writableStream = await newHandle.createWritable();

    // // write our file
    // await writableStream.write(blob);

    // // close the file and write the contents to disk.
    // await writableStream.close();
    const blobUrl = getImage();
    const link = document.createElement("a");
    link.download = "image." + format.value;
    link.href = blobUrl;
    link.click();
    close();
  }

  function getImage() {
    canvas.value.setZoom(width.value / cropRect.width);
    const toDataURLParams = {
      format: format.value,
      quality: quality.value / 100,
      left: cropRect.getBoundingRect().left + cropRect.strokeWidth / 2,
      top: cropRect.getBoundingRect().top + cropRect.strokeWidth / 2,
      width: width.value,
      height: height.value,
    };
    const data = canvas.value
      .toDataURL(toDataURLParams)
      .replace("data:image/" + format.value + ";base64,", "");
    const blob = b64toBlob(data, "image/" + format);
    size.value = formatBytes(blob.size);
    return URL.createObjectURL(blob);
  }

  function b64toBlob(b64Data: any, contentType = "", sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  function changeWidth(newWidth: number) {
    if (newWidth > maxW.value) newWidth = maxW.value;
    const newHeight = (newWidth * height.value) / width.value;
    width.value = newWidth;
    height.value = newHeight;
    preview.value = getImage();
    key.value++;
  }

  function changeHeight(newHeight: number) {
    if (newHeight > maxH.value) newHeight = maxH.value;
    const newWidth = (newHeight * width.value) / height.value;
    height.value = newHeight;
    width.value = newWidth;
    preview.value = getImage();
    key.value++;
  }

  function close() {
    const stateStore = useStateStore();
    stateStore.exporting = false;
    window.$event.emit("resize");
  }

  watch([format, quality], () => {
    preview.value = getImage();
  });

  return {
    maxH,
    maxW,
    width,
    height,
    preview,
    format,
    quality,
    size,
    key,
    changeWidth,
    changeHeight,
    download,
    close,
  };
};
