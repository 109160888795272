<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.24532 11.79C2.81507 11.5771 2.98686 11 3.4805 11L16.0406 11C16.5705 11 17 11.378 17 11.8442V17.1545C17 17.8002 16.2098 18.2069 15.5702 17.8904L3.24532 11.79Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.5 7.5L8.06119 7.5L15.5 3.81808V7.5ZM16.0406 9C16.5705 9 17 8.62203 17 8.15577V2.84546C17 2.19977 16.2098 1.79309 15.5702 2.10965L3.24532 8.20998C2.81507 8.42294 2.98686 9 3.4805 9L16.0406 9Z"
      fill="currentColor"
    />
  </svg>
</template>
