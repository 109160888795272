import { defineStore } from "pinia";
import IFont from "types/IFont/IFont";
import defaultFonts from "../defaults/defaultFonts";

interface FontOptions {
  override?: boolean;
  fonts: IFont[];
}

interface State {
  fonts: IFont[];
  fontSizes: number[];
}

export const useFontStore = defineStore("fonts", {
  state: (): State => ({
    fonts: defaultFonts,
    fontSizes: [12, 14, 16, 18, 24, 30, 48, 56, 64, 96, 128, 160, 198, 232],
  }),

  getters: {
    fontsWithUrl(): IFont[] {
      return this.fonts.filter((font) => font.url !== undefined);
    },
  },

  actions: {
    setFontSizes(fontSizes: number[]) {
      this.fontSizes = fontSizes;
    },

    setFonts(fontsOptions: FontOptions) {
      if (fontsOptions.override) {
        this.fonts = fontsOptions.fonts;
      } else {
        this.fonts = [...this.fonts, ...fontsOptions.fonts];
      }
    },
  },
});
