<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.5 21.9375C9.5 22.3945 9.85547 22.75 10.3125 22.75H20.875V24.375H10.3125C8.94141 24.375 7.875 23.3086 7.875 21.9375V9.75H3.8125C3.35547 9.75 3 9.39453 3 8.9375C3 8.53125 3.35547 8.125 3.8125 8.125H7.875V4.0625C7.875 3.65625 8.23047 3.25 8.6875 3.25C9.09375 3.25 9.5 3.65625 9.5 4.0625V21.9375ZM22.5 10.5625C22.5 10.1562 22.0938 9.75 21.6875 9.75H11.125V8.125H21.6875C23.0078 8.125 24.125 9.24219 24.125 10.5625V22.75H28.1875C28.5938 22.75 29 23.1562 29 23.5625C29 24.0195 28.5938 24.375 28.1875 24.375H24.125V28.4375C24.125 28.8945 23.7188 29.25 23.3125 29.25C22.8555 29.25 22.5 28.8945 22.5 28.4375V10.5625Z"
      fill="currentColor"
    />
  </svg>
</template>
