import { fabric } from "fabric";

export default (
  canvas: any,
  canvasMask: Ref<fabric.Rect>,
  cropRect: Ref<fabric.Rect>,
  bgImg: Ref<fabric.Image>
) => {
  const cropStore = useCropStore();

  function recalculateCanvasMask() {
    if (cropStore.isCropping) {
      canvasMask.value.width =
        (canvas.value.width || 0) / canvas.value.getZoom() + 2;
      canvasMask.value.height =
        (canvas.value.height || 0) / canvas.value.getZoom() + 2;
      const c = new fabric.Rect({
        width: bgImg.value.width,
        height: bgImg.value.height,
        originX: "center",
        originY: "center",
        left: bgImg.value.left,
        top: bgImg.value.top,
        angle: bgImg.value.angle,
        absolutePositioned: true,
      });
      c.inverted = true;
      canvasMask.value.clipPath = c;
      canvasMask.value.left = cropRect.value.left;
      canvasMask.value.top = cropRect.value.top;
    } else {
      canvasMask.value.width =
        (canvas.value.width || 0) / canvas.value.getZoom() + 2;
      canvasMask.value.height =
        (canvas.value.height || 0) / canvas.value.getZoom() + 2;
      const c = new fabric.Rect({
        width: cropRect.value.width,
        height: cropRect.value.height,
        originX: "center",
        originY: "center",
        left: cropRect.value.left,
        top: cropRect.value.top,
        absolutePositioned: true,
      });
      c.inverted = true;
      canvasMask.value.clipPath = c;
      canvasMask.value.left = cropRect.value.left;
      canvasMask.value.top = cropRect.value.top;
    }
    canvas.value.renderAll();
  }

  return { recalculateCanvasMask };
};
