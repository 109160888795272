<template>
  <MenuContentContainer
    :header="$t('edit_bg.duotone_presets')"
    :padding-left-right="paddingLeftRight || 20"
  >
    <ContentRow class="flex-wrap !gap-3">
      <FilterBox
        :title="$t('edit_bg.original')"
        :active="selectedDuotoneIndex === -1"
        @click="resetFilter"
      >
        <template #withText>
          <BanIcon />
          <span class="text-gray-200">{{ $t("default") }}</span>
        </template>
      </FilterBox>
      <FilterBox
        v-for="(d, index) in predefinedDuotone"
        :key="d.toString()"
        :active="selectedDuotoneIndex === index"
        @click="selectFilter(index)"
      >
        <img
          :src="previews[index] || './assets/img/placeholder.jpg'"
          class="min-h-[60px] object-cover"
        />
      </FilterBox>
    </ContentRow>
  </MenuContentContainer>
  <MenuContentContainer
    v-if="duotone.length === 2"
    border-full
    :padding-left-right="paddingLeftRight || 20"
  >
    <ContentRow block>
      <color-picker
        :label="$t('edit_bg.shadows_color')"
        color-picker-bottom
        :default-color="duotone[0]"
        flex
        @color-change="changeColor(0, $event)"
      ></color-picker>
    </ContentRow>
    <ContentRow block>
      <color-picker
        :label="$t('edit_bg.highlights_color')"
        color-picker-bottom
        :default-color="duotone[1]"
        flex
        @color-change="changeColor(1, $event)"
      ></color-picker>
    </ContentRow>
  </MenuContentContainer>
</template>

<script setup lang="ts">
  import colorPicker from "@/ui/TColorPicker/colorPicker.vue";
  import useDuotone from "./uses/useDuotone";

  const props = defineProps<{
    canvas: any;
    activePicture?: any;
    paddingLeftRight?: number;
  }>();

  const {
    selectedDuotoneIndex,
    duotone,
    previews,
    predefinedDuotone,
    selectFilter,
    changeColor,
    resetFilter,
    getData,
  } = useDuotone(props.canvas, props.activePicture);

  onMounted(() => {
    window.$event.on("getDuotone", () => {
      getData();
    });
  });

  onBeforeUnmount(() => {
    window.$event.off("getDuotone");
  });
</script>
