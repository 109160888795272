<template>
  <div>
    <ul class="colors">
      <li
        v-for="item in colorsDefault"
        :key="item"
        class="item"
        @click="selectColor(item)"
      >
        <div :style="{ background: `url(${imgAlphaBase64})` }" class="alpha" />
        <div :style="{ background: item }" class="color" />
      </li>
    </ul>
  </div>
</template>

<script>
import mixin from "./mixin";
export default {
  mixins: [mixin],
  props: {
    color: {
      type: String,
      default: "#000000",
    },
    colorsDefault: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      imgAlphaBase64: "",
    };
  },
  methods: {
    selectColor(color) {
      this.$emit("selectColor", color);
    },
  },
  created() {
    this.imgAlphaBase64 = this.createAlphaSquare(4).toDataURL();
  },
};
</script>

<style lang="scss">
.colors {
  padding: 0;
  margin: 0;
  &.history {
    margin-top: 10px;
    border-top: 1px solid #2e333a;
  }
  .item {
    position: relative;
    width: 16px;
    height: 16px;
    margin: 10px 0 0 10px;
    border-radius: 4px;
    box-sizing: border-box;
    vertical-align: top;
    display: inline-block;
    transition: all 0.1s;
    cursor: pointer;
    &:nth-child(7n + 1) {
      margin-left: 0;
    }
    &:hover {
      transform: scale(1.4);
    }
    .alpha {
      height: 100%;
      border-radius: 3px; // 大一像素，否则四个角会看到白点
    }
    .color {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      border-radius: 3px;
    }
  }
}
</style>
