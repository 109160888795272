import { fabric } from "fabric";
import useSnaps from "./useMoveAndScale/useSnaps";
import useResize from "./useMoveAndScale/useResize";
import useScaleImage from "./useMoveAndScale/useScaleImage";
import { ISnapObjectX, ISnapObjectY } from "types/Snap/Snap";

export default (
  canvas: Ref<fabric.Canvas>,
  snapTopLine: Ref<fabric.Line>,
  snapBottomLine: Ref<fabric.Line>,
  snapLeftLine: Ref<fabric.Line>,
  snapRightLine: Ref<fabric.Line>,
  snapCenterHorizontalLine: Ref<fabric.Line>,
  snapCenterVerticalLine: Ref<fabric.Line>
) => {
  const snapDistance = 10 / canvas.value.getZoom();
  const snapZoresX: fabric.Line[] = [];
  const snapZoresY: fabric.Line[] = [];

  const { snapToObjects, snapToObjectsNotFromCenter } = useSnaps(
    canvas,
    snapDistance
  );
  const {
    getd,
    getdtop,
    getdleft,
    getLeftFromBottom,
    getTopFromLeft,
    getTopFromRight,
    getLeftFromTop,
    getWidthFromBottom,
    getWidthFromLeft,
    getWidthFromRight,
    getWidthFromTop,
  } = useResize();
  const { scaleImage } = useScaleImage();

  function snapTo(obj: fabric.Object) {
    const { sortSnapingObjectX, sortSnapingObjectY } = snapToObjects(obj);

    snapingToX(obj, sortSnapingObjectX);
    snapingToY(obj, sortSnapingObjectY);
  }

  function snapingToX(obj: fabric.Object, snapingX: ISnapObjectX[]) {
    hideXSnaps();
    snapingX.map((s, i) => {
      if (i === 0) {
        obj.set("left", s.left);
        obj.setCoords();
      }
      if (Math.round(s.distance * 1000) === 0 || i === 0) {
        showLinesX(s, obj);
      }
    });
  }

  function snapingToY(obj: fabric.Object, snapingY: ISnapObjectY[]) {
    hideYSnaps();

    snapingY.map((s, i) => {
      if (i === 0) {
        obj.set("top", s.top);
        obj.setCoords();
        if (Math.round(s.distance * 1000) === 0 || i === 0) {
          showLinesY(s, obj);
        }
      }
    });
  }

  function showLinesX(s: ISnapObjectX, obj: fabric.Object) {
    if (s.object.id && s.object.id === "snapLineLeft") {
      snapLeftLine.value.set("opacity", 1);
    } else if (s.object.id && s.object.id === "snapLineCenterX") {
      snapCenterVerticalLine.value.set("opacity", 1);
    } else if (s.object.id && s.object.id === "snapLineRight") {
      snapRightLine.value.set("opacity", 1);
    } else {
      const topOfObject = obj.getBoundingRect(true, true).top;
      const topOfSnap = s.object.getBoundingRect(true, true).top;
      const topOfLine = topOfObject < topOfSnap ? topOfObject : topOfSnap;
      const heightOfSnap =
        topOfObject < topOfSnap
          ? topOfSnap +
            s.object.getBoundingRect(true, true).height -
            topOfObject
          : topOfObject + obj.getBoundingRect(true, true).height - topOfSnap;
      const snapZeroX = new fabric.Line([0, 0, 0, heightOfSnap], {
        top: topOfLine,
        left: s.x,
        stroke: "#FF9900",
        strokeWidth: 2,
        selectable: false,
        evented: false,
        //@ts-expect-error
        id: "snapObjectLineX",
      });
      canvas.value.add(snapZeroX);
      canvas.value.renderAll();
      snapZoresX.push(snapZeroX);
    }
  }

  function showLinesY(s: ISnapObjectY, obj: fabric.Object) {
    if (s.object.id && s.object.id === "snapLineTop") {
      snapTopLine.value.set("opacity", 1);
    } else if (s.object.id && s.object.id === "snapLineCenterY") {
      snapCenterHorizontalLine.value.set("opacity", 1);
    } else if (s.object.id && s.object.id === "snapLineBottom") {
      snapBottomLine.value.set("opacity", 1);
    } else {
      const leftOfObject = obj.getBoundingRect(true, true).left;
      const leftOfSnap = s.object.getBoundingRect(true, true).left;
      const leftOfLine = leftOfObject < leftOfSnap ? leftOfObject : leftOfSnap;
      const widthOfSnap =
        leftOfObject < leftOfSnap
          ? leftOfSnap +
            s.object.getBoundingRect(true, true).width -
            leftOfObject
          : leftOfObject + obj.getBoundingRect(true, true).width - leftOfSnap;
      const snapZeroY = new fabric.Line([0, 0, widthOfSnap, 0], {
        top: s.y,
        left: leftOfLine,
        stroke: "#FF9900",
        strokeWidth: 2,
        selectable: false,
        evented: false,
        //@ts-expect-error
        id: "snapObjectLineY",
      });
      canvas.value.add(snapZeroY);
      canvas.value.renderAll();
      snapZoresY.push(snapZeroY);
    }
  }

  function hideAllSnaps() {
    hideXSnaps();
    hideYSnaps();
  }

  function hideXSnaps() {
    snapLeftLine.value.set("opacity", 0);
    snapRightLine.value.set("opacity", 0);
    snapCenterVerticalLine.value.set("opacity", 0);
    if (snapZoresX.length > 0) {
      snapZoresX.map((s) => {
        canvas.value.remove(s);
        canvas.value.renderAll();
      });
      snapZoresX.length = 0;
    }
  }

  function hideYSnaps() {
    snapTopLine.value.set("opacity", 0);
    snapBottomLine.value.set("opacity", 0);
    snapCenterHorizontalLine.value.set("opacity", 0);
    if (snapZoresY.length > 0) {
      snapZoresY.map((s) => {
        canvas.value.remove(s);
        canvas.value.renderAll();
      });
      snapZoresY.length = 0;
    }
  }

  function resizeAndSnap(event: any) {
    const transform = event.transform;
    const obj = event.target;
    const alpha = obj.angle || 0;

    hideAllSnaps();

    const { sortSnapingObjectX, sortSnapingObjectY } =
      snapToObjectsNotFromCenter(obj);

    const filterSnapingX = sortSnapingObjectX.filter((s) => {
      if (alpha === 90 || alpha === 270) {
        return;
      }
      if (
        (transform.originX === "right" && (alpha < 90 || alpha > 270)) ||
        (transform.originX === "left" && alpha > 90 && alpha < 270)
      ) {
        if (s.from === "left" && Math.round(s.distance * 1000) !== 0) {
          return s;
        }
      } else {
        if (s.from === "right" && Math.round(s.distance * 1000) !== 0) {
          return s;
        }
      }
    });
    const filterSnapingY = sortSnapingObjectY.filter((s) => {
      if (alpha === 0 || alpha === 180) {
        return;
      }
      if (
        (transform.originX === "right" && alpha < 180) ||
        (transform.originX === "left" && alpha > 180)
      ) {
        if (s.from === "top" && Math.round(s.distance * 1000) !== 0) {
          return s;
        }
      } else {
        if (s.from === "bottom" && Math.round(s.distance * 1000) !== 0) {
          return s;
        }
      }
    });

    sortSnapingObjectX.map((s) => {
      if (Math.round(s.distance * 1000) === 0) {
        showLinesX(s, obj);
      }
    });
    sortSnapingObjectY.map((s) => {
      if (Math.round(s.distance * 1000) === 0) {
        showLinesY(s, obj);
      }
    });

    if (filterSnapingX.length === 0 && filterSnapingY.length === 0) {
      return;
    }

    if (filterSnapingX.length > 0 && filterSnapingY.length > 0) {
      if (filterSnapingX[0].distance < filterSnapingY[0].distance) {
        resizeToX(obj, filterSnapingX[0], transform.originX);
      } else {
        resizeToY(obj, filterSnapingY[0], transform.originX);
      }
    } else if (filterSnapingX.length > 0) {
      resizeToX(obj, filterSnapingX[0], transform.originX);
    } else {
      resizeToY(obj, filterSnapingY[0], transform.originX);
    }
  }

  function resizeToX(
    obj: fabric.Object,
    snap: ISnapObjectX,
    originX: "left" | "right"
  ) {
    showLinesX(snap, obj);
    const alpha = obj.angle || 0;
    const d = getd(alpha, snap.distance);
    const polarity = Math.floor(alpha / 90) % 2 === 0 ? -1 : 1;

    if (
      (originX === "right" && (alpha < 90 || alpha > 270)) ||
      (originX === "left" && alpha > 90 && alpha < 270)
    ) {
      obj.width =
        ((obj.width || 0) * (obj.scaleX || 1) - d.dx * snap.direction) /
        (obj.scaleX || 1);
      const dtop = getdtop(obj, snap, 1);
      obj.left = snap.x + obj.getBoundingRect(true, true).width / 2;
      obj.top = (obj.top || 0) + dtop.x * polarity;
    } else {
      obj.width =
        ((obj.width || 0) * (obj.scaleX || 1) + d.dx * snap.direction) /
        (obj.scaleX || 1);
      const dtop = getdtop(obj, snap, -1);
      obj.left = snap.x - obj.getBoundingRect(true, true).width / 2;
      obj.top = (obj.top || 0) + dtop.x * polarity;
    }
    obj.setCoords();
    canvas.value.renderAll();
  }

  function resizeToY(
    obj: fabric.Object,
    snap: ISnapObjectY,
    originX: "left" | "right"
  ) {
    showLinesY(snap, obj);
    const alpha = obj.angle || 0;
    const d = getd(alpha, snap.distance);
    const polarity = Math.floor(alpha / 90) % 2 === 0 ? -1 : 1;
    if (
      (originX === "right" && alpha < 180) ||
      (originX === "left" && alpha > 180)
    ) {
      obj.width =
        ((obj.width || 0) * (obj.scaleX || 0) - d.dy * snap.direction) /
        (obj.scaleX || 1);
      const dleft = getdleft(obj, snap, 1);
      obj.top = snap.y + obj.getBoundingRect(true, true).height / 2;
      obj.left = (obj.left || 0) + dleft.x * polarity;
    } else {
      obj.width =
        ((obj.width || 0) * (obj.scaleX || 0) + d.dy * snap.direction) /
        (obj.scaleX || 1);
      const dleft = getdleft(obj, snap, -1);
      obj.top = snap.y - obj.getBoundingRect(true, true).height / 2;
      obj.left = (obj.left || 0) + dleft.x * polarity;
    }
    obj.setCoords();
    canvas.value.renderAll();
  }

  function scalingAndSnap(event: any) {
    const transform = event.transform;
    const obj = event.target;

    hideAllSnaps();
    if (transform.corner.startsWith("m")) {
      scaleBySide(obj, transform);
    } else {
      scaleByCroner(obj, transform);
    }
  }

  function scaleBySide(obj: fabric.Object, transform: any) {
    const alpha = obj.angle || 0;
    const { sortSnapingObjectX, sortSnapingObjectY } =
      snapToObjectsNotFromCenter(obj);

    const filterSnapingX = sortSnapingObjectX.filter((s) => {
      if (transform.corner === "ml" || transform.corner === "mr") {
        if (alpha === 90 || alpha === 270) {
          return;
        }
        if (
          (transform.corner === "ml" && (alpha < 90 || alpha > 270)) ||
          (transform.corner === "mr" && alpha > 90 && alpha < 270)
        ) {
          if (s.from === "left" && Math.round(s.distance * 1000) !== 0) {
            return s;
          }
        } else {
          if (s.from === "right" && Math.round(s.distance * 1000) !== 0) {
            return s;
          }
        }
      } else {
        if (alpha === 0 || alpha === 180) {
          return;
        }
        if (
          (transform.corner === "mb" && alpha < 180) ||
          (transform.corner === "mt" && alpha > 180)
        ) {
          if (s.from === "left" && Math.round(s.distance * 1000) !== 0) {
            return s;
          }
        } else {
          if (s.from === "right" && Math.round(s.distance * 1000) !== 0) {
            return s;
          }
        }
      }
    });
    const filterSnapingY = sortSnapingObjectY.filter((s) => {
      if (transform.corner === "ml" || transform.corner === "mr") {
        if (alpha === 0 || alpha === 180) {
          return;
        }
        if (
          (transform.corner === "ml" && alpha < 180) ||
          (transform.corner === "mr" && alpha > 180)
        ) {
          if (s.from === "top" && Math.round(s.distance * 1000) !== 0) {
            return s;
          }
        } else {
          if (s.from === "bottom" && Math.round(s.distance * 1000) !== 0) {
            return s;
          }
        }
      } else {
        if (alpha === 90 || alpha === 270) {
          return;
        }
        if (
          (transform.corner === "mt" && (alpha < 90 || alpha > 270)) ||
          (transform.corner === "mb" && alpha > 90 && alpha < 270)
        ) {
          if (s.from === "top" && Math.round(s.distance * 1000) !== 0) {
            return s;
          }
        } else {
          if (s.from === "bottom" && Math.round(s.distance * 1000) !== 0) {
            return s;
          }
        }
      }
    });

    sortSnapingObjectX.map((s) => {
      if (Math.round(s.distance * 1000) === 0) {
        showLinesX(s, obj);
      }
    });
    sortSnapingObjectY.map((s) => {
      if (Math.round(s.distance * 1000) === 0) {
        showLinesY(s, obj);
      }
    });

    if (filterSnapingX.length === 0 && filterSnapingY.length === 0) {
      if (obj.type === "image") {
        scaleImage(obj, transform.corner);
        return;
      }
      return;
    }

    if (filterSnapingX.length > 0 && filterSnapingY.length > 0) {
      if (filterSnapingX[0].distance < filterSnapingY[0].distance) {
        scaleToXBySide(obj, filterSnapingX[0], transform.corner);
      } else {
        scaleToYBySide(obj, filterSnapingY[0], transform.corner);
      }
    } else if (filterSnapingX.length > 0) {
      scaleToXBySide(obj, filterSnapingX[0], transform.corner);
    } else {
      scaleToYBySide(obj, filterSnapingY[0], transform.corner);
    }
    if (obj.type === "image") {
      scaleImage(obj, transform.corner);
      return;
    }
  }

  function scaleToXBySide(
    obj: fabric.Object,
    snap: ISnapObjectX,
    corner: "ml" | "mr" | "mt" | "mb"
  ) {
    showLinesX(snap, obj);
    const alpha = obj.angle || 0;
    const d = getd(alpha, snap.distance);
    const polarity = Math.floor(alpha / 90) % 2 === 0 ? -1 : 1;
    if (corner === "ml" || corner === "mr") {
      if (
        (corner === "ml" && (alpha < 90 || alpha > 270)) ||
        (corner === "mr" && alpha > 90 && alpha < 270)
      ) {
        const newScale =
          ((obj.width || 0) * (obj.scaleX || 1) - d.dx * snap.direction) /
          (obj.width || 1);
        obj.scaleX = newScale;
        const dtop = getdtop(obj, snap, 1);

        obj.left = snap.x + obj.getBoundingRect(true, true).width / 2;
        if (alpha === 0 || alpha === 180) {
          return;
        }
        obj.top = (obj.top || 0) + dtop.x * polarity;
      } else {
        const newScale =
          ((obj.width || 0) * (obj.scaleX || 1) + d.dx * snap.direction) /
          (obj.width || 1);
        obj.scaleX = newScale;
        const dtop = getdtop(obj, snap, -1);

        obj.left = snap.x - obj.getBoundingRect(true, true).width / 2;
        if (alpha === 0 || alpha === 180) {
          return;
        }
        obj.top = (obj.top || 0) + dtop.x * polarity;
      }
    } else {
      if (
        (corner === "mb" && alpha < 180) ||
        (corner === "mt" && alpha > 180)
      ) {
        const newScale =
          ((obj.height || 0) * (obj.scaleY || 1) - d.dy * snap.direction) /
          (obj.height || 1);
        obj.scaleY = newScale;
        const dtop = getdtop(obj, snap, 1);

        obj.left = snap.x + obj.getBoundingRect(true, true).width / 2;
        if (alpha === 90 || alpha === 270) {
          return;
        }
        obj.top = (obj.top || 0) - dtop.y * polarity;
      } else {
        const newScale =
          ((obj.height || 0) * (obj.scaleY || 1) + d.dy * snap.direction) /
          (obj.height || 1);
        obj.scaleY = newScale;
        const dtop = getdtop(obj, snap, -1);

        obj.left = snap.x - obj.getBoundingRect(true, true).width / 2;
        if (alpha === 90 || alpha === 270) {
          return;
        }
        obj.top = (obj.top || 0) - dtop.y * polarity;
      }
    }
    obj.setCoords();
    canvas.value.renderAll();
  }

  function scaleToYBySide(
    obj: fabric.Object,
    snap: ISnapObjectY,
    corner: "ml" | "mr" | "mt" | "mb"
  ) {
    showLinesY(snap, obj);
    const alpha = obj.angle || 0;
    const d = getd(alpha, snap.distance);
    const polarity = Math.floor(alpha / 90) % 2 === 0 ? -1 : 1;
    if (corner === "ml" || corner === "mr") {
      if (
        (corner === "ml" && alpha < 180) ||
        (corner === "mr" && alpha > 180)
      ) {
        obj.scaleX =
          ((obj.width || 0) * (obj.scaleX || 0) - d.dy * snap.direction) /
          (obj.width || 1);
        const dleft = getdleft(obj, snap, 1);

        obj.top = snap.y + obj.getBoundingRect(true, true).height / 2;
        if (alpha === 90 || alpha === 270) {
          return;
        }
        obj.left = (obj.left || 0) + dleft.x * polarity;
      } else {
        obj.scaleX =
          ((obj.width || 0) * (obj.scaleX || 0) + d.dy * snap.direction) /
          (obj.width || 1);
        const dleft = getdleft(obj, snap, -1);

        obj.top = snap.y - obj.getBoundingRect(true, true).height / 2;
        if (alpha === 90 || alpha === 270) {
          return;
        }
        obj.left = (obj.left || 0) + dleft.x * polarity;
      }
    } else {
      if (
        (corner === "mt" && (alpha < 90 || alpha > 270)) ||
        (corner === "mb" && alpha > 90 && alpha < 270)
      ) {
        obj.scaleY =
          ((obj.height || 0) * (obj.scaleY || 0) - d.dx * snap.direction) /
          (obj.height || 1);
        const dleft = getdleft(obj, snap, 1);

        obj.top = snap.y + obj.getBoundingRect(true, true).height / 2;
        if (alpha === 0 || alpha === 180) {
          return;
        }
        obj.left = (obj.left || 0) - dleft.y * polarity;
      } else {
        obj.scaleY =
          ((obj.height || 0) * (obj.scaleY || 0) + d.dx * snap.direction) /
          (obj.height || 1);
        const dleft = getdleft(obj, snap, -1);

        obj.top = snap.y - obj.getBoundingRect(true, true).height / 2;
        if (alpha === 0 || alpha === 180) {
          return;
        }
        obj.left = (obj.left || 0) - dleft.y * polarity;
      }
    }
    obj.setCoords();
    canvas.value.renderAll();
  }

  function scaleByCroner(obj: fabric.Object, transform: any) {
    const { sortSnapingObjectX, sortSnapingObjectY } =
      snapToObjectsNotFromCenter(obj);

    const filterSnapingX = sortSnapingObjectX.filter(
      (s) => Math.round(s.distance * 1000) !== 0
    );
    const filterSnapingY = sortSnapingObjectY.filter(
      (s) => Math.round(s.distance * 1000) !== 0
    );

    sortSnapingObjectX.map((s) => {
      if (Math.round(s.distance * 1000) === 0) {
        showLinesX(s, obj);
      }
    });
    sortSnapingObjectY.map((s) => {
      if (Math.round(s.distance * 1000) === 0) {
        showLinesY(s, obj);
      }
    });

    if (filterSnapingX.length === 0 && filterSnapingY.length === 0) {
      return;
    }

    if (filterSnapingX.length > 0 && filterSnapingY.length > 0) {
      if (filterSnapingX[0].distance < filterSnapingY[0].distance) {
        scaleToX(obj, filterSnapingX[0], transform.corner);
      } else {
        scaleToY(obj, filterSnapingY[0], transform.corner);
      }
    } else if (filterSnapingX.length > 0) {
      scaleToX(obj, filterSnapingX[0], transform.corner);
    } else {
      scaleToY(obj, filterSnapingY[0], transform.corner);
    }
  }

  function scaleToX(
    obj: fabric.Object,
    snap: ISnapObjectX,
    corner: "tl" | "tr" | "bl" | "br"
  ) {
    showLinesX(snap, obj);
    const width = obj.width || 1;
    const fromRight =
      Math.abs(snap.x - obj.getBoundingRect(true, true).left) <= snapDistance;

    if (fromRight) {
      const newWidth = getWidthFromRight(obj, snap, corner);
      const scaleX = newWidth / width;
      const divisor = width * (obj.scaleX || 1);
      const scaleY = (newWidth * (obj.scaleY || 1)) / (divisor || 1);
      obj.scaleX = scaleX;
      obj.scaleY = scaleY;
      const newTop = getTopFromRight(
        obj,
        corner,
        (obj.left || 0) - (snap.x + obj.getBoundingRect(true, true).width / 2)
      );

      obj.left = snap.x + obj.getBoundingRect(true, true).width / 2;
      obj.top = newTop;
    } else {
      const newWidth = getWidthFromLeft(obj, snap, corner);
      const scaleX = newWidth / width;
      const divisor = width * (obj.scaleX || 1);
      const scaleY = (newWidth * (obj.scaleY || 1)) / (divisor || 1);
      obj.scaleX = scaleX;
      obj.scaleY = scaleY;
      const newTop = getTopFromLeft(
        obj,
        corner,
        (obj.left || 0) - (snap.x - obj.getBoundingRect(true, true).width / 2)
      );

      obj.left = snap.x - obj.getBoundingRect(true, true).width / 2;
      obj.top = newTop;
    }
    obj.setCoords();
    canvas.value.renderAll();
  }

  function scaleToY(
    obj: fabric.Object,
    snap: ISnapObjectY,
    corner: "tl" | "tr" | "bl" | "br"
  ) {
    showLinesY(snap, obj);
    const width = obj.width || 1;
    const fromBottom =
      Math.abs(snap.y - obj.getBoundingRect(true, true).top) <= snapDistance;

    if (fromBottom) {
      const newWidth = getWidthFromBottom(obj, snap, corner);
      const scaleX = newWidth / width;
      const divisor = width * (obj.scaleX || 1);
      const scaleY = (newWidth * (obj.scaleY || 1)) / (divisor || 1);
      obj.scaleX = scaleX;
      if (!corner.startsWith("m")) {
        obj.scaleY = scaleY;
      }
      const newLeft = getLeftFromBottom(
        obj,
        corner,
        (obj.top || 0) - (snap.y + obj.getBoundingRect(true, true).height / 2)
      );

      obj.top = snap.y + obj.getBoundingRect(true, true).height / 2;
      obj.left = newLeft;
    } else {
      const newWidth = getWidthFromTop(obj, snap, corner);
      const scaleX = newWidth / width;
      const divisor = width * (obj.scaleX || 1);
      const scaleY = (newWidth * (obj.scaleY || 1)) / (divisor || 1);
      obj.scaleX = scaleX;
      if (!corner.startsWith("m")) {
        obj.scaleY = scaleY;
      }
      const newLeft = getLeftFromTop(
        obj,
        corner,
        (obj.top || 0) - (snap.y - obj.getBoundingRect(true, true).height / 2)
      );

      obj.top = snap.y - obj.getBoundingRect(true, true).height / 2;
      obj.left = newLeft;
    }
    obj.setCoords();
    canvas.value.renderAll();
  }

  return {
    snapTo,
    hideAllSnaps,
    resizeAndSnap,
    scalingAndSnap,
  };
};
