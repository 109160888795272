<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 2.00049C10.4142 2.00049 10.75 2.33627 10.75 2.75049V7.00006H17C17.5523 7.00006 18 7.44778 18 8.00006V12.0001C18 12.5523 17.5523 13.0001 17 13.0001H10.75V17.2496C10.75 17.6639 10.4142 17.9996 10 17.9996C9.58579 17.9996 9.25 17.6639 9.25 17.2496V13.0001H3C2.44772 13.0001 2 12.5523 2 12.0001V8.00006C2 7.44778 2.44772 7.00006 3 7.00006H9.25V2.75049C9.25 2.33627 9.58579 2.00049 10 2.00049Z"
      fill="currentColor"
    />
  </svg>
</template>
