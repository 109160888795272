import { fabric } from "fabric";
import getAlphaFromHex from "@/utils/getAlphaFromHex";
import getAlphaToHex from "@/utils/getAlphaToHex";

export default (
  canvas: Ref<fabric.Canvas>,
  element: fabric.Object,
  from: string
) => {
  const stateStore = useStateStore();
  const type = ref(element.type);
  const fillColor = ref(
    element.fill
      ? element.fill.toString().length === 9
        ? element.fill.toString().slice(0, 7)
        : element.fill.toString()
      : ""
  );
  const fillAlpha = ref(
    Math.round(getAlphaFromHex(element.fill?.toString() || "") * 100)
  );
  const width = ref(Math.round((element.width || 0) * (element.scaleX || 1)));
  const height = ref(Math.round((element.height || 0) * (element.scaleY || 1)));
  const angle = ref(Math.round(element.angle || 0));
  //@ts-expect-error
  const borderRadius = ref(getRX(element.rx || 0, element.scaleX || 1));
  const borderColor = ref(element.stroke || "");
  const borderWidth = ref(getBorderWidth(element.strokeWidth || 0));
  const borderStyle = ref(element.strokeDashArray || []);
  //@ts-expect-error
  const keepProposition = ref(element._keepProposition || true);

  function emitChange() {
    window.$event.emit(
      from === "layer" ? "updateToolbarValues" : "updatePanelValues"
    );
    if (stateStore.group.selection) {
      stateStore.group.selection.addWithUpdate();
    }
  }

  function changeFillColor(props: { color?: string; alpha?: number }) {
    let color = props.color || fillColor.value;
    const a = props.alpha || fillAlpha.value;
    if (a / 100 !== 1) {
      const alpha = getAlphaToHex(a / 100);
      color = color + alpha;
    }
    element.set("fill", color);
    canvas.value.requestRenderAll();
    fillColor.value = props.color || fillColor.value;
    fillAlpha.value = a;
    emitChange();
  }

  function changeWidth(newWidth: number) {
    if (keepProposition.value) {
      const newHeight = (newWidth * height.value) / width.value;
      element.set("scaleY", newHeight / (element.height || 0));
    }
    element.set("scaleX", newWidth / (element.width || 0));
    canvas.value.requestRenderAll();
    width.value = newWidth;
    emitChange();
  }

  function changeHeight(newHeight: number) {
    if (keepProposition.value) {
      const newWidth = (newHeight * width.value) / height.value;
      element.set("scaleX", newWidth / (element.width || 0));
    }
    element.set("scaleY", newHeight / (element.height || 0));
    canvas.value.requestRenderAll();
    height.value = newHeight;
    emitChange();
  }

  function changeAngle(newAngle: number) {
    element.set("angle", newAngle);
    canvas.value.requestRenderAll();
    angle.value = newAngle;
    emitChange();
  }

  function changeBorderRadius(newRadius: number) {
    const radius =
      (Math.min(width.value, height.value) / 2) * (newRadius / 100);
    //@ts-expect-error
    element.set("rx", radius / (element.scaleX || 0));
    //@ts-expect-error
    element.set("ry", radius / (element.scaleY || 0));
    canvas.value.requestRenderAll();
    borderRadius.value = newRadius;
    emitChange();
  }

  function changeBorderColor(newColor: string) {
    element.set("stroke", newColor);
    canvas.value.requestRenderAll();
    borderColor.value = newColor;
    emitChange();
  }

  function changeBorderWidth(newWidth: number) {
    const bWidth = (Math.min(width.value, height.value) / 2) * (newWidth / 100);
    element.set("strokeWidth", bWidth);
    canvas.value.requestRenderAll();
    borderWidth.value = newWidth;
    emitChange();
  }

  function changeKeepProposition() {
    //@ts-expect-error
    element.set("_keepProposition", !keepProposition.value);
    canvas.value.requestRenderAll();
    keepProposition.value = !keepProposition.value;
    emitChange();
  }

  function flipHorizontal() {
    element.set("flipX", !element.flipX);
    canvas.value.requestRenderAll();
    emitChange();
  }

  function flipVertical() {
    element.set("flipY", !element.flipY);
    canvas.value.requestRenderAll();
    emitChange();
  }

  function updateValues() {
    if (!canvas || !canvas.value) return;
    const actElement = canvas.value.getActiveObject();
    if (!actElement) return;
    fillColor.value = actElement.fill
      ? actElement.fill.toString().length === 9
        ? actElement.fill.toString().slice(0, 7)
        : actElement.fill.toString()
      : "";
    fillAlpha.value = Math.round(
      getAlphaFromHex(actElement.fill?.toString() || "") * 100
    );
    width.value = Math.round(
      (actElement.width || 0) * (actElement.scaleX || 0)
    );
    height.value = Math.round(
      (actElement.height || 0) * (actElement.scaleY || 0)
    );
    angle.value = Math.round(actElement.angle || 0);
    //@ts-expect-error
    borderRadius.value = getRX(actElement.rx || 0, actElement.scaleX || 1);
    borderColor.value = actElement.stroke || "";
    borderWidth.value = getBorderWidth(actElement.strokeWidth || 0);
  }

  function getRX(rx: number, scaleX: number = 1) {
    return Math.round(
      ((rx * scaleX) / (Math.min(width.value, height.value) / 2)) * 100
    );
  }

  function getBorderWidth(bwidth: number) {
    return Math.round(
      (bwidth / (Math.min(width.value, height.value) / 2)) * 100
    );
  }

  return {
    type,
    width,
    height,
    angle,
    borderRadius,
    borderColor,
    borderWidth,
    borderStyle,
    keepProposition,
    fillColor,
    fillAlpha,
    changeFillColor,
    changeWidth,
    changeHeight,
    changeAngle,
    changeBorderRadius,
    changeBorderColor,
    changeBorderWidth,
    changeKeepProposition,
    flipHorizontal,
    flipVertical,
    updateValues,
  };
};
