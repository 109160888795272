<template>
  <div>
    <MenuContentContainer
      v-if="type !== 'rect' && type !== 'circle'"
      :header="$t('edit.flip')"
    >
      <ContentRow>
        <TButton
          :title="$t('edit_bg.crop.flip_X')"
          class="h-[32px] w-[32px]"
          @click="flipHorizontal"
        >
          <template #icon>
            <FlipHorizontalIcon />
          </template>
        </TButton>
        <TButton
          :title="$t('edit_bg.crop.flip_Y')"
          class="h-[32px] w-[32px]"
          @click="flipVertical"
        >
          <template #icon>
            <FlipVerticalIcon />
          </template>
        </TButton>
      </ContentRow>
    </MenuContentContainer>
    <MenuContentContainer :header="$t('edit.size')">
      <ContentRow class="!gap-x-1">
        <TInputNumber
          :model-value="width"
          :label="$t('dimensions.w')"
          flex
          :max="Infinity"
          :min="0"
          :controls="false"
          label-in
          @update:model-value="changeWidth"
        />
        <span class="font-inter text-xs font-medium text-gray-200">×</span>
        <TInputNumber
          :model-value="height"
          :label="$t('dimensions.h')"
          flex
          :max="Infinity"
          :min="0"
          :controls="false"
          label-in
          @update:model-value="changeHeight"
        />
      </ContentRow>
      <ContentRow>
        <TSwitch
          :model-value="keepProposition"
          :label="$t('size.keep_proportion')"
          @update:model-value="changeKeepProposition"
        />
      </ContentRow>
      <ContentRow block>
        <TInputNumber
          :model-value="angle"
          :label="$t('edit.angle')"
          flex
          :max="360"
          :min="-360"
          unit="°"
          @update:model-value="changeAngle"
        />
      </ContentRow>
      <ContentRow v-if="type === 'rect'" block>
        <TInputNumber
          :model-value="borderRadius"
          :label="$t('edit.border_radius')"
          flex
          :max="100"
          :min="0"
          unit="%"
          @update:model-value="changeBorderRadius"
        />
      </ContentRow>
    </MenuContentContainer>
    <MenuContentContainer :header="$t('edit.fill')">
      <ContentRow block>
        <color-picker
          :label="$t('edit.color')"
          flex
          color-picker-top
          :default-color="fillColor"
          @color-change="changeFillColor({ color: $event })"
        ></color-picker>
      </ContentRow>
      <div class="mb-6 mt-2">
        <TSlider
          :label="$t('edit.opacity')"
          label-small
          :model-value="fillAlpha"
          :max="100"
          unit="%"
          @update:model-value="changeFillColor({ alpha: $event })"
        />
      </div>
    </MenuContentContainer>

    <MenuContentContainer :header="$t('edit.border')">
      <ContentRow block>
        <color-picker
          :label="$t('edit.color')"
          flex
          color-picker-top
          :default-color="borderColor"
          @color-change="changeBorderColor"
        ></color-picker>
      </ContentRow>
      <div class="mb-6 mt-2">
        <TSlider
          :label="$t('dimensions.width')"
          :model-value="borderWidth"
          :max="100"
          unit="%"
          :reset-value="100"
          @update:model-value="changeBorderWidth"
        />
      </div>
    </MenuContentContainer>
  </div>
</template>

<script setup lang="ts">
  import useElement from "@/components/LayerPanel/Types/useElement/useElement";
  import TButton from "@/ui/TButton/TButton.vue";
  import TInputNumber from "@/ui/TInputNumber/TInputNumber.vue";
  import TSwitch from "@/ui/TSwitch/TSwitch.vue";
  import TSlider from "@/ui/TSlider/TSlider.vue";
  // import TButtonGroup from "@/ui/TButtonGroup/TButtonGroup.vue";
  // import TGroupButton from "@/ui/TButtonGroup/_internal/TButton.vue";
  import colorPicker from "@/ui/TColorPicker/colorPicker.vue";
  // import TLabel from "@/ui/TLabel/TLabel.vue";

  const props = defineProps<{
    canvas: Ref<fabric.Canvas>;
    activeObject: fabric.Object;
  }>();
  const emits = defineEmits(["update"]);

  const {
    type,
    width,
    height,
    angle,
    borderRadius,
    borderColor,
    borderWidth,
    keepProposition,
    fillColor,
    fillAlpha,
    changeFillColor,
    changeWidth,
    changeHeight,
    changeAngle,
    changeBorderRadius,
    changeBorderColor,
    changeBorderWidth,
    changeKeepProposition,
    flipHorizontal,
    flipVertical,
    updateValues,
  } = useElement(props.canvas, props.activeObject, "toolbar");

  onMounted(() => {
    window.$event.on("updatePanelValues", () => {
      updateValues();
      emits("update");
    });
  });

  onUnmounted(() => {
    window.$event.off("updatePanelValues");
  });
</script>
