<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.8747 15.9681C19.2365 16.6533 18.7717 17.5 18.0338 17.5H11.9648C11.432 17.5 11 17.0398 11 16.472V3.01483C11 2.48592 11.6595 2.30186 11.9029 2.76284L18.8747 15.9681ZM12.5 7.10664L17.1953 16H12.5V7.10664Z"
      fill="currentColor"
    />
    <path
      d="M8.09712 2.76284C8.3405 2.30186 9 2.48592 9 3.01483V16.472C9 17.0398 8.56803 17.5 8.03517 17.5H1.96624C1.22831 17.5 0.763535 16.6533 1.12531 15.9681L8.09712 2.76284Z"
      fill="currentColor"
    />
  </svg>
</template>
