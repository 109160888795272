import { fabric } from "fabric";

export default (canvas: Ref<fabric.Canvas>) => {
  function initSnapLines(width: number, height: number) {
    const snapTop = new fabric.Line([0, 0, width, 0], {
      top: 0,
      left: 0,
      stroke: "#FF9900",
      strokeWidth: 2 / (canvas.value.getZoom() || 1),
      selectable: false,
      evented: false,
      opacity: 0,
      //@ts-expect-error
      id: "snapLineTop",
      _allowSnaping: true,
      _disalowSelecting: true,
    });
    const snapBottom = new fabric.Line([0, 0, width, 0], {
      top: height,
      left: 0,
      stroke: "#FF9900",
      strokeWidth: 2 / (canvas.value.getZoom() || 1),
      selectable: false,
      evented: false,
      opacity: 0,
      originY: "bottom",
      //@ts-expect-error
      id: "snapLineBottom",
      _allowSnaping: true,
      _disalowSelecting: true,
    });
    const snapCenterHorizontal = new fabric.Line([0, 0, width, 0], {
      top: height / 2,
      left: 0,
      stroke: "#FF9900",
      strokeWidth: 2 / (canvas.value.getZoom() || 1),
      selectable: false,
      evented: false,
      opacity: 0,
      //@ts-expect-error
      id: "snapLineCenterY",
      _allowSnaping: true,
      _disalowSelecting: true,
    });
    const snapLeft = new fabric.Line([0, 0, 0, height], {
      top: 0,
      left: 0,
      stroke: "#FF9900",
      strokeWidth: 2 / (canvas.value.getZoom() || 1),
      selectable: false,
      evented: false,
      opacity: 0,
      //@ts-expect-error
      id: "snapLineLeft",
      _allowSnaping: true,
      _disalowSelecting: true,
    });
    const snapRight = new fabric.Line([0, 0, 0, height], {
      top: 0,
      left: width,
      stroke: "#FF9900",
      strokeWidth: 2 / (canvas.value.getZoom() || 1),
      selectable: false,
      evented: false,
      opacity: 0,
      originX: "right",
      //@ts-expect-error
      id: "snapLineRight",
      _allowSnaping: true,
      _disalowSelecting: true,
    });
    const snapCenterVertical = new fabric.Line([0, 0, 0, height], {
      top: 0,
      left: width / 2,
      stroke: "#FF9900",
      strokeWidth: 2 / (canvas.value.getZoom() || 1),
      selectable: false,
      evented: false,
      opacity: 0,
      //@ts-expect-error
      id: "snapLineCenterX",
      _allowSnaping: true,
      _disalowSelecting: true,
    });
    canvas.value.add(markRaw(snapTop));
    canvas.value.add(markRaw(snapBottom));
    canvas.value.add(markRaw(snapCenterVertical));
    canvas.value.add(markRaw(snapLeft));
    canvas.value.add(markRaw(snapRight));
    canvas.value.add(markRaw(snapCenterHorizontal));
    canvas.value.renderAll();
    return {
      snapTop,
      snapBottom,
      snapCenterHorizontal,
      snapLeft,
      snapRight,
      snapCenterVertical,
    };
  }

  return {
    initSnapLines,
  };
};
