<template>
  <div>
    <MenuContentContainer
      v-if="type !== 'rect' && type !== 'circle'"
      :header="$t('edit.flip')"
      border-full
      :padding-left-right="12"
    >
      <ContentRow class="!gap-x-2">
        <TButton
          :title="$t('edit_bg.crop.flip_X')"
          class="h-[32px] w-[32px]"
          @click="flipHorizontal"
        >
          <template #icon>
            <FlipHorizontalIcon />
          </template>
        </TButton>
        <TButton
          :title="$t('edit_bg.crop.flip_Y')"
          class="h-[32px] w-[32px]"
          @click="flipVertical"
        >
          <template #icon>
            <FlipVerticalIcon />
          </template>
        </TButton>
      </ContentRow>
    </MenuContentContainer>
    <MenuContentContainer :header="$t('edit.size')" :padding-left-right="12">
      <ContentRow class="!gap-x-1">
        <TInputNumber
          :model-value="width"
          :label="$t('dimensions.w')"
          flex
          :max="Infinity"
          :min="0"
          :controls="false"
          label-in
          @update:model-value="changeWidth"
        />
        <span class="font-inter text-xs font-medium text-gray-200">×</span>
        <TInputNumber
          :model-value="height"
          :label="$t('dimensions.h')"
          flex
          :max="Infinity"
          :min="0"
          :controls="false"
          label-in
          @update:model-value="changeHeight"
        />
      </ContentRow>
      <ContentRow block>
        <TInputNumber
          :model-value="angle"
          :label="$t('edit.angle')"
          flex
          :max="360"
          :min="-360"
          unit="°"
          @update:model-value="changeAngle"
        />
      </ContentRow>
      <div class="mb-6 mt-2">
        <TSlider
          :label="$t('edit.opacity')"
          :model-value="opacity"
          :max="100"
          unit="%"
          :reset-value="100"
          @update:model-value="changeOpacity($event)"
        />
      </div>
    </MenuContentContainer>
    <Duotone
      :canvas="canvas"
      :active-picture="activeObject"
      :padding-left-right="12"
    />
    <Adjust
      :canvas="canvas"
      :active-picture="activeObject"
      :padding-left-right="12"
    />
    <MenuContentContainer :header="$t('picture.mask')" :padding-left-right="12">
      <template #header-action>
        <HeaderAction
          :title="showAllMasks ? $t('show_less') : $t('show_more')"
          @button-click="toggleShowAllMasks"
        >
          <PlusIcon v-if="!showAllMasks" />
          <MinusIcon v-else />
        </HeaderAction>
      </template>
      <div class="grid grid-cols-4 items-center justify-items-center gap-3">
        <FilterBox
          :title="$t('edit_bg.original')"
          :active="maskType === undefined"
          @click="resetMask"
        >
          <BanIcon />
        </FilterBox>
        <div
          v-for="mask in maskToShow"
          :key="mask.type"
          @click="addMaskToPicture(mask.type)"
        >
          <img
            :src="mask.preview || './assets/img/placeholder.jpg'"
            class="h-max-full"
            :class="{
              grayscale: maskType !== mask.type,
            }"
          />
        </div>
      </div>
    </MenuContentContainer>
  </div>
</template>

<script setup lang="ts">
  import useImage from "./useImage/useImage";
  import TButton from "@/ui/TButton/TButton.vue";
  import TInputNumber from "@/ui/TInputNumber/TInputNumber.vue";
  import TSlider from "@/ui/TSlider/TSlider.vue";

  const props = defineProps<{
    canvas: Ref<fabric.Canvas>;
    activeObject: fabric.Object;
  }>();
  const emits = defineEmits(["update"]);

  const showAllMasks = ref(false);

  const {
    type,
    width,
    height,
    angle,
    opacity,
    maskType,
    addMaskToPicture,
    resetMask,
    changeWidth,
    changeHeight,
    changeAngle,
    changeOpacity,
    flipHorizontal,
    flipVertical,
    updateValues,
  } = useImage(props.canvas, props.activeObject, "toolbar");
  const picturesStore = usePicturesStore();

  const masks = computed(() => {
    return picturesStore.masksPreview;
  });

  const maskToShow = computed(() => {
    return showAllMasks.value ? masks.value : masks.value.slice(0, 7);
  });

  function toggleShowAllMasks() {
    showAllMasks.value = !showAllMasks.value;
  }

  onMounted(() => {
    window.$event.on("updatePanelValues", () => {
      updateValues();
      emits("update");
    });
  });

  onUnmounted(() => {
    window.$event.off("updatePanelValues");
  });
</script>
