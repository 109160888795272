import { fabric } from "fabric";

export default (props: {
  bgImg: Ref<fabric.Image>;
  cropRect: Ref<fabric.Rect>;
  cropMask: Ref<fabric.Rect>;
  cropLeftFirstThird: Ref<fabric.Line>;
  cropLeftSecondThird: Ref<fabric.Line>;
  cropTopFirstThird: Ref<fabric.Line>;
  cropTopSecondThird: Ref<fabric.Line>;
}) => {
  function recalculateMask() {
    const c = new fabric.Rect({
      width: props.cropRect.value.width,
      height: props.cropRect.value.height,
      top:
        (props.cropRect.value.top || 0) -
        (props.cropRect.value.height || 0) / 2,
      left:
        (props.cropRect.value.left || 0) -
        (props.cropRect.value.width || 0) / 2,
      absolutePositioned: true,
    });
    c.inverted = true;
    props.cropMask.value.angle = props.bgImg.value.angle;
    props.cropMask.value.clipPath = c;
    props.cropMask.value.left = props.bgImg.value.left;
    props.cropMask.value.top = props.bgImg.value.top;
    props.cropLeftFirstThird.value.set({
      height: props.cropRect.value.height,
      top:
        (props.cropRect.value.top || 0) -
        (props.cropRect.value.height || 0) / 2,
      left:
        (props.cropRect.value.left || 0) -
        (props.cropRect.value.width || 0) / 2 +
        (props.cropRect.value.width || 0) / 3,
    });
    props.cropLeftSecondThird.value.set({
      height: props.cropRect.value.height,
      top:
        (props.cropRect.value.top || 0) -
        (props.cropRect.value.height || 0) / 2,
      left:
        (props.cropRect.value.left || 0) +
        (props.cropRect.value.width || 0) / 2 -
        (props.cropRect.value.width || 0) / 3,
    });
    props.cropTopFirstThird.value.set({
      width: props.cropRect.value.width || 0,
      left:
        (props.cropRect.value.left || 0) -
        (props.cropRect.value.width || 0) / 2,
      top:
        (props.cropRect.value.top || 0) -
        (props.cropRect.value.height || 0) / 2 +
        (props.cropRect.value.height || 0) / 3,
    });
    props.cropTopSecondThird.value.set({
      width: props.cropRect.value.width,
      left:
        (props.cropRect.value.left || 0) -
        (props.cropRect.value.width || 0) / 2,
      top:
        (props.cropRect.value.top || 0) +
        (props.cropRect.value.height || 0) / 2 -
        (props.cropRect.value.height || 0) / 3,
    });
  }
  return {
    recalculateMask,
  };
};
