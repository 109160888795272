<template>
  <div
    v-if="openLayer && activeObject"
    class="full-height w-[304px] bg-gray-700"
  >
    <PanelTitle
      :key="activeObject.type"
      :title="$t(`edit.title_${activeObject.type}`)"
      :show-close="true"
      @close="stateStore.openLayer = false"
    />
    <PerfectScrollbar ref="scroll" class="full-height-close">
      <MenuContentContainer
        v-if="!elementInGroup"
        :header="$t('edit.position')"
        :padding-left-right="12"
      >
        <ContentRow class="!gap-x-2">
          <TInputNumber
            v-model="left"
            :min="0"
            :max="props.canvas.value.width"
            label="X"
            label-in
            unit=""
            :controls="false"
            @update:model-value="setLeft"
          />
          <TInputNumber
            v-model="top"
            :min="0"
            :max="props.canvas.value.height"
            label="Y"
            label-in
            unit=""
            :controls="false"
            @update:model-value="setTop"
          />
        </ContentRow>
        <ContentRow class="!gap-x-2">
          <TButtonGroup>
            <TGroupButton
              data="left"
              data-testid="align-left"
              :title="$t('edit.align_left')"
              medium
              :active="halign === 'left'"
              @button-clicked="changeHAlign('left')"
            >
              <AligmentLeft />
            </TGroupButton>
            <TGroupButton
              data="middle"
              data-testid="align-center"
              :title="$t('edit.align_center')"
              medium
              :active="halign === 'center'"
              @button-clicked="changeHAlign('center')"
            >
              <AligmentCenter />
            </TGroupButton>
            <TGroupButton
              data="bottom"
              data-testid="align-right"
              :title="$t('edit.align_right')"
              medium
              :active="halign === 'right'"
              @button-clicked="changeHAlign('right')"
            >
              <AligmentRight />
            </TGroupButton>
          </TButtonGroup>
          <TButtonGroup>
            <TGroupButton
              data="top"
              data-testid="align-top"
              :title="$t('edit.align_top')"
              medium
              :active="valign === 'top'"
              @button-clicked="changeVAlign('top')"
            >
              <AligmentTop />
            </TGroupButton>
            <TGroupButton
              data="middle"
              data-testid="align-middle"
              :title="$t('edit.align_middle')"
              medium
              :active="valign === 'middle'"
              @button-clicked="changeVAlign('middle')"
            >
              <AligmentCenter class="rotate-90" />
            </TGroupButton>
            <TGroupButton
              data="bottom"
              data-testid="align-bottom"
              :title="$t('edit.align_bottom')"
              medium
              :active="valign === 'bottom'"
              @button-clicked="changeVAlign('bottom')"
            >
              <AligmentBottom />
            </TGroupButton>
          </TButtonGroup>
        </ContentRow>
      </MenuContentContainer>

      <MenuContentContainer
        v-if="!elementInGroup"
        :header="$t('edit.order')"
        border-full
        :padding-left-right="12"
      >
        <ContentRow>
          <TButtonGroup>
            <TGroupButton
              data="forward"
              data-testid="bring-forward"
              :title="$t('edit.bring_forward')"
              medium
              :disabled="disableForward"
              @button-clicked="brigForward()"
            >
              <ForwardIcon />
            </TGroupButton>
            <TGroupButton
              data="backwards"
              data-testid="send-backwards"
              :title="$t('edit.send_backwards')"
              medium
              :disabled="disableBackwards"
              @button-clicked="sendBackwards()"
            >
              <BackWardsIcon />
            </TGroupButton>
            <TGroupButton
              data="front"
              data-testid="bring-to-front"
              :title="$t('edit.bring_to_front')"
              medium
              :disabled="disableForward"
              @button-clicked="bringToFront()"
            >
              <FrontIcon />
            </TGroupButton>
            <TGroupButton
              data="back"
              data-testid="send-to-back"
              :title="$t('edit.send_to_back')"
              medium
              :disabled="disableBackwards"
              @button-clicked="sendToBack()"
            >
              <BackIcon />
            </TGroupButton>
          </TButtonGroup>
        </ContentRow>
      </MenuContentContainer>
      <TextPanel
        v-if="activeObject.type === 'text' || activeObject.type === 'textbox'"
        :canvas="props.canvas"
        :active-object="activeObject as fabric.Textbox"
        @update="getValues"
      />
      <ImagePanel
        v-else-if="activeObject.type === 'image'"
        :canvas="props.canvas"
        :active-object="activeObject as fabric.Image"
        @update="getValues"
      />
      <ElementPanel
        v-else
        :canvas="props.canvas"
        :active-object="activeObject"
        @update="getValues"
      />
    </PerfectScrollbar>
  </div>
</template>

<script setup lang="ts">
  import TButtonGroup from "@/ui/TButtonGroup/TButtonGroup.vue";
  import TGroupButton from "@/ui/TButtonGroup/_internal/TButton.vue";
  import TInputNumber from "@/ui/TInputNumber/TInputNumber.vue";
  import { fabric } from "fabric";

  const stateStore = useStateStore();
  const props = defineProps<{
    canvas: Ref<fabric.Canvas>;
    activeObject: fabric.Object;
  }>();

  const valign = ref("");
  const halign = ref("");
  const left = ref(0);
  const top = ref(0);
  const disableForward = ref(true);
  const disableBackwards = ref(true);
  const elementInGroup = ref(false);

  const openLayer = computed(() => {
    return stateStore.openLayer;
  });

  const activeGroupElement = computed(() => {
    return stateStore.activeGroupElement;
  });

  function getActObject() {
    elementInGroup.value = false;
    if (!props.canvas || !props.canvas.value) return;
    const activeObj = props.canvas.value.getActiveObject();
    //@ts-expect-error
    if (!activeObj || activeObj.id === "cropRect") return;
    if (
      (activeObj.type === "group" || activeObj.type === "activeSelection") &&
      activeGroupElement.value
    ) {
      elementInGroup.value = true;
      return activeGroupElement.value;
    }
    return activeObj;
  }

  function setLeft(value: number) {
    const actObj = getActObject();
    if (actObj) {
      actObj.set(
        "left",
        value + (actObj.getBoundingRect(true, true).width || 0) / 2
      );
      props.canvas.value.requestRenderAll();
      window.$event.emit("updatePanelValues");
    }
  }

  function setTop(value: number) {
    const actObj = getActObject();
    if (actObj) {
      actObj.set(
        "top",
        value + (actObj.getBoundingRect(true, true).height || 0) / 2
      );
      props.canvas.value.requestRenderAll();
      window.$event.emit("updatePanelValues");
    }
  }

  function changeHAlign(align: string) {
    const actObj = getActObject();
    if (actObj) {
      const objWidth = (actObj.width || 0) * (actObj.scaleX || 1);
      if (align === "left") {
        const cropLeft =
          //@ts-expect-error
          props.canvas.value.item(1).left -
          //@ts-expect-error
          props.canvas.value.item(1).width / 2;
        actObj.set("left", cropLeft + objWidth / 2);
        props.canvas.value.requestRenderAll();
      } else if (align === "center") {
        //@ts-expect-error
        actObj.set("left", props.canvas.value.item(1).left);
        props.canvas.value.requestRenderAll();
      } else if (align === "right") {
        const cropRight =
          //@ts-expect-error
          props.canvas.value.item(1).left +
          //@ts-expect-error
          props.canvas.value.item(1).width / 2;
        actObj.set("left", cropRight - objWidth / 2);
        props.canvas.value.requestRenderAll();
      }
      halign.value = align;
      window.$event.emit("updatePanelValues");
    }
  }

  function changeVAlign(align: string) {
    const actObj = getActObject();
    if (actObj) {
      const objHeight = (actObj.height || 0) * (actObj.scaleY || 1);
      if (align === "top") {
        const cropTop =
          //@ts-expect-error
          props.canvas.value.item(1).top -
          //@ts-expect-error
          props.canvas.value.item(1).height / 2;
        actObj.set("top", cropTop + objHeight / 2);
        props.canvas.value.requestRenderAll();
      } else if (align === "middle") {
        actObj
          //@ts-expect-error
          .set("top", props.canvas.value.item(1).top);
        props.canvas.value.requestRenderAll();
      } else if (align === "bottom") {
        const cropBottom =
          //@ts-expect-error
          props.canvas.value.item(1).top +
          //@ts-expect-error
          props.canvas.value.item(1).height / 2;
        actObj.set("top", cropBottom - objHeight / 2);
        props.canvas.value.requestRenderAll();
      }
      valign.value = align;
      window.$event.emit("updatePanelValues");
    }
  }

  function brigForward() {
    const actObj = getActObject();
    if (actObj) {
      actObj.bringForward();
      props.canvas.value.renderAll();
      window.$event.emit("reorderLayers");
      getReorder();
    }
  }

  function sendBackwards() {
    const actObj = getActObject();
    if (actObj) {
      actObj.sendBackwards();
      props.canvas.value.renderAll();
      window.$event.emit("reorderLayers");
      getReorder();
    }
  }

  function bringToFront() {
    const actObj = getActObject();
    if (actObj) {
      actObj.bringToFront();
      props.canvas.value.renderAll();
      window.$event.emit("reorderLayers");
      getReorder();
    }
  }

  function sendToBack() {
    const actObj = getActObject();
    if (actObj) {
      actObj.sendToBack();
      props.canvas.value.renderAll();
      window.$event.emit("reorderLayers");
      getReorder();
    }
  }

  function getValues() {
    const actObj = getActObject();
    if (actObj) {
      getHAlign();
      getVAlign();
      getReorder();
      left.value = Math.round(actObj.getBoundingRect(true, true).left);
      top.value = Math.round(actObj.getBoundingRect(true, true).top);
    }
  }

  function getHAlign() {
    const obj = props.canvas.value.getActiveObject();
    const cropper = props.canvas.value.item(1);

    if (!obj || !cropper) {
      return;
    }

    if (
      Math.round((obj.left || 0) * 1000) ===
      //@ts-expect-error
      Math.round((cropper.left || 0) * 1000)
    ) {
      halign.value = "center";
    } else if (
      Math.round(((obj.left || 0) - (obj.width || 0) / 2) * 1000) ===
      //@ts-expect-error
      Math.round((cropper.left - cropper.width / 2) * 1000)
    ) {
      halign.value = "left";
    } else if (
      Math.round(((obj.left || 0) + (obj.width || 0) / 2) * 1000) ===
      //@ts-expect-error
      Math.round((cropper.left + cropper.width / 2) * 1000)
    ) {
      halign.value = "right";
    } else {
      halign.value = "";
    }
  }

  function getVAlign() {
    const obj = props.canvas.value.getActiveObject();
    const cropper = props.canvas.value.item(1);

    if (!obj || !cropper) {
      return;
    }

    //@ts-expect-error
    if (Math.round((obj.top || 0) * 1000) === Math.round(cropper.top * 1000)) {
      valign.value = "middle";
    } else if (
      Math.round(((obj.top || 0) - (obj.height || 0) / 2) * 1000) ===
      //@ts-expect-error
      Math.round((cropper.top - (obj.height || 0) / 2) * 1000)
    ) {
      valign.value = "top";
    } else if (
      Math.round(((obj.top || 0) + (obj.height || 0) / 2) * 1000) ===
      //@ts-expect-error
      Math.round((cropper.top + cropper.height / 2) * 1000)
    ) {
      valign.value = "bottom";
    } else {
      valign.value = "";
    }
  }

  function getReorder() {
    const obj = getActObject();
    if (!obj) {
      return;
    }
    const objects = props.canvas.value
      .getObjects()
      .filter((item: any) => item._ableToOrder === true);
    const objIndex = objects.indexOf(obj);
    let objsUnder = false;
    let objsAbove = false;
    objects.map((item: fabric.Object, index: number) => {
      if (index < objIndex) {
        if (item.intersectsWithObject(obj)) {
          objsUnder = true;
        }
      } else if (index > objIndex) {
        if (obj.intersectsWithObject(item)) {
          objsAbove = true;
        }
      }
    });
    disableForward.value = !objsAbove;
    disableBackwards.value = !objsUnder;
  }

  watch(openLayer, (value) => {
    if (value) {
      getValues();
    }
  });

  onMounted(() => {
    getValues();
  });
</script>

<style scoped>
  .full-height-close {
    height: calc(100vh - 48px - 42px);
  }
</style>
