<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.125 4.5C16.125 4.99219 15.7148 5.375 15.25 5.375H9.125C8.63281 5.375 8.25 4.99219 8.25 4.5C8.25 4.03516 8.63281 3.625 9.125 3.625H15.25C15.7148 3.625 16.125 4.03516 16.125 4.5ZM16.125 11.5C16.125 11.9922 15.7148 12.375 15.25 12.375H9.125C8.63281 12.375 8.25 11.9922 8.25 11.5C8.25 11.0352 8.63281 10.625 9.125 10.625H15.25C15.7148 10.625 16.125 11.0352 16.125 11.5ZM3.875 8C3.875 7.53516 4.25781 7.125 4.75 7.125H15.25C15.7148 7.125 16.125 7.53516 16.125 8C16.125 8.49219 15.7148 8.875 15.25 8.875H4.75C4.25781 8.875 3.875 8.49219 3.875 8ZM16.125 15C16.125 15.4922 15.7148 15.875 15.25 15.875H4.75C4.25781 15.875 3.875 15.4922 3.875 15C3.875 14.5352 4.25781 14.125 4.75 14.125H15.25C15.7148 14.125 16.125 14.5352 16.125 15Z"
      fill="currentColor"
    />
  </svg>
</template>
