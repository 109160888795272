import { fabric } from "fabric";

export default (canvas: Ref<fabric.Canvas>) => {
  const cropStore = useCropStore();
  function initBackgroundImage(oImg: any) {
    const bgImg = oImg.set({
      hasControls: false,
      lockMovementX: true,
      lockMovementY: true,
      hoverCursor: "default",
      selectable: false,
      originX: "center",
      originY: "center",
      id: "background",
      _disalowSelecting: true,
    });
    bgImg.filters = [];

    bgImg.filters.push(new fabric.Image.filters.Invert({ invert: false }));
    bgImg.filters.push(new fabric.Image.filters.Brightness({ brightness: 0 }));
    bgImg.filters.push(new fabric.Image.filters.Saturation({ saturation: 0 }));
    bgImg.filters.push(new fabric.Image.filters.Contrast({ contrast: 0 }));
    bgImg.filters.push(new fabric.Image.filters.HueRotation({ rotation: 0 }));
    bgImg.filters.push(new fabric.Image.filters.Noise({ noise: 0 }));
    bgImg.filters.push(new fabric.Image.filters.Blur({ blur: 0 }));
    bgImg.filters.push(
      new fabric.Image.filters.Convolute({
        matrix: [0, 0, 0, 0, 1, 0, 0, 0, 0],
      })
    );

    bgImg.applyFilters();

    bgImg.set({
      left: (canvas.value.width || 0) / 2,
      top: (canvas.value.height || 0) / 2,
    });

    bgImg.angle = cropStore.rotate;

    canvas.value.add(markRaw(bgImg));
    canvas.value.renderAll();
    return bgImg;
  }

  return { initBackgroundImage };
};
