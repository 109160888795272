<template>
  <PanelTitle :title="$t('uploads.title')" />
  <input
    ref="addNewImage"
    type="file"
    name="image"
    multiple
    accept="image/*"
    style="display: none"
    @change="uploadImage"
  />
  <MenuContentContainer :border-bottom="false">
    <ContentRow>
      <TButton class="min-h-[40px] w-full" success @click="addNewImage.click()">
        <template #icon>
          <UploadIcon width="18" height="18" />
        </template>
        {{ $t("pictures.upload") }}
      </TButton>
    </ContentRow>
  </MenuContentContainer>
  <MenuContentContainer v-if="pictures.length > 0" :border-bottom="false">
    <div class="flex grid-cols-4 flex-row gap-3 lg:grid-cols-2">
      <div v-for="column in 2" :key="column" class="basis-1/2">
        <div v-for="(photo, i) in pictures" :key="i">
          <img
            v-if="i % 2 === column - 1"
            :src="photo.file as string"
            :alt="photo.name"
            class="mb-3 cursor-pointer"
            @click="addPicture(photo.file)"
          />
        </div>
      </div>
    </div>
  </MenuContentContainer>
</template>

<script setup lang="ts">
  import TButton from "@/ui/TButton/TButton.vue";

  const picturesStore = usePicturesStore();

  const addNewImage = ref();

  const pictures = computed(() => {
    return picturesStore.pictures;
  });

  function addPicture(file: string | ArrayBuffer) {
    window.$event.emit("addPicture", { file: file });
  }

  function uploadImage() {
    const fileList = addNewImage.value.files;
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = (f) => {
          if (!f.target || !f.target.result) return;
          const imgFile = f.target.result;

          picturesStore.pictures.push({
            file: imgFile,
            name: fileList[i].name,
          });
        };
        reader.readAsDataURL(file);
      }
    }
  }
</script>
