<template>
  <div ref="temporary">
    <div
      v-if="borderTop"
      class="my-0 border-0 border-t border-solid border-gray-500"
      :class="{ '!m-0': borderFull }"
      :style="{
        marginLeft: paddingLeftRight - 1 + 'px',
        marginRight: paddingLeftRight - 1 + 'px',
      }"
    ></div>

    <div
      :style="{
        marginTop: adjustedMarginTop + 'px',
        paddingLeft: paddingLeftRight + 'px',
        paddingRight: paddingLeftRight + 'px',
      }"
    >
      <div
        v-if="header || chip"
        class="flex items-center justify-between"
        :style="{ marginBottom: adjustedHeaderMarginBottom + 'px' }"
      >
        <t-chip
          v-if="chip !== ''"
          :class="{ '!bg-content-1': chipSecondary }"
          >{{ chip }}</t-chip
        >
        <h1
          v-if="header !== ''"
          class="font-inter text-sm font-semibold leading-6 text-gray-50"
        >
          {{ header }}
        </h1>
        <slot name="header-action" />
      </div>

      <slot />
    </div>

    <div
      v-if="borderBottom"
      class="my-0 border-0 border-t border-solid border-gray-500"
      :style="{
        marginTop: marginBottom + 'px',
        marginLeft: paddingLeftRight - 1 + 'px',
        marginRight: paddingLeftRight - 1 + 'px',
      }"
      :class="{ '!mx-0': borderFull }"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import TChip from "@/ui/TChip/TChip.vue";

const props = defineProps({
  header: {
    type: String,
    default: "",
  },
  borderBottom: {
    type: Boolean,
    default: true,
  },
  borderTop: {
    type: Boolean,
    default: false,
  },
  borderFull: {
    type: Boolean,
    default: false,
  },
  chip: {
    type: String,
    default: "",
  },
  chipSecondary: {
    type: Boolean,
    default: false,
  },
  marginTop: {
    type: Number,
    default: 12,
  },
  marginBottom: {
    type: Number,
    default: 20,
  },
  headerMarginBottom: {
    type: Number,
    default: 8,
  },
  paddingLeftRight: {
    type: Number,
    default: 20,
  },
});

const adjustedMarginTop = ref(props.marginTop);
const adjustedHeaderMarginBottom = ref(props.headerMarginBottom);

onMounted(() => {
  if (document.querySelectorAll(".mini").length > 0) {
    adjustedMarginTop.value -= 4;
    adjustedHeaderMarginBottom.value -= 4;
  }
});
</script>
<style lang="scss"></style>
