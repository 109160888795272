<template>
  <div class="flex min-h-[48px] items-center bg-gray-900 px-5 py-2">
    <div class="flex w-full justify-between">
      <div class="flex gap-2 py-0">
        <t-button
          class="h-[32px] w-[32px]"
          :disabled="stateStore.stateIndex < 1"
          @click="back"
        >
          <template #icon>
            <Undo />
          </template>
        </t-button>
        <t-button
          class="w-[32px]"
          :disabled="stateStore.stateIndex === stateStore.snapshots.length - 1"
          @click="forward"
        >
          <template #icon>
            <Redo />
          </template>
        </t-button>
      </div>
      <div>
        <TButton @click="stateStore.exporting = true">Export</TButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import TButton from "@/ui/TButton/TButton.vue";

  const stateStore = useStateStore();

  function back() {
    window.$event.emit("back");
  }

  function forward() {
    window.$event.emit("forward");
  }
</script>
