<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 18C1.44772 18 1 17.5523 1 17V3C1 2.44771 1.44772 2 2 2H18C18.5523 2 19 2.44771 19 3V9C19 9.55228 18.5523 10 18 10H9V17C9 17.5523 8.55228 18 8 18H2ZM9 3.5V8.5H17.5V3.5H9Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.7906 12.5C14.8837 12.2362 14.8247 11.9307 14.6137 11.7197C14.3208 11.4268 13.8459 11.4268 13.553 11.7197L11.053 14.2197C10.7601 14.5126 10.7601 14.9874 11.053 15.2803L13.553 17.7803C13.8459 18.0732 14.3208 18.0732 14.6137 17.7803C14.8247 17.5693 14.8837 17.2638 14.7906 17C14.7545 16.8976 14.6955 16.8015 14.6137 16.7197L13.394 15.5L15.75 15.5C17.5809 15.5 19 14.0809 19 12.25V11.4167C19 11.0025 18.6642 10.6667 18.25 10.6667C17.8358 10.6667 17.5 11.0025 17.5 11.4167V12.25C17.5 13.2525 16.7525 14 15.75 14L13.394 14L14.6129 12.781M14.6137 12.7803C14.6955 12.6985 14.7545 12.6024 14.7906 12.5L14.6137 12.7803Z"
      fill="currentColor"
    />
  </svg>
</template>
