<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4531 11.2734L25.4453 20.3633C25.8008 20.8203 25.8008 21.4297 25.5469 21.9375C25.293 22.4453 24.7344 22.75 24.1758 22.75H7.77344C7.21484 22.75 6.65625 22.4453 6.40234 21.9375C6.14844 21.3789 6.19922 20.7695 6.50391 20.3125L9.80469 15.7422C10.3633 14.9297 11.7344 14.9297 12.3438 15.7422L13.1562 16.9102L16.8633 11.2734C17.4727 10.4102 18.8945 10.4102 19.4531 11.2734ZM7.82422 21.125H24.0742L18.1328 12.1875L13.8672 18.7891C13.7148 18.9922 13.4609 19.1445 13.207 19.1445C12.9531 19.1953 12.6992 19.043 12.5469 18.7891L11.0234 16.707L7.82422 21.125ZM25.6992 4.875C27.5273 4.875 28.9492 6.34766 28.9492 8.125V24.375C28.9492 26.2031 27.4766 27.625 25.6992 27.625H6.19922C4.42188 27.625 2.94922 26.2031 2.94922 24.375V8.125C2.94922 6.34766 4.42188 4.875 6.19922 4.875H25.6992ZM27.375 24.375V8.125C27.375 7.26172 26.6133 6.5 25.75 6.5H6.25C5.33594 6.5 4.625 7.26172 4.625 8.125V24.375C4.625 25.2891 5.33594 26 6.25 26H25.75C26.6133 26 27.375 25.2891 27.375 24.375ZM10.3125 13C8.94141 13 7.875 11.9336 7.875 10.5625C7.875 9.24219 8.94141 8.125 10.3125 8.125C11.6328 8.125 12.75 9.24219 12.75 10.5625C12.75 11.9336 11.6328 13 10.3125 13ZM10.3125 9.75C9.85547 9.75 9.5 10.1562 9.5 10.5625C9.5 11.0195 9.85547 11.375 10.3125 11.375C10.7188 11.375 11.0742 11.0195 11.0742 10.5625C11.0742 10.1562 10.7188 9.75 10.3125 9.75Z"
      fill="currentColor"
    />
  </svg>
</template>
