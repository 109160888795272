<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 2C7.44772 2 7 2.44771 7 3L7 13C7 13.5523 7.44771 14 8 14H12C12.5523 14 13 13.5523 13 13L13 3C13 2.44771 12.5523 2 12 2H8Z"
      fill="currentColor"
    />
    <path
      d="M2.75 18C2.33579 18 2 17.6642 2 17.25C2 16.8358 2.33579 16.5 2.75 16.5L17.2492 16.5C17.6634 16.5 17.9992 16.8358 17.9992 17.25C17.9992 17.6642 17.6634 18 17.2492 18L2.75 18Z"
      fill="currentColor"
    />
  </svg>
</template>
