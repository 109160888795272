import { fabric } from "fabric";
import useGetActiveObj from "@/components/LayerPanel/Types/useGetActiveObj";

export default (canvas: any, element?: any) => {
  let picture = element;

  if (!element) {
    picture = canvas.value.item(0);
  }

  const invert = ref(picture.invert || false);
  const brightness = ref(picture.brightness || 0);
  const saturation = ref(picture.saturation || 0);
  const contrast = ref(picture.contrast || 0);
  const hueRotation = ref(picture.hueRotation || 0);
  const temperature = ref(picture.temperature || 0);
  const grayscale = ref(picture.grayscale || 0);
  const noise = ref(picture.noise || 0);
  const blur = ref(picture.blur || 0);
  const sharpness = ref(Math.round(((picture.sharpness || 1) - 1) * 10));
  const emboss = ref(picture.emboss || 0);
  const pixelization = ref(picture.pixelization || 0);
  const vignetteAmount = ref(picture.vignetteAmount || 0);
  const vignetteColor = ref(picture.vignetteColor || "#000000");
  const tintValue = ref(picture.tintValue || 0);
  const tintColor = ref(picture.tintColor || "#ffffff");
  const colorRemoverDistance = ref(picture.colorRemoverDistance || 0);
  const colorRemoverColor = ref(picture.colorRemoverColor || "#ffffff");

  function changeInvert(val: boolean) {
    picture.invert = invert.value = !invert.value;
    window.$event.emit("filter", {
      element: picture,
      filterIndex: 0,
      filterName: "invert",
      filterValue: val,
    });
  }

  function changeBrightness(val: number) {
    picture.brightness = brightness.value = val;
    window.$event.emit("filter", {
      element: picture,
      filterIndex: 1,
      filterName: "brightness",
      filterValue: val,
    });
  }

  function changeSaturation(val: number) {
    picture.saturation = saturation.value = val;
    window.$event.emit("filter", {
      element: picture,
      filterIndex: 2,
      filterName: "saturation",
      filterValue: val,
    });
  }

  function changeContrast(val: number) {
    picture.contrast = contrast.value = val;
    window.$event.emit("filter", {
      element: picture,
      filterIndex: 3,
      filterName: "contrast",
      filterValue: val,
    });
  }

  function changeHueRotation(val: number) {
    picture.hueRotation = hueRotation.value = val;
    window.$event.emit("filter", {
      element: picture,
      filterIndex: 4,
      filterName: "rotation",
      filterValue: fabric.util.degreesToRadians(val) / Math.PI,
    });
  }

  function changeTemperature(val: number) {
    picture.temperature = temperature.value = val;
    window.$event.emit("filterMatrix", {
      element: picture,
      filterIndex: 10,
      filterName: "temperature",
      filterValue: val,
    });
  }

  function changeGrayscale(val: number) {
    picture.grayscale = grayscale.value = val;
    window.$event.emit("filterMatrix", {
      element: picture,
      filterIndex: 11,
      filterName: "baw",
      filterValue: val,
    });
  }

  function changeNoise(val: number) {
    picture.noise = noise.value = val;
    window.$event.emit("filter", {
      element: picture,
      filterIndex: 5,
      filterName: "noise",
      filterValue: val,
    });
  }

  function changeBlur(val: number) {
    picture.blur = blur.value = val;
    window.$event.emit("filter", {
      element: picture,
      filterIndex: 6,
      filterName: "blur",
      filterValue: val,
    });
  }

  function changeSharpness(val: number) {
    picture.sharpness = val / 10 + 1;
    sharpness.value = val;
    window.$event.emit("sharpness", {
      element: picture,
      value: val / 10 + 1,
    });
  }

  function changeEmboss(val: number) {
    picture.emboss = emboss.value = val;
    window.$event.emit("emboss", {
      element: picture,
      value: val,
    });
  }

  function changePixelization(val: number) {
    picture.pixelization = pixelization.value = val;
    window.$event.emit("pixelization", {
      element: picture,
      value: val + 1,
    });
  }

  function changeVignetteAmount(val: number) {
    picture.vignetteAmount = vignetteAmount.value = val;
    window.$event.emit("vignetteAmount", {
      amount: val,
      color: vignetteColor.value,
    });
  }

  function changeVignetteColor(val: string) {
    picture.vignetteColor = vignetteColor.value = val;
    window.$event.emit("vignetteColor", {
      amount: vignetteAmount.value,
      color: val,
    });
  }

  function changeTintValue(val: number) {
    picture.tintValue = tintValue.value = val;
    window.$event.emit("tint", {
      element: picture,
      value: val,
      color: tintColor.value,
    });
  }

  function changeTintColor(val: string) {
    picture.tintColor = tintColor.value = val;
    window.$event.emit("tint", {
      element: picture,
      value: tintValue.value,
      color: val,
    });
  }

  function changeColorRemoverDistance(val: number) {
    picture.colorRemoverDistance = colorRemoverDistance.value = val;
    window.$event.emit("removeColor", {
      element: picture,
      distance: val,
      color: colorRemoverColor.value,
    });
  }

  function changeColorRemoverColor(val: string) {
    picture.colorRemoverColor = colorRemoverColor.value = val;
    window.$event.emit("removeColor", {
      element: picture,
      distance: colorRemoverDistance.value,
      color: val,
    });
  }

  function resetAll() {
    changeInvert(false);
    changeBrightness(0);
    changeSaturation(0);
    changeContrast(0);
    changeHueRotation(0);
    changeTemperature(0);
    changeGrayscale(0);
    changeNoise(0);
    changeBlur(0);
    changeSharpness(1);
    changeEmboss(0);
    changePixelization(0);
    changeVignetteAmount(0);
    changeVignetteColor("#000000");
    changeTintValue(0);
    changeTintColor("#ffffff");
    changeColorRemoverDistance(0);
    changeColorRemoverColor("#ffffff");
  }

  function getData() {
    let object = useGetActiveObj(canvas);

    if (!element) {
      object = canvas.value.item(0);
    }

    invert.value = object.invert || false;
    brightness.value = object.brightness || 0;
    saturation.value = object.saturation || 0;
    contrast.value = object.contrast || 0;
    hueRotation.value = object.hueRotation || 0;
    temperature.value = object.temperature || 0;
    grayscale.value = object.grayscale || 0;
    noise.value = object.noise || 0;
    blur.value = object.blur || 0;
    sharpness.value = Math.round(((object.sharpness || 1) - 1) * 10);
    emboss.value = object.emboss || 0;
    pixelization.value = object.pixelization || 0;
    vignetteAmount.value = object.vignetteAmount || 0;
    vignetteColor.value = object.vignetteColor || "#000000";
    tintValue.value = object.tintValue || 0;
    tintColor.value = object.tintColor || "#ffffff";
    colorRemoverDistance.value = object.colorRemoverDistance || 0;
    colorRemoverColor.value = object.colorRemoverColor || "#ffffff";
  }

  return {
    adjust: {
      invert,
      brightness,
      saturation,
      contrast,
      hueRotation,
      temperature,
      grayscale,
      noise,
      blur,
      sharpness,
      emboss,
      pixelization,
      vignetteAmount,
      vignetteColor,
      tintValue,
      tintColor,
      colorRemoverDistance,
      colorRemoverColor,
      changeInvert,
      changeBrightness,
      changeSaturation,
      changeContrast,
      changeHueRotation,
      changeTemperature,
      changeGrayscale,
      changeNoise,
      changeBlur,
      changeSharpness,
      changeEmboss,
      changePixelization,
      changeVignetteAmount,
      changeVignetteColor,
      changeTintValue,
      changeTintColor,
      changeColorRemoverDistance,
      changeColorRemoverColor,
      resetAll,
      getData,
    },
  };
};
