import { defineStore } from "pinia";
import IPluginOptions from "types/PluginOptions";
import setInitState from "@/init/setInitState";

interface State {
  originBgImage: string;
  control: string;
  canvasSize: {
    width: number;
    height: number;
  };
  stateIndex: number;
  snapshots: any[];
  edittedWithoutSnapshot: boolean;
  openLayer: boolean;
  hideToolbar: boolean;
  initialLoaded: boolean;
  exporting: boolean;
  loading: boolean;
  fitZoom: number;
  activeGroupElement?: any;
  group: {
    selection?: any;
    text?: any;
  };
}

const propertiesToInclude = [
  "borderColor",
  "borderScaleFactor",
  "cornerColor",
  "cornerSize",
  "cornerStrokeColor",
  "cornerStyle",
  "drawControls",
  "editingBorderColor",
  "evented",
  "hasBorders",
  "hasControls",
  "hasRotatingPoint",
  "hoverCursor",
  "id",
  "lockMovementX",
  "lockMovementY",
  "lockRotation",
  "lockScalingFlip",
  "lockSkewingX",
  "lockSkewingY",
  "lockUniScaling",
  "objectCaching",
  "scaleX",
  "scaleY",
  "selectable",
  "strokeUniform",
  "strokeWidth",
  "transparentCorners",
  "_controlsVisibility",
  "_isRetinaScaling",
  "_allowSnaping",
  "_ableToOrder",
  "_originImage",
  "duotone",
  "selectedDuotoneIndex",
  "filters",
  "brightness",
  "saturation",
  "contrast",
  "hueRotation",
  "temperature",
  "grayscale",
  "blur",
  "noise",
  "sharpness",
  "emboss",
  "pixelization",
  "tintValue",
  "tintColor",
  "vignetteAmount",
  "vignetteColor",
  "colorRemoverDistance",
  "colorRemoverColor",
  "invert",
];

export const useStateStore = defineStore("stateStore", {
  state: (): State => ({
    originBgImage: "",
    control: "",
    canvasSize: {
      width: 1000,
      height: 1000,
    },
    stateIndex: 0,
    snapshots: [],
    edittedWithoutSnapshot: false,
    openLayer: false,
    hideToolbar: false,
    initialLoaded: false,
    exporting: false,
    loading: false,
    fitZoom: 1,
    group: {},
  }),

  actions: {
    async setInitialLoader(
      options: IPluginOptions,
      hostname: string,
      mode: "production" | "testing" | "development"
    ): Promise<void> {
      await setInitState(options, hostname, mode);

      this.initialLoaded = true;
    },
    restoreRedoSnapshot() {
      if (this.stateIndex < this.snapshots.length - 1) {
        this.stateIndex++;
        const forwardCanvas = this.snapshots[this.stateIndex];
        return forwardCanvas;
      }
    },

    restoreSnapshot() {
      if (this.stateIndex > 0) {
        this.stateIndex--;
        const backCanvas = this.snapshots[this.stateIndex];
        return backCanvas;
      }
    },

    takeSnapshot(canvas: fabric.Canvas): any {
      const cropStore = useCropStore();
      if (this.stateIndex < this.snapshots.length - 1) {
        this.snapshots.length = this.stateIndex + 1;
      }

      const newIndex = this.snapshots.length;
      this.snapshots[newIndex] = {
        json: canvas.toJSON(propertiesToInclude),
        cropStore: cropStore.getState(),
        canvasSize: JSON.parse(JSON.stringify(this.canvasSize)),
      };
      this.stateIndex = newIndex;
    },
  },
});
