import { ref } from "vue";
// import { useAiTextStore } from "@/stores/aiTextStore";
import { getText } from "@/composables/text-generator/useOpenAiApi";
// import IContext from "types/Ai/Text/IContext";
import { useI18n } from "vue-i18n";

export default () => {
  // const aiTextStore = useAiTextStore();
  const authorizeStore = useAuthorizeStore();
  const { t } = useI18n();

  // const context = ref<IContext | undefined>(undefined);
  const loading = ref(false);

  const generateAiText = async (prompt: string) => {
    loading.value = true;

    const text = await getText(
      prompt,
      authorizeStore.apiKey,
      authorizeStore.hostname,
      authorizeStore.entityId
    );

    if (text === null) {
      loading.value = false;
      return;
    }

    loading.value = false;
    return text;
  };

  const improve = async (textBlock: any) => {
    const newText = await generateAiText(
      t("ai_prompts.improve") + ": " + textBlock.text + '"'
    );

    if (newText) {
      textBlock.text = newText;
    }
  };

  const makeShorter = async (textBlock: any) => {
    const newText = await generateAiText(
      t("ai_prompts.make_shorter") + ": " + textBlock.text + '"'
    );

    if (newText) {
      textBlock.text = newText;
    }
  };

  const makeLonger = async (textBlock: any) => {
    const newText = await generateAiText(
      t("ai_prompts.make_longer") + ": " + textBlock.text + '"'
    );

    if (newText) {
      textBlock.text = newText;
    }
  };

  const rephrase = async (textBlock: any) => {
    const newText = await generateAiText(
      t("ai_prompts.rephrase") + ": " + textBlock.text + '"'
    );

    if (newText) {
      textBlock.text = newText;
    }
  };

  return {
    improve,
    makeLonger,
    makeShorter,
    rephrase,
    loading,
  };
};
