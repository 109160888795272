<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.75 4.5C11.75 4.99219 11.3398 5.375 10.875 5.375H4.75C4.25781 5.375 3.875 4.99219 3.875 4.5C3.875 4.03516 4.25781 3.625 4.75 3.625H10.875C11.3398 3.625 11.75 4.03516 11.75 4.5ZM11.75 11.5C11.75 11.9922 11.3398 12.375 10.875 12.375H4.75C4.25781 12.375 3.875 11.9922 3.875 11.5C3.875 11.0352 4.25781 10.625 4.75 10.625H10.875C11.3398 10.625 11.75 11.0352 11.75 11.5ZM3.875 8C3.875 7.53516 4.25781 7.125 4.75 7.125H15.25C15.7148 7.125 16.125 7.53516 16.125 8C16.125 8.49219 15.7148 8.875 15.25 8.875H4.75C4.25781 8.875 3.875 8.49219 3.875 8ZM16.125 15C16.125 15.4922 15.7148 15.875 15.25 15.875H4.75C4.25781 15.875 3.875 15.4922 3.875 15C3.875 14.5352 4.25781 14.125 4.75 14.125H15.25C15.7148 14.125 16.125 14.5352 16.125 15Z"
      fill="currentColor"
    />
  </svg>
</template>
