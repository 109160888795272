import { fabric } from "fabric";

export default (canvas: Ref<fabric.Canvas>, scaleOfCanvas: Ref<number>) => {
  const cropStore = useCropStore();
  function initCropRect(width: number, height: number) {
    const cropRect = new fabric.Rect({
      objectCaching: false,
      angle: 0,
      width: width,
      height: height,
      originX: "center",
      originY: "center",
      fill: "transparent",
      stroke: "transparent",
      strokeWidth: 2 / canvas.value.getZoom(),
      strokeLineCap: "square",
      strokeUniform: true,
      hasBorders: false,
      backgroundColor: "transparent",
      borderColor: "rgb(255, 255, 255)",
      borderScaleFactor: 1 / (scaleOfCanvas.value || 1),
      selectable: false,
      hoverCursor: "default",
      lockMovementX: true,
      lockMovementY: true,
      lockRotation: true,
      lockSkewingX: true,
      lockSkewingY: true,
      lockScalingFlip: true,
      hasRotatingPoint: false,
      //@ts-expect-error
      id: "cropRect",
      _disalowSelecting: true,
    });
    cropRect.set({
      //@ts-expect-error
      left: canvas.value.width / 2,
      //@ts-expect-error
      top: canvas.value.height / 2,
    });
    cropRect.setControlsVisibility({ mtr: false });
    cropRect.setControlsVisibility({
      mb: false,
      ml: false,
      mr: false,
      mt: false,
    });
    //@ts-expect-error
    cropRect.dimension = width < height ? "portrait" : "landscape";
    cropStore.setCropperSize(width, height);
    canvas.value.add(markRaw(cropRect));
    canvas.value.renderAll();
    return cropRect;
  }

  function initCropMask(width: number, height: number) {
    const cropMask = new fabric.Rect({
      objectCaching: false,
      strokeWidth: 0,
      selectable: false,
      evented: false,
      angle: 0,
      width: width,
      height: height,
      fill: "rgba(10, 10, 10, 0.65)",
      originX: "center",
      originY: "center",
      //@ts-expect-error
      left: canvas.value.width / 2,
      //@ts-expect-error
      top: canvas.value.height / 2,
      opacity: 0,
      //@ts-expect-error
      id: "cropMask",
      _disalowSelecting: true,
    });
    const c = new fabric.Rect({
      width: width,
      height: height,
      originX: "center",
      originY: "center",
      left: 0,
      top: 0,
    });
    c.inverted = true;
    cropMask.clipPath = c;
    canvas.value.add(markRaw(cropMask));
    canvas.value.renderAll();
    return cropMask;
  }

  function initCropLines(width: number, height: number) {
    const cropTF = new fabric.Line([0, 0, width, 0], {
      top: height / 3,
      stroke: "#D5D6D8",
      strokeWidth: 2 / (canvas.value.getZoom() || 1),
      strokeDashArray: [
        10 / (canvas.value.getZoom() || 1),
        10 / (canvas.value.getZoom() || 1),
      ],
      selectable: false,
      evented: false,
      opacity: 0,
      //@ts-expect-error
      id: "cropMaskLineTF",
      _disalowSelecting: true,
    });
    const cropTS = new fabric.Line([0, 0, width, 0], {
      top: height - height / 3,
      stroke: "#D5D6D8",
      strokeWidth: 2 / (canvas.value.getZoom() || 1),
      strokeDashArray: [
        10 / (canvas.value.getZoom() || 1),
        10 / (canvas.value.getZoom() || 1),
      ],
      selectable: false,
      evented: false,
      opacity: 0,
      //@ts-expect-error
      id: "cropMaskLineTS",
      _disalowSelecting: true,
    });
    const cropLF = new fabric.Line([0, 0, 0, height], {
      left: width / 3,
      stroke: "#D5D6D8",
      strokeWidth: 2 / (canvas.value.getZoom() || 1),
      strokeDashArray: [
        10 / (canvas.value.getZoom() || 1),
        10 / (canvas.value.getZoom() || 1),
      ],
      selectable: false,
      evented: false,
      opacity: 0,
      //@ts-expect-error
      id: "cropMaskLineLF",
      _disalowSelecting: true,
    });
    const cropLS = new fabric.Line([0, 0, 0, height], {
      left: width - width / 3,
      stroke: "#D5D6D8",
      strokeWidth: 2 / (canvas.value.getZoom() || 1),
      strokeDashArray: [
        10 / (canvas.value.getZoom() || 1),
        10 / (canvas.value.getZoom() || 1),
      ],
      selectable: false,
      evented: false,
      opacity: 0,
      //@ts-expect-error
      id: "cropMaskLineLS",
      _disalowSelecting: true,
    });
    canvas.value.add(markRaw(cropLF));
    canvas.value.add(markRaw(cropLS));
    canvas.value.add(markRaw(cropTF));
    canvas.value.add(markRaw(cropTS));
    canvas.value.renderAll();
    return {
      cropLF,
      cropLS,
      cropTF,
      cropTS,
    };
  }

  return {
    initCropRect,
    initCropMask,
    initCropLines,
  };
};
