<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34 18C34 26.875 26.8125 34 18 34C9.125 34 2 26.875 2 18C2 9.1875 9.125 2 18 2C26.8125 2 34 9.1875 34 18ZM7.375 8.875C5.25 11.3125 4 14.5 4 18C4 25.75 10.25 32 18 32C21.5 32 24.6875 30.75 27.125 28.625L7.375 8.875ZM32 18C32 10.3125 25.6875 4 18 4C14.4375 4 11.25 5.3125 8.8125 7.4375L28.5625 27.1875C30.6875 24.75 32 21.5625 32 18Z"
      fill="currentColor"
    />
  </svg>
</template>
