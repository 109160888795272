<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.9989 2.75C17.9989 2.33579 17.6631 2 17.2489 2C16.8347 2 16.4989 2.33579 16.4989 2.75V17.2492C16.4989 17.6634 16.8347 17.9992 17.2489 17.9992C17.6631 17.9992 17.9989 17.6634 17.9989 17.2492V2.75Z"
      fill="currentColor"
    />
    <path
      d="M2 7.99958C2 7.44729 2.44772 6.99958 3 6.99958H13C13.5523 6.99958 14 7.44729 14 7.99958V11.9996C14 12.5519 13.5523 12.9996 13 12.9996H3C2.44772 12.9996 2 12.5519 2 11.9996V7.99958Z"
      fill="currentColor"
    />
  </svg>
</template>
