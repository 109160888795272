export default (canvas: any) => {
  const stateStore = useStateStore();

  if (!canvas || !canvas.value) return;
  const activeObj = canvas.value.getActiveObject();
  if (!activeObj || activeObj.id === "cropRect") return;
  if (
    (activeObj.type === "group" || activeObj.type === "activeSelection") &&
    stateStore.activeGroupElement
  ) {
    return stateStore.activeGroupElement;
  }
  return activeObj;
};
