export default (props: {
  canvas: Ref<fabric.Canvas>;
  bgImg: Ref<fabric.Image>;
  vignetteLayer: Ref<fabric.Rect | null>;
  saveStateBounced: any;
}) => {
  const globalEmitsHandler = window.$event;

  const {
    applyFilter,
    applyFilterMatrix,
    applyDuoTone,
    applyColorRemove,
    applyEmboss,
    applyPixelization,
    applySharpness,
    applyTint,
    applyVignetteAmount,
    applyVignetteColor,
  } = useFilters(props.canvas, props.bgImg, props.vignetteLayer);

  globalEmitsHandler.on(
    "filter",
    (event: {
      element: any;
      filterIndex: number;
      filterName: string;
      filterValue: number;
    }) => {
      applyFilter(
        event.filterIndex,
        event.filterName,
        event.filterValue,
        event.element
      );
      props.saveStateBounced();
    }
  );
  globalEmitsHandler.on(
    "filterMatrix",
    (event: {
      filterIndex: number;
      filterName: string;
      filterValue: number;
      element: any;
    }) => {
      applyFilterMatrix(
        event.filterIndex,
        event.filterName,
        event.filterValue,
        event.element
      );
      props.saveStateBounced();
    }
  );

  globalEmitsHandler.on(
    "filterDuotone",
    (event: {
      element: any;
      color1: string;
      color2: string;
      reset?: boolean;
    }) => {
      applyDuoTone(event);
      props.saveStateBounced();
    }
  );

  globalEmitsHandler.on(
    "sharpness",
    (event: { element: any; value: number }) => {
      applySharpness(event.value, event.element);
      props.saveStateBounced();
    }
  );

  globalEmitsHandler.on("emboss", (event: { element: any; value: number }) => {
    applyEmboss(event.value, event.element);
    props.saveStateBounced();
  });

  globalEmitsHandler.on(
    "pixelization",
    (event: { element: any; value: number }) => {
      applyPixelization(event.value, event.element);
      props.saveStateBounced();
    }
  );

  globalEmitsHandler.on(
    "tint",
    (event: { value: number; color: string; element: any }) => {
      applyTint(event.value, event.color, event.element);
      props.saveStateBounced();
    }
  );

  globalEmitsHandler.on(
    "vignetteAmount",
    (event: { amount: number; color: string; element: any }) => {
      applyVignetteAmount(event.amount, event.color);
      props.saveStateBounced();
    }
  );

  globalEmitsHandler.on(
    "vignetteColor",
    (event: { amount: number; color: string; element: any }) => {
      applyVignetteColor(event.amount, event.color);
      props.saveStateBounced();
    }
  );

  globalEmitsHandler.on(
    "removeColor",
    (event: { distance: number; color: string; element: any }) => {
      applyColorRemove(event.distance, event.color, event.element);
      props.saveStateBounced();
    }
  );
};
