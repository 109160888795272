import { fabric } from "fabric";
import { ISnapObjectX, ISnapObjectY } from "types/Snap/Snap";

export default (canvas: Ref<fabric.Canvas>, snapDistance: number) => {
  function snapToObjects(obj: fabric.Object) {
    const { height, width, topY, bottomY, leftX, rightX, centerX, centerY } =
      getObjectCoords(obj);

    const objectsToSnap = canvas.value
      .getObjects()
      //@ts-expect-error
      .filter((o) => o !== obj && o._allowSnaping === true);

    const snapingObjectsX: ISnapObjectX[] = [];
    const snapingObjectsY: ISnapObjectY[] = [];

    objectsToSnap.map((o) => {
      const {
        topY: oTopY,
        bottomY: oBottomY,
        leftX: oLeftX,
        rightX: oRightX,
        centerX: oCenterX,
        centerY: oCenterY,
      } = getObjectCoords(o);

      if (
        //@ts-expect-error
        o.id !== "snapLineTop" &&
        //@ts-expect-error
        o.id !== "snapLineCenterY" &&
        //@ts-expect-error
        o.id !== "snapLineBottom"
      ) {
        const snapLeft = snapFromLeft(
          leftX,
          oLeftX,
          oRightX,
          oCenterX,
          o,
          width
        );
        const snapCenterX = snapFromCenterX(
          centerX,
          oCenterX,
          oLeftX,
          oRightX,
          o
        );
        const snapRight = snapFromRight(
          rightX,
          oLeftX,
          oRightX,
          oCenterX,
          o,
          width
        );
        if (snapLeft) {
          snapingObjectsX.push(snapLeft);
        }
        if (snapCenterX) {
          snapingObjectsX.push(snapCenterX);
        }
        if (snapRight) {
          snapingObjectsX.push(snapRight);
        }
      }
      if (
        //@ts-expect-error
        o.id !== "snapLineLeft" &&
        //@ts-expect-error
        o.id !== "snapLineCenterX" &&
        //@ts-expect-error
        o.id !== "snapLineRight"
      ) {
        const snapTop = snapFromTop(topY, oCenterY, oTopY, oBottomY, o, height);
        const snapCenterY = snapFromCenterY(
          centerY,
          oCenterY,
          oTopY,
          oBottomY,
          o
        );
        const snapBottom = snapFromBottom(
          bottomY,
          oCenterY,
          oTopY,
          oBottomY,
          o,
          height
        );
        if (snapTop) {
          snapingObjectsY.push(snapTop);
        }
        if (snapCenterY) {
          snapingObjectsY.push(snapCenterY);
        }
        if (snapBottom) {
          snapingObjectsY.push(snapBottom);
        }
      }
    });
    const sortSnapingObjectX = snapingObjectsX.sort((a, b) =>
      a.distance > b.distance ? 1 : -1
    );
    const sortSnapingObjectY = snapingObjectsY.sort((a, b) =>
      a.distance > b.distance ? 1 : -1
    );
    return { sortSnapingObjectX, sortSnapingObjectY };
  }

  function snapToObjectsFromLeft(obj: fabric.Object) {
    const { width, leftX } = getObjectCoords(obj);

    const objectsToSnap = canvas.value
      .getObjects()
      //@ts-expect-error
      .filter((o) => o !== obj && o._allowSnaping === true);

    const snapingObjects: ISnapObjectX[] = [];

    objectsToSnap.map((o) => {
      const {
        leftX: oLeftX,
        rightX: oRightX,
        centerX: oCenterX,
      } = getObjectCoords(o);

      if (
        //@ts-expect-error
        o.id !== "snapLineTop" &&
        //@ts-expect-error
        o.id !== "snapLineCenterY" &&
        //@ts-expect-error
        o.id !== "snapLineBottom"
      ) {
        const snapLeft = snapFromLeft(
          leftX,
          oLeftX,
          oRightX,
          oCenterX,
          o,
          width
        );
        if (snapLeft) {
          snapingObjects.push(snapLeft);
        }
      }
    });
    const sortSnapingObject = snapingObjects.sort((a, b) =>
      a.distance > b.distance ? 1 : -1
    );
    return sortSnapingObject;
  }

  function snapToObjectsFromRight(obj: fabric.Object) {
    const { width, rightX } = getObjectCoords(obj);

    const objectsToSnap = canvas.value
      .getObjects()
      //@ts-expect-error
      .filter((o) => o !== obj && o._allowSnaping === true);

    const snapingObjects: ISnapObjectX[] = [];

    objectsToSnap.map((o) => {
      const {
        leftX: oLeftX,
        rightX: oRightX,
        centerX: oCenterX,
      } = getObjectCoords(o);

      if (
        //@ts-expect-error
        o.id !== "snapLineTop" &&
        //@ts-expect-error
        o.id !== "snapLineCenterY" &&
        //@ts-expect-error
        o.id !== "snapLineBottom"
      ) {
        const snapRight = snapFromRight(
          rightX,
          oLeftX,
          oRightX,
          oCenterX,
          o,
          width
        );
        if (snapRight) {
          snapingObjects.push(snapRight);
        }
      }
    });
    const sortSnapingObject = snapingObjects.sort((a, b) =>
      a.distance > b.distance ? 1 : -1
    );
    return sortSnapingObject;
  }

  function snapToObjectsFromTop(obj: fabric.Object) {
    const { height, topY } = getObjectCoords(obj);

    const objectsToSnap = canvas.value
      .getObjects()
      //@ts-expect-error
      .filter((o) => o !== obj && o._allowSnaping === true);

    const snapingObjects: ISnapObjectY[] = [];

    objectsToSnap.map((o) => {
      const {
        topY: oTopY,
        bottomY: oBottomY,
        centerY: oCenterY,
      } = getObjectCoords(o);

      if (
        //@ts-expect-error
        o.id !== "snapLineLeft" &&
        //@ts-expect-error
        o.id !== "snapLineCenterX" &&
        //@ts-expect-error
        o.id !== "snapLineRight"
      ) {
        const snapTop = snapFromTop(topY, oCenterY, oTopY, oBottomY, o, height);
        if (snapTop) {
          snapingObjects.push(snapTop);
        }
      }
    });
    const sortSnapingObject = snapingObjects.sort((a, b) =>
      a.distance > b.distance ? 1 : -1
    );
    return sortSnapingObject;
  }

  function snapToObjectsFromBottom(obj: fabric.Object) {
    const { height, bottomY } = getObjectCoords(obj);

    const objectsToSnap = canvas.value
      .getObjects()
      //@ts-expect-error
      .filter((o) => o !== obj && o._allowSnaping === true);

    const snapingObjects: ISnapObjectY[] = [];

    objectsToSnap.map((o) => {
      const {
        topY: oTopY,
        bottomY: oBottomY,
        centerY: oCenterY,
      } = getObjectCoords(o);

      if (
        //@ts-expect-error
        o.id !== "snapLineLeft" &&
        //@ts-expect-error
        o.id !== "snapLineCenterX" &&
        //@ts-expect-error
        o.id !== "snapLineRight"
      ) {
        const snapBottom = snapFromBottom(
          bottomY,
          oCenterY,
          oTopY,
          oBottomY,
          o,
          height
        );
        if (snapBottom) {
          snapingObjects.push(snapBottom);
        }
      }
    });
    const sortSnapingObject = snapingObjects.sort((a, b) =>
      a.distance > b.distance ? 1 : -1
    );
    return sortSnapingObject;
  }

  function snapToObjectsNotFromCenter(obj: fabric.Object) {
    const { height, width, topY, bottomY, leftX, rightX } =
      getObjectCoords(obj);

    const objectsToSnap = canvas.value
      .getObjects()
      //@ts-expect-error
      .filter((o) => o !== obj && o._allowSnaping === true);

    const snapingObjectsX: ISnapObjectX[] = [];
    const snapingObjectsY: ISnapObjectY[] = [];

    objectsToSnap.map((o) => {
      const {
        topY: oTopY,
        bottomY: oBottomY,
        leftX: oLeftX,
        rightX: oRightX,
        centerX: oCenterX,
        centerY: oCenterY,
      } = getObjectCoords(o);

      if (
        //@ts-expect-error
        o.id !== "snapLineTop" &&
        //@ts-expect-error
        o.id !== "snapLineCenterY" &&
        //@ts-expect-error
        o.id !== "snapLineBottom"
      ) {
        const snapLeft = snapFromLeft(
          leftX,
          oLeftX,
          oRightX,
          oCenterX,
          o,
          width
        );
        const snapRight = snapFromRight(
          rightX,
          oLeftX,
          oRightX,
          oCenterX,
          o,
          width
        );
        if (snapLeft) {
          snapingObjectsX.push(snapLeft);
        }
        if (snapRight) {
          snapingObjectsX.push(snapRight);
        }
      }
      if (
        //@ts-expect-error
        o.id !== "snapLineLeft" &&
        //@ts-expect-error
        o.id !== "snapLineCenterX" &&
        //@ts-expect-error
        o.id !== "snapLineRight"
      ) {
        const snapTop = snapFromTop(topY, oCenterY, oTopY, oBottomY, o, height);
        const snapBottom = snapFromBottom(
          bottomY,
          oCenterY,
          oTopY,
          oBottomY,
          o,
          height
        );
        if (snapTop) {
          snapingObjectsY.push(snapTop);
        }
        if (snapBottom) {
          snapingObjectsY.push(snapBottom);
        }
      }
    });
    const sortSnapingObjectX = snapingObjectsX.sort((a, b) =>
      a.distance > b.distance ? 1 : -1
    );
    const sortSnapingObjectY = snapingObjectsY.sort((a, b) =>
      a.distance > b.distance ? 1 : -1
    );
    return { sortSnapingObjectX, sortSnapingObjectY };
  }

  function snapFromLeft(
    leftX: number,
    oLeftX: number,
    oRightX: number,
    oCenterX: number,
    o: fabric.Object,
    width: number
  ) {
    const dxCenterLeft = oCenterX - leftX;
    const dxLeftLeft = oLeftX - leftX;
    const dxRightLeft = oRightX - leftX;
    const dxCenterLeftAbs = Math.abs(dxCenterLeft);
    const dxLeftLeftAbs = Math.abs(dxLeftLeft);
    const dxRightLeftAbs = Math.abs(dxRightLeft);

    if (dxCenterLeftAbs < snapDistance) {
      return {
        x: oCenterX,
        left: oCenterX + width / 2,
        distance: dxCenterLeftAbs,
        direction: dxCenterLeft < 0 ? -1 : 1,
        object: o,
        from: "left",
      };
    } else if (dxLeftLeftAbs < snapDistance) {
      return {
        x: oLeftX,
        left: oLeftX + width / 2,
        distance: dxLeftLeftAbs,
        direction: dxLeftLeft < 0 ? -1 : 1,
        object: o,
        from: "left",
      };
    } else if (dxRightLeftAbs < snapDistance) {
      return {
        x: oRightX,
        left: oRightX + width / 2,
        distance: dxRightLeftAbs,
        direction: dxRightLeft < 0 ? -1 : 1,
        object: o,
        from: "left",
      };
    }
  }

  function snapFromRight(
    rightX: number,
    oLeftX: number,
    oRightX: number,
    oCenterX: number,
    o: fabric.Object,
    width: number
  ) {
    const dxCenterRight = oCenterX - rightX;
    const dxLeftRight = oLeftX - rightX;
    const dxRightRight = oRightX - rightX;
    const dxCenterRightAbs = Math.abs(dxCenterRight);
    const dxLeftRightAbs = Math.abs(dxLeftRight);
    const dxRightRightAbs = Math.abs(dxRightRight);

    if (dxCenterRightAbs < snapDistance) {
      return {
        x: oCenterX,
        left: oCenterX - width / 2,
        distance: dxCenterRightAbs,
        direction: dxCenterRight < 0 ? -1 : 1,
        object: o,
        from: "right",
      };
    } else if (dxLeftRightAbs < snapDistance) {
      return {
        x: oLeftX,
        left: oLeftX - width / 2,
        distance: dxLeftRightAbs,
        direction: dxLeftRight < 0 ? -1 : 1,
        object: o,
        from: "right",
      };
    } else if (dxRightRightAbs < snapDistance) {
      return {
        x: oRightX,
        left: oRightX - width / 2,
        distance: dxRightRightAbs,
        direction: dxRightRight < 0 ? -1 : 1,
        object: o,
        from: "right",
      };
    }
  }

  function snapFromCenterX(
    centerX: number,
    oCenterX: number,
    oLeftX: number,
    oRightX: number,
    o: fabric.Object
  ) {
    const dxCenterCenter = oCenterX - centerX;
    const dxLeftCenter = oLeftX - centerX;
    const dxRightCenter = oRightX - centerX;
    const dxCenterCenterAbs = Math.abs(dxCenterCenter);
    const dxLeftCenterAbs = Math.abs(dxLeftCenter);
    const dxRightCenterAbs = Math.abs(dxRightCenter);

    if (dxCenterCenterAbs < snapDistance) {
      return {
        x: oCenterX,
        left: oCenterX,
        distance: dxCenterCenterAbs,
        direction: dxCenterCenter < 0 ? -1 : 1,
        object: o,
        from: "center",
      };
    } else if (dxLeftCenterAbs < snapDistance) {
      return {
        x: oLeftX,
        left: oLeftX,
        distance: dxLeftCenterAbs,
        direction: dxLeftCenter < 0 ? -1 : 1,
        object: o,
        from: "center",
      };
    } else if (dxRightCenterAbs < snapDistance) {
      return {
        x: oRightX,
        left: oRightX,
        distance: dxRightCenterAbs,
        direction: dxRightCenter < 0 ? -1 : 1,
        object: o,
        from: "center",
      };
    }
  }

  function snapFromTop(
    topY: number,
    oCenterY: number,
    oTopY: number,
    oBottomY: number,
    o: fabric.Object,
    height: number
  ) {
    const dyCenterTop = oCenterY - topY;
    const dyTopTop = oTopY - topY;
    const dyBottomTop = oBottomY - topY;
    const dyCenterTopAbs = Math.abs(dyCenterTop);
    const dyTopTopAbs = Math.abs(dyTopTop);
    const dyBottomTopAbs = Math.abs(dyBottomTop);
    if (dyCenterTopAbs < snapDistance) {
      return {
        y: oCenterY,
        top: oCenterY + height / 2,
        distance: dyCenterTopAbs,
        direction: dyCenterTop < 0 ? -1 : 1,
        object: o,
        from: "top",
      };
    } else if (dyTopTopAbs < snapDistance) {
      return {
        y: oTopY,
        top: oTopY + height / 2,
        distance: dyTopTopAbs,
        direction: dyTopTop < 0 ? -1 : 1,
        object: o,
        from: "top",
      };
    } else if (dyBottomTopAbs < snapDistance) {
      return {
        y: oBottomY,
        top: oBottomY + height / 2,
        distance: dyBottomTopAbs,
        direction: dyBottomTop < 0 ? -1 : 1,
        object: o,
        from: "top",
      };
    }
  }

  function snapFromBottom(
    bottomY: number,
    oCenterY: number,
    oTopY: number,
    oBottomY: number,
    o: fabric.Object,
    height: number
  ) {
    const dyCenterBottom = oCenterY - bottomY;
    const dyTopBottom = oTopY - bottomY;
    const dyBottomBottom = oBottomY - bottomY;
    const dyCenterBottomAbs = Math.abs(dyCenterBottom);
    const dyTopBottomAbs = Math.abs(dyTopBottom);
    const dyBottomBottomAbs = Math.abs(dyBottomBottom);
    if (dyCenterBottomAbs < snapDistance) {
      return {
        y: oCenterY,
        top: oCenterY - height / 2,
        distance: dyCenterBottomAbs,
        direction: dyCenterBottom < 0 ? -1 : 1,
        object: o,
        from: "bottom",
      };
    } else if (dyTopBottomAbs < snapDistance) {
      return {
        y: oTopY,
        top: oTopY - height / 2,
        distance: dyTopBottomAbs,
        direction: dyTopBottom < 0 ? -1 : 1,
        object: o,
        from: "bottom",
      };
    } else if (dyBottomBottomAbs < snapDistance) {
      return {
        y: oBottomY,
        top: oBottomY - height / 2,
        distance: dyBottomBottomAbs,
        direction: dyBottomBottom < 0 ? -1 : 1,
        object: o,
        from: "bottom",
      };
    }
  }

  function snapFromCenterY(
    centerY: number,
    oCenterY: number,
    oTopY: number,
    oBottomY: number,
    o: fabric.Object
  ) {
    const dyCenterCenter = oCenterY - centerY;
    const dyTopCenter = oTopY - centerY;
    const dyBottomCenter = oBottomY - centerY;
    const dyCenterCenterAbs = Math.abs(dyCenterCenter);
    const dyTopCenterAbs = Math.abs(dyTopCenter);
    const dyBottomCenterAbs = Math.abs(dyBottomCenter);

    if (dyCenterCenterAbs < snapDistance) {
      return {
        y: oCenterY,
        top: oCenterY,
        distance: dyCenterCenterAbs,
        direction: dyCenterCenter < 0 ? -1 : 1,
        object: o,
        from: "center",
      };
    } else if (dyTopCenterAbs < snapDistance) {
      return {
        y: oTopY,
        top: oTopY,
        distance: dyTopCenterAbs,
        direction: dyTopCenter < 0 ? -1 : 1,
        object: o,
        from: "center",
      };
    } else if (dyBottomCenterAbs < snapDistance) {
      return {
        y: oBottomY,
        top: oBottomY,
        distance: dyBottomCenterAbs,
        direction: dyBottomCenter < 0 ? -1 : 1,
        object: o,
        from: "center",
      };
    }
  }

  // ???? add tl, tr, bl, br, lt, rt, lb, rb
  function getObjectCoords(obj: fabric.Object) {
    const topY = obj.getBoundingRect(true, true).top;
    const left = obj.getBoundingRect(true, true).left;
    const height = obj.getBoundingRect(true, true).height;
    const width = obj.getBoundingRect(true, true).width;
    const bottomY = topY + height;
    const leftX = left;
    const rightX = left + width;
    const centerX = obj.left || 0;
    const centerY = obj.top || 0;
    return {
      height,
      width,
      topY,
      bottomY,
      leftX,
      rightX,
      centerX,
      centerY,
    };
  }

  return {
    snapToObjects,
    snapToObjectsFromLeft,
    snapToObjectsFromRight,
    snapToObjectsFromTop,
    snapToObjectsFromBottom,
    snapToObjectsNotFromCenter,
  };
};
