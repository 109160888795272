import { defineStore } from "pinia";

interface State {
  predefinedDuotone: string[][];
  previews: string[];
}

export const useFiltersStore = defineStore("filtersStore", {
  state: (): State => ({
    predefinedDuotone: [
      ["#FE208B", "#B8E986"],
      ["#3E25CA", "#C1E9E5"],
      ["#0107C1", "#00EBBC"],
      ["#002033", "#60FFBB"],
      ["#002538", "#F59724"],
      ["#002033", "#FA7A57"],
      ["#011D6A", "#ED3221"],
    ],
    previews: [],
  }),

  getters: {
    getState: (state) => () => {
      return JSON.parse(JSON.stringify(state));
    },
  },

  actions: {
    setState(stateSnep: State) {
      this.$patch(stateSnep);
    },
  },
});
