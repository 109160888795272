<template>
  <div id="rightPanel">
    <LayerEdit
      v-if="activeObject && activeObject.id !== 'cropRect'"
      :canvas="canvas"
      :active-object="activeObject"
    />
  </div>
</template>

<script setup lang="ts">
  import useGetActiveObj from "./Types/useGetActiveObj";
  const props = defineProps<{ canvas: any }>();

  const activeObject = ref(useGetActiveObj(props.canvas));

  onMounted(() => {
    window.$event.on("updatePanel", () => {
      activeObject.value = useGetActiveObj(props.canvas);
      window.$event.emit("updatePanelValues");
    });
  });
</script>
