<template>
  <button
    class="t-button active:outlin-accent-1 box-border flex cursor-pointer items-center justify-center whitespace-nowrap rounded-md bg-gray-500 px-[--button-padding] font-inter text-xs font-medium leading-[--button-leading] text-gray-100 transition duration-300 hover:bg-gray-400 hover:text-gray-50 focus:bg-gray-600 focus:outline focus:outline-2 focus:outline-accent-1 active:bg-gray-600 active:outline active:outline-2 disabled:!cursor-not-allowed disabled:!border-transparent disabled:!bg-gray-600 disabled:!text-gray-200 disabled:!outline-none group-hover:bg-gray-400 group-hover:text-gray-50"
    v-bind="$attrs"
    :class="{
      'min-h-[24px] !px-0.5': $slots.icon && !$slots.default,
      '!px-[--button-padding-xs] !leading-6': xs,
      '!pl-1.5': !xs && $slots.icon && $slots.default,
      '!pl-1': xs && $slots.icon && $slots.default,
      '!pr-1.5': !xs && $slots.iconRight,
      '!pr-1': xs && $slots.iconRight,
      '!bg-confirm-1 !text-white hover:!bg-confirm-2 focus:!outline-confirm-2 active:!outline-confirm-2':
        success,
      '!bg-accent-1 !text-gray-50 hover:!bg-accent-1 hover:!text-gray-10 focus:!outline-accent-1 active:!outline-accent-1':
        accent,
      '!bg-structure-1 !text-gray-800 hover:!bg-structure-2 focus:!outline-structure-2 active:!outline-structure-2':
        purple,
      'focus:!outlone-destructive-2 !bg-destructive-1 !text-white hover:!bg-destructive-2 active:!outline-destructive-2':
        danger,
      '!justify-start': alignLeft,
    }"
    :disabled="disabled || loading"
    style="position: relative"
  >
    <span
      :class="{ 'opacity-100': !loading, 'opacity-0': loading }"
      class="flex items-center justify-center gap-1"
    >
      <div
        v-if="$slots.icon"
        :class="{ '': $slots.icon && !$slots.default }"
        class="grid h-[20px] min-w-[20px] items-center justify-center"
      >
        <slot name="icon" />
      </div>

      <slot />

      <div
        v-if="$slots.iconRight"
        class="grid h-[20px] min-w-[20px] items-center justify-center"
      >
        <slot name="iconRight" />
      </div>
    </span>
    <t-loading-spinner
      v-show="loading"
      class="position"
      style="position: absolute"
    ></t-loading-spinner>
  </button>
</template>

<script lang="ts">
  // @TODO: After vue 3.3 upgrade refactor this: https://vuejs.org/guide/components/attrs.html#disabling-attribute-inheritance
  // InheritAttrs is not supported in script setup
  // So we define script and script with setup
  export default {
    inheritAttrs: false,
  };
</script>

<script lang="ts" setup>
  import TLoadingSpinner from "../TLoadingSpinner/TLoadingSpinner.vue";

  defineProps({
    /**
     * Native button disabled attribute
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    arrowLeft: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
    xs: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
    danger: {
      type: Boolean,
      default: false,
    },
    purple: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    alignLeft: {
      type: Boolean,
      default: false,
    },
    accent: {
      type: Boolean,
      default: false,
    },
  });
</script>
<style lang="scss">
  @import "./TButton.scss";
</style>
