import { fabric } from "fabric";
import useGetActiveObj from "../useGetActiveObj";

export default (
  canvas: Ref<fabric.Canvas>,
  element: fabric.Object,
  from: string
) => {
  const stateStore = useStateStore();
  const type = ref(element.type);
  const width = ref(Math.round((element.width || 0) * (element.scaleX || 1)));
  const height = ref(Math.round((element.height || 0) * (element.scaleY || 1)));
  const angle = ref(Math.round(element.angle || 0));
  const opacity = ref(Math.round((element.opacity || 1) * 100));
  // @ts-expect-error
  const maskType = ref(element.clipPath ? element.clipPath._type : undefined);

  function emitChange() {
    window.$event.emit(
      from === "layer" ? "updateToolbarValues" : "updatePanelValues"
    );
    if (stateStore.group.selection) {
      stateStore.group.selection.addWithUpdate();
    }
  }

  function changeWidth(newWidth: number) {
    const newHeight = (newWidth * height.value) / width.value;
    element.set("scaleY", newHeight / (element.height || 0));
    element.set("scaleX", newWidth / (element.width || 0));
    canvas.value.requestRenderAll();
    width.value = newWidth;
    emitChange();
  }

  function changeHeight(newHeight: number) {
    const newWidth = (newHeight * width.value) / height.value;
    element.set("scaleX", newWidth / (element.width || 0));
    element.set("scaleY", newHeight / (element.height || 0));
    canvas.value.requestRenderAll();
    height.value = newHeight;
    emitChange();
  }

  function changeAngle(newAngle: number) {
    element.set("angle", newAngle);
    canvas.value.requestRenderAll();
    angle.value = newAngle;
    emitChange();
  }

  function changeOpacity(newOpacity: number) {
    element.set("opacity", newOpacity / 100);
    canvas.value.requestRenderAll();
    opacity.value = newOpacity;
    emitChange();
  }

  function flipHorizontal() {
    element.set("flipX", !element.flipX);
    canvas.value.requestRenderAll();
    emitChange();
  }

  function flipVertical() {
    element.set("flipY", !element.flipY);
    canvas.value.requestRenderAll();
    emitChange();
  }

  function addMaskToPicture(type: string) {
    window.$event.emit("addMaskToPicture", {
      picture: element,
      type: type,
    });
    maskType.value = type;
    emitChange();
  }

  function resetMask() {
    window.$event.emit("resetPictureMask", element);
    maskType.value = undefined;
    emitChange();
  }

  function updateValues() {
    if (!canvas || !canvas.value) return;
    const actElement = useGetActiveObj(canvas);
    if (!actElement) return;
    width.value = Math.round(
      (actElement.width || 0) * (actElement.scaleX || 0)
    );
    height.value = Math.round(
      (actElement.height || 0) * (actElement.scaleY || 0)
    );
    angle.value = Math.round(actElement.angle || 0);
    opacity.value = Math.round((actElement.opacity || 1) * 100);
    maskType.value = actElement.clipPath
      ? actElement.clipPath._type
      : undefined;
  }

  return {
    type,
    width,
    height,
    angle,
    opacity,
    maskType,
    addMaskToPicture,
    resetMask,
    changeWidth,
    changeHeight,
    changeAngle,
    changeOpacity,
    flipHorizontal,
    flipVertical,
    updateValues,
  };
};
