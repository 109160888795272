<template>
  <div class="h-[100vh] bg-bg">
    <div
      v-if="loading"
      class="fixed z-[99999] h-[100vh] w-[100vw] bg-gray-800/80"
    >
      <TLoading :loading="loading" extra-large />
    </div>
    <HeaderMenu />
    <div class="flex">
      <ControlPanel :canvas="canvasProp" />
      <!-- <Layers :canvas="canvas" /> -->
      <div
        id="canvas-space"
        class="full-height mx-auto"
        :class="{
          'w-[--w-full]': control === '' && !openLayer,
          'w-[--w-control]': control !== '' || !openLayer,
          'w-[--w-control-layer]': control !== '' && openLayer,
          'w-[--w-layer]': control === '' && openLayer,
        }"
      >
        <!-- <div id="workspace" class="canvas-height overflow-auto"> -->
        <PerfectScrollbar
          id="workspace"
          ref="scroll"
          class="canvas-height w-full"
        >
          <canvas id="canvas" ref="canvasReference"> </canvas>
          <ActiveObjectToolbar :canvas="canvasProp" />
        </PerfectScrollbar>
        <!-- </div> -->
        <Zoom v-if="canvasProp" :canvas="canvasProp" />
        <!-- <Crop :canvas="canvasProp" /> -->
      </div>
      <LayerPanel :canvas="canvasProp" />
    </div>
    <Export v-if="stateStore.exporting" :canvas="canvasProp" />
  </div>
</template>

<script lang="ts" setup>
  import TLoading from "./ui/TLoading/TLoading.vue";

  const canvasProp = ref();

  const stateStore = useStateStore();

  const control = computed(() => {
    return stateStore.control;
  });

  const openLayer = computed(() => {
    return stateStore.openLayer;
  });

  const loading = computed(() => {
    return stateStore.loading;
  });

  watch(control, (newVal) => {
    if (newVal !== "" && openLayer.value) {
      stateStore.openLayer = false;
    }
    setTimeout(() => {
      window.$event.emit("resize");
    }, 5);
  });

  watch(openLayer, () => {
    setTimeout(() => {
      window.$event.emit("resize");
    }, 5);
  });

  onMounted(() => {
    const { canvas, init } = useInitWorspace();

    canvasProp.value = canvas;

    init("./testDuo.jpeg");
    // init();

    window.addEventListener("resize", () => {
      window.$event.emit("resize");
    });
  });
</script>

<style lang="scss">
  /* Import colors and typography variables */
  // @import "./css/all";
  #canvas-space {
    // width: calc(100vw - 288px);
    --w-full: calc(100vw - 64px);
    --w-control: calc(100vw - 64px - 320px);
    --w-control-layer: calc(100vw - 64px - 320px - 304px);
    --w-layer: calc(100vw - 64px - 305px);
  }
  .full-height {
    height: calc(100vh - 48px);
  }
  .canvas-height {
    height: calc(100% - 56px);
  }
</style>
