<template>
  <PanelTitle :title="$t('pictures.title')" />
  <input
    ref="addNewImage"
    type="file"
    name="image"
    multiple
    accept="image/*"
    style="display: none"
    @change="uploadImage"
  />
  <MenuContentContainer :border-bottom="false">
    <ContentRow>
      <TButton class="min-h-[40px] w-full" success @click="addNewImage.click()">
        <template #icon>
          <UploadIcon width="18" height="18" />
        </template>
        {{ $t("pictures.upload") }}
      </TButton>
    </ContentRow>
  </MenuContentContainer>
  <MenuContentContainer :border-bottom="false" :header="$t('pictures.pexels')">
    <ContentRow>
      <TInput
        v-model="searchValue"
        :placeholder="$t('pictures.search')"
        class="w-full"
        @enter-pressed="search"
      ></TInput>
    </ContentRow>
  </MenuContentContainer>
  <MenuContentContainer v-if="pexelsPhotos.length > 0" :border-bottom="false">
    <PerfectScrollbar
      class="scroll-pexels"
      style="height: 750px"
      @ps-y-reach-end="loadMore"
    >
      <div class="flex grid-cols-4 flex-row gap-3 lg:grid-cols-2">
        <div v-for="column in 2" :key="column" class="basis-1/2">
          <div v-for="(image, i) in pexelsPhotos" :key="i">
            <img
              v-if="i % 2 === column - 1"
              :src="image.src.medium"
              class="mb-3 cursor-pointer"
              @click="addPicture(image.src.original)"
            />
          </div>
        </div>
      </div>
    </PerfectScrollbar>
  </MenuContentContainer>
</template>

<script setup lang="ts">
  import TButton from "@/ui/TButton/TButton.vue";
  import TInput from "@/ui/TInput/TInput.vue";
  import usePexels from "@/composables/usePictures/usePexels";
  import { Photo } from "types/Pexels/Types";

  const picturesStore = usePicturesStore();
  const stateStore = useStateStore();
  const { getPexelsPhoto, searchPixelsPhotos, currentPage, query } =
    usePexels();

  const addNewImage = ref();
  const pexelsPhotos: Ref<Photo[]> = ref([]);
  const searchValue = ref("");

  function addPicture(file: string | ArrayBuffer) {
    window.$event.emit("addPicture", { file: file, url: true });
  }

  function uploadImage() {
    const fileList = addNewImage.value.files;
    for (let i = 0; i < fileList.length; i++) {
      const file = fileList[i];
      if (file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = (f) => {
          if (!f.target || !f.target.result) return;
          const imgFile = f.target.result;

          picturesStore.pictures.push({
            file: imgFile,
            name: fileList[i].name,
          });
        };
        reader.readAsDataURL(file);
      }
    }
    stateStore.control = "upload";
  }

  async function search() {
    query.value = searchValue.value;
    if (query.value !== "") {
      pexelsPhotos.value = [];
      currentPage.value = 1;
      const response = await searchPixelsPhotos(query.value);
      pexelsPhotos.value = response ? response.photos : [];
    } else {
      pexelsPhotos.value = [];
      currentPage.value = 1;
      const response = await getPexelsPhoto();
      pexelsPhotos.value = response ? response.photos : [];
    }
  }

  async function loadMore() {
    currentPage.value++;
    try {
      let response;
      if (query.value === "") {
        response = await getPexelsPhoto();
      } else {
        response = await searchPixelsPhotos(query.value);
      }
      const nextPhotos = response ? response.photos : [];
      pexelsPhotos.value.push(...nextPhotos);
    } catch (err) {
      currentPage.value--;
    }
  }

  watch(searchValue, (newValue) => {
    if (newValue === "") {
      search();
    }
  });

  onMounted(async () => {
    const resp = await getPexelsPhoto();
    if (resp) {
      pexelsPhotos.value = resp.photos;
    }
  });
</script>
