import IFont from "types/IFont/IFont";
import { fabric } from "fabric";
import useGetActiveObj from "../useGetActiveObj";
import getAlphaFromHex from "@/utils/getAlphaFromHex";
import getAlphaToHex from "@/utils/getAlphaToHex";

export default (
  canvas: Ref<fabric.Canvas>,
  text: fabric.Textbox | fabric.IText,
  from: string
) => {
  const size = ref(Math.round(Number(text.fontSize || 0) * (text.scaleX || 1)));
  const color = ref(text.fill?.toString() || "");
  const fontFamily = ref(text.fontFamily || "");
  const textAlighn = ref(text.textAlign || "");
  const fontWeight: Ref<number> = ref(Number(text.fontWeight) || 0);
  const fontStyle = ref(text.fontStyle || "");
  const underline = ref(text.underline || false);
  const lineThrough = ref(text.linethrough || false);
  const lineHeight = ref(text.lineHeight || 1);
  const letterSpasing = ref(Math.round((text.charSpacing || 0) / 100));
  const outlineColor = ref(text.stroke || "");
  const outlineWidth = ref(text.strokeWidth || 0);
  //@ts-expect-error
  const shadowColor = ref(text.shadow?.color.slice(0, 7) || "#000000");
  const shadowAlpha = ref(
    //@ts-expect-error
    Math.round(getAlphaFromHex(text.shadow?.color || "") * 100)
  );
  //@ts-expect-error
  const shadowOffsetX = ref(text.shadow?.offsetX || 0);
  //@ts-expect-error
  const shadowOffsetY = ref(text.shadow?.offsetY || 0);
  //@ts-expect-error
  const shadowBlur = ref(text.shadow?.blur || 0);
  const stateStore = useStateStore();

  function emitChange() {
    window.$event.emit(
      from === "layer" ? "updateToolbarValues" : "updatePanelValues"
    );
    if (stateStore.group.selection) {
      stateStore.group.selection.addWithUpdate();
    }
  }

  function changeColor(newColor: string) {
    text.set({ fill: newColor });
    canvas.value.requestRenderAll();
    color.value = newColor;
    emitChange();
  }

  function changeSize(newSize: number) {
    if (Number(newSize) < 12) {
      newSize = 12;
    }
    text.set({ fontSize: Number(newSize / (text.scaleX || 1)) });
    canvas.value.requestRenderAll();
    size.value = Number(newSize);
    emitChange();
  }

  function changeFont(newFont: IFont) {
    text.set({ fontFamily: newFont.label });
    canvas.value.requestRenderAll();
    fontFamily.value = newFont.label;
    emitChange();
  }

  function changeAlign(align: string) {
    text.set({ textAlign: align });
    canvas.value.requestRenderAll();
    textAlighn.value = align;
    emitChange();
  }

  function changeWeight(weight: number) {
    text.set({ fontWeight: weight });
    canvas.value.requestRenderAll();
    fontWeight.value = weight;
    emitChange();
  }

  function changeLineHeight(newHeight: number) {
    text.set({ lineHeight: newHeight });
    canvas.value.requestRenderAll();
    lineHeight.value = newHeight;
    emitChange();
  }

  function changeLetterSpacing(newSpacing: number) {
    text.set({ charSpacing: newSpacing * 100 });
    canvas.value.requestRenderAll();
    letterSpasing.value = newSpacing;
    emitChange();
  }

  function changeStyle() {
    if (fontStyle.value === "italic") {
      text.set({ fontStyle: "normal" });
      canvas.value.requestRenderAll();
      fontStyle.value = "normal";
    } else {
      text.set({ fontStyle: "italic" });
      canvas.value.requestRenderAll();
      fontStyle.value = "italic";
    }
    emitChange();
  }

  function changeUnderline() {
    if (underline.value) {
      text.set({ underline: false });
      canvas.value.requestRenderAll();
      underline.value = false;
    } else {
      text.set({ underline: true });
      canvas.value.requestRenderAll();
      underline.value = true;
    }
    emitChange();
  }

  function changeLineThrough() {
    if (lineThrough.value) {
      text.set({ linethrough: false });
      canvas.value.requestRenderAll();
      lineThrough.value = false;
    } else {
      text.set({ linethrough: true });
      canvas.value.requestRenderAll();
      lineThrough.value = true;
    }
    emitChange();
  }

  function changeOutlineColor(newColor: string) {
    text.set({ stroke: newColor });
    canvas.value.requestRenderAll();
    outlineColor.value = newColor;
    emitChange();
  }

  function changeOutlineWidth(newWidth: number) {
    text.set({ strokeWidth: newWidth });
    canvas.value.requestRenderAll();
    outlineWidth.value = newWidth;
    emitChange();
  }

  function changeShadow(props: {
    color?: string;
    alpha?: number;
    offsetX?: number;
    offsetY?: number;
    blur?: number;
  }) {
    let color = props.color || shadowColor.value;
    const a = props.alpha || shadowAlpha.value;
    if (a / 100 !== 1) {
      const alpha = getAlphaToHex(a / 100);
      color = color + alpha;
    }
    const offsetX = props.offsetX || shadowOffsetX.value;
    const offsetY = props.offsetY || shadowOffsetY.value;
    const blur = props.blur || shadowBlur.value;
    text.set({ shadow: `${color} ${offsetX}px ${offsetY}px ${blur}px` });
    canvas.value.requestRenderAll();
    shadowColor.value = props.color || shadowColor.value;
    shadowAlpha.value = Math.round(a * 100);
    shadowOffsetX.value = offsetX;
    shadowOffsetY.value = offsetY;
    shadowBlur.value = blur;
    emitChange();
  }

  function updateValues() {
    const actObj = useGetActiveObj(canvas) as fabric.Textbox | fabric.IText;
    if (!actObj) return;
    color.value = actObj.fill?.toString() || "";
    size.value = Math.round(
      Number(actObj.fontSize || 0) * (actObj.scaleX || 1)
    );
    fontFamily.value = actObj.fontFamily || "";
    textAlighn.value = actObj.textAlign || "";
    fontWeight.value = Number(actObj.fontWeight) || 0;
    fontStyle.value = actObj.fontStyle || "";
    underline.value = actObj.underline || false;
    lineThrough.value = actObj.linethrough || false;
    lineHeight.value = actObj.lineHeight || 1;
    letterSpasing.value = Math.round((actObj.charSpacing || 0) / 100);
    outlineColor.value = actObj.stroke || "#96a4b0";
    outlineWidth.value = actObj.strokeWidth || 0;
    shadowColor.value =
      //@ts-expect-error
      actObj.shadow?.color.slice(0, 7) || "#000000";
    shadowAlpha.value =
      //@ts-expect-error
      Math.round(getAlphaFromHex(actObj.shadow?.color || "") * 100);
    //@ts-expect-error
    shadowOffsetX.value = actObj.shadow?.offsetX || 0;
    //@ts-expect-error
    shadowOffsetY.value = actObj.shadow?.offsetY || 0;
    //@ts-expect-error
    shadowBlur.value = actObj.shadow?.blur || 0;
  }

  return {
    size,
    color,
    fontFamily,
    textAlighn,
    fontWeight,
    fontStyle,
    underline,
    lineThrough,
    lineHeight,
    letterSpasing,
    outlineColor,
    outlineWidth,
    shadowColor,
    shadowAlpha,
    shadowOffsetX,
    shadowOffsetY,
    shadowBlur,
    changeColor,
    changeSize,
    changeFont,
    changeAlign,
    changeWeight,
    changeLineHeight,
    changeLetterSpacing,
    changeStyle,
    changeUnderline,
    changeLineThrough,
    changeOutlineColor,
    changeOutlineWidth,
    changeShadow,
    updateValues,
  };
};
