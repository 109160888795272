<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.875 10.5C14.875 10.9219 14.5234 11.25 14.125 11.25H5.875C5.45312 11.25 5.125 10.9219 5.125 10.5C5.125 10.1016 5.45312 9.75 5.875 9.75H14.125C14.5234 9.75 14.875 10.1016 14.875 10.5Z"
      fill="currentColor"
    />
  </svg>
</template>
