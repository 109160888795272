<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.8125 6.25C5.87109 6.25 4.3125 7.83594 4.3125 9.75C4.3125 10.1328 4.01172 10.4062 3.65625 10.4062C3.27344 10.4062 3 10.1328 3 9.75C3 7.09766 5.13281 4.9375 7.8125 4.9375H11.7227V3.40625C11.7227 3.16016 11.8594 2.91406 12.1055 2.80469C12.3516 2.72266 12.6523 2.77734 12.8438 2.96875L15.0312 5.15625C15.2773 5.40234 15.2773 5.8125 15.0312 6.05859L12.8438 8.24609C12.6523 8.4375 12.3516 8.49219 12.1055 8.41016C11.8594 8.30078 11.7227 8.05469 11.7227 7.78125V6.25H7.8125ZM16.3438 9.09375C16.6992 9.09375 17 9.39453 17 9.75C17 12.4297 14.8398 14.5625 12.1875 14.5625H8.25V16.0938C8.25 16.3672 8.08594 16.6133 7.83984 16.7227C7.59375 16.8047 7.29297 16.75 7.12891 16.5586L4.91406 14.3711C4.66797 14.125 4.66797 13.7148 4.91406 13.4414L7.12891 11.2539C7.29297 11.0898 7.59375 11.0352 7.83984 11.1172C8.08594 11.2266 8.25 11.4727 8.25 11.7188V13.25H12.1875C14.1016 13.25 15.6875 11.6914 15.6875 9.75C15.6875 9.39453 15.9609 9.09375 16.3438 9.09375Z"
      fill="currentColor"
    />
  </svg>
</template>
