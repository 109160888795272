<template>
  <div class="group/input" style="width: 100%">
    <t-label v-if="label" :for="uuid" :tip="tip" :disabled="disabled">{{
      label
    }}</t-label>
    <div class="relative">
      <input
        :id="uuid"
        ref="selectRef"
        class="t-input block w-full rounded-md border border-solid border-transparent bg-gray-600 px-2.5 py-[7px] font-inter text-xs font-medium leading-[16px] text-gray-50 transition duration-300 placeholder:text-gray-100 focus:!border-transparent focus:bg-gray-700 focus:!text-gray-10 focus:outline focus:outline-2 focus:outline-accent-1 disabled:!cursor-not-allowed disabled:!border-transparent disabled:!bg-gray-600 disabled:!text-gray-200 group-hover/input:border-gray-400 group-hover:border-gray-400"
        :class="{
          'has-icon': searchIcon,
          '!outline !outline-1 !outline-destructive-2 focus:!outline-destructive-2':
            errorInput,
        }"
        type="text"
        :value="modelValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :autocomplete="autocomplete"
        @mouseover="hovered = true"
        @mouseleave="hovered = false"
        @input="valueChanged"
        @click="selectAllFunc"
        @blur="onBlur"
        @focus="$emit('on-focus', $event)"
        @keydown.enter="$emit('enter-pressed')"
      />

      <div
        v-if="searchIcon"
        class="absolute inset-y-0 right-[10px] flex h-full items-center"
      >
        <svg
          width="14"
          height="14"
          viewBox="0 0 14 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.8185 12.1015L11.0822 9.36976C10.9453 9.26049 10.7812 9.17854 10.617 9.17854H10.1792C10.918 8.22244 11.3832 7.02049 11.3832 5.68195C11.3832 2.5678 8.81099 0 5.69155 0C2.54475 0 -6.10352e-05 2.5678 -6.10352e-05 5.68195C-6.10352e-05 8.82341 2.54475 11.3639 5.69155 11.3639C7.005 11.3639 8.209 10.9268 9.19408 10.162V10.6263C9.19408 10.7902 9.24881 10.9541 9.38563 11.0907L12.0946 13.7951C12.3683 14.0683 12.7787 14.0683 13.025 13.7951L13.7912 13.0302C14.0648 12.7844 14.0648 12.3746 13.8185 12.1015ZM5.69155 9.17854C3.74874 9.17854 2.18902 7.62146 2.18902 5.68195C2.18902 3.76976 3.74874 2.18537 5.69155 2.18537C7.607 2.18537 9.19408 3.76976 9.19408 5.68195C9.19408 7.62146 7.607 9.17854 5.69155 9.17854Z"
            fill="#CBCBCD"
          />
        </svg>
      </div>
    </div>
    <div
      v-if="errorInput && errorText !== ''"
      class="font-regular mt-1 flex items-start gap-[5px] font-inter text-xs leading-[16px] text-destructive-2"
    >
      <div class="h-5 w-5">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 2.75C13.8555 2.75 17 5.89453 17 9.75C17 13.6328 13.8555 16.75 10 16.75C6.11719 16.75 3 13.6328 3 9.75C3 5.89453 6.11719 2.75 10 2.75ZM10 15.4375C13.1172 15.4375 15.6875 12.8945 15.6875 9.75C15.6875 6.63281 13.1172 4.0625 10 4.0625C6.85547 4.0625 4.3125 6.63281 4.3125 9.75C4.3125 12.8945 6.85547 15.4375 10 15.4375ZM10 11.0625C9.61719 11.0625 9.34375 10.7891 9.34375 10.4062V6.90625C9.34375 6.55078 9.61719 6.25 10 6.25C10.3555 6.25 10.6562 6.55078 10.6562 6.90625V10.4062C10.6562 10.7891 10.3555 11.0625 10 11.0625ZM10 11.9922C10.4648 11.9922 10.8477 12.375 10.8477 12.8398C10.8477 13.3047 10.4648 13.6875 10 13.6875C9.50781 13.6875 9.125 13.3047 9.125 12.8398C9.125 12.375 9.50781 11.9922 10 11.9922Z"
            fill="currentColor"
          />
        </svg>
      </div>
      <span class="inline-flex min-h-[20px] items-center">{{ errorText }}</span>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { v4 as uuidv4 } from "uuid";
  import TLabel from "../TLabel/TLabel.vue";
  import { ref } from "vue";

  const props = defineProps({
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    searchIcon: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: "off",
    },
    errorInput: {
      type: Boolean,
      default: false,
    },
    errorText: {
      type: String,
      default: "",
    },
    //when clicking on input select all text
    selectAll: {
      type: Boolean,
      default: false,
    },
    tip: {
      type: String,
      default: "",
    },
  });

  const emit = defineEmits([
    "update:modelValue",
    "enter-pressed",
    "on-blur",
    "on-focus",
  ]);

  const uuid = uuidv4();
  const hovered = ref(false);
  const selectRef = ref();

  function valueChanged(e: Event) {
    const target = e.target as HTMLInputElement;
    emit("update:modelValue", target.value);
  }

  function selectAllFunc() {
    if (!props.disabled && props.selectAll) {
      selectRef.value.select();
    }
  }

  function onBlur() {
    emit("on-blur");
  }
</script>
<style lang="scss">
  .t-input {
    //--t-input-width: calc(100% - 20px - 4px);
  }
</style>
