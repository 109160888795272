export default (sides: number, radius: number) => {
  const angle = (Math.PI * 2) / sides;
  const points = [];
  for (let i = 0; i < sides; i++) {
    if (sides % 2 === 0) {
      points.push({
        x: radius + radius * Math.cos(angle * i),
        y: radius + radius * Math.sin(angle * i),
      });
    } else {
      points.push({
        x: radius + radius * Math.cos(angle * i - Math.PI / 2),
        y: radius + radius * Math.sin(angle * i - Math.PI / 2),
      });
    }
  }
  return points;
};
