<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5625 4.5V8C2.5625 8.24609 2.75391 8.4375 3 8.4375H4.75V9.75H3C2.01562 9.75 1.25 8.98438 1.25 8V4.5C1.25 3.54297 2.01562 2.75 3 2.75H6.5C7.45703 2.75 8.25 3.54297 8.25 4.5H6.9375C6.9375 4.28125 6.71875 4.0625 6.5 4.0625H3C2.75391 4.0625 2.5625 4.28125 2.5625 4.5ZM13.5 16.75C12.5156 16.75 11.75 15.9844 11.75 15H13.0625C13.0625 15.2461 13.2539 15.4375 13.5 15.4375H17C17.2188 15.4375 17.4375 15.2461 17.4375 15V11.5C17.4375 11.2812 17.2188 11.0625 17 11.0625H15.25V9.75H17C17.957 9.75 18.75 10.543 18.75 11.5V15C18.75 15.9844 17.957 16.75 17 16.75H13.5ZM12.625 5.375C13.582 5.375 14.375 6.16797 14.375 7.125V12.375C14.375 13.3594 13.582 14.125 12.625 14.125H7.375C6.39062 14.125 5.625 13.3594 5.625 12.375V7.125C5.625 6.16797 6.39062 5.375 7.375 5.375H12.625Z"
      fill="currentColor"
    />
  </svg>
</template>
