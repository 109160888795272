import { fabric } from "fabric";

export default (canvas: Ref<fabric.Canvas>) => {
  function addText(props: {
    text?: string;
    size?: number;
    weight?: number;
    family?: string;
    width?: number;
    stroke?: number;
    shadow?: string;
    curve?: boolean;
  }) {
    const textBox = props.curve ? getText(props) : getTextBox(props);
    textBox.setCoords();
    textBox.setControlsVisibility({
      mb: false,
      mt: false,
      ml: props.curve ? false : true,
      mr: props.curve ? false : true,
    });

    canvas.value.viewportCenterObject(textBox);
    canvas.value.add(markRaw(textBox));
    canvas.value.setActiveObject(textBox);
  }

  function getText(props: {
    text?: string;
    size?: number;
    weight?: number;
    family?: string;
    width?: number;
    stroke?: number;
    shadow?: string;
    curve?: boolean;
  }) {
    return new fabric.Text(props.text || "Lorum ipsum dolor sit amet", {
      fontSize: (props.size || 0) / canvas.value.getZoom(),
      fontFamily: props.family || "Helvetica",
      charSpacing: 1,
      lineHeight: 1.5,
      textAlign: "center",
      linethrough: false,
      underline: false,
      overline: false,
      originX: "center",
      originY: "center",
      textBackgroundColor: "transparent",
      stroke: "#96a4b0",
      strokeWidth: (props.stroke || 0) / canvas.value.getZoom(),
      shadow: props.shadow || undefined,
      fontStyle: "normal",
      fontWeight: props.weight || 400,
      fill: "#000000",
      //@ts-expect-error
      path: props.curve
        ? getCurve(
            (props.width || 200) / canvas.value.getZoom(),
            -100 / canvas.value.getZoom()
          )
        : undefined,
      _allowSnaping: true,
      _ableToOrder: true,
    });
  }

  function getTextBox(props: {
    text?: string;
    size?: number;
    weight?: number;
    family?: string;
    width?: number;
    stroke?: number;
    shadow?: string;
  }) {
    return new fabric.Textbox(props.text || "Lorum ipsum dolor sit amet", {
      fontSize: (props.size || 0) / canvas.value.getZoom(),
      fontFamily: props.family || "Helvetica",
      charSpacing: 1,
      lineHeight: 1.5,
      textAlign: "center",
      linethrough: false,
      underline: false,
      overline: false,
      originX: "center",
      originY: "center",
      textBackgroundColor: "transparent",
      stroke: "#96a4b0",
      strokeWidth: (props.stroke || 0) / canvas.value.getZoom(),
      shadow: props.shadow || undefined,
      fontStyle: "normal",
      fontWeight: props.weight || 400,
      fill: "#000000",
      width: (props.width || 180) / canvas.value.getZoom(),
      //@ts-expect-error
      _allowSnaping: true,
      _ableToOrder: true,
    });
  }

  function getCurve(width: number, height: number) {
    return new fabric.Path(`M 0 0 Q ${width / 2}, ${height}, ${width}, 0`, {
      strokeWidth: 1,
      visible: false,
    });
  }

  return { addText };
};
