export default (hex: string, alpha: number) => {
  const parsed = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!parsed) {
    return "";
  }

  return (
    "rgba(" +
    parseInt(parsed[1], 16) +
    ", " +
    parseInt(parsed[2], 16) +
    ", " +
    parseInt(parsed[3], 16) +
    ", " +
    alpha +
    ")"
  );
};
