import { fabric } from "fabric";
import getRegularPolygonPoints from "@/utils/getRegularPolygonPoints";
import getStartPolygonPoints from "@/utils/getStartPolygonPoints";

export default {
  rect: function () {
    return new fabric.Rect({
      width: 300,
      height: 300,
      top: 150,
      left: 150,
      fill: "#cbcbcd",
      originX: "center",
      originY: "center",
      strokeWidth: 0,
      strokeDashArray: [50, 5],
      strokeUniform: true,
      //@ts-expect-error
      _allowSnaping: true,
      _ableToOrder: true,
      _basicShape: true,
    });
  },
  circle: function () {
    return new fabric.Circle({
      radius: 150,
      top: 150,
      left: 150,
      fill: "#cbcbcd",
      originX: "center",
      originY: "center",
      strokeWidth: 0,
      //@ts-expect-error
      _allowSnaping: true,
      _ableToOrder: true,
      _basicShape: true,
    });
  },
  triangle: function () {
    return new fabric.Triangle({
      width: 300,
      height: 300,
      top: 150,
      left: 150,
      fill: "#cbcbcd",
      originX: "center",
      originY: "center",
      strokeWidth: 0,
      //@ts-expect-error
      _allowSnaping: true,
      _ableToOrder: true,
      _basicShape: true,
    });
  },
  pentagon: function () {
    return new fabric.Polygon(getRegularPolygonPoints(5, 200), {
      fill: "#cbcbcd",
      left: 190,
      originX: "center",
      originY: "center",
      strokeWidth: 0,
      //@ts-expect-error
      _allowSnaping: true,
      _ableToOrder: true,
      _basicShape: true,
    });
  },
  hexagon: function () {
    return new fabric.Polygon(getRegularPolygonPoints(6, 200), {
      fill: "#cbcbcd",
      top: 180,
      originX: "center",
      originY: "center",
      strokeWidth: 0,
      //@ts-expect-error
      _allowSnaping: true,
      _ableToOrder: true,
      _basicShape: true,
    });
  },
  octagon: function () {
    return new fabric.Polygon(getRegularPolygonPoints(8, 200), {
      fill: "#cbcbcd",
      originX: "center",
      originY: "center",
      strokeWidth: 0,
      angle: 22.5,
      //@ts-expect-error
      _allowSnaping: true,
      _ableToOrder: true,
      _basicShape: true,
    });
  },
  ellipse: function () {
    return new fabric.Ellipse({
      rx: 300,
      ry: 200,
      top: 200,
      left: 300,
      fill: "#cbcbcd",
      originX: "center",
      originY: "center",
      strokeWidth: 0,
      //@ts-expect-error
      _allowSnaping: true,
      _ableToOrder: true,
      _basicShape: true,
    });
  },
  arrow: function () {
    return new fabric.Polygon(
      [
        { x: 0, y: 50 },
        { x: 180, y: 50 },
        { x: 180, y: 0 },
        { x: 280, y: 100 },
        { x: 180, y: 200 },
        { x: 180, y: 150 },
        { x: 0, y: 150 },
      ],
      {
        fill: "#cbcbcd",
        originX: "center",
        originY: "center",
        strokeWidth: 0,
        //@ts-expect-error
        _allowSnaping: true,
        _ableToOrder: true,
        _basicShape: true,
      }
    );
  },
  double_arrow: function () {
    return new fabric.Polygon(
      [
        { x: 0, y: 100 },
        { x: 100, y: 0 },
        { x: 100, y: 50 },
        { x: 260, y: 50 },
        { x: 260, y: 0 },
        { x: 360, y: 100 },
        { x: 260, y: 200 },
        { x: 260, y: 150 },
        { x: 100, y: 150 },
        { x: 100, y: 200 },
      ],
      {
        fill: "#cbcbcd",
        originX: "center",
        originY: "center",
        strokeWidth: 0,
        //@ts-expect-error
        _allowSnaping: true,
        _ableToOrder: true,
        _basicShape: true,
      }
    );
  },
  star_4: function () {
    return new fabric.Polygon(getStartPolygonPoints(4, 200, 100), {
      fill: "#cbcbcd",
      originX: "center",
      originY: "center",
      strokeWidth: 0,
      //@ts-expect-error
      _allowSnaping: true,
      _ableToOrder: true,
      _basicShape: true,
    });
  },
  star_5: function () {
    return new fabric.Polygon(getStartPolygonPoints(5, 200, 100), {
      fill: "#cbcbcd",
      top: 180,
      originX: "center",
      originY: "center",
      strokeWidth: 0,
      //@ts-expect-error
      _allowSnaping: true,
      _ableToOrder: true,
      _basicShape: true,
    });
  },
  star_6: function () {
    return new fabric.Polygon(getStartPolygonPoints(6, 200, 100), {
      fill: "#cbcbcd",
      top: 180,
      originX: "center",
      originY: "center",
      strokeWidth: 0,
      //@ts-expect-error
      _allowSnaping: true,
      _ableToOrder: true,
      _basicShape: true,
    });
  },
  star_8: function () {
    return new fabric.Polygon(getStartPolygonPoints(8, 200, 150), {
      fill: "#cbcbcd",
      originX: "center",
      originY: "center",
      strokeWidth: 0,
      //@ts-expect-error
      _allowSnaping: true,
      _ableToOrder: true,
      _basicShape: true,
    });
  },
  star_16: function () {
    return new fabric.Polygon(getStartPolygonPoints(16, 200, 150), {
      fill: "#cbcbcd",
      originX: "center",
      originY: "center",
      strokeWidth: 0,
      //@ts-expect-error
      _allowSnaping: true,
      _ableToOrder: true,
      _basicShape: true,
    });
  },
  star_20: function () {
    return new fabric.Polygon(getStartPolygonPoints(20, 200, 175), {
      fill: "#cbcbcd",
      originX: "center",
      originY: "center",
      strokeWidth: 0,
      //@ts-expect-error
      _allowSnaping: true,
      _ableToOrder: true,
      _basicShape: true,
    });
  },
  rect_with_triangle: function () {
    return new fabric.Polygon(
      [
        { x: 0, y: 0 },
        { x: 300, y: 0 },
        { x: 400, y: 100 },
        { x: 300, y: 200 },
        { x: 0, y: 200 },
      ],
      {
        fill: "#cbcbcd",
        originX: "center",
        originY: "center",
        strokeWidth: 0,
        //@ts-expect-error
        _allowSnaping: true,
        _ableToOrder: true,
        _basicShape: true,
      }
    );
  },
  rect_without_triangle: function () {
    return new fabric.Polygon(
      [
        { x: 0, y: 0 },
        { x: 300, y: 0 },
        { x: 300, y: 200 },
        { x: 0, y: 200 },
        { x: 100, y: 100 },
      ],
      {
        fill: "#cbcbcd",
        originX: "center",
        originY: "center",
        strokeWidth: 0,
        //@ts-expect-error
        _allowSnaping: true,
        _ableToOrder: true,
        _basicShape: true,
      }
    );
  },
  rect_with_and_without_triangle: function () {
    return new fabric.Polygon(
      [
        { x: 0, y: 0 },
        { x: 300, y: 0 },
        { x: 400, y: 100 },
        { x: 300, y: 200 },
        { x: 0, y: 200 },
        { x: 100, y: 100 },
      ],
      {
        fill: "#cbcbcd",
        originX: "center",
        originY: "center",
        strokeWidth: 0,
        //@ts-expect-error
        _allowSnaping: true,
        _ableToOrder: true,
        _basicShape: true,
      }
    );
  },
  rect_without_and_without_triangle: function () {
    return new fabric.Polygon(
      [
        { x: 0, y: 0 },
        { x: 400, y: 0 },
        { x: 300, y: 100 },
        { x: 400, y: 200 },
        { x: 0, y: 200 },
        { x: 100, y: 100 },
      ],
      {
        fill: "#cbcbcd",
        originX: "center",
        originY: "center",
        strokeWidth: 0,
        //@ts-expect-error
        _allowSnaping: true,
        _ableToOrder: true,
        _basicShape: true,
      }
    );
  },
  rect_with_and_with_triangle: function () {
    return new fabric.Polygon(
      [
        { x: 100, y: 0 },
        { x: 300, y: 0 },
        { x: 400, y: 100 },
        { x: 300, y: 200 },
        { x: 100, y: 200 },
        { x: 0, y: 100 },
      ],
      {
        fill: "#cbcbcd",
        originX: "center",
        originY: "center",
        strokeWidth: 0,
        //@ts-expect-error
        _allowSnaping: true,
        _ableToOrder: true,
        _basicShape: true,
      }
    );
  },
  rect_with_hlf_circle: function () {
    return new fabric.Path(
      "M 300 150 L 300 400 L 0 400 L 0 150 A150,150 0 0,1 300,150 z",
      {
        fill: "#cbcbcd",
        originX: "center",
        originY: "center",
        strokeWidth: 0,
        //@ts-expect-error
        _allowSnaping: true,
        _ableToOrder: true,
        _basicShape: true,
      }
    );
  },
};
