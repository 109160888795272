<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.125 15.4375H15.25C15.4688 15.4375 15.6875 15.2461 15.6875 15V8.875C15.6875 8.65625 15.4688 8.4375 15.25 8.4375H13.5V7.125H15.25C16.207 7.125 17 7.91797 17 8.875V15C17 15.9844 16.207 16.75 15.25 16.75H9.125C8.14062 16.75 7.375 15.9844 7.375 15V13.25H8.6875V15C8.6875 15.2461 8.87891 15.4375 9.125 15.4375ZM4.75 12.375C3.76562 12.375 3 11.6094 3 10.625V4.5C3 3.54297 3.76562 2.75 4.75 2.75H10.875C11.832 2.75 12.625 3.54297 12.625 4.5V10.625C12.625 11.6094 11.832 12.375 10.875 12.375H4.75Z"
      fill="currentColor"
    />
  </svg>
</template>
