<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.875 2.75C11.832 2.75 12.625 3.54297 12.625 4.5V6.25H9.125C7.64844 6.25 6.5 7.42578 6.5 8.875V12.375H4.75C3.76562 12.375 3 11.6094 3 10.625V4.5C3 3.54297 3.76562 2.75 4.75 2.75H10.875ZM7.375 8.875C7.375 7.91797 8.14062 7.125 9.125 7.125H15.25C16.207 7.125 17 7.91797 17 8.875V15C17 15.9844 16.207 16.75 15.25 16.75H9.125C8.14062 16.75 7.375 15.9844 7.375 15V8.875ZM8.6875 8.875V15C8.6875 15.2461 8.87891 15.4375 9.125 15.4375H15.25C15.4688 15.4375 15.6875 15.2461 15.6875 15V8.875C15.6875 8.65625 15.4688 8.4375 15.25 8.4375H9.125C8.87891 8.4375 8.6875 8.65625 8.6875 8.875Z"
      fill="currentColor"
    />
  </svg>
</template>
