import { fabric } from "fabric";

export default (canvas: Ref<fabric.Canvas>) => {
  function initCanvasMask(width: number, height: number) {
    const canvasMask = new fabric.Rect({
      objectCaching: false,
      strokeWidth: 0,
      selectable: false,
      evented: false,
      angle: 0,
      width: canvas.value.width,
      height: canvas.value.height,
      stroke: "#333333",
      fill: "#333333",
      originX: "center",
      originY: "center",
      left: (canvas.value.width || 0) / 2,
      top: (canvas.value.height || 0) / 2,
      //@ts-expect-error
      id: "canvasMask",
      _disalowSelecting: true,
    });
    const c = new fabric.Rect({
      width: width,
      height: height,
      originX: "center",
      originY: "center",
      left: 0,
      top: 0,
    });
    c.inverted = true;
    canvasMask.clipPath = c;
    canvas.value.add(markRaw(canvasMask));
    canvas.value.renderAll();
    return canvasMask;
  }

  return { initCanvasMask };
};
