import { fabric } from "fabric";

export default (
  canvas: Ref<fabric.Canvas>,
  bgImg: Ref<fabric.Image>,
  cropRect: Ref<fabric.Rect>,
  cropMask: Ref<fabric.Rect>,
  cropLeftFirstThird: Ref<fabric.Line>,
  cropLeftSecondThird: Ref<fabric.Line>,
  cropTopFirstThird: Ref<fabric.Line>,
  cropTopSecondThird: Ref<fabric.Line>,
  snapTopLine: Ref<fabric.Line>,
  snapBottomLine: Ref<fabric.Line>,
  snapLeftLine: Ref<fabric.Line>,
  snapRightLine: Ref<fabric.Line>,
  snapCenterHorizontalLine: Ref<fabric.Line>,
  snapCenterVerticalLine: Ref<fabric.Line>
) => {
  const stateStore = useStateStore();
  const cropStore = useCropStore();

  function goForward() {
    if (stateStore.stateIndex < stateStore.snapshots.length - 1) {
      const redoState = stateStore.restoreRedoSnapshot();
      canvas.value.loadFromJSON(redoState.json, function () {
        afterCanvasJsonParsed();
      });
      cropStore.setState(redoState.cropStore);
      stateStore.canvasSize = redoState.canvasSize;
    }
  }

  function goBack() {
    if (stateStore.snapshots.length > 0) {
      const undoState = stateStore.restoreSnapshot();
      canvas.value.loadFromJSON(undoState.json, function () {
        afterCanvasJsonParsed();
      });
      cropStore.setState(undoState.cropStore);
      stateStore.canvasSize = undoState.canvasSize;
    }
  }

  function afterCanvasJsonParsed() {
    canvas.value.renderAll();
    canvas.value.getObjects().map(function (o) {
      //@ts-expect-error
      switch (o.id) {
        case "background":
          //@ts-expect-error
          bgImg.value = o;
          return;
        case "cropRect":
          cropRect.value = o;
          return;
        case "cropMask":
          cropMask.value = o;
          return;
        case "cropMaskLineTF":
          //@ts-expect-error
          cropTopFirstThird.value = o;
          return;
        case "cropMaskLineTS":
          //@ts-expect-error
          cropTopSecondThird.value = o;
          return;
        case "cropMaskLineLF":
          //@ts-expect-error
          cropLeftFirstThird.value = o;
          return;
        case "cropMaskLineLS":
          //@ts-expect-error
          cropLeftSecondThird.value = o;
          return;
        case "snapLineTop":
          //@ts-expect-error
          snapTopLine.value = o;
          return;
        case "snapLineBottom":
          //@ts-expect-error
          snapBottomLine.value = o;
          return;
        case "snapLineLeft":
          //@ts-expect-error
          snapLeftLine.value = o;
          return;
        case "snapLineRight":
          //@ts-expect-error
          snapRightLine.value = o;
          return;
        case "snapLineCenterY":
          //@ts-expect-error
          snapCenterHorizontalLine.value = o;
          return;
        case "snapLineCenterX":
          //@ts-expect-error
          snapCenterVerticalLine.value = o;
          return;
      }
    });
    canvas.value.renderAll();
  }

  const saveStateBounced = useDebounceFn(async function () {
    stateStore.takeSnapshot(canvas.value);
  }, 500);

  return {
    goForward,
    goBack,
    saveStateBounced,
  };
};
