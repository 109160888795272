<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9609 5.48438C14.2422 5.17969 14.7344 5.17969 15.0156 5.48438C15.3203 5.76562 15.3203 6.25781 15.0156 6.53906L11.0547 10.5L15.0156 14.4844C15.3203 14.7656 15.3203 15.2578 15.0156 15.5391C14.7344 15.8438 14.2422 15.8438 13.9609 15.5391L10 11.5781L6.01562 15.5391C5.73438 15.8438 5.24219 15.8438 4.96094 15.5391C4.65625 15.2578 4.65625 14.7656 4.96094 14.4844L8.92188 10.5L4.96094 6.53906C4.65625 6.25781 4.65625 5.76562 4.96094 5.48438C5.24219 5.17969 5.73438 5.17969 6.01562 5.48438L10 9.44531L13.9609 5.48438Z"
      fill="currentColor"
    />
  </svg>
</template>
