import type { I18nOptions } from "vue-i18n";
import { createI18n, I18n } from "vue-i18n";
import { nextTick } from "vue";

import messages from "@intlify/unplugin-vue-i18n/messages";

export const i18n = createI18n({
  locale: "en", // set locale
  allowComposition: true,
  fallbackLocale: "en",
  messages: messages as I18nOptions["messages"], // set locale messages
  warnHtmlInMessage: "off",
});

export const i18nGlobal = i18n.global;

export async function loadLanguageAsync(i18nLocal: I18n, lang: string) {
  // If the language hasn't been loaded yet
  i18nLocal.global.locale = lang;

  // Vite plugin handles loading language files

  return nextTick();
}
