<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.44801 14C8.19166 14 7.94348 13.8998 7.7597 13.7179L5.28515 11.2685C4.90495 10.8921 4.90495 10.2818 5.28515 9.90546C5.66535 9.52905 6.28187 9.52905 6.66207 9.90546L8.26807 11.4951C9.25894 10.0043 11.2482 7.35156 13.5642 6.1156C14.0376 5.86304 14.6281 6.03809 14.8832 6.5066C15.1384 6.97514 14.9615 7.55974 14.4883 7.81231C11.737 9.2805 9.31979 13.4687 9.2956 13.5108C9.14586 13.7728 8.88074 13.9506 8.57941 13.9912C8.5355 13.9971 8.4916 14 8.44801 14Z"
      fill="currentColor"
    />
  </svg>
</template>
