<template>
  <div v-if="canvas" class="flex justify-start p-3">
    <div class="flex gap-2 rounded-md bg-gray-500 px-3">
      <div
        class="relative text-right font-inter text-xs font-medium leading-8 text-gray-100"
      >
        <span
          class="inline-block min-w-[32px] cursor-pointer"
          @click="openedZoomDrop = true"
        >
          {{ Math.round(zoom * 10) / 10 }}%
        </span>
        <div
          v-if="openedZoomDrop"
          ref="zoomDrop"
          class="absolute bottom-10 left-[50%] translate-x-[-50%] rounded-md bg-gray-500 py-1 text-center leading-6"
        >
          <PerfectScrollbar class="h-[140px] space-y-1">
            <div
              v-for="predZoom in predefinedZooms"
              :key="predZoom"
              class="cursor-pointer bg-gray-500 px-3.5 text-gray-100 transition duration-300 hover:bg-gray-600 hover:text-gray-50"
              @click="setZoomByClick(predZoom)"
            >
              {{ predZoom }}%
            </div>
          </PerfectScrollbar>
          <div
            class="cursor-pointer bg-gray-500 px-3.5 text-gray-100 transition duration-300 hover:bg-gray-600 hover:text-gray-50"
            @click="setZoomByClick(stateStore.fitZoom * 100)"
          >
            {{ $t("zoom.fit") }}
          </div>
        </div>
      </div>
      <TSliderV2
        :model-value="zoom"
        :min="10"
        :max="500"
        xs
        dark
        @update:model-value="setZoom"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
  import TSliderV2 from "@/ui/TSliderV2/TSliderV2.vue";

  const props = defineProps<{ canvas: any }>();
  const stateStore = useStateStore();
  const cropStore = useCropStore();

  const zoom = ref(10);
  const openedZoomDrop = ref(false);
  const zoomDrop = ref();
  const workspaceEl = document.querySelector("#workspace") as HTMLElement;
  const predefinedZooms = [10, 25, 50, 75, 100, 200, 300, 400, 500];
  const zooming = ref(false);

  function setZoom(value: number) {
    openedZoomDrop.value = false;
    zooming.value = true;
    if (props.canvas) {
      props.canvas.value.setZoom(value / 100);
      zoom.value = value;
      if (value / 100 <= stateStore.fitZoom) {
        props.canvas.value.setWidth(workspaceEl.clientWidth);
        props.canvas.value.setHeight(workspaceEl.clientHeight);
        workspaceEl.scrollTop = 0;
        workspaceEl.scrollLeft = 0;
        window.$event.emit("centerCanvas");
      } else {
        if (cropStore.isCropping) {
          const differebceX =
            props.canvas.value.viewportTransform[4] < 0
              ? 0
              : props.canvas.value.viewportTransform[4];
          const differebceY =
            props.canvas.value.viewportTransform[5] < 0
              ? 0
              : props.canvas.value.viewportTransform[5];
          const bgImage = props.canvas.value
            .getObjects()
            .find((obj: any) => obj.id === "background");
          if (bgImage) {
            props.canvas.value.setWidth(
              bgImage.getBoundingRect(true, true).width * (value / 100) +
                differebceX * 2
            );
            props.canvas.value.setHeight(
              bgImage.getBoundingRect(true, true).height * (value / 100) +
                differebceY * 2
            );
          }
        } else {
          const differebceX = props.canvas.value.viewportTransform[4];
          const differebceY = props.canvas.value.viewportTransform[5];
          props.canvas.value.setWidth(
            stateStore.canvasSize.width * (value / 100) + differebceX * 2
          );
          props.canvas.value.setHeight(
            stateStore.canvasSize.height * (value / 100) + differebceY * 2
          );
        }
        const canvasElement = document.querySelector(
          ".canvas-container"
        ) as HTMLElement;

        if (
          workspaceEl.scrollTop + workspaceEl.clientHeight >
          canvasElement.clientHeight
        ) {
          workspaceEl.scrollTop =
            canvasElement.clientHeight - workspaceEl.clientHeight;
        }
        if (
          workspaceEl.scrollLeft + workspaceEl.clientWidth >
          canvasElement.clientWidth
        ) {
          workspaceEl.scrollLeft =
            canvasElement.clientWidth - workspaceEl.clientWidth;
        }
      }
      zooming.value = false;
      props.canvas.value.renderAll();
      window.$event.emit("recalculateCanvasMask");
    }
  }

  function setZoomByClick(value: number) {
    if (value < 100 && zoom.value > 100) {
      setZoom(100);
    }
    if (value > 75 && zoom.value < 75) {
      setZoom(75);
    }
    setZoom(value);
  }

  function getZoom() {
    if (props.canvas && !zooming.value) {
      zoom.value = Math.round(props.canvas.value.getZoom() * 1000) / 10;
    }
  }

  onClickOutside(zoomDrop, () => (openedZoomDrop.value = false));

  onMounted(() => {
    getZoom();
    window.$event.on("refreshZoom", getZoom);
  });
</script>
