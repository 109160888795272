import { fabric } from "fabric";
import useMask from "./usePictures/useMask";

export default (canvas: Ref<fabric.Canvas>, cropRect: Ref<fabric.Rect>) => {
  async function addPicture(file: string | ArrayBuffer, url?: boolean) {
    const stateStore = useStateStore();
    stateStore.loading = true;
    if (url && typeof file === "string") {
      file = (await imageUrlToBase64(
        file + "?auto=compress&cs=tinysrgb&dpr=2&h=1500&w=1500"
      )) as string;
    }
    const img = new Image();
    img.src = file as string;
    img.onload = () => {
      const picture = new fabric.Image(img, {
        left: 0,
        top: 0,
        angle: 0,
        originX: "center",
        originY: "center",
        crossOrigin: "anonymous",
        lockSkewingX: true,
        lockSkewingY: true,
        //@ts-expect-error
        _allowSnaping: true,
        _ableToOrder: true,
        _file: file,
      });
      //@ts-expect-error
      picture._originImage = picture.getSvgSrc();
      const scale = Math.min(
        (0.6 * (cropRect.value.width || 1)) / (picture.width || 1),
        (0.6 * (cropRect.value.height || 1)) / (picture.height || 1)
      );
      picture.scaleX = scale;
      picture.scaleY = scale;
      //@ts-expect-error
      picture.cropWidth = picture.width;
      //@ts-expect-error
      picture.cropHeight = picture.height;

      picture.filters = [];

      picture.filters.push(new fabric.Image.filters.Invert({ invert: false }));
      picture.filters.push(
        new fabric.Image.filters.Brightness({ brightness: 0 })
      );
      picture.filters.push(
        new fabric.Image.filters.Saturation({ saturation: 0 })
      );
      picture.filters.push(new fabric.Image.filters.Contrast({ contrast: 0 }));
      picture.filters.push(
        new fabric.Image.filters.HueRotation({ rotation: 0 })
      );
      picture.filters.push(new fabric.Image.filters.Noise({ noise: 0 }));
      picture.filters.push(new fabric.Image.filters.Blur({ blur: 0 }));
      picture.setCoords();
      canvas.value.viewportCenterObject(picture);
      canvas.value.add(markRaw(picture));
      canvas.value.setActiveObject(picture);
      canvas.value.renderAll();
      stateStore.loading = false;
    };
  }

  function addMaskToPicture(picture: any, maskType: string) {
    const width =
      picture._originalElement.naturalWidth -
      (picture.cropX || 0) -
      (picture.cropXr || 0);
    const height =
      picture._originalElement.naturalHeight -
      (picture.cropY || 0) -
      (picture.cropYb || 0);
    const size = Math.min(width, height);
    if (!picture.clipPath) {
      if (size === width) {
        picture.maskCropY = (height - width) / 2;
        picture.cropY = picture.cropY + (height - width) / 2;
      } else {
        picture.maskCropX = (width - height) / 2;
        picture.cropX = picture.cropX + (width - height) / 2;
      }
      picture.width = size;
      picture.height = size;
      picture.setControlsVisibility({
        mb: false,
        ml: false,
        mr: false,
        mt: false,
      });
    } else {
      picture.clipPath = null;
      canvas.value.renderAll();
    }
    //@ts-expect-error
    const mask = useMask[maskType]();
    const maskScale = size / (Math.max(mask.width, mask.height) || 1);
    mask.set({
      left: 0,
      top: 0,
      scaleX: maskScale,
      scaleY: maskScale,
      absolutePositioned: false,
    });
    picture.clipPath = mask;
    picture.setCoords();
    canvas.value.renderAll();
  }

  function resetMask(picture: any) {
    const width =
      picture._originalElement.naturalWidth -
      (picture.cropX || 0) -
      (picture.cropXr || 0) +
      (picture.maskCropX || 0);
    const height =
      picture._originalElement.naturalHeight -
      (picture.cropY || 0) -
      (picture.cropYb || 0) +
      (picture.maskCropY || 0);
    const size = Math.min(width, height);
    picture.clipPath = null;
    if (size === picture.cropWidth) {
      picture.cropY = (picture.cropY || 0) - (picture.maskCropY || 0);
    } else {
      picture.cropX = (picture.cropX || 0) - (picture.maskCropX || 0);
    }
    picture.width = width;
    picture.height = height;
    picture.setControlsVisibility({
      mb: true,
      ml: true,
      mr: true,
      mt: true,
    });
    picture.setCoords();
    canvas.value.renderAll();
  }

  const imageUrlToBase64 = async (url: string) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
      reader.onerror = reject;
    });
  };

  return { addPicture, addMaskToPicture, resetMask };
};
