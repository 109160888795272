export default {
  sepia: function (val: number) {
    return [
      1 - 0.607 * val,
      0.769 * val,
      0.189 * val,
      0,
      0,
      0.349 * val,
      1 - 0.314 * val,
      0.168 * val,
      0,
      0,
      0.272 * val,
      0.534 * val,
      1 - 0.869 * val,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
    ];
  },

  brownie: function (val: number) {
    return [
      1 - 0.4003 * val,
      0.34553 * val,
      -0.27082 * val,
      0,
      0.186 * val,
      -0.0377 * val,
      1 - 0.13905 * val,
      0.15059 * val,
      0,
      -0.1449 * val,
      0.24113 * val,
      -0.07441 * val,
      1 - 0.55028 * val,
      0,
      -0.02965 * val,
      0,
      0,
      0,
      1,
      0,
    ];
  },

  vintage: function (val: number) {
    return [
      1 - 0.37207 * val,
      0.32021 * val,
      -0.03965 * val,
      0,
      0.03784 * val,
      0.02578 * val,
      1 - 0.35589 * val,
      0.03259 * val,
      0,
      0.02926 * val,
      0.0466 * val,
      -0.08512 * val,
      1 - 0.47584 * val,
      0,
      0.02023 * val,
      0,
      0,
      0,
      1,
      0,
    ];
  },

  kodachrome: function (val: number) {
    return [
      1 + 0.12855 * val,
      -0.39673 * val,
      -0.03992 * val,
      0,
      0.24991 * val,
      -0.16404 * val,
      1 + 0.08352 * val,
      -0.05498 * val,
      0,
      0.09698 * val,
      -0.16786 * val,
      -0.56034 * val,
      1 + 0.60148 * val,
      0,
      0.13972 * val,
      0,
      0,
      0,
      1,
      0,
    ];
  },

  technicolor: function (val: number) {
    return [
      1 + 0.91252 * val,
      -0.85453 * val,
      -0.09155 * val,
      0,
      0.04624 * val,
      -0.30878 * val,
      1 + 0.76589 * val,
      -0.10601 * val,
      0,
      -0.27589 * val,
      -0.2311 * val,
      -0.75018 * val,
      1 + 0.84759 * val,
      0,
      0.12137 * val,
      0,
      0,
      0,
      1,
      0,
    ];
  },

  polaroid: function (val: number) {
    return [
      1 + 0.438 * val,
      -0.062 * val,
      -0.062 * val,
      0,
      0,
      -0.122 * val,
      1 + 0.378 * val,
      -0.122 * val,
      0,
      0,
      -0.016 * val,
      -0.016 * val,
      1 + 0.438 * val,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
    ];
  },

  baw: function (val: number) {
    return [
      1,
      0,
      0,
      0,
      0,
      0 + val,
      1 - val,
      0,
      0,
      0,
      0 + val,
      0,
      1 - val,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
    ];
  },

  temperature: function (val: number) {
    return [
      1 + val,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
      0,
      0,
      0,
      0,
      1 - val,
      0,
      0,
      0,
      0,
      0,
      1,
      0,
    ];
  },
};
