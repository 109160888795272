<template>
  <div class="color-type">
    <t-input v-model="modelColor" :label="name" :placeholder="name"></t-input>
  </div>
</template>

<script>
import TInput from "../../TInput/TInput.vue";

export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "",
    },
  },
  components: {
    TInput,
  },
  computed: {
    modelColor: {
      get() {
        return this.color;
      },
      set(val) {
        this.$emit("inputColor", val);
      },
    },
  },
};
</script>

<style lang="scss">
.color-type {
  display: flex;
  margin-top: 8px;
  margin-bottom: 8px;
}
</style>
