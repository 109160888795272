import useRecalculateSnaps from "../useCrop/useRecalculateSnaps";

export default (props: {
  canvas: Ref<fabric.Canvas>;
  bgImg: Ref<fabric.Image>;
  cropRect: Ref<fabric.Rect>;
  cropMask: Ref<fabric.Rect>;
  cropLeftFirstThird: Ref<fabric.Line>;
  cropLeftSecondThird: Ref<fabric.Line>;
  cropTopFirstThird: Ref<fabric.Line>;
  cropTopSecondThird: Ref<fabric.Line>;
  snapTopLine: Ref<fabric.Line>;
  snapBottomLine: Ref<fabric.Line>;
  snapLeftLine: Ref<fabric.Line>;
  snapRightLine: Ref<fabric.Line>;
  snapCenterHorizontalLine: Ref<fabric.Line>;
  snapCenterVerticalLine: Ref<fabric.Line>;
  auto: any;
  reorderLayersBounced: any;
  setCanvaSize: any;
}) => {
  const globalEmitsHandler = window.$event;
  const stateStore = useStateStore();

  const { crop } = useCrop(props);
  const { recalculateSpansLines } = useRecalculateSnaps(props);

  globalEmitsHandler.on("lockRatio", (val: boolean) => {
    crop.lockRatio(val);
    props.auto();
  });
  globalEmitsHandler.on("bg-flipX", () => {
    props.bgImg.value.flipX = !props.bgImg.value.flipX;
    props.canvas.value.renderAll();
  });
  globalEmitsHandler.on("bg-flipY", () => {
    props.bgImg.value.flipY = !props.bgImg.value.flipY;
    props.canvas.value.renderAll();
  });
  globalEmitsHandler.on("resetCrop", () => {
    crop.resetCrop();
    recalculateSpansLines();
    props.auto();
    props.reorderLayersBounced();
  });
  globalEmitsHandler.on("showCropper", () => {
    crop.showCropper();
    stateStore.canvasSize.width = props.bgImg.value.width || 1;
    stateStore.canvasSize.height = props.bgImg.value.height || 1;
    props.auto();
  });
  globalEmitsHandler.on("hideCropper", () => {
    crop.hideCropper();
    recalculateSpansLines();
    props.auto();
  });
  globalEmitsHandler.on("rotateImg", (val: number) => {
    crop.rotateBg(val);
    props.auto();
  });
  globalEmitsHandler.on("rotate90", (val: any) => {
    crop.rotate90(val);
    if (Math.abs(val.actual / 90) % 2) {
      props.setCanvaSize(props.bgImg.value.height, props.bgImg.value.width);
    } else {
      props.setCanvaSize(props.bgImg.value.width, props.bgImg.value.height);
    }
    props.auto();
  });
  globalEmitsHandler.on("crop", () => {
    crop.crop();
    stateStore.canvasSize.width = props.cropRect.value.width || 1;
    stateStore.canvasSize.height = props.cropRect.value.height || 1;
    recalculateSpansLines();
    props.auto();
    props.reorderLayersBounced();
    stateStore.takeSnapshot(props.canvas.value);
  });
  globalEmitsHandler.on("setCropHeight", (val: number) => {
    crop.setCropHeight(val);
    props.auto();
  });
  globalEmitsHandler.on("setCropWidth", (val: number) => {
    crop.setCropWidth(val);
    props.auto();
  });
  globalEmitsHandler.on(
    "setCropRatio",
    (event: {
      val: number;
      custom?: boolean;
      xy?: { x: number; y: number };
    }) => {
      crop.setCropRatio(event.val, event.custom, event.xy);
      props.auto();
    }
  );
  globalEmitsHandler.on("setDimension", (val: string) => {
    crop.setDimension(val);
    props.auto();
  });
};
