import { fabric } from "fabric";
import getRegularPolygonPoints from "@/utils/getRegularPolygonPoints";
import getStartPolygonPoints from "@/utils/getStartPolygonPoints";

export default {
  square: function () {
    return new fabric.Rect({
      width: 300,
      height: 300,
      originX: "center",
      originY: "center",
      //@ts-expect-error
      _type: "square",
    });
  },
  circle: function () {
    return new fabric.Circle({
      radius: 150,
      originX: "center",
      originY: "center",
      //@ts-expect-error
      _type: "circle",
    });
  },
  triangle: function () {
    return new fabric.Triangle({
      width: 300,
      height: 300,
      originX: "center",
      originY: "center",
      //@ts-expect-error
      _type: "triangle",
    });
  },
  pentagon: function () {
    return new fabric.Polygon(getRegularPolygonPoints(5, 200), {
      originX: "center",
      originY: "center",
      //@ts-expect-error
      _type: "pentagon",
    });
  },
  hexagon: function () {
    return new fabric.Polygon(getRegularPolygonPoints(6, 200), {
      originX: "center",
      originY: "center",
      //@ts-expect-error
      _type: "hexagon",
    });
  },
  star_5: function () {
    return new fabric.Polygon(getStartPolygonPoints(5, 200, 100), {
      originX: "center",
      originY: "center",
      //@ts-expect-error
      _type: "star_5",
    });
  },
  star_6: function () {
    return new fabric.Polygon(getStartPolygonPoints(6, 200, 100), {
      originX: "center",
      originY: "center",
      //@ts-expect-error
      _type: "star_6",
    });
  },
  star_8: function () {
    return new fabric.Polygon(getStartPolygonPoints(8, 200, 150), {
      originX: "center",
      originY: "center",
      //@ts-expect-error
      _type: "star_8",
    });
  },
  star_16: function () {
    return new fabric.Polygon(getStartPolygonPoints(16, 200, 150), {
      originX: "center",
      originY: "center",
      //@ts-expect-error
      _type: "star_16",
    });
  },
  star_20: function () {
    return new fabric.Polygon(getStartPolygonPoints(20, 200, 175), {
      originX: "center",
      originY: "center",
      //@ts-expect-error
      _type: "star_20",
    });
  },
  rect_with_triangle: function () {
    return new fabric.Polygon(
      [
        { x: 0, y: 0 },
        { x: 300, y: 0 },
        { x: 400, y: 100 },
        { x: 300, y: 200 },
        { x: 0, y: 200 },
      ],
      {
        fill: "#cbcbcd",
        originX: "center",
        originY: "center",
        strokeWidth: 0,
        //@ts-expect-error
        _allowSnaping: true,
        _ableToOrder: true,
        _basicShape: true,
      }
    );
  },
  rect_without_triangle: function () {
    return new fabric.Polygon(
      [
        { x: 0, y: 0 },
        { x: 300, y: 0 },
        { x: 300, y: 200 },
        { x: 0, y: 200 },
        { x: 100, y: 100 },
      ],
      {
        fill: "#cbcbcd",
        originX: "center",
        originY: "center",
        strokeWidth: 0,
        //@ts-expect-error
        _allowSnaping: true,
        _ableToOrder: true,
        _basicShape: true,
      }
    );
  },
  rect_with_and_without_triangle: function () {
    return new fabric.Polygon(
      [
        { x: 0, y: 0 },
        { x: 300, y: 0 },
        { x: 400, y: 100 },
        { x: 300, y: 200 },
        { x: 0, y: 200 },
        { x: 100, y: 100 },
      ],
      {
        fill: "#cbcbcd",
        originX: "center",
        originY: "center",
        strokeWidth: 0,
        //@ts-expect-error
        _allowSnaping: true,
        _ableToOrder: true,
        _basicShape: true,
      }
    );
  },
  rect_without_and_without_triangle: function () {
    return new fabric.Polygon(
      [
        { x: 0, y: 0 },
        { x: 400, y: 0 },
        { x: 300, y: 100 },
        { x: 400, y: 200 },
        { x: 0, y: 200 },
        { x: 100, y: 100 },
      ],
      {
        fill: "#cbcbcd",
        originX: "center",
        originY: "center",
        strokeWidth: 0,
        //@ts-expect-error
        _allowSnaping: true,
        _ableToOrder: true,
        _basicShape: true,
      }
    );
  },
  rect_with_and_with_triangle: function () {
    return new fabric.Polygon(
      [
        { x: 100, y: 0 },
        { x: 300, y: 0 },
        { x: 400, y: 100 },
        { x: 300, y: 200 },
        { x: 100, y: 200 },
        { x: 0, y: 100 },
      ],
      {
        fill: "#cbcbcd",
        originX: "center",
        originY: "center",
        strokeWidth: 0,
        //@ts-expect-error
        _allowSnaping: true,
        _ableToOrder: true,
        _basicShape: true,
      }
    );
  },
  rect_with_hlf_circle: function () {
    return new fabric.Path(
      "M 300 150 L 300 400 L 0 400 L 0 150 A150,150 0 0,1 300,150 z",
      {
        fill: "#cbcbcd",
        originX: "center",
        originY: "center",
        strokeWidth: 0,
        //@ts-expect-error
        _allowSnaping: true,
        _ableToOrder: true,
        _basicShape: true,
      }
    );
  },
};
