import { defineStore } from "pinia";

type Picture = {
  file: string | ArrayBuffer;
  name: string;
};

interface State {
  pictures: Picture[];
  masksPreview: {
    type: string;
    preview: string;
  }[];
  douPreviews: string[];
}

export const usePicturesStore = defineStore("picturesStore", {
  state: (): State => ({
    pictures: [],
    masksPreview: [
      {
        type: "square",
        preview: "",
      },
      {
        type: "circle",
        preview: "",
      },
      {
        type: "triangle",
        preview: "",
      },
      {
        type: "pentagon",
        preview: "",
      },
      {
        type: "hexagon",
        preview: "",
      },
      {
        type: "star_5",
        preview: "",
      },
      {
        type: "star_6",
        preview: "",
      },
      {
        type: "star_8",
        preview: "",
      },
      {
        type: "star_16",
        preview: "",
      },
      {
        type: "star_20",
        preview: "",
      },
    ],
    douPreviews: [],
  }),

  getters: {},

  actions: {},
});
