import { defineStore } from "pinia";

interface State {
  isCropping: boolean;
  lockRatio: boolean;
  cropperSize: {
    width: number;
    height: number;
  };
  rotate: number;
}

export const useCropStore = defineStore("cropStore", {
  state: (): State => ({
    isCropping: false,
    lockRatio: true,
    cropperSize: {
      width: 0,
      height: 0,
    },
    rotate: 0,
  }),

  getters: {
    getState: (state) => () => {
      return JSON.parse(JSON.stringify(state));
    },
  },

  actions: {
    rotateMinus90() {
      const historyRotate = this.rotate;
      if (this.rotate === 0) {
        this.rotate = 270;
      } else if (this.rotate === 270) {
        this.rotate = 180;
      } else if (this.rotate === 180) {
        this.rotate = 90;
      } else {
        this.rotate = 0;
      }
      window.$event.emit("rotate90", {
        actual: this.rotate,
        history: historyRotate,
      });
    },
    rotatePlus90() {
      const historyRotate = this.rotate;
      if (this.rotate === 0) {
        this.rotate = 90;
      } else if (this.rotate === 90) {
        this.rotate = 180;
      } else if (this.rotate === 180) {
        this.rotate = 270;
      } else {
        this.rotate = 0;
      }
      window.$event.emit("rotate90", {
        actual: this.rotate,
        history: historyRotate,
      });
    },

    setCropperSize(width: number, height: number) {
      this.cropperSize.width = width;
      this.cropperSize.height = height;
    },

    setState(stateSnep: State) {
      this.$patch(stateSnep);
    },
  },
});
