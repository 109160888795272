<template>
  <PanelTitle
    :title="$t('elements.title')"
    :show-back="openedElementsType !== ''"
    @back="openType('')"
  />
  <div v-if="openedElementsType === ''">
    <MenuContentContainer
      :header="$t('elements.basic_shapes')"
      :border-bottom="false"
    >
      <template #header-action>
        <HeaderAction
          :title="$t('show_more')"
          @button-click="openType('basic_shapes')"
        >
          <PlusIcon />
        </HeaderAction>
      </template>
      <PerfectScrollbar style="height: 70px">
        <div class="flex gap-3">
          <div
            v-for="shape in basicShapes.slice(0, 8)"
            :key="shape.name"
            @click="addShape(shape.name)"
          >
            <img :src="shape.url" class="h-[60px] w-auto max-w-none" />
          </div>
        </div>
      </PerfectScrollbar>
    </MenuContentContainer>
  </div>
  <ListOfAll
    v-if="openedElementsType !== ''"
    :title="$t(`elements.${openedElementsType}`)"
    :data="dataOfOpenedType"
    @add-element="addElement"
  />
</template>

<script setup lang="ts">
  const openedElementsType = ref("");
  const dataOfOpenedType: Ref<any> = ref([]);

  const elementsStore = useElementsStore();

  const basicShapes = computed(() => {
    return elementsStore.basicShapes;
  });

  function openType(type: string) {
    openedElementsType.value = type;
    if (type === "basic_shapes") {
      dataOfOpenedType.value = basicShapes.value;
    }
  }

  function addShape(type: string) {
    window.$event.emit("addShape", type);
  }

  function addElement(data: any) {
    if (openedElementsType.value === "basic_shapes") {
      addShape(data);
    }
  }
</script>
