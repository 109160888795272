import { defineStore } from "pinia";
import { IElement } from "types/Elements/Elements";

interface State {
  basicShapes: IElement[];
}

export const useElementsStore = defineStore("elementStore", {
  state: (): State => ({
    basicShapes: [
      { name: "rect", url: "" },
      { name: "circle", url: "" },
      { name: "triangle", url: "" },
      { name: "pentagon", url: "" },
      { name: "hexagon", url: "" },
      { name: "octagon", url: "" },
      { name: "ellipse", url: "" },
      { name: "arrow", url: "" },
      { name: "double_arrow", url: "" },
      { name: "star_4", url: "" },
      { name: "star_5", url: "" },
      { name: "star_6", url: "" },
      { name: "star_8", url: "" },
      { name: "star_16", url: "" },
      { name: "star_20", url: "" },
      { name: "rect_with_triangle", url: "" },
      { name: "rect_without_triangle", url: "" },
      { name: "rect_with_and_without_triangle", url: "" },
      { name: "rect_without_and_without_triangle", url: "" },
      { name: "rect_with_and_with_triangle", url: "" },
      { name: "rect_with_hlf_circle", url: "" },
    ],
  }),

  getters: {
    getState: (state) => () => {
      return JSON.parse(JSON.stringify(state));
    },
  },

  actions: {
    setState(stateSnep: State) {
      this.$patch(stateSnep);
    },
  },
});
