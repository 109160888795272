<template>
  <MenuContentContainer :header="$t('text.types')" border-full>
    <ContentRow>
      <TButton
        class="min-h-[45px] w-full"
        @click="addText($t('text.add_text_heading'), 24, 700)"
      >
        <span class="font-helvetica text-2xl font-bold">
          {{ $t("text.add_text_heading") }}
        </span>
      </TButton>
    </ContentRow>
    <ContentRow>
      <TButton
        class="min-h-[45px] w-full"
        @click="addText($t('text.add_text_subheading'), 18, 700)"
      >
        <span class="font-helvetica text-lg font-bold">
          {{ $t("text.add_text_subheading") }}
        </span>
      </TButton>
    </ContentRow>
    <ContentRow>
      <TButton
        class="min-h-[45px] w-full"
        @click="addText($t('text.add_text_normal'), 14)"
      >
        <span class="font-helvetica text-sm font-normal">
          {{ $t("text.add_text_normal") }}
        </span>
      </TButton>
    </ContentRow>
  </MenuContentContainer>

  <MenuContentContainer
    :header="$t('text.with_effects')"
    :border-bottom="false"
  >
    <ContentRow>
      <TButton
        class="min-h-[45px] w-full"
        @click="addText($t('text.add_text_outlined'), 24, 700, 1)"
      >
        <span class="outlined font-helvetica text-2xl font-bold text-gray-600">
          {{ $t("text.add_text_outlined") }}
        </span>
      </TButton>
    </ContentRow>
    <ContentRow>
      <TButton
        class="min-h-[45px] w-full"
        @click="addText($t('text.add_text_shadow'), 24, 700, 0, true)"
      >
        <span
          class="whitespace-normal font-helvetica text-2xl font-bold [text-shadow:rgba(203,203,205,0.3)_5px_5px_5px]"
        >
          {{ $t("text.add_text_shadow") }}
        </span>
      </TButton>
    </ContentRow>
    <!-- <ContentRow>
      <TButton
        class="w-full min-h-[45px]"
        @click="addText($t('text.add_text_curve'), 24, 'bold', 0, false, true)"
      >
        <span class="font-helvetica text-2xl font-bold">
          <svg viewBox="0 0 202 80" height="80" width="202">
            <path
              id="curve"
              d="M 10 70 Q 100 -20 190 70"
              style="fill: transparent !important"
            />
            <text width="200" fill="var(--c-g100)">
              <textPath xlink:href="#curve">
                {{ $t("text.add_text_curve") }}
              </textPath>
            </text>
          </svg>
        </span>
      </TButton>
    </ContentRow> -->
  </MenuContentContainer>
</template>

<script setup lang="ts">
  import TButton from "@/ui/TButton/TButton.vue";

  function addText(
    text: string,
    size: number,
    weight: number = 400,
    stroke: number = 0,
    shadow: boolean = false,
    curve: boolean = false
  ) {
    window.$event.emit("addText", {
      text,
      size,
      weight,
      stroke,
      shadow: shadow ? "#0000007F 15px 15px 10px" : undefined,
      curve,
    });
  }
</script>

<style scoped>
  .outlined {
    text-shadow:
      rgb(203, 203, 205) -1px -1px 0px,
      rgb(203, 203, 205) 1px -1px 0px,
      rgb(203, 203, 205) -1px 1px 0px,
      rgb(203, 203, 205) 1px 1px 0px;
  }
</style>
