<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 4.5C13.5 4.99219 13.0898 5.375 12.625 5.375H7.375C6.88281 5.375 6.5 4.99219 6.5 4.5C6.5 4.03516 6.88281 3.625 7.375 3.625H12.625C13.0898 3.625 13.5 4.03516 13.5 4.5ZM16.125 8C16.125 8.49219 15.7148 8.875 15.25 8.875H4.75C4.25781 8.875 3.875 8.49219 3.875 8C3.875 7.53516 4.25781 7.125 4.75 7.125H15.25C15.7148 7.125 16.125 7.53516 16.125 8ZM3.875 15C3.875 14.5352 4.25781 14.125 4.75 14.125H15.25C15.7148 14.125 16.125 14.5352 16.125 15C16.125 15.4922 15.7148 15.875 15.25 15.875H4.75C4.25781 15.875 3.875 15.4922 3.875 15ZM13.5 11.5C13.5 11.9922 13.0898 12.375 12.625 12.375H7.375C6.88281 12.375 6.5 11.9922 6.5 11.5C6.5 11.0352 6.88281 10.625 7.375 10.625H12.625C13.0898 10.625 13.5 11.0352 13.5 11.5Z"
      fill="currentColor"
    />
  </svg>
</template>
