import { fabric } from "fabric";
import useCoords from "./useCoords";
import useMoving from "./useMoving";

export default (canvas: Ref<fabric.Canvas>, bgImg: Ref<fabric.Image>) => {
  const cropStore = useCropStore();
  const { getTopPositionOnBg, getLeftPositionOnBg } = useCoords(bgImg);
  const { movingAngle } = useMoving(canvas, bgImg);

  function scaling(
    cropper: fabric.Rect,
    corner: "tl" | "tr" | "bl" | "br" | "mt" | "mb" | "ml" | "mr"
  ) {
    const angle =
      (bgImg.value.angle ? bgImg.value.angle : 0) - cropStore.rotate;

    if (corner === "tl") {
      scaleTL(cropper, angle);
    }

    if (corner === "bl") {
      scaleBL(cropper, angle);
    }

    if (corner === "tr") {
      scaleTR(cropper, angle);
    }

    if (corner === "br") {
      scaleBR(cropper, angle);
    }

    if (corner === "mt") {
      scaleMT(cropper, angle);
    }

    if (corner === "mb") {
      scaleMB(cropper, angle);
    }

    if (corner === "ml") {
      scaleML(cropper, angle);
    }

    if (corner === "mr") {
      scaleMR(cropper, angle);
    }
  }

  function naturalScale(
    cropper: fabric.Rect,
    cropWidth: number,
    cropHeight: number,
    bgWidth: number,
    bgHeight: number
  ) {
    cropper.width = cropWidth;
    cropper.height = cropHeight;
    cropStore.setCropperSize(cropWidth, cropHeight);
    const dx = (cropper.left || 0) - bgWidth / 2;
    const dy = (cropper.top || 0) - bgHeight / 2;
    setScaleCenter(cropper, bgWidth, bgHeight);
    cropper.setCoords();
    movingAngle(cropper, dx, dy);
    return;
  }

  function setScaleCenter(
    cropper: fabric.Rect,
    bgWidth: number,
    bgHeight: number
  ) {
    cropper.scaleX = 1;
    cropper.scaleY = 1;
    cropper.top = bgHeight / 2;
    cropper.left = bgWidth / 2;
  }

  function getDimensions(cropper: fabric.Rect, angle: number) {
    const bgWidth = bgImg.value.getBoundingRect(true, true).width;
    const bgHeight = bgImg.value.getBoundingRect(true, true).height;
    const cropWidth = (cropper.width || 0) * (cropper.scaleX || 0);
    const cropHeight = (cropper.height || 0) * (cropper.scaleY || 0);
    const cropTop = (cropper.top || 0) - cropHeight / 2;
    const cropLeft = (cropper.left || 0) - cropWidth / 2;
    const cropBottom = cropTop + cropHeight;
    const cropRight = cropLeft + cropWidth;
    const alfa = Math.atan(cropHeight / (cropWidth || 1));
    const delta = fabric.util.degreesToRadians(angle < 0 ? 90 + angle : angle);

    return {
      bgWidth,
      bgHeight,
      cropWidth,
      cropHeight,
      cropTop,
      cropLeft,
      cropBottom,
      cropRight,
      alfa,
      delta,
    };
  }

  function scaleTL(cropper: fabric.Rect, angle: number) {
    const {
      bgWidth,
      bgHeight,
      cropWidth,
      cropHeight,
      cropTop,
      cropLeft,
      cropBottom,
      cropRight,
      alfa,
      delta,
    } = getDimensions(cropper, angle);
    const { ll: bl } = getLeftPositionOnBg(cropBottom);
    const { tt: rt } = getTopPositionOnBg(cropRight);
    const { ll: tl } = getLeftPositionOnBg(cropTop > rt ? cropTop : rt);
    const dxb = cropLeft - bl;
    let dxr = 0;
    let dxl = 0;

    if (cropTop < rt) {
      dxr = (rt - cropTop) / (Math.tan(alfa) || 1);
    }
    if (cropLeft < tl) {
      const dht = (tl - cropLeft - dxr) * Math.tan(alfa);
      const xt = dht * Math.tan(delta);
      const divisor = Math.tan(alfa) + Math.tan(Math.PI / 2 - delta);
      const dx = (xt * Math.tan(Math.PI / 2 - delta)) / (divisor || 1);
      dxl = tl - cropLeft - dx;
    }

    const min = Math.min(-dxr, -dxl, dxb);

    if (min >= 0) {
      naturalScale(cropper, cropWidth, cropHeight, bgWidth, bgHeight);
      return;
    }

    if (min === -dxr) {
      cropper.height = cropHeight - (rt - cropTop);
      cropper.width = cropWidth - dxr;
      setScaleCenter(cropper, bgWidth, bgHeight);
      const dy = (cropper.top || 0) - (rt + cropper.height / 2);
      const dx = dy / (Math.tan(alfa) || 1);
      bgImg.value.top = (bgImg.value.top || 0) + dy;
      bgImg.value.left = (bgImg.value.left || 0) + dx;
      return;
    }
    if (min === -dxl) {
      const newWidth = cropWidth - dxl;
      const scale = newWidth / (cropWidth || 1);
      const llt = cropLeft + dxl;
      cropper.height = cropHeight * scale;
      cropper.width = newWidth;
      setScaleCenter(cropper, bgWidth, bgHeight);
      const dx = (cropper.left || 0) - (llt + cropper.width / 2);
      const dy = dx * Math.tan(alfa);
      bgImg.value.left = (bgImg.value.left || 0) + dx;
      bgImg.value.top = (bgImg.value.top || 0) + dy;
      return;
    }
    if (min === dxb) {
      const newWidth = cropWidth + dxb;
      const scale = newWidth / (cropWidth || 1);
      cropper.width = newWidth;
      cropper.height = cropHeight * scale;
      setScaleCenter(cropper, bgWidth, bgHeight);
      const dx = (cropper.left || 0) - (bl + cropper.width / 2);
      const dy = dx * Math.tan(alfa);
      bgImg.value.left = (bgImg.value.left || 0) + dx;
      bgImg.value.top = (bgImg.value.top || 0) + dy;
      return;
    }
  }

  function scaleBL(cropper: fabric.Rect, angle: number) {
    const {
      bgWidth,
      bgHeight,
      cropWidth,
      cropHeight,
      cropTop,
      cropLeft,
      cropBottom,
      cropRight,
      alfa,
      delta,
    } = getDimensions(cropper, angle);

    const { ll: tl } = getLeftPositionOnBg(cropTop);
    const { tb: rb } = getTopPositionOnBg(cropRight);
    const { ll: bl } = getLeftPositionOnBg(cropBottom < rb ? cropBottom : rb);
    const dxt = cropLeft - tl;
    let dxr = 0;
    let dxb = 0;

    if (cropBottom > rb) {
      dxr = (cropBottom - rb) / (Math.tan(alfa) || 1);
    }

    if (cropLeft < bl) {
      const dht = (bl - cropLeft - dxr) * Math.tan(alfa);
      const xb = dht * Math.tan(Math.PI / 2 - delta);
      const divisor = Math.tan(alfa) + Math.tan(delta);
      const dx = (xb * Math.tan(delta)) / (divisor || 1);
      dxb = bl - cropLeft - dx;
    }

    const min = Math.min(-dxr, -dxb, dxt);

    if (min >= 0) {
      naturalScale(cropper, cropWidth, cropHeight, bgWidth, bgHeight);
      return;
    }

    if (min === -dxr) {
      cropper.height = cropHeight - (cropBottom - rb);
      cropper.width = cropWidth - dxr;
      setScaleCenter(cropper, bgWidth, bgHeight);
      const dy = (cropper.top || 0) - (rb - cropper.height / 2);
      const dx = dy / (Math.tan(alfa) || 1);
      bgImg.value.top = (bgImg.value.top || 0) + dy;
      bgImg.value.left = (bgImg.value.left || 0) - dx;
      return;
    }

    if (min === -dxb) {
      const newWidth = cropWidth - dxb;
      const scale = newWidth / (cropWidth || 1);
      const llt = cropLeft + dxb;
      cropper.height = cropHeight * scale;
      cropper.width = newWidth;
      setScaleCenter(cropper, bgWidth, bgHeight);
      const dx = (cropper.left || 0) - (llt + cropper.width / 2);
      const dy = dx * Math.tan(alfa);
      bgImg.value.left = (bgImg.value.left || 0) + dx;
      bgImg.value.top = (bgImg.value.top || 0) - dy;
      return;
    }

    if (min === dxt) {
      const newWidth = cropWidth + dxt;
      const scale = newWidth / (cropWidth || 1);
      cropper.width = newWidth;
      cropper.height = cropHeight * scale;
      setScaleCenter(cropper, bgWidth, bgHeight);
      const dx = (cropper.left || 0) - (tl + cropper.width / 2);
      const dy = dx * Math.tan(alfa);
      bgImg.value.left = (bgImg.value.left || 0) + dx;
      bgImg.value.top = (bgImg.value.top || 0) - dy;
      return;
    }
  }

  function scaleTR(cropper: fabric.Rect, angle: number) {
    const {
      bgWidth,
      bgHeight,
      cropWidth,
      cropHeight,
      cropTop,
      cropLeft,
      cropBottom,
      cropRight,
      alfa,
      delta,
    } = getDimensions(cropper, angle);

    const { lr: br } = getLeftPositionOnBg(cropBottom);
    const { tt: lt } = getTopPositionOnBg(cropLeft);
    const { lr: tr } = getLeftPositionOnBg(cropTop > lt ? cropTop : lt);
    const dxb = br - cropRight;
    let dxl = 0;
    let dxt = 0;

    if (cropTop < lt) {
      dxl = (lt - cropTop) / (Math.tan(alfa) || 1);
    }
    if (tr < cropRight) {
      const dht = (cropRight - tr - dxl) * Math.tan(alfa);
      const xt = dht / (Math.tan(delta) || 1);
      const divisor = Math.tan(alfa) + Math.tan(delta);
      const dx = (xt * Math.tan(delta)) / (divisor || 1);
      dxt = cropRight - tr - dx;
    }

    const min = Math.min(-dxl, -dxt, dxb);

    if (min >= 0) {
      naturalScale(cropper, cropWidth, cropHeight, bgWidth, bgHeight);
      return;
    }

    if (min === -dxl) {
      cropper.height = cropHeight - (lt - cropTop);
      cropper.width = cropWidth - dxl;
      setScaleCenter(cropper, bgWidth, bgHeight);
      const dy = (cropper.top || 0) - (lt + cropper.height / 2);
      const dx = dy / (Math.tan(alfa) || 1);
      bgImg.value.top = (bgImg.value.top || 0) + dy;
      bgImg.value.left = (bgImg.value.left || 0) - dx;
      return;
    }

    if (min === -dxt) {
      const newWidth = cropWidth - dxt;
      const scale = newWidth / (cropWidth || 1);
      const rrt = cropRight - dxt;
      cropper.height = cropHeight * scale;
      cropper.width = newWidth;
      setScaleCenter(cropper, bgWidth, bgHeight);
      const dx = (cropper.left || 0) - (rrt - cropper.width / 2);
      const dy = dx * Math.tan(alfa);
      bgImg.value.left = (bgImg.value.left || 0) + dx;
      bgImg.value.top = (bgImg.value.top || 0) - dy;
      return;
    }

    if (min === dxb) {
      const newWidth = cropWidth + dxb;
      const scale = newWidth / (cropWidth || 1);
      cropper.width = newWidth;
      cropper.height = cropHeight * scale;
      setScaleCenter(cropper, bgWidth, bgHeight);
      const dx = (cropper.left || 0) - (br - cropper.width / 2);
      const dy = dx * Math.tan(alfa);
      bgImg.value.left = (bgImg.value.left || 0) + dx;
      bgImg.value.top = (bgImg.value.top || 0) - dy;
      return;
    }
  }

  function scaleBR(cropper: fabric.Rect, angle: number) {
    const {
      bgWidth,
      bgHeight,
      cropWidth,
      cropHeight,
      cropTop,
      cropLeft,
      cropBottom,
      cropRight,
      alfa,
      delta,
    } = getDimensions(cropper, angle);

    const { lr: tr } = getLeftPositionOnBg(cropTop);
    const { tb: lb } = getTopPositionOnBg(cropLeft);
    const { lr: br } = getLeftPositionOnBg(cropBottom < lb ? cropBottom : lb);
    const dxt = tr - cropRight;
    let dxl = 0;
    let dxb = 0;

    if (cropBottom > lb) {
      dxl = (cropBottom - lb) / (Math.tan(alfa) || 1);
    }

    if (br < cropRight) {
      const dht = (cropRight - br - dxl) * Math.tan(alfa);
      const xb = dht * Math.tan(delta);
      const divisor = Math.tan(alfa) + Math.tan(Math.PI / 2 - delta);
      const dx = (xb * Math.tan(Math.PI / 2 - delta)) / (divisor || 1);
      dxb = cropRight - br - dx;
    }

    const min = Math.min(-dxl, -dxb, dxt);

    if (min >= 0) {
      naturalScale(cropper, cropWidth, cropHeight, bgWidth, bgHeight);
      return;
    }

    if (min === -dxl) {
      cropper.height = cropHeight - (cropBottom - lb);
      cropper.width = cropWidth - dxl;
      setScaleCenter(cropper, bgWidth, bgHeight);
      const dy = (cropper.top || 0) - (lb - cropper.height / 2);
      const dx = dy / (Math.tan(alfa) || 1);
      bgImg.value.top = (bgImg.value.top || 0) + dy;
      bgImg.value.left = (bgImg.value.left || 0) + dx;
      return;
    }

    if (min === -dxb) {
      const newWidth = cropWidth - dxb;
      const scale = newWidth / (cropWidth || 1);
      const rrb = cropRight - dxb;
      cropper.height = cropHeight * scale;
      cropper.width = newWidth;
      setScaleCenter(cropper, bgWidth, bgHeight);
      const dx = (cropper.left || 0) - (rrb - cropper.width / 2);
      const dy = dx * Math.tan(alfa);
      bgImg.value.left = (bgImg.value.left || 0) + dx;
      bgImg.value.top = (bgImg.value.top || 0) + dy;
      return;
    }

    if (min === dxt) {
      const newWidth = cropWidth + dxt;
      const scale = newWidth / (cropWidth || 1);
      cropper.width = newWidth;
      cropper.height = cropHeight * scale;
      setScaleCenter(cropper, bgWidth, bgHeight);
      const dx = (cropper.left || 0) - (tr - cropper.width / 2);
      const dy = dx * Math.tan(alfa);
      bgImg.value.left = (bgImg.value.left || 0) + dx;
      bgImg.value.top = (bgImg.value.top || 0) + dy;
      return;
    }
  }

  function scaleMT(cropper: fabric.Rect, angle: number) {
    const {
      bgWidth,
      bgHeight,
      cropWidth,
      cropHeight,
      cropTop,
      cropLeft,
      cropBottom,
      cropRight,
    } = getDimensions(cropper, angle);

    const { tt: lt } = getTopPositionOnBg(cropLeft);
    const { tt: rt } = getTopPositionOnBg(cropRight);
    const maxT = Math.max(rt, lt);
    if (cropTop >= maxT) {
      naturalScale(cropper, cropWidth, cropHeight, bgWidth, bgHeight);
      return;
    }

    const maxheight = cropBottom - maxT;
    cropper.height = maxheight;
    setScaleCenter(cropper, bgWidth, bgHeight);
    const dy = (cropper.top || 0) - (maxT + maxheight / 2);
    bgImg.value.top = (bgImg.value.top || 0) + dy;
    return;
  }

  function scaleMB(cropper: fabric.Rect, angle: number) {
    const {
      bgWidth,
      bgHeight,
      cropWidth,
      cropHeight,
      cropTop,
      cropLeft,
      cropBottom,
      cropRight,
    } = getDimensions(cropper, angle);

    const { tb: lb } = getTopPositionOnBg(cropLeft);
    const { tb: rb } = getTopPositionOnBg(cropRight);
    const minB = Math.min(rb, lb);
    if (cropBottom <= minB) {
      naturalScale(cropper, cropWidth, cropHeight, bgWidth, bgHeight);
      return;
    }

    const maxheight = minB - cropTop;
    cropper.height = maxheight;
    setScaleCenter(cropper, bgWidth, bgHeight);
    const dy = (cropper.top || 0) - (minB - maxheight / 2);
    bgImg.value.top = (bgImg.value.top || 0) + dy;
    return;
  }

  function scaleML(cropper: fabric.Rect, angle: number) {
    const {
      bgWidth,
      bgHeight,
      cropWidth,
      cropHeight,
      cropTop,
      cropLeft,
      cropBottom,
      cropRight,
    } = getDimensions(cropper, angle);

    const { ll: bl } = getLeftPositionOnBg(cropBottom);
    const { ll: tl } = getLeftPositionOnBg(cropTop);
    const maxL = Math.max(bl, tl);
    if (cropLeft >= maxL) {
      naturalScale(cropper, cropWidth, cropHeight, bgWidth, bgHeight);
      return;
    }

    const maxwidth = cropRight - maxL;
    cropper.width = maxwidth;
    setScaleCenter(cropper, bgWidth, bgHeight);
    const dx = (cropper.left || 0) - (maxL + maxwidth / 2);
    bgImg.value.left = (bgImg.value.left || 0) + dx;
    return;
  }

  function scaleMR(cropper: fabric.Rect, angle: number) {
    const {
      bgWidth,
      bgHeight,
      cropWidth,
      cropHeight,
      cropTop,
      cropLeft,
      cropBottom,
      cropRight,
    } = getDimensions(cropper, angle);

    const { lr: br } = getLeftPositionOnBg(cropBottom);
    const { lr: tr } = getLeftPositionOnBg(cropTop);
    const minR = Math.min(br, tr);
    if (cropRight <= minR) {
      naturalScale(cropper, cropWidth, cropHeight, bgWidth, bgHeight);
      return;
    }

    const maxwidth = minR - cropLeft;
    cropper.width = maxwidth;
    setScaleCenter(cropper, bgWidth, bgHeight);
    const dx = (cropper.left || 0) - (minR - maxwidth / 2);
    bgImg.value.left = (bgImg.value.left || 0) + dx;
    return;
  }

  return {
    scaling,
  };
};
