import useGetActiveObj from "@/components/LayerPanel/Types/useGetActiveObj";

export default (canvas: any, element?: any) => {
  let picture = element;

  if (!element) {
    picture = canvas.value.item(0);
  }

  const filtersStore = useFiltersStore();
  const picturesStore = usePicturesStore();
  const duotone = ref(picture.duotone || []);
  const selectedDuotoneIndex = ref(
    picture.selectedDuotoneIndex === undefined
      ? -1
      : picture.selectedDuotoneIndex
  );
  const previews = computed(() => {
    if (!element) {
      return filtersStore.previews;
    }
    return picturesStore.douPreviews;
  });
  const predefinedDuotone = computed(() => {
    return filtersStore.predefinedDuotone;
  });

  function selectFilter(index: number) {
    if (selectedDuotoneIndex.value === index) {
      return;
    } else {
      picture.selectedDuotoneIndex = selectedDuotoneIndex.value = index;
      picture.duotone = duotone.value = predefinedDuotone.value[index];
      window.$event.emit("filterDuotone", {
        element: picture,
        color1: predefinedDuotone.value[index][0],
        color2: predefinedDuotone.value[index][1],
      });
    }
  }

  function changeColor(index: number, color: string) {
    if (index === 0) {
      picture.duotone = duotone.value = [color, duotone.value[1]];
    } else {
      picture.duotone = duotone.value = [duotone.value[0], color];
    }
    window.$event.emit("filterDuotone", {
      element: picture,
      color1: duotone.value[0],
      color2: duotone.value[1],
    });
  }

  function resetFilter() {
    window.$event.emit("filterDuotone", {
      element: picture,
      color1: "",
      color2: "",
      reset: true,
    });
    picture.selectedDuotoneIndex = selectedDuotoneIndex.value = -1;
    picture.duotone = duotone.value = [];
  }

  function getData() {
    let object = useGetActiveObj(canvas);

    if (!element) {
      object = canvas.value.item(0);
    }
    duotone.value = object.duotone || [];
    selectedDuotoneIndex.value =
      object.selectedDuotoneIndex === undefined
        ? -1
        : object.selectedDuotoneIndex;
  }

  return {
    duotone,
    selectedDuotoneIndex,
    previews,
    predefinedDuotone,
    selectFilter,
    changeColor,
    resetFilter,
    getData,
  };
};
