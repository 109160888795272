<template>
  <div
    class="t-t-slider group/slider relative overflow-hidden rounded-md bg-gray-600"
    @dblclick="reset($event)"
  >
    <vue-slider
      v-model="localVal"
      :min="min"
      :max="max"
      :height="height"
      :duration="0.3"
      :interval="interval"
      :process="process"
      :tooltip="'none'"
      :marks="marks"
    >
      <template #mark="{ pos }">
        <div
          class="z-25 absolute top-0 w-[1px] bg-gray-600 transition duration-500 group-hover/slider:bg-gray-200"
          :class="{
            hidden: pos === 0 || pos === 100,
            'h-[6px]': pos % 25 === 0,
            'h-[4px]': pos % 25 !== 0,
          }"
          :style="{ left: `${pos}%` }"
        ></div> </template
    ></vue-slider>
    <span
      v-if="label !== ''"
      class="absolute top-[50%] z-[1] ml-2 translate-y-[-50%] select-none font-inter text-xs font-medium leading-[1] text-gray-100 transition duration-300 group-hover/slider:text-gray-50"
    >
      {{ label }}
    </span>
    <div
      class="absolute left-[100%] top-[50%] flex translate-x-[-100%] translate-y-[-50%] items-center pr-2 text-right font-inter text-xs font-medium leading-[1] text-gray-100 outline-none transition duration-300 group-hover/slider:text-gray-50"
      :class="{
        'z-50': input,
      }"
    >
      <input
        v-if="input"
        ref="thisInput"
        v-model.number="localVal"
        type="number"
        :min="min"
        :max="max"
        :step="interval"
        class="border-none bg-transparent p-0 text-right font-inter text-xs font-medium leading-[1] text-gray-100 outline-none transition duration-300 group-hover/slider:text-gray-50"
      />
      <span v-else>
        {{ localVal }}
      </span>
      <span v-if="unit !== ''" class="pl-1">{{ unit }}</span>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import VueSlider from "vue-slider-component";
  import { computed } from "vue";

  interface Props {
    modelValue: number;
    resetValue?: number;
    min?: number;
    max?: number;
    unit?: string;
    label?: string;
    interval?: number;
    height?: number;
    process?: any;
    input?: boolean;
  }
  const props = withDefaults(defineProps<Props>(), {
    resetValue: undefined,
    min: 0,
    max: 100,
    unit: "%",
    label: "",
    interval: 1,
    height: 32,
    process: true,
    input: false,
  });

  const emit = defineEmits(["update:modelValue"]);

  const marks = computed(() => {
    const marks = [];
    const interval = (props.max - props.min) / 8;
    for (let i = 0; i <= 8; i++) {
      marks.push(props.min + i * interval);
    }

    return marks;
  });

  const localVal = computed({
    get() {
      if (props.modelValue > props.max) {
        return props.max;
      }
      return props.modelValue;
    },

    set: function (val: number) {
      emit("update:modelValue", val);
    },
  });

  function reset(event: any) {
    if (props.resetValue !== undefined) {
      event.preventDefault();
      localVal.value = props.resetValue;
    }
  }
</script>
<style lang="scss">
  @import "./TSlider.scss";
</style>
