<template>
  <div
    v-if="activeObject"
    id="activeObjectToolbar"
    class="fixed left-[50%] top-[150px] z-50 flex translate-x-[-50%] items-center gap-2 rounded-[9px] bg-gray-700 p-1"
    :class="{
      invisible:
        stateStore.hideToolbar ||
        activeObject.type === 'activeSelection' ||
        activeObject.type === 'group',
    }"
    :style="{ top: top + 'px', left: left + 'px' }"
  >
    <TextToolbar
      v-if="activeObject.type === 'text' || activeObject.type === 'textbox'"
      :canvas="canvas"
      :active-object="activeObject as fabric.Textbox"
      @update="update"
    />
    <ImageToolbar
      v-else-if="activeObject.type === 'image'"
      :canvas="canvas"
      :active-object="activeObject as fabric.Textbox"
      @update="update"
    />
    <ElementToolbar
      v-else
      :canvas="canvas"
      :active-object="activeObject"
      @update="update"
    />
    <div class="flex gap-2">
      <AiContextMenu
        v-if="activeObject.type === 'text' || activeObject.type === 'textbox'"
        :text="activeObject"
        :canvas="canvas"
      />
      <TButton class="h-8 w-8" @click="removeObject">
        <template #icon>
          <BinIcon />
        </template>
      </TButton>
      <TButton class="h-8 w-8" @click="openLayer">
        <template #icon>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 13.125C10.6094 13.125 11.125 13.6406 11.125 14.25C11.125 14.8828 10.6094 15.375 10 15.375C9.36719 15.375 8.875 14.8828 8.875 14.25C8.875 13.6406 9.36719 13.125 10 13.125ZM10 9.375C10.6094 9.375 11.125 9.89062 11.125 10.5C11.125 11.1328 10.6094 11.625 10 11.625C9.36719 11.625 8.875 11.1328 8.875 10.5C8.875 9.89062 9.36719 9.375 10 9.375ZM10 7.875C9.36719 7.875 8.875 7.38281 8.875 6.75C8.875 6.14062 9.36719 5.625 10 5.625C10.6094 5.625 11.125 6.14062 11.125 6.75C11.125 7.38281 10.6094 7.875 10 7.875Z"
              fill="currentColor"
            />
          </svg>
        </template>
      </TButton>
    </div>
  </div>
  <div
    v-if="activeObject"
    id="leftToolbar"
    class="fixed translate-x-[-100%] translate-y-[100%]"
    :class="{
      invisible:
        stateStore.hideToolbar ||
        (activeObject.type !== 'activeSelection' &&
          activeObject.type !== 'group'),
    }"
    :style="{ top: leftToolbarTop + 'px', left: leftToolbarLeft + 'px' }"
  >
    <TButton
      v-if="activeObject.type !== 'group'"
      accent
      @click="groupSelection"
      >{{ $t("toolbar.group") }}</TButton
    >
    <TButton
      v-if="activeObject.type === 'group'"
      accent
      @click="ungroupSelection"
      >{{ $t("toolbar.ungroup") }}</TButton
    >
  </div>
</template>

<script setup lang="ts">
  import TButton from "@/ui/TButton/TButton.vue";
  import useGetActiveObj from "../LayerPanel/Types/useGetActiveObj";

  const props = defineProps<{ canvas: Ref<fabric.Canvas> }>();
  const stateStore = useStateStore();

  const activeObject = ref(useGetActiveObj(props.canvas));
  const top = ref(100);
  const left = ref(100);
  const leftToolbarLeft = ref(100);
  const leftToolbarTop = ref(100);

  const activeGroupElement = computed(() => {
    return stateStore.activeGroupElement;
  });

  function removeObject() {
    if (!props.canvas || !props.canvas.value) return;
    const activeObj = props.canvas.value.getActiveObject();
    if (activeObj) {
      props.canvas.value.remove(activeObj);
    }
  }

  function openLayer() {
    stateStore.openLayer = true;
    stateStore.control = "";
  }

  function groupSelection() {
    if (!props.canvas || !props.canvas.value) return;
    const activeObj = props.canvas.value.getActiveObject();
    if (!activeObj) {
      return;
    }
    if (activeObj.type !== "activeSelection") {
      return;
    }
    //@ts-expect-error
    activeObj.toGroup();
    activeObj.originX = "center";
    activeObj.originY = "center";
    props.canvas.value.requestRenderAll();
    activeObject.value = useGetActiveObj(props.canvas);
  }

  function ungroupSelection() {
    if (!props.canvas || !props.canvas.value) return;
    const activeObj = props.canvas.value.getActiveObject();
    if (!activeObj) {
      return;
    }
    if (activeObj.type !== "group") {
      return;
    }
    //@ts-expect-error
    activeObj.toActiveSelection();
    props.canvas.value.requestRenderAll();
    activeObject.value = useGetActiveObj(props.canvas);
  }

  function getPosition() {
    if (!props.canvas || !props.canvas.value) return;
    const activeObj = props.canvas.value.getActiveObject();
    if (activeObj) {
      const leftPanel = document.getElementById("leftPanel");
      const workspace = document.getElementById("workspace");
      const toolbar = document.getElementById("activeObjectToolbar");
      let offsetLeft = 0;
      let offsetTop = 0;
      let widthOfToolbar = 0;
      if (toolbar) {
        widthOfToolbar = toolbar.clientWidth;
      }
      if (leftPanel) {
        offsetLeft += leftPanel.clientWidth;
      }
      if (workspace) {
        offsetTop =
          (workspace.clientHeight -
            //@ts-expect-error
            props.canvas.value.item(1).height * props.canvas.value.getZoom()) /
          2;
        offsetLeft +=
          (workspace.clientWidth -
            //@ts-expect-error
            props.canvas.value.item(1).width * props.canvas.value.getZoom()) /
          2;
      }
      const cropTop =
        //@ts-expect-error
        props.canvas.value.item(1).top - props.canvas.value.item(1).height / 2;
      const cropLeft =
        //@ts-expect-error
        props.canvas.value.item(1).left - props.canvas.value.item(1).width / 2;
      const objTop =
        ((activeObj.top || 0) - cropTop) * props.canvas.value.getZoom() -
        (activeObj.getBoundingRect(true, true).height / 2) *
          props.canvas.value.getZoom();
      const objBottom =
        ((activeObj.top || 0) - cropTop) * props.canvas.value.getZoom() +
        (activeObj.getBoundingRect(true, true).height / 2) *
          props.canvas.value.getZoom();
      const objLeft =
        ((activeObj.left || 0) - cropLeft) * props.canvas.value.getZoom() -
        (activeObj.getBoundingRect(true, true).width / 2) *
          props.canvas.value.getZoom();
      const objCenterX =
        ((activeObj.left || 0) - cropLeft) * props.canvas.value.getZoom();

      top.value = objBottom + 60 + offsetTop;
      leftToolbarTop.value = objTop + offsetTop + 20;
      leftToolbarLeft.value = objLeft + offsetLeft - 15;
      const spaceRight =
        window.innerWidth - (objCenterX + offsetLeft + widthOfToolbar / 2);
      const spaceLeft = objCenterX - widthOfToolbar / 2;
      if (spaceRight < 0) {
        left.value = objCenterX + offsetLeft + spaceRight - 10;
      } else if (spaceLeft < 0) {
        left.value = objCenterX + offsetLeft - spaceLeft;
      } else {
        left.value = objCenterX + offsetLeft;
      }
    }
  }

  function update() {
    activeObject.value = useGetActiveObj(props.canvas);
    setTimeout(() => {
      getPosition();
    }, 5);
  }

  watch(activeGroupElement, () => {
    update();
  });

  onMounted(() => {
    getPosition();
    window.$event.on("updateToolbar", () => {
      update();
    });
  });

  onUnmounted(() => {
    window.$event.off("updateToolbar");
  });
</script>
