<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 2.75049C2 2.33627 2.33579 2.00049 2.75 2.00049C3.16421 2.00049 3.5 2.33627 3.5 2.75049L3.5 17.2496C3.5 17.6639 3.16421 17.9996 2.75 17.9996C2.33579 17.9996 2 17.6639 2 17.2496L2 2.75049Z"
      fill="currentColor"
    />
    <path
      d="M17.9989 8.00006C17.9989 7.44778 17.5511 7.00006 16.9989 7.00006L6.99886 7.00006C6.44658 7.00006 5.99886 7.44778 5.99886 8.00006L5.99886 12.0001C5.99886 12.5523 6.44658 13.0001 6.99886 13.0001L16.9989 13.0001C17.5511 13.0001 17.9989 12.5523 17.9989 12.0001V8.00006Z"
      fill="currentColor"
    />
  </svg>
</template>
