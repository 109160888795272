<template>
  <div class="flex gap-2">
    <TButton
      v-if="activeObject.type !== 'rect' && activeObject.type !== 'circle'"
      :title="$t('edit_bg.crop.flip_X')"
      class="h-[32px] w-[32px]"
      @click="flipHorizontal"
    >
      <template #icon>
        <FlipHorizontalIcon />
      </template>
    </TButton>
    <TButton
      v-if="activeObject.type !== 'rect' && activeObject.type !== 'circle'"
      :title="$t('edit_bg.crop.flip_Y')"
      class="h-[32px] w-[32px]"
      @click="flipVertical"
    >
      <template #icon>
        <FlipVerticalIcon />
      </template>
    </TButton>
  </div>
</template>

<script setup lang="ts">
  import TButton from "@/ui/TButton/TButton.vue";
  import useImage from "@/components/LayerPanel/Types/useImage/useImage";
  import { fabric } from "fabric";

  const props = defineProps<{
    canvas: Ref<fabric.Canvas>;
    activeObject: fabric.Object;
  }>();

  const emits = defineEmits(["update"]);

  const { flipHorizontal, flipVertical, updateValues } = useImage(
    props.canvas,
    props.activeObject,
    "toolbar"
  );

  onMounted(() => {
    window.$event.on("updateToolbarValues", () => {
      updateValues();
      emits("update");
    });
  });

  onUnmounted(() => {
    window.$event.off("updateToolbarValues");
  });
</script>
