import { fabric } from "fabric";
import filters from "../utils/filters";
import hexToRGBA from "@/utils/hexToRGBA";

export default (
  canvas: Ref<fabric.Canvas>,
  bgImg: any,
  vignetteLayer: Ref<fabric.Rect | null>
) => {
  function applyFilter(index: number, name: string, val: number, element: any) {
    if (element.filters === undefined) {
      element.filters = [];
    }
    element.filters[index][name] = val;
    element.applyFilters();
    canvas.value.renderAll();
  }

  function applyFilterMatrix(
    index: number,
    name: string,
    value: number,
    element: any
  ) {
    if (value !== 0) {
      //@ts-expect-error
      const matrix = filters[name](value);
      element.filters[index] = new fabric.Image.filters.ColorMatrix({
        matrix: matrix,
      });
    } else {
      element.filters[index] = null;
    }

    element.applyFilters();
    canvas.value.renderAll();
  }

  function applyDuoTone(props: {
    element: any;
    color1: string;
    color2: string;
    reset?: boolean;
  }) {
    if (props.reset) {
      props.element.filters[20] = null;
      props.element.applyFilters();
      canvas.value.renderAll();
      return;
    }
    // @ts-expect-error
    props.element.filters[20] = new fabric.Image.filters.Composed({
      subFilters: [
        new fabric.Image.filters.Grayscale({ mode: "luminosity" }), // make it black and white
        new fabric.Image.filters.BlendColor({
          color: props.color2,
          mode: "darken",
          alpha: 0.8,
        }), // apply light color
        new fabric.Image.filters.BlendColor({
          color: props.color1,
          mode: "lighten",
          alpha: 0.8,
        }), // apply a darker color
      ],
    });
    props.element.applyFilters();
    canvas.value.renderAll();
  }

  function applySharpness(val: number, element: any) {
    const n = -((val - 1) / 4);
    element.filters[6] = new fabric.Image.filters.Convolute({
      matrix: [0, n, 0, n, val, n, 0, n, 0],
    });
    element.applyFilters();
    canvas.value.renderAll();
  }

  function applyEmboss(val: number, element: any) {
    element.filters[22] = new fabric.Image.filters.Convolute({
      matrix: [
        -(val / 5),
        -(val / 10),
        0,
        -(val / 10),
        1,
        val / 10,
        0,
        val / 10,
        val / 5,
      ],
    });
    element.applyFilters();
    canvas.value.renderAll();
  }

  function applyPixelization(val: number, element: any) {
    element.filters[23] = new fabric.Image.filters.Pixelate({
      blocksize: val,
    });
    element.applyFilters();
    canvas.value.renderAll();
  }

  const applyTint = useDebounceFn(
    (val: number, color: string, element: any) => {
      element.filters[20] = new fabric.Image.filters.BlendColor({
        mode: "tint",
        color: color,
        alpha: val,
      });
      element.applyFilters();
      canvas.value.renderAll();
    },
    5
  );

  const applyVignetteAmount = useDebounceFn((amount: number, color: string) => {
    if (amount === 0) {
      if (vignetteLayer.value !== null) {
        canvas.value.remove(vignetteLayer.value);
        vignetteLayer.value = null;
      }
    } else if (vignetteLayer.value === null) {
      vignetteLayer.value = new fabric.Rect({
        // @ts-expect-error
        id: "vignette",
        top: bgImg.value.top,
        left: bgImg.value.left,
        width: bgImg.value.height,
        height: bgImg.value.height,
        originX: "center",
        originY: "center",
        evented: false,
        selectable: false,
      });

      updateVignette(amount, color);
      vignetteLayer.value.scaleX = bgImg.value.width / bgImg.value.height;

      canvas.value.add(vignetteLayer.value);
    } else {
      updateVignette(amount, color);
    }
    canvas.value.renderAll();
  }, 5);

  function applyVignetteColor(amount: number, color: string) {
    if (vignetteLayer.value !== null) {
      updateVignette(amount, color);
      canvas.value.renderAll();
    }
  }

  function updateVignette(amount: number, color: string) {
    if (vignetteLayer.value !== null) {
      vignetteLayer.value.set(
        "fill",
        new fabric.Gradient({
          type: "radial",
          coords: {
            r1:
              (vignetteLayer.value.height || 0) / 2 -
              (vignetteLayer.value.height || 0) / 6,
            r2:
              (vignetteLayer.value.height || 0) / 2 +
              (vignetteLayer.value.height || 0) / 6,
            x1: (vignetteLayer.value.height || 0) / 2,
            y1: (vignetteLayer.value.height || 0) / 2,
            x2: (vignetteLayer.value.height || 0) / 2,
            y2: (vignetteLayer.value.height || 0) / 2,
          },
          colorStops: [
            { color: hexToRGBA(color, 0), offset: 0 },
            { color: hexToRGBA(color, amount), offset: 1 },
          ],
        })
      );
    }
  }

  function applyColorRemove(distance: number, color: string, element: any) {
    // @ts-expect-error
    element.filters[21] = new fabric.Image.filters.RemoveColor({
      distance: distance,
      color: color,
    });
    element.applyFilters();
    canvas.value.renderAll();
  }

  return {
    applyFilter,
    applyFilterMatrix,
    applyDuoTone,
    applySharpness,
    applyEmboss,
    applyPixelization,
    applyTint,
    applyVignetteAmount,
    applyVignetteColor,
    applyColorRemove,
  };
};
