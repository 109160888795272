<template>
  <div class="t-dropdown group">
    <div
      :class="{
        'flex items-center justify-between': flex,
      }"
    >
      <t-label v-if="label" :opened="opened">{{ label }}</t-label>
      <div
        ref="outsideDrop"
        class="t-dropdown dark:text-gray-5 relative box-border rounded-md border border-solid border-gray-600 bg-gray-600 font-inter text-xs font-medium text-gray-50 transition duration-300 group-focus-within:text-gray-10 group-focus-within:outline group-focus-within:outline-2 group-focus-within:outline-accent-1 group-hover:border-gray-400"
        :class="{
          '!rounded-b-none !border-transparent !text-gray-10 outline outline-2 outline-accent-1':
            opened,
          '!outline !outline-1 !outline-destructive-2 focus:!outline-destructive-2':
            error,
        }"
      >
        <button
          v-tooltip.bottom="{
            content:
              typeof selected === 'string' && selected.length > 15
                ? selected
                : '',
            delay: { show: 250 },
          }"
          class="box-border flex w-full items-center justify-between overflow-hidden rounded-md bg-gray-600 pl-2.5 pr-1 font-inter text-xs font-medium leading-[30px] text-gray-50 transition duration-300 focus:text-gray-10"
          :class="{
            '!text-gray-10': opened,
            '!pl-0': withInput,
          }"
          data-cy="t-dropdown-selected-button"
          aria-haspopup="true"
          :aria-expanded="opened"
          @click="opened = !opened"
        >
          <p
            v-if="!$slots['selected-content'] && selected && !withInput"
            class="truncate font-inter text-xs font-medium leading-[30px]"
          >
            {{ selected }}
          </p>
          <p
            v-if="!$slots['selected-content'] && !selected && !withInput"
            class="truncate font-inter text-xs font-medium leading-[30px]"
          >
            {{ data[0] }}
          </p>

          <input
            v-if="withInput && inputType === 'number'"
            v-model="localValue"
            class="w-11 border-none bg-gray-600 p-0 pl-[9px] pr-2.5 text-center font-inter text-xs font-medium leading-[30px] text-gray-50 outline-none transition duration-300 disabled:!cursor-not-allowed disabled:bg-gray-600 disabled:text-gray-200 group-focus-within:bg-gray-700 group-focus-within:text-gray-10"
            type="number"
            :min="imin"
            :max="imax"
          />

          <input
            v-if="withInput && inputType !== 'number'"
            v-model="localValue"
            class="border-none bg-gray-600 p-0 pl-[9px] pr-2.5 text-center font-inter text-xs font-medium leading-[30px] text-gray-50 outline-none transition duration-300 disabled:!cursor-not-allowed disabled:bg-gray-600 disabled:text-gray-200 group-focus-within:bg-gray-700 group-focus-within:text-gray-10"
            :type="inputType"
          />

          <slot v-if="!withInput" name="selected-content" />

          <div
            class="flex h-[20px] w-[20px] cursor-pointer items-center justify-center"
            :class="{
              'pl-[10px]': !withInput,
            }"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              width="7.5"
              height="12"
            >
              <path
                d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"
                fill="currentColor"
              />
            </svg>
          </div>
        </button>

        <PerfectScrollbar
          v-if="!$slots.content && opened"
          tag="ul"
          :style="{ height: calculatedHeight + 'px' }"
          :options="{ wheelPropagation: false }"
        >
          <li
            v-for="option in data"
            :key="getOptionKey(option)"
            @click="selectionChanged(option)"
          >
            <p
              v-tooltip="{
                content:
                  getOptionLabel(option).length > 18
                    ? getOptionLabel(option)
                    : '',
                delay: { show: 250 },
              }"
              style="padding-left: 0"
              class="truncate"
            >
              {{ getOptionLabel(option) }}
            </p>
          </li>
        </PerfectScrollbar>

        <div v-show="opened" v-else>
          <slot name="content" />
        </div>
      </div>
    </div>
    <div
      v-if="error && errorText !== ''"
      class="font-regular mt-1 flex items-start gap-[5px] font-inter text-xs leading-[16px] text-destructive-2"
    >
      <div class="h-5 w-5">
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 2.75C13.8555 2.75 17 5.89453 17 9.75C17 13.6328 13.8555 16.75 10 16.75C6.11719 16.75 3 13.6328 3 9.75C3 5.89453 6.11719 2.75 10 2.75ZM10 15.4375C13.1172 15.4375 15.6875 12.8945 15.6875 9.75C15.6875 6.63281 13.1172 4.0625 10 4.0625C6.85547 4.0625 4.3125 6.63281 4.3125 9.75C4.3125 12.8945 6.85547 15.4375 10 15.4375ZM10 11.0625C9.61719 11.0625 9.34375 10.7891 9.34375 10.4062V6.90625C9.34375 6.55078 9.61719 6.25 10 6.25C10.3555 6.25 10.6562 6.55078 10.6562 6.90625V10.4062C10.6562 10.7891 10.3555 11.0625 10 11.0625ZM10 11.9922C10.4648 11.9922 10.8477 12.375 10.8477 12.8398C10.8477 13.3047 10.4648 13.6875 10 13.6875C9.50781 13.6875 9.125 13.3047 9.125 12.8398C9.125 12.375 9.50781 11.9922 10 11.9922Z"
            fill="currentColor"
          />
        </svg>
      </div>
      <span class="inline-flex min-h-[20px] items-center">{{ errorText }}</span>
    </div>
  </div>
</template>
<script lang="ts" setup>
  import TLabel from "../TLabel/TLabel.vue";
  import { onClickOutside } from "@vueuse/core";
  import { ref, computed } from "vue";

  interface Props {
    data?: Array<any>;
    selected?: string | number;
    roundedLeft?: boolean;
    roundedRight?: boolean;
    roundedFull?: boolean;
    label?: string;
    noBorderLeft?: boolean;
    withInput?: boolean;
    inputType?: string;
    imax?: number;
    imin?: number;
    error?: boolean;
    errorText?: string;
    flex?: boolean;
  }

  type DropdownOption = {
    label?: string;
    value: string;
  };

  const props = withDefaults(defineProps<Props>(), {
    data: () => [],
    selected: "",
    roundedLeft: false,
    roundedRight: false,
    roundedFull: false,
    label: "",
    noBorderLeft: false,
    withInput: false,
    inputType: "text",
    imax: 99,
    imin: 0,
    error: false,
    errorText: "",
  });

  const emit = defineEmits<{
    (e: "selection-changed", option: DropdownOption): void;
    (e: "input-changed", option: string): void;
  }>();

  const opened = ref(false);
  const outsideDrop = ref();

  const localValue = computed({
    get() {
      if (props.selected !== "") {
        return props.selected;
      }

      if (props.data[0].value) {
        return props.data[0].value;
      }

      return "";
    },
    set: function (val) {
      emit("input-changed", val);
    },
  });

  const calculatedHeight = computed(() => {
    if (props.data.length > 5) {
      return 200;
    } else {
      return props.data.length * 32;
    }
  });

  function selectionChanged(option: DropdownOption) {
    emit("selection-changed", option);
    opened.value = false;
  }

  function getOptionLabel(option: DropdownOption | string): string {
    if (typeof option === "string") {
      return option;
    }

    return option.label || "";
  }

  function getOptionKey(option: DropdownOption | string): string {
    if (typeof option === "string") {
      return option;
    }
    return option.value;
  }

  onClickOutside(outsideDrop, () => {
    opened.value = false;
  });

  defineExpose({
    opened,
  });
</script>
<style lang="scss">
  @import "./TDropdown.scss";
  .t-dropdown {
    .truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .ps__rail-y {
      right: 0 !important;
      left: auto !important;
    }
  }
</style>
