<template>
  <MenuContentContainer
    :header="$t('edit_bg.adjust_image')"
    :padding-left-right="paddingLeftRight || 20"
  >
    <ContentRow class="mt-3" block>
      <TSlider
        :model-value="adjust.brightness.value"
        :label="$t('edit_bg.brightness')"
        :reset-value="0"
        :min="-1"
        :max="1"
        unit=""
        :interval="0.01"
        :process="process"
        input
        @update:model-value="adjust.changeBrightness"
      />
    </ContentRow>
    <ContentRow block>
      <TSlider
        :model-value="adjust.saturation.value"
        :label="$t('edit_bg.saturation')"
        :min="-1"
        :max="1"
        unit=""
        :interval="0.01"
        :process="process"
        :reset-value="0"
        input
        @update:model-value="adjust.changeSaturation"
      />
    </ContentRow>
    <ContentRow block>
      <TSlider
        :model-value="adjust.contrast.value"
        :label="$t('edit_bg.contrast')"
        :min="-1"
        :max="1"
        unit=""
        :interval="0.01"
        :process="process"
        :reset-value="0"
        input
        @update:model-value="adjust.changeContrast"
      />
    </ContentRow>
    <ContentRow block>
      <TSlider
        :model-value="adjust.temperature.value"
        :label="$t('edit_bg.temperature')"
        :min="-1"
        :max="1"
        unit=""
        :interval="0.01"
        :process="process"
        :reset-value="0"
        input
        @update:model-value="adjust.changeTemperature"
      />
    </ContentRow>
    <ContentRow block>
      <TSlider
        :model-value="adjust.hueRotation.value"
        :label="$t('edit_bg.hue_rotation')"
        :min="0"
        :max="360"
        unit="deg"
        :reset-value="0"
        input
        @update:model-value="adjust.changeHueRotation"
      />
    </ContentRow>
    <ContentRow block>
      <TSlider
        :model-value="adjust.grayscale.value"
        :label="$t('edit_bg.grayscale')"
        :max="1"
        unit=""
        :interval="0.01"
        :reset-value="0"
        @update:model-value="adjust.changeGrayscale"
      />
    </ContentRow>
    <ContentRow block>
      <TSlider
        :model-value="adjust.noise.value"
        :label="$t('edit_bg.noise')"
        :min="0"
        :max="100"
        unit=""
        :reset-value="0"
        @update:model-value="adjust.changeNoise"
      />
    </ContentRow>
    <ContentRow block>
      <TSlider
        :model-value="adjust.blur.value"
        :label="$t('edit_bg.blur')"
        :max="1"
        unit=""
        :interval="0.01"
        :reset-value="0"
        @update:model-value="adjust.changeBlur"
      />
    </ContentRow>
    <ContentRow block>
      <TSlider
        :model-value="adjust.sharpness.value"
        :label="$t('edit_bg.sharpness')"
        unit=""
        :reset-value="0"
        @update:model-value="adjust.changeSharpness"
      />
    </ContentRow>
    <ContentRow block>
      <TSlider
        :model-value="adjust.emboss.value"
        :label="$t('edit_bg.emboss')"
        unit=""
        :max="50"
        :reset-value="0"
        @update:model-value="adjust.changeEmboss"
      />
    </ContentRow>
    <ContentRow block>
      <TSlider
        :model-value="adjust.pixelization.value"
        :label="$t('edit_bg.pixelization')"
        unit=""
        :max="50"
        :reset-value="0"
        @update:model-value="adjust.changePixelization"
      />
    </ContentRow>
  </MenuContentContainer>
  <MenuContentContainer :padding-left-right="paddingLeftRight || 20">
    <ContentRow block>
      <TButton class="w-full" @click="adjust.resetAll">
        {{ $t("edit_bg.reset_adjust") }}
      </TButton>
    </ContentRow>
  </MenuContentContainer>
  <MenuContentContainer
    v-if="!activePicture"
    :padding-left-right="8"
    :border-bottom="false"
    :margin-top="20"
  >
    <div class="space-y-2">
      <TTabsToggle :header="$t('edit_bg.vignette')" no-move>
        <MenuContentContainer :padding-left-right="12">
          <ContentRow>
            <color-picker
              color-picker-top
              :default-color="adjust.vignetteColor.value"
              @color-change="adjust.changeVignetteColor"
            ></color-picker>
          </ContentRow>
          <ContentRow :margin-bottom="20" block>
            <TSlider
              :model-value="adjust.vignetteAmount.value"
              :max="1"
              :interval="0.01"
              unit=""
              :reset-value="0"
              @update:model-value="adjust.changeVignetteAmount"
            />
          </ContentRow>
        </MenuContentContainer>
      </TTabsToggle>

      <!-- <TTabsToggle :header="$t('edit_bg.tint')" no-move>
        <MenuContentContainer :padding-left-right="12">
          <ContentRow>
            <color-picker
              color-picker-top
              :default-color="adjust.tintColor.value"
              @color-change="adjust.changeTintColor"
            ></color-picker>
          </ContentRow>
          <ContentRow :margin-bottom="20" block>
            <TSlider
              :model-value="adjust.tintValue.value"
              :max="1"
              unit=""
              @update:model-value="adjust.changeTintValue"
            />
          </ContentRow>
        </MenuContentContainer>
      </TTabsToggle>

      <TTabsToggle :header="$t('edit_bg.color_remover')" no-move>
        <MenuContentContainer :padding-left-right="12">
          <ContentRow>
            <color-picker
              color-picker-top
              :default-color="adjust.colorRemoverColor.value"
              @color-change="adjust.changeColorRemoverColor"
            ></color-picker>
          </ContentRow>
          <ContentRow :margin-bottom="20" block>
            <TSlider
              :model-value="adjust.colorRemoverDistance.value"
              :max="1"
              unit=""
              @update:model-value="adjust.changeColorRemoverDistance"
            />
          </ContentRow>
        </MenuContentContainer>
      </TTabsToggle> -->
    </div>
  </MenuContentContainer>
</template>

<script setup lang="ts">
  import TButton from "@/ui/TButton/TButton.vue";
  import colorPicker from "@/ui/TColorPicker/colorPicker.vue";
  import TSlider from "@/ui/TSlider/TSlider.vue";
  import TTabsToggle from "@/ui/TTabsToggle/TTabsToggle.vue";
  import useAdjust from "./uses/useAdjust";

  const props = defineProps<{
    canvas: any;
    activePicture?: any;
    paddingLeftRight?: number;
  }>();

  const { adjust } = useAdjust(props.canvas, props.activePicture);

  const process = (dotsPos: any) => [[50, dotsPos[0]]];

  //db click to slider reset value ????

  onMounted(() => {
    window.$event.on("getAdjust", () => {
      adjust.getData();
    });
  });

  onUnmounted(() => {
    window.$event.off("getAdjust");
  });
</script>
