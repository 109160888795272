<template>
  <div v-if="bgImage">
    <PanelTitle :title="$t('size.title')" />
    <MenuContentContainer>
      <template #header-action>
        <TButton xs @click="reset">
          <template #icon>
            <ResetIcon class="h-[11px]" />
          </template>
          {{ $t("reset") }}
        </TButton>
      </template>
      <ContentRow class="gap-x-2">
        <div class="flex items-center gap-1">
          <TInputNumber
            :key="key"
            :model-value="width"
            :label="$t('dimensions.w')"
            label-in
            flex
            :max="bgImage.width"
            :min="0"
            unit=""
            :controls="false"
            @update:model-value="changeWidth"
          />
          <span class="font-inter text-xs font-medium text-gray-200">×</span>
          <TInputNumber
            :key="key"
            :model-value="height"
            :label="$t('dimensions.h')"
            label-in
            flex
            :max="bgImage.height"
            :min="0"
            unit=""
            :controls="false"
            @update:model-value="changeHeight"
          />
        </div>
      </ContentRow>
    </MenuContentContainer>

    <MenuContentContainer :header="$t('size.crop.flip_rotate')">
      <ContentRow :margin-bottom="15" class="!gap-x-2">
        <TButton
          :title="$t('size.crop.flip_X')"
          class="h-[32px] w-[32px]"
          @click="flipHorizont"
        >
          <template #icon>
            <FlipHorizontalIcon />
          </template>
        </TButton>
        <TButton
          :title="$t('size.crop.flip_Y')"
          class="h-[32px] w-[32px]"
          @click="flipVertical"
        >
          <template #icon>
            <FlipVerticalIcon />
          </template>
        </TButton>
        <TButton
          :title="$t('size.crop.rotate_plus')"
          class="h-[32px] w-[32px]"
          @click="rotatePlus90"
        >
          <template #icon>
            <RotateRightIcon />
          </template>
        </TButton>
        <TButton
          :title="$t('size.crop.rotate_minus')"
          class="h-[32px] w-[32px]"
          @click="rotateMinus90"
        >
          <template #icon>
            <RotateLeftIcon />
          </template>
        </TButton>
      </ContentRow>
      <TSlider
        v-model="bgAngle"
        :label="$t('size.crop.angle')"
        :min="-45"
        :max="45"
        unit="°"
        :process="(dotsPos: any) => [[50, dotsPos[0]]]"
        :reset-value="0"
        @update:model-value="rotate"
      />
    </MenuContentContainer>
    <MenuContentContainer :header="$t('size.crop.aspect_ratio')">
      <ContentRow>
        <TRadioButtonGroup
          v-model="ratio"
          :options="[
            { label: $t('size.crop.ratio.original'), value: 0 },
            { label: $t('size.crop.ratio.free'), value: 'free' },
            { label: $t('size.crop.ratio.square'), value: 1 },
            { label: '16:9', value: 16 / 9 },
            { label: '7:5', value: 7 / 5 },
            { label: '5:4', value: 5 / 4 },
            { label: '5:3', value: 5 / 3 },
            { label: '4:3', value: 4 / 3 },
            { label: '3:2', value: 3 / 2 },
            { label: '2:1', value: 2 / 1 },
            { label: $t('size.crop.ratio.custom'), value: 'custom' },
          ]"
          name="crop-ratio"
          @update:model-value="setCropRatio"
        >
        </TRadioButtonGroup>
      </ContentRow>
      <ContentRow v-if="ratio === 'custom'">
        <TInputNumber
          v-model="customx"
          :min="1"
          :controls="false"
          unit=""
          @update:model-value="setCustomRatio"
        />
        <span class="mx-2 text-gray-200">:</span>
        <TInputNumber
          v-model="customy"
          :min="1"
          :controls="false"
          unit=""
          @update:model-value="setCustomRatio"
        />
      </ContentRow>
    </MenuContentContainer>
    <MenuContentContainer v-if="ratio !== 1 && ratio !== 'free'">
      <ContentRow class="justify-center">
        <div
          class="box-border grid h-8 w-12 items-center justify-center rounded-md bg-gray-500 text-gray-10"
          :class="{
            'border-2 border-accent-1 bg-gray-800': width > height,
          }"
          @click="setDimension('landscape')"
        >
          <Check v-if="width > height" />
        </div>
        <div
          class="box-border grid h-12 w-8 items-center justify-center rounded-md bg-gray-500 text-gray-10"
          :class="{
            'border-2 border-accent-1 bg-gray-800': width < height,
          }"
          @click="setDimension('portrait')"
        >
          <Check v-if="width < height" />
        </div>
      </ContentRow>
    </MenuContentContainer>
    <MenuContentContainer :border-bottom="false">
      <ContentRow block>
        <TButton class="w-full" success @click="crop">
          {{ $t("size.crop.crop") }}
        </TButton>
      </ContentRow>
      <ContentRow block>
        <TButton class="w-full" @click="reset">
          {{ $t("reset") }}
        </TButton>
      </ContentRow>
    </MenuContentContainer>
  </div>
</template>

<script setup lang="ts">
  import TButton from "@/ui/TButton/TButton.vue";
  import TInputNumber from "@/ui/TInputNumber/TInputNumber.vue";
  import TRadioButtonGroup from "@/ui/TRadioButton/TRadioButtonGroup.vue";
  import TSlider from "@/ui/TSlider/TSlider.vue";

  const props = defineProps<{ canvas: any }>();

  const stateStore = useStateStore();
  const cropStore = useCropStore();
  const bgAngle = ref(0);
  const height = ref(0);
  const width = ref(0);
  const key = ref(0);
  const ratio: Ref<number | string> = ref(0);
  const customx = ref(1);
  const customy = ref(1);

  const bgImage = computed(() => {
    if (props.canvas && props.canvas.value.item(0)) {
      return props.canvas.value.item(0);
    }
    return undefined;
  });

  const rotateVal = computed(() => {
    return cropStore.rotate;
  });

  // const lockRatio = computed({
  //   get() {
  //     return cropStore.lockRatio;
  //   },
  //   set(val: boolean) {
  //     window.$event.emit("lockRatio", val);
  //     cropStore.lockRatio = val;
  //   },
  // });

  function changeWidth(val: number) {
    window.$event.emit("setCropWidth", Number(val));
  }

  function changeHeight(val: number) {
    window.$event.emit("setCropHeight", val);
  }

  function crop() {
    window.$event.emit("crop");
    stateStore.control = "";
  }

  function reset() {
    window.$event.emit("resetCrop");
  }

  function flipHorizont() {
    window.$event.emit("bg-flipX");
  }

  function flipVertical() {
    window.$event.emit("bg-flipY");
  }

  function rotatePlus90() {
    cropStore.rotatePlus90();
  }

  function rotateMinus90() {
    cropStore.rotateMinus90();
  }

  function rotate(value: number) {
    window.$event.emit("rotateImg", Number(value) + cropStore.rotate);
  }

  function setDimension(val: string) {
    window.$event.emit("setDimension", val);
  }

  function setCropRatio(val: number | string) {
    if (val === "custom") {
      window.$event.emit("setCropRatio", {
        val: customx.value / customy.value,
        custom: true,
        xy: {
          x: customx.value,
          y: customy.value,
        },
      });
    }
    window.$event.emit("setCropRatio", { val: val });
  }

  function setCustomRatio() {
    window.$event.emit("setCropRatio", {
      val: customx.value / customy.value,
      custom: true,
      xy: {
        x: customx.value,
        y: customy.value,
      },
    });
  }

  function getData() {
    if (!props.canvas || !props.canvas.value) return;
    const bgImage = props.canvas.value.item(0);
    const cropRect = props.canvas.value.getActiveObject();
    if (!cropRect || !bgImage) return;
    key.value++;
    bgAngle.value = bgImage.angle - rotateVal.value;
    height.value = Math.round(cropRect.height);
    width.value = Math.round(cropRect.width);
    if (cropRect.customRatio) {
      ratio.value = "custom";
    } else {
      ratio.value = cropRect.ratio || 0;
    }
    customx.value = cropRect.customRatioX || 1;
    customy.value = cropRect.customRatioY || 1;
  }

  watch(rotateVal, () => {
    getData();
  });

  onMounted(() => {
    cropStore.isCropping = true;
    window.$event.emit("showCropper");
    getData();
    window.$event.on("cropUpdated", getData);
  });

  onBeforeUnmount(() => {
    if (cropStore.isCropping === true) {
      window.$event.emit("crop");
      window.$event.emit("hideCropper");
    }
  });
</script>
