import { fabric } from "fabric";

export default (props: {
  canvas: Ref<fabric.Canvas>;
  cropRect: Ref<fabric.Rect>;
  snapTopLine: Ref<fabric.Line>;
  snapBottomLine: Ref<fabric.Line>;
  snapLeftLine: Ref<fabric.Line>;
  snapRightLine: Ref<fabric.Line>;
  snapCenterHorizontalLine: Ref<fabric.Line>;
  snapCenterVerticalLine: Ref<fabric.Line>;
}) => {
  function recalculateSpansLines() {
    const { width, height } = props.cropRect.value;
    const top =
      (props.cropRect.value.top ? props.cropRect.value.top : 0) -
      (height ? height : 0) / 2;
    const bottom = top + (height ? height : 0);
    const left =
      (props.cropRect.value.left ? props.cropRect.value.left : 0) -
      (width ? width : 0) / 2;
    const right = left + (width ? width : 0);
    props.snapTopLine.value.set({
      width: width,
      top: top,
      left: left,
    });
    props.snapBottomLine.value.set({
      width: width,
      top: bottom,
      left: left,
    });
    props.snapCenterHorizontalLine.value.set({
      width: width,
      top: props.cropRect.value.top,
      left: left,
    });
    props.snapLeftLine.value.set({
      height: height,
      top: top,
      left: left,
    });
    props.snapRightLine.value.set({
      height: height,
      top: top,
      left: right,
    });
    props.snapCenterVerticalLine.value.set({
      height: height,
      top: top,
      left: props.cropRect.value.left,
    });
    props.canvas.value.renderAll();
  }

  return {
    recalculateSpansLines,
  };
};
