<template>
  <div id="leftPanel" class="full-height flex">
    <div class="full-height bg-gray-800">
      <ControlButton
        :title="$t('controls.edit_bg')"
        :active="control === 'edit_bg'"
        @click="openControl('edit_bg')"
      >
        <EditIcon />
      </ControlButton>
      <ControlButton
        :title="$t('controls.size')"
        :active="control === 'crop'"
        @click="openControl('crop')"
      >
        <CropIcon />
      </ControlButton>
      <ControlButton
        :title="$t('controls.text')"
        :active="control === 'text'"
        @click="openControl('text')"
      >
        <TextIcon />
      </ControlButton>
      <ControlButton
        :title="$t('controls.elements')"
        :active="control === 'elements'"
        @click="openControl('elements')"
      >
        <ShapesIcon />
      </ControlButton>
      <ControlButton
        :title="$t('controls.image')"
        :active="control === 'image'"
        @click="openControl('image')"
      >
        <ImageIcon />
      </ControlButton>
      <ControlButton
        :title="$t('controls.upload')"
        :active="control === 'upload'"
        @click="openControl('upload')"
      >
        <UploadIcon />
      </ControlButton>
    </div>
    <div
      v-if="control && control !== ''"
      class="full-height w-[320px] bg-gray-700"
    >
      <PerfectScrollbar ref="scroll" class="full-height">
        <EditBg v-if="control === 'edit_bg'" :canvas="canvas" />
        <Crop v-if="control === 'crop'" :canvas="canvas" />
        <Text v-if="control === 'text'" />
        <Elements v-if="control === 'elements'" />
        <Images v-if="control === 'image'" />
        <Upload v-if="control === 'upload'" />
      </PerfectScrollbar>
    </div>
  </div>
</template>

<script setup lang="ts">
  defineProps<{ canvas: Ref<fabric.Canvas> }>();

  const stateStore = useStateStore();

  const control = computed(() => {
    return stateStore.control;
  });

  function openControl(type: string) {
    if (control.value === type) {
      stateStore.control = "";
      return;
    } else {
      stateStore.control = type;
    }
  }
</script>
