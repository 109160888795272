import { lockedApi } from "@/api";
// import { useNotificationsStore } from "@/stores/notifications";
// import { i18nGlobal } from "../../init/i18n-setup";
import axios from "axios";

const TIMEOUT_MS = 25_000; // 25 seconds

export async function getText(
  prompt: string,
  apiKey: string,
  hostname: string,
  userId: string | null | undefined
): Promise<string | null> {
  // const { t } = i18nGlobal;

  try {
    const res = await axios.post(
      lockedApi.GENERATE_TEXT,
      {
        prompt: prompt,
        apiKey: apiKey,
        hostname: hostname,
        userId: userId,
      },
      { timeout: TIMEOUT_MS }
    );
    return res.data.data;
  } catch (err) {
    // if (String(err).includes("Error: timeout of")) {
    //   useNotificationsStore().createNotification({
    //     type: "error",
    //     title: t("errors.error_occurred"),
    //     text: t("errors.timeout"),
    //   });
    // } else {
    //   useNotificationsStore().createNotification({
    //     type: "error",
    //     title: t("errors.error_occurred"),
    //     text: t("errors.try_later"),
    //   });
    // }
  }

  return null;
}

// export async function getKeywords(prompt: string): Promise<string[]> {
//   const res = await axios.post(lockedApi.TLDR, {
//     prompt: prompt,
//   });

//   const trimmed = res.data.keywords.trim();
//   return trimmed.split(",");
// }
