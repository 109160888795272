import { IAPI, ILockedAPI } from "types/API/API";

const SERVER: string =
  ["localhost", "127.0.0.1"].indexOf(window.location.hostname) !== -1
    ? "http://private-b2d8b-editor4.apiary-mock.com/"
    : "";
const APIARY_MOCK: string =
  ["localhost", "127.0.0.1"].indexOf(window.location.hostname) !== -1
    ? "https://private-68405b-products174.apiary-mock.com/"
    : "";

const LAMBDA_PROD_URL =
  "https://o6lwlm3sld.execute-api.eu-west-1.amazonaws.com/";

export let LAMBDA_URL: string = LAMBDA_PROD_URL;

const LAMBDA_DEV_URL =
  "https://su3qmxpwhi.execute-api.eu-west-1.amazonaws.com/";

const LAMBDA_LOCAL_URL = "http://localhost:3000/";

type LambdaStage = "local" | "production" | "develop";

export let api: IAPI = setDefaultApi();
export let lockedApi: ILockedAPI = setLockedApi();

function setDefaultApi(): IAPI {
  return {
    GET_AUTOSAVE: SERVER + "get-autosave",
    AUTOSAVES: APIARY_MOCK + "autosaves",
    PRODUCTS: APIARY_MOCK + "products",
    FEEDS: APIARY_MOCK + "feeds",
    AUTOSAVE: SERVER + "autosave",
    CONVERSATIONS: SERVER + "conversations",
    COMMENTS: SERVER + "comments",
    SYNCED_ROWS: SERVER + "synced-rows",
    //@ts-expect-error
    SAVE: null,
    IMAGE_EDITOR_UPLOAD: "",
    IMAGE_UPLOAD: "upload",

    PREVIEW: LAMBDA_URL + "preview/{key}",
    FOLDERS: LAMBDA_URL + "folders/{key}",
    LOAD: LAMBDA_URL + "load/{key}/account/{id}/{uuid}",
    TEST_EMAIL: LAMBDA_URL + "testmail/{key}",
  };
}

export function setApi(index: unknown, value: string): void {
  //@ts-expect-error
  api[index] = value;
}

function getUrlForStage(stage: LambdaStage): string {
  switch (stage) {
    case "local":
      return LAMBDA_LOCAL_URL;
    case "develop":
      return LAMBDA_DEV_URL;
    case "production":
      return LAMBDA_PROD_URL;
    default:
      return LAMBDA_PROD_URL;
  }
}

export function setLambdaStage(stage: LambdaStage): void {
  LAMBDA_URL = getUrlForStage(stage);
  api = setDefaultApi();
  lockedApi = setLockedApi();
}

function setLockedApi(): ILockedAPI {
  return {
    GIPHY_API_KEY: "l41YcFzYxeAUvxXG0",
    GIPHY_SEARCH: "https://api.giphy.com/v1/gifs/search",
    AUTHORIZE: LAMBDA_URL + "authorize",
    GCS_SIGNED_URL: LAMBDA_URL + "get-signed-url/",
    SAVE_SERVER: LAMBDA_URL + "save",
    GENERATE_IMAGE: LAMBDA_URL + "generate-image",
    GENERATE_TEXT: LAMBDA_URL + "text-generation",
  };
}
