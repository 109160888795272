<template>
  <div
    v-tooltip="{ content: disabled ? tooltip : '' }"
    class="group flex items-center gap-3"
  >
    <Switch
      :id="uuid"
      v-model="enabled"
      tabindex="0"
      :class="{
        '!bg-accent-1': enabled,
      }"
      class="duration-600 relative inline-flex h-[20px] w-[36px] flex-[0_0_36px] cursor-pointer items-center rounded-xl bg-gray-600 transition focus:bg-gray-600 focus:outline focus:outline-2 focus:outline-offset-[-2px] focus:outline-accent-1 disabled:!cursor-not-allowed disabled:!bg-gray-600 group-hover:bg-gray-400"
      :disabled="disabled"
    >
      <span class="sr-only">Enable notifications</span>
      <span
        :class="{
          'translate-x-5 !bg-white dark:!bg-gray-10': enabled,
          'translate-x-1': !enabled,
          '!bg-gray-200': disabled,
        }"
        class="group-focus-whithin:bg-gray-100 dark:group-focus-whithin:bg-gray-100 inline-block h-[12px] w-[12px] translate-y-0 rotate-0 skew-x-0 skew-y-0 scale-100 transform rounded-full bg-gray-100 transition duration-300 group-hover:bg-gray-50"
      />
    </Switch>
    <label
      :for="uuid"
      class="block cursor-pointer font-inter text-xs font-medium leading-5 text-gray-200 transition duration-300 group-focus-within:!text-gray-100 group-hover:text-gray-100"
      :class="{
        '!text-gray-300': disabled,
        flex: tip !== '',
      }"
    >
      {{ label }}

      <TTip v-if="tip" :tip="tip" />
    </label>
  </div>
</template>
<script lang="ts" setup>
import TTip from "../TTip/TTip.vue";
import { Switch } from "@headlessui/vue";
import { v4 as uuidv4 } from "uuid";
import { computed } from "vue";

const uuid = uuidv4();

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  label: {
    type: String,
    default: "",
  },
  tooltip: {
    type: String,
    default: "",
  },
  tip: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["update:modelValue"]);

const enabled = computed({
  get() {
    return props.modelValue;
  },
  set(val) {
    if (props.disabled !== true) {
      emit("update:modelValue", val);
    }
  },
});
</script>
<style lang="scss"></style>
