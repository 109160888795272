<template>
  <div class="picker-wrapper">
    <t-input-color
      ref="input"
      v-model="color"
      :with-input="withInput"
      :label="label"
      :disabled="pickerDisplayed"
      :no-input="noInput"
      :no-border-right="noBorderRight"
      :short="short"
      :flex="flex"
      :medium="medium"
      @open-picker="openPicker"
    ></t-input-color>

    <div
      id="color-picker"
      class="picker"
      :class="{
        'no-input': noInput,
        'position-left': position === 'left',
        'position-right': position === 'right',
        'position-top': colorPickerTop,
      }"
      @keyup.esc="() => (pickerDisplayed = false)"
    >
      <color-picker
        v-if="pickerDisplayed"
        ref="outsideColorPicker"
        theme="generalStore.light ? 'light' : 'dark'"
        :color="color"
        :colors-default="presetColors"
        @changeColor="
          (c: string) => {
            color = c;
          }
        "
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
  import TInputColor from "../TInputColor/TInputColor.vue";
  //@ts-expect-error Forked vue-colorpicker
  import colorPicker from "./vue-colorpicker/Index.vue";
  import { onClickOutside } from "@vueuse/core";
  import { ref, computed } from "vue";

  type Color =
    | string
    | {
        rgba: {
          a: number;
        };
        hex: string;
      };

  const props = defineProps({
    defaultColor: {
      type: String,
      default: undefined,
    },

    attribute: {
      type: String,
      required: false,
      default: undefined,
    },

    label: {
      type: String,
      default: "",
    },

    withInput: {
      type: Boolean,
      default: true,
    },

    /*
     * Set position of color picker - left / right
     */
    position: {
      type: String,
      default: "left",
    },

    colorPickerTop: {
      type: Boolean,
      default: false,
    },

    noBorderRight: {
      type: Boolean,
      default: false,
    },

    short: {
      type: Boolean,
      default: false,
    },

    noInput: {
      type: Boolean,
      default: false,
    },

    product: {
      type: Boolean,
      default: false,
    },

    prodstructure: {
      type: [Object, Boolean],
      default: null,
    },
    flex: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
  });

  const emits = defineEmits(["color-change"]);

  const pickerDisplayed = ref(false);
  const presetColors = ref([
    "rgba(0,0,0,0)",
    "#D0021B",
    "#F5A623",
    "#F8E71C",
    "#8B572A",
    "#7ED321",
    "#417505",
    "#BD10E0",
    "#9013FE",
    "#4A90E2",
    "#50E3C2",
    "#B8E986",
    "#000000",
    "#4A4A4A",
    "#9B9B9B",
    "#FFFFFF",
  ]);
  const input = ref();
  const outsideColorPicker = ref();

  const color = computed<string>({
    get: function () {
      //if attr is not present, try to use default color
      if (props.defaultColor) {
        return props.defaultColor;
      }

      //default color
      return "transparent";
    },

    set: function (val: Color) {
      //color was changed to the same color
      if (val === color.value) {
        return;
      }

      if (typeof val !== "string" && val.rgba && val.rgba.a === 0) {
        val = "transparent";
        emits("color-change", val);

        return;
      }

      // From Color Picker
      if (typeof val !== "string" && val.hex !== undefined) {
        emits("color-change", val.hex);
      }

      //From Input
      else {
        //set empty color to be transparent
        if (val === "") {
          val = "transparent";
        }

        emits("color-change", val);
      }
    },
  });

  function openPicker() {
    pickerDisplayed.value = true;
  }

  onClickOutside(outsideColorPicker, (e: any) => {
    //dont close color picker when clicking on input
    if (e.srcElement.id !== input.value.uuid) {
      pickerDisplayed.value = false;
    }
  });

  // onMounted(() => {
  //   const colors = colorsStore.colors;

  //   if (colors != null && colors.length > 0) {
  //     presetColors.value = [];
  //     presetColors.value.push(...colors);
  //   }
  // });
</script>

<style lang="scss" scoped>
  .picker {
    position: absolute;
    z-index: 9999999999;
    top: 36px;

    &.no-input {
      top: 43px;
    }
  }

  .picker-wrapper {
    position: relative;
  }

  .position-left {
    left: 0;
  }

  .position-right {
    right: 0;
  }

  .position-top {
    top: -380px;
  }
</style>
