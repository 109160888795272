import { fabric } from "fabric";
import useBasicShapes from "../useElements/useBasicShapes";
import useMask from "../usePictures/useMask";
import { IElement } from "types/Elements/Elements";

export default () => {
  const stateStore = useStateStore();
  const originBgImage = computed(() => {
    return stateStore.originBgImage;
  });
  function setPreviewsForFilters(file?: string) {
    const filtersStore = useFiltersStore();
    const picturesStore = usePicturesStore();
    if (!file) {
      filtersStore.previews = [];
    }
    picturesStore.douPreviews = [];

    filtersStore.predefinedDuotone.map((duotone: string[], index: number) => {
      fabric.Image.fromURL(
        file || stateStore.originBgImage,
        (img) => {
          const scale =
            120 /
            ((img.width || 0) < (img.height || 0)
              ? img.width || 1
              : img.height || 1);
          img.scale(scale);

          const canvas = new fabric.StaticCanvas(null, {
            width: img.getBoundingRect(true, true).width,
            height: img.getBoundingRect(true, true).height,
          });
          canvas.add(img);
          // @ts-expect-error
          const filter = new fabric.Image.filters.Composed({
            subFilters: [
              new fabric.Image.filters.Grayscale({ mode: "luminosity" }), // make it black and white
              new fabric.Image.filters.BlendColor({
                color: duotone[1],
                mode: "darken",
                alpha: 0.8,
              }), // apply light color
              new fabric.Image.filters.BlendColor({
                color: duotone[0],
                mode: "lighten",
                alpha: 0.8,
              }), // apply a darker color
            ],
          });
          img.filters = [];
          img.filters.push(filter);
          img.applyFilters();
          canvas.renderAll();
          if (file) {
            picturesStore.douPreviews[index] = canvas.toDataURL();
          } else {
            filtersStore.previews[index] = canvas.toDataURL();
          }
        },
        { crossOrigin: "Anonymous" }
      );
    });
  }

  function setPreviewsForShapes() {
    const elementStore = useElementsStore();

    elementStore.basicShapes.map((item: IElement) => {
      //@ts-expect-error
      const shape = useBasicShapes[item.name]();

      const StaticCanvas = new fabric.StaticCanvas(null, {
        width: shape.width,
        height: shape.height,
      });
      StaticCanvas.add(shape);
      StaticCanvas.renderAll();
      item.url = StaticCanvas.toDataURL();
    });
  }

  function setPreviewsForMasks() {
    const picturesStore = usePicturesStore();

    picturesStore.masksPreview.map((item: any) => {
      fabric.Image.fromURL("./assets/img/placeholder.jpg", function (img) {
        img.set({
          left: 0,
          top: 0,
        });

        //@ts-expect-error
        const mask = useMask[item.type]();
        //@ts-expect-error
        const maskScale = img.width / (Math.max(mask.width, mask.height) || 1);
        mask.set({
          left: 0,
          top: 0,
          scaleX: maskScale,
          scaleY: maskScale,
          absolutePositioned: false,
        });
        img.clipPath = mask;

        const StaticCanvas = new fabric.StaticCanvas(null, {
          width: img.width,
          height: img.height,
        });
        StaticCanvas.add(img);
        StaticCanvas.renderAll();
        item.preview = StaticCanvas.toDataURL();
      });
    });
  }

  watch(originBgImage, (newVal) => {
    if (newVal !== "") {
      setPreviewsForFilters();
    }
  });

  return { setPreviewsForFilters, setPreviewsForShapes, setPreviewsForMasks };
};
