<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.4688 4.9375C15.8242 4.9375 16.125 5.23828 16.125 5.59375C16.125 5.97656 15.8242 6.25 15.4688 6.25H15.1406L14.4844 15.1367C14.4023 16.0664 13.6641 16.75 12.7344 16.75H7.23828C6.30859 16.75 5.57031 16.0664 5.48828 15.1367L4.83203 6.25H4.53125C4.14844 6.25 3.875 5.97656 3.875 5.59375C3.875 5.23828 4.14844 4.9375 4.53125 4.9375H6.41797L7.42969 3.43359C7.70312 3.02344 8.19531 2.75 8.71484 2.75H11.2578C11.7773 2.75 12.2695 3.02344 12.543 3.43359L13.5547 4.9375H15.4688ZM8.71484 4.0625C8.63281 4.0625 8.55078 4.11719 8.52344 4.17188L8.00391 4.9375H11.9688L11.4492 4.17188C11.4219 4.11719 11.3398 4.0625 11.2578 4.0625H8.71484ZM13.8281 6.25H6.14453L6.80078 15.0547C6.82812 15.2734 7.01953 15.4375 7.23828 15.4375H12.7344C12.9531 15.4375 13.1445 15.2734 13.1719 15.0547L13.8281 6.25Z"
      fill="currentColor"
    />
  </svg>
</template>
