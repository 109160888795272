<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 2C1.44772 2 1 2.44772 1 3V17C1 17.5523 1.44772 18 2 18H18C18.5523 18 19 17.5523 19 17V11C19 10.4477 18.5523 10 18 10H9V3C9 2.44772 8.55228 2 8 2H2ZM9 16.5V11.5H17.5V16.5H9Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14.7906 7.5C14.8837 7.76383 14.8247 8.06931 14.6137 8.28033C14.3208 8.57322 13.8459 8.57322 13.553 8.28033L11.053 5.78033C10.7601 5.48744 10.7601 5.01257 11.053 4.71967L13.553 2.21967C13.8459 1.92678 14.3208 1.92678 14.6137 2.21967C14.8247 2.43069 14.8837 2.73617 14.7906 3C14.7545 3.10236 14.6955 3.19846 14.6137 3.28033L13.394 4.5L15.75 4.5C17.5809 4.5 19 5.91912 19 7.75V8.58333C19 8.99755 18.6642 9.33333 18.25 9.33333C17.8358 9.33333 17.5 8.99755 17.5 8.58333V7.75C17.5 6.74755 16.7525 6 15.75 6L13.394 6L14.6129 7.21895M14.6137 7.21967C14.6955 7.30154 14.7545 7.39764 14.7906 7.5L14.6137 7.21967Z"
      fill="currentColor"
    />
  </svg>
</template>
