import { defineStore } from "pinia";

interface State {
  apiKey: string;
  hostname: string;
  entityId: string | number;
}

export const useAuthorizeStore = defineStore("authorizeStore", {
  state: (): State => ({
    apiKey: "",
    hostname: "",
    entityId: "",
  }),

  getters: {},

  actions: {},
});
