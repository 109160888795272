<template>
  <t-dropdown
    ref="dropdownWeightRef"
    class="dropdown-weight"
    rounded-right
    no-border-left
  >
    <template #selected-content>
      <p
        v-if="typeof localValue !== 'string'"
        style="width: 64px; padding-left: 0px; text-align: left"
        :style="{ 'font-weight': localValue.value }"
      >
        {{ localValue.label }}
      </p>
      <p v-else style="width: 64px; padding-left: 0px">
        {{ localValue }}
      </p>
    </template>

    <template #content>
      <ul>
        <VueCustomScrollbar
          style="position: relative; margin: auto"
          :style="{ height: calculatedHeightFonts + 'px' }"
          :settings="{ wheelPropagation: false }"
        >
          <li
            v-for="(weight, k) in weights"
            :key="k"
            style="padding: 4px 0; display: flex; justify-content: flex-start"
            @click="weightChanged(weight)"
          >
            <p
              v-if="weight.label !== 'Default'"
              style="width: 120px; padding-left: 12px"
              :style="{ 'font-weight': weight.value }"
            >
              {{ weight.label }}
            </p>
            <p v-else style="width: 120px; padding-left: 12px">
              {{ weight.label }}
            </p>
          </li>
        </VueCustomScrollbar>
      </ul>
    </template>
  </t-dropdown>
</template>

<script lang="ts" setup>
  import TDropdown from "@/ui/TDropdown/TDropdown.vue";
  import { PerfectScrollbar as VueCustomScrollbar } from "vue3-perfect-scrollbar";

  type IWeight = {
    label: string;
    value: number;
  };

  interface Props {
    selectWeight?: string;
    selectedWeight: number;
  }

  const props = withDefaults(defineProps<Props>(), {
    isResetable: false,
    selectWeight: "Select font",
  });

  const weights = [
    {
      label: "Thin",
      value: 100,
    },
    {
      label: "Extra Light",
      value: 200,
    },
    {
      label: "Light",
      value: 300,
    },
    {
      label: "Regular",
      value: 400,
    },
    {
      label: "Medium",
      value: 500,
    },
    {
      label: "Semi Bold",
      value: 600,
    },
    {
      label: "Bold",
      value: 700,
    },
    {
      label: "Extra Bold",
      value: 800,
    },
    {
      label: "Black",
      value: 900,
    },
  ];

  const emit = defineEmits(["weight-changed"]);

  const dropdownWeightRef = ref();

  const localValue = computed(() => {
    const weight = weights.find((w) => w.value === props.selectedWeight);
    if (weight) {
      return weight;
    }
    return props.selectWeight;
  });

  const calculatedHeightFonts = computed(() => {
    const height = weights.length * 40;

    if (height > 240) {
      return 240;
    }

    return height;
  });

  const weightChanged = (weight: IWeight) => {
    emit("weight-changed", weight.value);
    dropdownWeightRef.value.opened = false;
  };
</script>
