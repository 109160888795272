<template>
  <div class="flex gap-2">
    <FontFamily
      :fonts="fonts"
      :selected-font="fontFamily"
      @font-changed="changeFont"
    />
    <FontWeight :selected-weight="fontWeight" @weight-changed="changeWeight" />
    <FontSize :selected-size="size" @size-changed="changeSize" />
    <ColorPicker :default-color="color" no-input @color-change="changeColor" />
  </div>
</template>

<script setup lang="ts">
  import ColorPicker from "@/ui/TColorPicker/colorPicker.vue";
  import useText from "@/components/LayerPanel/Types/useText/useText";

  const fontStore = useFontStore();
  const props = defineProps<{
    canvas: Ref<fabric.Canvas>;
    activeObject: fabric.Textbox | fabric.IText;
  }>();

  const emits = defineEmits(["update"]);

  const {
    size,
    color,
    fontFamily,
    fontWeight,
    changeFont,
    changeColor,
    changeWeight,
    changeSize,
    updateValues,
  } = useText(props.canvas, props.activeObject, "toolbar");

  const fonts = computed(() => {
    return fontStore.fonts;
  });

  onMounted(() => {
    window.$event.on("updateToolbarValues", () => {
      updateValues();
      emits("update");
    });
  });

  onUnmounted(() => {
    window.$event.off("updateToolbarValues");
  });
</script>
