<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.0586 5.28125L20.3164 8.07422L24.1758 11.9336L26.9688 9.19141C27.4258 8.68359 27.4258 7.92188 26.9688 7.46484L24.7852 5.28125C24.3281 4.82422 23.5664 4.82422 23.0586 5.28125ZM19.1484 9.19141L8.02734 20.3125H8.6875C9.09375 20.3125 9.5 20.7188 9.5 21.125V22.75H11.125C11.5312 22.75 11.9375 23.1562 11.9375 23.5625V24.2227L23.0586 13.1016L19.1484 9.19141ZM6.35156 22.5469L4.98047 27.2695L9.70312 25.8984C9.90625 25.7969 10.1094 25.7461 10.3125 25.6445V24.375H8.6875C8.23047 24.375 7.875 24.0195 7.875 23.5625V21.9375H6.60547C6.50391 22.1406 6.45312 22.3438 6.35156 22.5469ZM25.9531 4.11328L28.1367 6.29688C29.2031 7.41406 29.2031 9.19141 28.1367 10.3086L12.2422 26.2031C11.6328 26.7617 10.9219 27.2188 10.1602 27.4219L4.01562 29.25C3.71094 29.3008 3.40625 29.25 3.20312 29.0469C3 28.8438 2.94922 28.5391 3 28.2344L4.82812 22.0898C5.03125 21.3281 5.48828 20.6172 6.04688 20.0078L21.9414 4.11328C23.0586 3.04688 24.8359 3.04688 25.9531 4.11328ZM19.8086 13.5586L13.3086 20.0586C13.0039 20.3633 12.4453 20.3633 12.1406 20.0586C11.8359 19.7539 11.8359 19.2461 12.1406 18.9414L18.6406 12.4414C18.9453 12.0859 19.5039 12.0859 19.8086 12.4414C20.1133 12.7461 20.1133 13.2539 19.8086 13.5586Z"
      fill="currentColor"
    />
  </svg>
</template>
