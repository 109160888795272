export default (bgImg: Ref<fabric.Image>) => {
  const cropStore = useCropStore();

  const bgWidth =
    Math.abs(cropStore.rotate / 90) % 2 === 1
      ? bgImg.value.height || 0
      : bgImg.value.width || 0;
  const bgHeight =
    Math.abs(cropStore.rotate / 90) % 2 === 1
      ? bgImg.value.width || 0
      : bgImg.value.height || 0;

  return { bgWidth, bgHeight };
};
