<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 2.75C7.45703 2.75 8.25 3.54297 8.25 4.5V8C8.25 8.98438 7.45703 9.75 6.5 9.75H3C2.01562 9.75 1.25 8.98438 1.25 8V4.5C1.25 3.54297 2.01562 2.75 3 2.75H6.5ZM6.5 4.0625H3C2.75391 4.0625 2.5625 4.28125 2.5625 4.5V8C2.5625 8.24609 2.75391 8.4375 3 8.4375H6.5C6.71875 8.4375 6.9375 8.24609 6.9375 8V4.5C6.9375 4.28125 6.71875 4.0625 6.5 4.0625ZM17 9.75C17.957 9.75 18.75 10.543 18.75 11.5V15C18.75 15.9844 17.957 16.75 17 16.75H13.5C12.5156 16.75 11.75 15.9844 11.75 15V11.5C11.75 10.543 12.5156 9.75 13.5 9.75H17ZM17 11.0625H13.5C13.2539 11.0625 13.0625 11.2812 13.0625 11.5V15C13.0625 15.2461 13.2539 15.4375 13.5 15.4375H17C17.2188 15.4375 17.4375 15.2461 17.4375 15V11.5C17.4375 11.2812 17.2188 11.0625 17 11.0625ZM6.5 10.625C7.94922 10.625 9.125 9.44922 9.125 8V5.375H12.625C13.582 5.375 14.375 6.16797 14.375 7.125V8.875H13.5C12.0234 8.875 10.875 10.0508 10.875 11.5V14.125H7.375C6.39062 14.125 5.625 13.3594 5.625 12.375V10.625H6.5Z"
      fill="currentColor"
    />
  </svg>
</template>
