<template>
  <div class="group inline-flex items-center">
    <div class="relative h-[20px]">
      <input
        :id="uuid"
        v-model="radioButtonVal"
        :value="value"
        :name="name"
        class="focus-outline m-0 box-border h-[20px] w-[20px] cursor-pointer appearance-none rounded-full bg-gray-600 transition duration-300 checked:border-[6px] checked:border-accent-1 checked:!bg-white checked:!outline-none focus:bg-gray-600 focus:outline-2 focus:outline-accent-1 disabled:!cursor-not-allowed disabled:!bg-gray-600 group-hover:bg-gray-400"
        :disabled="disabled"
        type="radio"
      />
    </div>
    <label
      class="block cursor-pointer pl-3 font-inter text-xs font-medium leading-6 text-gray-200 transition duration-300 group-focus-within:!text-gray-100 group-hover:text-gray-100"
      :class="{
        '!text-gray-300': disabled,
      }"
      :disabled="disabled"
      :for="uuid"
    >
      {{ label }}
    </label>
  </div>
</template>
<script lang="ts" setup>
  import { v4 as uuidv4 } from "uuid";
  import { computed } from "vue";

  const props = defineProps<{
    modelValue: string | number;
    label: string;
    name: string;
    value: string | number;
    disabled?: boolean;
  }>();

  const emit = defineEmits(["update:modelValue"]);

  const uuid = uuidv4();

  const radioButtonVal = computed({
    get() {
      return props.modelValue;
    },
    set(val) {
      emit("update:modelValue", val);
    },
  });
</script>
<style lang="scss"></style>
