<template>
  <div>
    <div
      class="fixed left-0 top-0 z-[9999] box-border grid h-full w-full items-center justify-center overflow-auto bg-black/[.65] pt-1.5"
      @click.self="$emit('close')"
    >
      <!--PREMADE DIALOG STRUCTURE -->
      <div
        v-if="!$slots['custom-modal']"
        role="dialog"
        class="relative m-auto w-[1145px] max-w-[98vw] overflow-hidden rounded-xl bg-gray-700"
        :class="{
          '!w-[800px]': xl,
          '!w-[600px]': lg,
          '!w-[500px]': md,
          'min-h-[452px] !w-[380px]': xs,
        }"
      >
        <TButton
          class="!absolute right-[8px] top-[8px]"
          data-cy="close-modal"
          @click="$emit('close')"
        >
          <template #icon>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.9609 5.48438C14.2422 5.17969 14.7344 5.17969 15.0156 5.48438C15.3203 5.76562 15.3203 6.25781 15.0156 6.53906L11.0547 10.5L15.0156 14.4844C15.3203 14.7656 15.3203 15.2578 15.0156 15.5391C14.7344 15.8438 14.2422 15.8438 13.9609 15.5391L10 11.5781L6.01562 15.5391C5.73438 15.8438 5.24219 15.8438 4.96094 15.5391C4.65625 15.2578 4.65625 14.7656 4.96094 14.4844L8.92188 10.5L4.96094 6.53906C4.65625 6.25781 4.65625 5.76562 4.96094 5.48438C5.24219 5.17969 5.73438 5.17969 6.01562 5.48438L10 9.44531L13.9609 5.48438Z"
                fill="currentColor"
              />
            </svg>
          </template>
        </TButton>
        <div
          class="flex items-center gap-3 px-5 pb-10 pt-5"
          :class="{
            '!pb-4': headerLow,
          }"
        >
          <div
            v-if="$slots.icon"
            class="box-border grid h-[40px] w-[40px] items-center justify-center rounded-md border border-solid border-gray-500"
            :class="{
              'bg-gray-700': dark,
            }"
          >
            <slot name="icon" />
          </div>
          <div class="text-md font-regular font-inter leading-4 text-gray-100">
            <h1 class="mb-1 text-base font-semibold leading-4 text-gray-50">
              {{ header }}
            </h1>
            <p class="text-sm leading-4">{{ description }}</p>
          </div>
        </div>
        <div class="body">
          <slot name="dialog-body" />
        </div>
        <div class="footer">
          <slot name="dialog-footer" />
        </div>
      </div>

      <!-- CUSTOM MODAL e.g. FileManager -->
      <div v-else class="m-auto">
        <slot name="custom-modal" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
  import { onMounted, onBeforeUnmount } from "vue";
  import TButton from "../TButton/TButton.vue";

  defineProps({
    header: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    xs: {
      type: Boolean,
      default: false,
    },
    md: {
      type: Boolean,
      default: false,
    },
    lg: {
      type: Boolean,
      default: false,
    },
    xl: {
      type: Boolean,
      default: false,
    },
    headerLow: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(["close"]);

  function closeOnEscKey(e: KeyboardEvent) {
    if (e.key === "Escape") {
      //@ts-expect-error
      if (e.target.localName !== "input") {
        emit("close");
      }
    }
  }

  onMounted(() => {
    document.addEventListener("keydown", closeOnEscKey);
  });

  onBeforeUnmount(() => {
    document.removeEventListener("keydown", closeOnEscKey);
  });
</script>

<style lang="scss"></style>
