<template>
  <t-dropdown
    ref="dropdownFontRef"
    class="dropdown-font"
    rounded-right
    no-border-left
  >
    <template #selected-content>
      <p
        v-if="selectedFont !== 'Default'"
        style="width: 132px; padding-left: 0px; text-align: left"
        :style="{ 'font-family': selectedFont }"
      >
        {{ selectedFont ? getSimpleSelectedFont(selectedFont) : selectFont }}
      </p>
      <p v-else style="width: 132px; padding-left: 0px">
        {{ selectedFont }}
      </p>
    </template>

    <template #content>
      <ul>
        <VueCustomScrollbar
          style="position: relative; margin: auto"
          :style="{ height: calculatedHeightFonts + 'px' }"
          :settings="{ wheelPropagation: false }"
        >
          <li
            v-for="(font, k) in localFonts"
            :key="k"
            style="padding: 4px 0; display: flex; justify-content: flex-start"
            @click="fontChanged(font)"
          >
            <p
              v-if="font.label !== 'Default'"
              style="width: 120px; padding-left: 12px"
              :style="{ 'font-family': font.style }"
            >
              {{ font.label }}
            </p>
            <p v-else style="width: 120px; padding-left: 12px">
              {{ font.label }}
            </p>
          </li>
        </VueCustomScrollbar>
      </ul>
    </template>
  </t-dropdown>
</template>

<script lang="ts" setup>
  import TDropdown from "@/ui/TDropdown/TDropdown.vue";
  import { PerfectScrollbar as VueCustomScrollbar } from "vue3-perfect-scrollbar";
  import IFont from "types/IFont/IFont";

  const fontStore = useFontStore();

  interface Props {
    isResetable?: boolean;
    selectFont?: string;
    selectedFont?: IFont | string;
  }

  const props = withDefaults(defineProps<Props>(), {
    isResetable: false,
    selectFont: "Select font",
    selectedFont: undefined,
  });

  const emit = defineEmits(["size-changed", "font-changed", "color-changed"]);

  const dropdownFontRef = ref();

  const fonts = computed(() => {
    return fontStore.fonts;
  });

  const localFonts = computed((): IFont[] => {
    if (props.isResetable) {
      return [
        {
          label: "Default",
          //@ts-expect-error
          value: "",
        },
        ...fonts.value,
      ];
    }

    return fonts.value;
  });

  const calculatedHeightFonts = computed(() => {
    const height = fonts.value.length * 40;

    if (height > 240) {
      return 240;
    }

    return height;
  });

  const fontChanged = (font: IFont) => {
    emit("font-changed", font);
    dropdownFontRef.value.opened = false;
  };

  const getSimpleSelectedFont = (font: string | IFont) => {
    if (typeof font === "object") {
      return font.label;
    }

    const fontArray = font.split(",");
    return fontArray[0].replace(/['"]+/g, "");
  };
</script>
