<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8906 3.50391C16.043 3.35156 16.2461 3.25 16.5 3.25C16.7031 3.25 16.9062 3.35156 17.0586 3.50391L24.3711 10.8164C24.6758 11.1211 24.6758 11.6797 24.3711 11.9844C24.0664 12.2891 23.5078 12.2891 23.2031 11.9844L17.3125 6.04297V20.3125C17.3125 20.7695 16.9062 21.125 16.5 21.125C16.043 21.125 15.6875 20.7695 15.6875 20.3125V6.04297L9.74609 11.9844C9.44141 12.2891 8.88281 12.2891 8.57812 11.9844C8.27344 11.6797 8.27344 11.1211 8.57812 10.8164L15.8906 3.50391ZM26.25 20.3125C26.25 19.9062 26.6055 19.5 27.0625 19.5C27.4688 19.5 27.875 19.9062 27.875 20.3125V25.1875C27.875 27.4727 26.0469 29.25 23.8125 29.25H9.1875C6.90234 29.25 5.125 27.4727 5.125 25.1875V20.3125C5.125 19.9062 5.48047 19.5 5.9375 19.5C6.34375 19.5 6.75 19.9062 6.75 20.3125V25.1875C6.75 26.5586 7.81641 27.625 9.1875 27.625H23.8125C25.1328 27.625 26.25 26.5586 26.25 25.1875V20.3125Z"
      fill="currentColor"
    />
  </svg>
</template>
