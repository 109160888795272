import { fabric } from "fabric";
import getDiagonalAndItsAngle from "@/utils/getDiagonalAndItsAngle";
import getDiagonalRotateAngles from "@/utils/getDiagonalRotateAngles";
import useGetBgSize from "./useGetBgSize";

export default (bgImg: Ref<fabric.Image>, cropRect: Ref<fabric.Rect>) => {
  const cropStore = useCropStore();
  function getScaleOfBg(val: number) {
    const { diagAngle } = getDiagonalAndItsAngle(
      cropRect.value.width || 0,
      cropRect.value.height || 0
    );
    const { newDiagH, newDiagW } = getDiagOnBg(val);
    let scale = 1;
    const width = cropStore.cropperSize.width || 0;
    const height = cropStore.cropperSize.height || 0;

    const divisor1H = Math.sin(diagAngle) * newDiagH;
    const divisor2H = Math.cos(diagAngle) * newDiagH;
    const divisor1W = Math.sin(diagAngle) * newDiagW;
    const divisor2W = Math.cos(diagAngle) * newDiagW;
    const scale1H = height / (divisor1H || 1);
    const scale2H = width / (divisor2H || 1);
    const scale1W = height / (divisor1W || 1);
    const scale2W = width / (divisor2W || 1);
    if (Math.max(scale1H, scale2H, scale1W, scale2W) > 1) {
      scale = Math.max(scale1H, scale2H, scale1W, scale2W);
    }

    return scale;
  }

  function getDiagOnBg(val: number) {
    const { bgWidth, bgHeight } = useGetBgSize(bgImg);
    const { diagAngle } = getDiagonalAndItsAngle(
      cropRect.value.width || 0,
      cropRect.value.height || 0
    );
    const { ang1, ang2 } = getDiagonalRotateAngles(
      diagAngle,
      fabric.util.degreesToRadians(Math.abs(val))
    );

    const newDiagH = bgHeight / (Math.cos(ang1) || 1);
    const newDiagW = bgWidth / (Math.cos(ang2) || 1);

    return { newDiagH, newDiagW };
  }

  return {
    getScaleOfBg,
    getDiagOnBg,
  };
};
