import { fabric } from "fabric";

export default () => {
  const borderColor = "#409eff";
  const rotateOffset = -30;
  const cornerSize = 11;
  const rotateIcon =
    "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg version='1.1' id='Ebene_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='512' height='512' viewBox='0 0 850 850' xml:space='preserve'%3E%3Ccircle style='fill:%23409eff;' cx='425' cy='425' r='425'/%3E%3Cpath d='M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z' transform='translate(169,169)' fill='white'/%3E%3C/svg%3E";

  const imgCursor = encodeURIComponent(`
  <svg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24'>
    <defs>
      <filter id='a' width='266.7%' height='156.2%' x='-75%' y='-21.9%' filterUnits='objectBoundingBox'>
        <feOffset dy='1' in='SourceAlpha' result='shadowOffsetOuter1'/>
        <feGaussianBlur in='shadowOffsetOuter1' result='shadowBlurOuter1' stdDeviation='1'/>
        <feColorMatrix in='shadowBlurOuter1' result='shadowMatrixOuter1' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0'/>
        <feMerge>
          <feMergeNode in='shadowMatrixOuter1'/>
          <feMergeNode in='SourceGraphic'/>
        </feMerge>
      </filter>
      <path id='b' d='M1.67 12.67a7.7 7.7 0 0 0 0-9.34L0 5V0h5L3.24 1.76a9.9 9.9 0 0 1 0 12.48L5 16H0v-5l1.67 1.67z'/>
    </defs>
    <g fill='none' fill-rule='evenodd'><path d='M0 24V0h24v24z'/>
      <g fill-rule='nonzero' filter='url(#a)' transform='rotate(-90 9.25 5.25)'>
        <use fill='#000' fill-rule='evenodd' xlink:href='#b'/>
        <path stroke='#FFF' d='M1.6 11.9a7.21 7.21 0 0 0 0-7.8L-.5 6.2V-.5h6.7L3.9 1.8a10.4 10.4 0 0 1 0 12.4l2.3 2.3H-.5V9.8l2.1 2.1z'/>
      </g>
    </g>
  </svg>`);

  const middleSvg = encodeURIComponent(`
    <svg width="24" height="8" viewBox="0 0 24 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="22" height="6" rx="3" fill="white" stroke="#0A99FF" stroke-width="2"/>
    </svg>`);
  const middleRoteSvg = encodeURIComponent(`
    <svg width="8" height="24" viewBox="0 0 8 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="6" height="22" ry="3" fill="white" stroke="#0A99FF" stroke-width="2"/>
    </svg>`);
  const cornerSvg = encodeURIComponent(`
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1" y="1" width="10" height="10" rx="3" fill="white" stroke="#0A99FF" stroke-width="2"/>
    </svg>`);
  const rotateImg = document.createElement("img");
  rotateImg.src = rotateIcon;
  const middleImg = document.createElement("img");
  middleImg.src = `data:image/svg+xml;charset=utf-8,${middleSvg}`;
  const middleRoteImg = document.createElement("img");
  middleRoteImg.src = `data:image/svg+xml;charset=utf-8,${middleRoteSvg}`;
  const cornerImg = document.createElement("img");
  cornerImg.src = `data:image/svg+xml;charset=utf-8,${cornerSvg}`;
  function renderIcon(
    icon: any,
    corner?: "mt" | "mb" | "ml" | "mr" | "tl" | "tr" | "bl" | "br"
  ) {
    return function renderIcon(
      ctx: any,
      left: any,
      top: any,
      styleOverride: any,
      fabricObject: any
    ) {
      if (
        corner &&
        (corner === "mb" || corner === "mt") &&
        fabricObject.width * fabricObject.scaleX <
          (this.sizeX + 15) / fabricObject.canvas.getZoom()
      ) {
        return;
      }
      if (
        corner &&
        (corner === "mr" || corner === "ml") &&
        fabricObject.height * fabricObject.scaleY <
          (this.sizeY + 15) / fabricObject.canvas.getZoom()
      ) {
        return;
      }
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(
        icon,
        -this.sizeX / 2,
        -this.sizeY / 2,
        this.sizeX,
        this.sizeY
      );
      ctx.restore();
    };
  }
  fabric.Object.prototype.transparentCorners = false;
  fabric.Object.prototype.cornerColor = "white";
  fabric.Object.prototype.cornerStyle = "circle";
  fabric.Object.prototype.cornerStrokeColor = borderColor;
  fabric.Object.prototype.cornerSize = 10;
  fabric.Object.prototype.borderColor = borderColor;
  fabric.Object.prototype.borderScaleFactor = 2;

  fabric.Object.prototype.controls.tl.render =
    fabric.Textbox.prototype.controls.tl.render =
    fabric.Text.prototype.controls.tl.render =
      renderIcon(cornerImg);
  fabric.Object.prototype.controls.tl.sizeX =
    fabric.Textbox.prototype.controls.tl.sizeX =
    fabric.Text.prototype.controls.tl.sizeX =
      cornerSize;
  fabric.Object.prototype.controls.tl.sizeY =
    fabric.Textbox.prototype.controls.tl.sizeY =
    fabric.Text.prototype.controls.tl.sizeY =
      cornerSize;

  fabric.Object.prototype.controls.bl.render =
    fabric.Textbox.prototype.controls.bl.render =
    fabric.Text.prototype.controls.bl.render =
      renderIcon(cornerImg);
  fabric.Object.prototype.controls.bl.sizeX =
    fabric.Textbox.prototype.controls.bl.sizeX =
    fabric.Text.prototype.controls.bl.sizeX =
      cornerSize;
  fabric.Object.prototype.controls.bl.sizeY =
    fabric.Textbox.prototype.controls.bl.sizeY =
    fabric.Text.prototype.controls.bl.sizeY =
      cornerSize;

  fabric.Object.prototype.controls.tr.render =
    fabric.Textbox.prototype.controls.tr.render =
    fabric.Text.prototype.controls.tr.render =
      renderIcon(cornerImg);
  fabric.Object.prototype.controls.tr.sizeX =
    fabric.Textbox.prototype.controls.tr.sizeX =
    fabric.Text.prototype.controls.tr.sizeX =
      cornerSize;
  fabric.Object.prototype.controls.tr.sizeY =
    fabric.Textbox.prototype.controls.tr.sizeY =
    fabric.Text.prototype.controls.tr.sizeY =
      cornerSize;

  fabric.Object.prototype.controls.br.render =
    fabric.Textbox.prototype.controls.br.render =
    fabric.Text.prototype.controls.br.render =
      renderIcon(cornerImg);
  fabric.Object.prototype.controls.br.sizeX =
    fabric.Textbox.prototype.controls.br.sizeX =
    fabric.Text.prototype.controls.br.sizeX =
      cornerSize;
  fabric.Object.prototype.controls.br.sizeY =
    fabric.Textbox.prototype.controls.br.sizeY =
    fabric.Text.prototype.controls.br.sizeY =
      cornerSize;

  fabric.Object.prototype.controls.mt.render = renderIcon(middleImg, "mt");
  fabric.Object.prototype.controls.mt.sizeX = 24;
  fabric.Object.prototype.controls.mt.sizeY = 8;
  // fabric.Object.prototype.controls.mt.offsetY = 1;

  fabric.Object.prototype.controls.mb.render = renderIcon(middleImg, "mb");
  fabric.Object.prototype.controls.mb.sizeX = 24;
  fabric.Object.prototype.controls.mb.sizeY = 8;
  // fabric.Object.prototype.controls.mb.offsetY = 1;

  fabric.Object.prototype.controls.ml.render =
    fabric.Textbox.prototype.controls.ml.render =
    fabric.Text.prototype.controls.ml.render =
      renderIcon(middleRoteImg, "ml");
  fabric.Object.prototype.controls.ml.sizeX =
    fabric.Textbox.prototype.controls.ml.sizeX =
    fabric.Text.prototype.controls.ml.sizeX =
      8;
  fabric.Object.prototype.controls.ml.sizeY =
    fabric.Textbox.prototype.controls.ml.sizeY =
    fabric.Text.prototype.controls.ml.sizeY =
      24;
  // fabric.Object.prototype.controls.ml.offsetX =
  //   fabric.Textbox.prototype.controls.ml.offsetX =
  //   fabric.Text.prototype.controls.ml.offsetX =
  //     -1;

  fabric.Object.prototype.controls.mr.render =
    fabric.Textbox.prototype.controls.mr.render =
    fabric.Text.prototype.controls.mr.render =
      renderIcon(middleRoteImg, "mr");
  fabric.Object.prototype.controls.mr.sizeX =
    fabric.Textbox.prototype.controls.mr.sizeX =
    fabric.Text.prototype.controls.mr.sizeX =
      8;
  fabric.Object.prototype.controls.mr.sizeY =
    fabric.Textbox.prototype.controls.mr.sizeY =
    fabric.Text.prototype.controls.mr.sizeY =
      24;
  // fabric.Object.prototype.controls.mr.offsetX =
  //   fabric.Textbox.prototype.controls.mr.offsetX =
  //   fabric.Text.prototype.controls.mr.offsetX =
  //     1;

  fabric.Object.prototype.controls.mtr.render =
    fabric.Textbox.prototype.controls.mtr.render =
    fabric.Text.prototype.controls.mtr.render =
      renderIcon(rotateImg);
  fabric.Object.prototype.controls.mtr.sizeX =
    fabric.Textbox.prototype.controls.mtr.sizeX =
    fabric.Text.prototype.controls.mtr.sizeX =
      20;
  fabric.Object.prototype.controls.mtr.sizeY =
    fabric.Textbox.prototype.controls.mtr.sizeY =
    fabric.Text.prototype.controls.mtr.sizeY =
      20;
  fabric.Object.prototype.controls.mtr.offsetY =
    fabric.Textbox.prototype.controls.mtr.offsetY =
    fabric.Text.prototype.controls.mtr.offsetY =
      rotateOffset;
  fabric.Object.prototype.controls.mtr.cursorStyle =
    fabric.Textbox.prototype.controls.mtr.cursorStyle =
    fabric.Text.prototype.controls.mtr.cursorStyle =
      `url("data:image/svg+xml;charset=utf-8,${imgCursor}") 12 12, crosshair`;
  fabric.Object.prototype.controls.mtr.withConnection =
    fabric.Textbox.prototype.controls.mtr.withConnection =
    fabric.Text.prototype.controls.mtr.withConnection =
      false;

  fabric.Textbox.prototype.editingBorderColor = borderColor;
  //@ts-expect-error
  fabric.Text.prototype.editingBorderColor = borderColor;
  fabric.Group.prototype.borderColor = "white";
  fabric.Group.prototype.borderDashArray = [5, 5];
  fabric.Group.prototype.setControlsVisibility({
    ml: false,
    mr: false,
    mt: false,
    mb: false,
  });
  fabric.Group.prototype.originX = "center";
  fabric.Group.prototype.originY = "center";
  fabric.Group.prototype.subTargetCheck = true;
};
