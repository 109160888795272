import { createClient } from "./pexels-client/main";

export default () => {
  const pexelsClient = createClient(
    "563492ad6f9170000100000101287a5183e34dd5994204a0494b7dca"
  );
  const loadingNext = ref(false);
  const perPage = ref(15);
  const currentPage = ref(1);
  const locale = "en-US";
  const query = ref("");

  const getPexelsPhoto = async () => {
    try {
      //@ts-expect-error
      const response: PhotosWithTotalResults =
        await pexelsClient.photos.curated({
          per_page: perPage.value,
          page: currentPage.value,
        });
      return response;
    } catch (err) {
      console.error("Getting images failed");
    }
  };

  const searchPixelsPhotos = async (query: string) => {
    loadingNext.value = true;

    try {
      //@ts-expect-error
      const response: PhotosWithTotalResults = await pexelsClient.photos.search(
        {
          query: query,
          per_page: perPage.value,
          page: currentPage.value,
          locale: locale,
        }
      );
      //   totalItemsForCurrentQuery.value = response.total_results;
      return response;
    } catch (err) {
      console.error("Getting images failed");
    } finally {
      loadingNext.value = false;
    }
  };

  return {
    searchPixelsPhotos,
    loadingNext,
    getPexelsPhoto,
    perPage,
    currentPage,
    locale,
    query,
  };
};
