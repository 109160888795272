<template>
  <div class="flex flex-col gap-2">
    <TRadioButton
      v-for="item in options"
      :key="item.value"
      v-model="selected"
      :label="item.label"
      :value="item.value"
      :name="name"
    />
  </div>
</template>

<script setup lang="ts">
  import TRadioButton from "./TRadioButton.vue";

  const props = defineProps<{
    options: { label: string; value: string | number }[];
    modelValue: string | number;
    name: string;
  }>();

  const emit = defineEmits(["update:modelValue"]);

  const selected = computed({
    get() {
      return props.modelValue;
    },
    set(val) {
      emit("update:modelValue", val);
    },
  });
</script>
