export default () => {
  function scaleImage(image: any, corner: "ml" | "mr" | "mt" | "mb") {
    const width = image._originalElement.naturalWidth;
    const height = image._originalElement.naturalHeight;
    if (image.cropWidth === undefined) image.cropWidth = image.width;
    if (image.cropHeight === undefined) image.cropHeight = image.height;
    if (image.hCropL === undefined) image.hCropL = image.cropX || 0;
    if (image.hCropT === undefined) image.hCropT = image.cropY || 0;
    if (image.cropXr === undefined) image.cropXr = 0;
    if (image.cropYb === undefined) image.cropYb = 0;
    if (image.hCropR === undefined) image.hCropR = 0;
    if (image.hCropB === undefined) image.hCropB = 0;
    const cropW = image.cropWidth;
    const cropH = image.cropHeight;
    if (corner === "mr" || corner === "ml") {
      const dCropX =
        (image.width * (image.scaleY - image.scaleX)) / image.scaleY;
      const cropX = (corner === "ml" ? image.cropX : image.cropXr) + dCropX;
      const scaleOY = (image.height * image.scaleY) / cropH;
      if (
        cropX > 0 &&
        Math.round(scaleOY * 100000) === Math.round(image.scaleY * 100000)
      ) {
        image.scaleX = image.scaleY;
        if (corner === "ml") {
          image.cropX = image.hCropL = cropX;
        } else {
          image.cropXr = image.hCropR = cropX;
        }
        image.width = width - image.cropX - image.cropXr;
        image.cropWidth = image.width;
      } else {
        if (scaleOY > image.scaleX) {
          const dCX = (image.width * (scaleOY - image.scaleX)) / scaleOY;
          image.scaleX = image.scaleY = scaleOY;
          image.cropY = image.hCropT;
          image.cropYb = image.hCropB;
          image.height = cropH;
          if (dCX > 0) {
            if (corner === "ml") {
              image.cropX = image.hCropL = dCX;
            } else {
              image.cropXr = image.hCropR = dCX;
            }
            image.width = width - image.cropX - image.cropXr;
            image.cropWidth = image.width;
          }
        } else {
          if (corner === "ml" && image.cropX > 0) {
            const scaleX =
              (image.width * image.scaleX) / (width - image.cropXr);
            const dCropY = (image.height * (scaleX - image.scaleY)) / scaleX;
            const cropY = cropH - image.height + dCropY;
            image.cropX = image.hCropL = 0;
            image.width = width - image.cropXr;
            image.cropWidth = image.width;
            image.scaleY = image.scaleX = scaleX;
            image.cropY = image.cropY + dCropY / 2;
            image.cropYb = image.cropYb + dCropY / 2;
            image.height = cropH - cropY;
          } else if (corner === "mr" && image.cropXr > 0) {
            const scaleX = (image.width * image.scaleX) / (width - image.cropX);
            const dCropY = (image.height * (scaleX - image.scaleY)) / scaleX;
            const cropY = cropH - image.height + dCropY;
            image.cropXr = image.hCropR = 0;
            image.width = width - image.cropX;
            image.cropWidth = image.width;
            image.scaleY = image.scaleX = scaleX;
            image.cropY = image.cropY + dCropY / 2;
            image.cropYb = image.cropYb + dCropY / 2;
            image.height = cropH - cropY;
          } else {
            const dCropY =
              (image.height * (image.scaleX - image.scaleY)) / image.scaleX;
            const cropY = cropH - image.height + dCropY;

            image.scaleY = image.scaleX;
            image.cropY = image.cropY + dCropY / 2;
            image.cropYb = image.cropYb + dCropY / 2;
            image.height = cropH - cropY;
          }
        }
      }
    } else {
      const dCropY =
        (image.height * (image.scaleX - image.scaleY)) / image.scaleX;
      const cropY = (corner === "mt" ? image.cropY : image.cropYb) + dCropY;
      const scaleOX = (image.width * image.scaleX) / cropW;
      if (
        cropY > 0 &&
        Math.round(scaleOX * 100000) === Math.round(image.scaleX * 100000)
      ) {
        image.scaleY = image.scaleX;
        if (corner === "mt") {
          image.cropY = image.hCropT = cropY;
        } else {
          image.cropYb = image.hCropB = cropY;
        }
        image.height = height - image.cropY - image.cropYb;
        image.cropHeight = image.height;
      } else {
        if (scaleOX > image.scaleY) {
          const dCY = (image.height * (scaleOX - image.scaleY)) / scaleOX;
          image.scaleX = image.scaleY = scaleOX;
          image.cropX = image.hCropL;
          image.cropXr = image.hCropR;
          image.width = cropW;
          if (dCY > 0) {
            if (corner === "mt") {
              image.cropY = image.hCropT = dCY;
            } else {
              image.cropYb = image.hCropB = dCY;
            }
            image.height = height - image.cropY - image.cropYb;
            image.cropHeight = image.height;
          }
        } else {
          if (corner === "mt" && image.cropY > 0) {
            const scaleY =
              (image.height * image.scaleY) / (height - image.cropYb);
            const dCropX = (image.width * (scaleY - image.scaleX)) / scaleY;
            const cropX = cropW - image.width + dCropX;
            image.cropY = image.hCropT = 0;
            image.height = height - image.cropYb;
            image.cropHeight = image.height;
            image.scaleY = image.scaleX = scaleY;
            image.cropX = image.cropX + dCropX / 2;
            image.cropXr = image.cropXr + dCropX / 2;
            image.width = cropW - cropX;
          } else if (corner === "mb" && image.cropYb > 0) {
            const scaleY =
              (image.height * image.scaleY) / (height - image.cropY);
            const dCropX = (image.width * (scaleY - image.scaleX)) / scaleY;
            const cropX = cropW - image.width + dCropX;
            image.cropYb = image.hCropB = 0;
            image.height = height - image.cropY;
            image.cropHeight = image.height;
            image.scaleY = image.scaleX = scaleY;
            image.cropX = image.cropX + dCropX / 2;
            image.cropXr = image.cropXr + dCropX / 2;
            image.width = cropW - cropX;
          } else {
            const dCropX =
              (image.width * (image.scaleY - image.scaleX)) / image.scaleY;
            const cropX = cropW - image.width + dCropX;
            image.scaleX = image.scaleY;
            image.cropX = image.cropX + dCropX / 2;
            image.cropXr = image.cropXr + dCropX / 2;
            image.width = cropW - cropX;
          }
        }
      }
    }
    image.setCoords();
  }

  return { scaleImage };
};
