<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.0625 26C27.4688 26 27.875 26.4062 27.875 26.8125C27.875 27.2695 27.4688 27.625 27.0625 27.625H23C22.543 27.625 22.1875 27.2695 22.1875 26.8125C22.1875 26.4062 22.543 26 23 26H24.168L22.1367 21.125H10.8125L8.78125 26H10C10.4062 26 10.8125 26.4062 10.8125 26.8125C10.8125 27.2695 10.4062 27.625 10 27.625H5.9375C5.48047 27.625 5.125 27.2695 5.125 26.8125C5.125 26.4062 5.48047 26 5.9375 26H7.00391L15.7383 5.33203C15.9922 4.77344 16.957 4.77344 17.2109 5.33203L25.9453 26H27.0625ZM11.5234 19.5H21.4258L16.5 7.76953L11.5234 19.5Z"
      fill="currentColor"
    />
  </svg>
</template>
