<template>
  <label
    class="block font-inter text-xs font-medium leading-6 text-gray-200 transition duration-300 group-focus-within/input:!text-gray-100 group-focus-within:!text-gray-100 group-hover:text-gray-100 dark:group-focus-within/input:!text-gray-100"
    :class="{
      '!text-gray-100': opened,
      '!text-gray-300': disabled,
      'no-margin': noMargin,
      flex: tip !== '',
    }"
    :for="forId"
  >
    <slot />

    <TTip v-if="tip !== ''" :tip="tip" />
  </label>
</template>
<script lang="ts" setup>
import TTip from "../TTip/TTip.vue";
import { ref } from "vue";

const props = defineProps({
  for: {
    type: String,
    default: "",
  },
  opened: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  noMargin: {
    type: Boolean,
    default: false,
  },
  tip: {
    type: String,
    default: "",
  },
});

const forId = ref(props.for);
</script>
