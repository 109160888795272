import useInitPreviews from "./useInitPreviews";

export default (props: {
  canvas: Ref<fabric.Canvas>;
  bgImg: Ref<fabric.Image>;
  cropRect: Ref<fabric.Rect>;
  cropMask: Ref<fabric.Rect>;
  cropLeftFirstThird: Ref<fabric.Line>;
  cropLeftSecondThird: Ref<fabric.Line>;
  cropTopFirstThird: Ref<fabric.Line>;
  cropTopSecondThird: Ref<fabric.Line>;
  snapTopLine: Ref<fabric.Line>;
  snapBottomLine: Ref<fabric.Line>;
  snapLeftLine: Ref<fabric.Line>;
  snapRightLine: Ref<fabric.Line>;
  snapCenterHorizontalLine: Ref<fabric.Line>;
  snapCenterVerticalLine: Ref<fabric.Line>;
  auto: any;
  setCenterFromObject: any;
  saveStateBounced: any;
}) => {
  const stateStore = useStateStore();
  const { snapTo, hideAllSnaps, resizeAndSnap, scalingAndSnap } =
    useMoveAndScale(
      props.canvas,
      props.snapTopLine,
      props.snapBottomLine,
      props.snapLeftLine,
      props.snapRightLine,
      props.snapCenterHorizontalLine,
      props.snapCenterVerticalLine
    );
  const { setPreviewsForFilters } = useInitPreviews();

  const { crop } = useCrop(props);
  const group: Ref<any> = ref(undefined);
  const groupText: Ref<any> = ref(undefined);

  props.canvas.value.on("object:moving", (event: any) => {
    snapTo(event.target);
    stateStore.hideToolbar = true;
  });
  props.canvas.value.on("object:scaling", (event: any) => {
    if (event.target.id === "cropRect") {
      crop.scalingCropRect(event.target, event.transform.corner);
    } else {
      scalingAndSnap(event);
      stateStore.hideToolbar = true;
    }
  });
  props.canvas.value.on("object:modified", (event: any) => {
    if (event.target.id === "cropRect") {
      window.$event.emit("resize");
    } else {
      stateStore.hideToolbar = false;
      hideAllSnaps();
      window.$event.emit("updateToolbarValues");
      window.$event.emit("updatePanelValues");
      props.saveStateBounced();
    }
  });
  props.canvas.value.on("object:resizing", (event: any) => {
    if (event.target.type === "textbox") {
      resizeAndSnap(event);
      stateStore.hideToolbar = true;
    }
  });
  props.canvas.value.on("object:rotating", (event: any) => {
    if (event.target.id !== "cropRect") {
      stateStore.hideToolbar = true;
    }
  });

  props.canvas.value.on("selection:updated", () => {
    window.$event.emit("updateToolbar");
    window.$event.emit("updatePanel");
    stateStore.openLayer = false;
  });
  props.canvas.value.on("selection:cleared", () => {
    window.$event.emit("updateToolbar");
    window.$event.emit("updatePanel");
    stateStore.openLayer = false;
  });
  props.canvas.value.on("selection:created", (event: any) => {
    stateStore.openLayer = false;
    if (event.selected.length === 1 && event.selected[0].type === "image") {
      setPreviewsForFilters(event.selected[0]._originImage);
    }
    window.$event.emit("updateToolbar");
    window.$event.emit("updatePanel");
  });

  const cropIsMoving = ref(false);
  const x = ref(0);
  const y = ref(0);
  props.canvas.value.on("mouse:down", (event: any) => {
    if (event.target && event.target.id === "cropRect") {
      if (stateStore.activeGroupElement) {
        stateStore.activeGroupElement = undefined;
        props.canvas.value.requestRenderAll();
        window.$event.emit("updateToolbar");
        window.$event.emit("updatePanel");
      }
      cropIsMoving.value = true;
      x.value = event.absolutePointer.x;
      y.value = event.absolutePointer.y;
      return;
    }
    if (
      event.target &&
      (event.target.type === "group" ||
        (event.target.type === "activeSelection" &&
          stateStore.group.selection)) &&
      event.subTargets.length > 0
    ) {
      setTimeout(() => {
        event.target.forEachObject((obj: any) => {
          if (event.subTargets[0] === obj) {
            obj.hasBorders = true;
          } else {
            obj.hasBorders = false;
          }
        });
        stateStore.activeGroupElement = event.subTargets[0];
        stateStore.group.selection = event.target.toActiveSelection();
        if (event.subTargets[0].type === "image") {
          setPreviewsForFilters(event.subTargets[0]._originImage);
        }
        if (event.subTargets[0].type === "textbox") {
          groupText.value = event.subTargets[0].enterEditing();
        }
        window.$event.emit("updateToolbar");
        window.$event.emit("updatePanel");
      }, 5);
    } else if (stateStore.activeGroupElement) {
      stateStore.activeGroupElement = undefined;
      window.$event.emit("updateToolbar");
      window.$event.emit("updatePanel");
    }
  });

  props.canvas.value.on("mouse:down:before", (event: any) => {
    if (groupText.value) {
      groupText.value.exitEditing();
      groupText.value = undefined;
    }
    if (event.target && event.target.type === "activeSelection") {
      return;
    }
    if (stateStore.group.selection) {
      stateStore.group.selection.forEachObject((obj: any) => {
        obj.hasBorders = true;
      });
      stateStore.group.selection.toGroup();
      stateStore.group.selection = undefined;
    }
  });
  props.canvas.value.on("mouse:up", (event: any) => {
    if (event.target && event.target.id === "cropRect") {
      cropIsMoving.value = false;
    }
  });
  props.canvas.value.on("mouse:over", (event: any) => {
    props.canvas.value.renderTop();
    if (
      event.target &&
      event.target.selectable &&
      event.target !== props.canvas.value.getActiveObject()
    ) {
      // @ts-expect-error
      event.target._renderControls(props.canvas.value.contextTop, {
        hasControls: false,
      });
      // @ts-expect-error
      props.canvas.value.contextTopDirty = true;
    }
  });
  props.canvas.value.on("mouse:out", () => {
    props.canvas.value.renderTop();
  });
  props.canvas.value.on("mouse:move", (event: any) => {
    if (
      cropIsMoving.value &&
      event.target &&
      event.target.id === "cropRect" &&
      event.transform &&
      event.transform?.action === "drag"
    ) {
      const dx = x.value - event.absolutePointer.x;
      const dy = y.value - event.absolutePointer.y;
      crop.movingCropRect(event.target, dx, dy);
      x.value = event.absolutePointer.x;
      y.value = event.absolutePointer.y;
    }
  });
};
