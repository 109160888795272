import { fabric } from "fabric";
import useBasicShapes from "./useElements/useBasicShapes";

export default (canvas: Ref<fabric.Canvas>) => {
  function addShape(type: string) {
    //@ts-expect-error
    const shape = useBasicShapes[type]();

    canvas.value.viewportCenterObject(shape);
    canvas.value.add(markRaw(shape));
    canvas.value.setActiveObject(shape);
  }

  return { addShape };
};
