<template>
  <div class="relative">
    <TButton
      class="grid h-8 w-8 items-center rounded-[3px] bg-gray-400 px-1 py-0.5 !text-gray-50 transition duration-300 hover:bg-gray-600"
      @mouseover="showMenu = true"
    >
      <template #icon>
        <AIIcon />
      </template>
    </TButton>

    <div
      v-if="showMenu"
      class="absolute right-[85%] top-0 z-[999] pt-[20px]"
      :class="{
        '!right-[-100px]': right,
      }"
      @mouseleave="showMenu = false"
    >
      <div class="space-y-1 rounded-lg bg-white/60 p-1">
        <t-button
          class="w-full"
          xs
          align-left
          :disabled="loading"
          @click="improveText"
        >
          <template #icon>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.90625 2.88672C8.93359 2.80469 9.01562 2.75 9.125 2.75C9.20703 2.75 9.28906 2.80469 9.31641 2.88672L9.69922 3.92578L10.7383 4.30859C10.8203 4.33594 10.875 4.41797 10.875 4.5C10.875 4.60938 10.8203 4.69141 10.7383 4.71875L9.69922 5.10156L9.31641 6.11328C9.28906 6.19531 9.20703 6.25 9.125 6.25C9.01562 6.25 8.93359 6.19531 8.90625 6.11328L8.52344 5.10156L7.48438 4.71875C7.40234 4.69141 7.375 4.60938 7.375 4.5C7.375 4.41797 7.40234 4.33594 7.48438 4.30859L8.52344 3.92578L8.90625 2.88672ZM15.6602 3.16016L16.5898 4.08984C17.1094 4.60938 17.1094 5.42969 16.5898 5.94922L6.17188 16.3672C5.65234 16.8867 4.83203 16.8867 4.3125 16.3672L3.38281 15.4375C2.86328 14.918 2.86328 14.0977 3.38281 13.5781L13.8008 3.16016C14.3203 2.64062 15.1406 2.64062 15.6602 3.16016ZM14.7305 4.0625L11.7227 7.07031L12.6797 8.02734L15.6602 5.01953L14.7305 4.0625ZM5.24219 15.4375L11.75 8.95703L10.793 8L4.3125 14.5078L5.24219 15.4375ZM2.31641 5.97656L3.875 5.375L4.44922 3.84375C4.47656 3.70703 4.61328 3.625 4.75 3.625C4.85938 3.625 4.99609 3.70703 5.02344 3.84375L5.625 5.375L7.15625 5.97656C7.29297 6.00391 7.375 6.14062 7.375 6.25C7.375 6.38672 7.29297 6.52344 7.15625 6.55078L5.625 7.125L5.02344 8.68359C4.99609 8.79297 4.85938 8.875 4.75 8.875C4.61328 8.875 4.47656 8.79297 4.44922 8.68359L3.875 7.125L2.31641 6.55078C2.17969 6.52344 2.125 6.38672 2.125 6.25C2.125 6.14062 2.17969 6.00391 2.31641 5.97656ZM11.9414 12.9766L13.5 12.375L14.0742 10.8438C14.1016 10.707 14.2383 10.625 14.375 10.625C14.4844 10.625 14.6211 10.707 14.6484 10.8438L15.25 12.375L16.7812 12.9766C16.918 13.0039 17 13.1406 17 13.25C17 13.3867 16.918 13.5234 16.7812 13.5508L15.25 14.125L14.6484 15.6836C14.6211 15.793 14.4844 15.875 14.375 15.875C14.2383 15.875 14.1016 15.793 14.0742 15.6836L13.5 14.125L11.9414 13.5508C11.8047 13.5234 11.75 13.3867 11.75 13.25C11.75 13.1406 11.8047 13.0039 11.9414 12.9766Z"
                fill="#CBCBCD"
              />
            </svg>
          </template>
          <span>{{ t("editor.improve") }}</span>
        </t-button>
        <t-button
          class="w-full"
          xs
          align-left
          :disabled="loading"
          @click="rephraseText"
        >
          <template #icon>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.8125 6.25H11.7227V7.78125C11.7227 8.05469 11.8594 8.30078 12.1055 8.41016C12.3516 8.49219 12.6523 8.4375 12.8438 8.24609L15.0312 6.05859C15.2773 5.8125 15.2773 5.40234 15.0312 5.15625L12.8438 2.96875C12.6523 2.77734 12.3516 2.72266 12.1055 2.80469C11.8594 2.91406 11.7227 3.16016 11.7227 3.40625V4.9375H7.8125C5.13281 4.9375 3 7.09766 3 9.75C3 10.1328 3.27344 10.4062 3.65625 10.4062C4.01172 10.4062 4.3125 10.1328 4.3125 9.75C4.3125 7.83594 5.87109 6.25 7.8125 6.25ZM16.3438 9.09375C15.9609 9.09375 15.6875 9.39453 15.6875 9.75C15.6875 11.6914 14.1016 13.25 12.1875 13.25H8.25V11.7188C8.25 11.4727 8.08594 11.2266 7.83984 11.1172C7.59375 11.0352 7.29297 11.0898 7.12891 11.2539L4.91406 13.4414C4.66797 13.7148 4.66797 14.125 4.91406 14.3711L7.12891 16.5586C7.29297 16.75 7.59375 16.8047 7.83984 16.7227C8.08594 16.6133 8.25 16.3672 8.25 16.0938V14.5625H12.1875C14.8398 14.5625 17 12.4297 17 9.75C17 9.39453 16.6992 9.09375 16.3438 9.09375Z"
                fill="#CBCBCD"
              />
            </svg>
          </template>
          <span>{{ t("editor.rephrase") }}</span>
        </t-button>
        <t-button
          class="w-full"
          xs
          align-left
          :disabled="loading"
          @click="makeLongerText"
        >
          <template #icon>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 9.75C17 9.94141 16.918 10.1328 16.7812 10.2422L13.9375 12.8672C13.8008 12.9766 13.6367 13.0312 13.5 13.0312C13.3086 13.0312 13.1445 12.9766 13.0078 12.8398C12.7617 12.5664 12.7617 12.1562 13.0352 11.9102L14.6484 10.4062H5.32422L6.9375 11.9102C7.21094 12.1562 7.21094 12.5664 6.96484 12.8398C6.82812 12.9766 6.66406 13.0312 6.5 13.0312C6.33594 13.0312 6.17188 12.9766 6.03516 12.8672L3.19141 10.2422C3.05469 10.1328 3 9.94141 3 9.75C3 9.58594 3.05469 9.39453 3.19141 9.28516L6.03516 6.66016C6.30859 6.41406 6.71875 6.41406 6.96484 6.6875C7.21094 6.96094 7.21094 7.37109 6.9375 7.61719L5.32422 9.09375H14.6484L13.0352 7.61719C12.7891 7.37109 12.7617 6.96094 13.0078 6.6875C13.2539 6.41406 13.6641 6.41406 13.9375 6.66016L16.7812 9.28516C16.918 9.39453 17 9.58594 17 9.75Z"
                fill="#CBCBCD"
              />
            </svg>
          </template>
          <span>{{ t("editor.make_longer") }}</span>
        </t-button>
        <t-button
          class="w-full"
          xs
          align-left
          :disabled="loading"
          @click="makeShorterText"
        >
          <template #icon>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.9062 14.8633L11.5312 11.1992L12.543 10.3242L16.7539 13.8516C17.0273 14.0977 17.0547 14.5078 16.8359 14.7812C16.5898 15.0547 16.1797 15.082 15.9062 14.8633ZM6.0625 2.75C7.73047 2.75 9.125 4.14453 9.125 5.8125C9.125 6.30469 8.98828 6.76953 8.79688 7.17969L10.8477 8.90234L15.9062 4.66406C16.1797 4.44531 16.5898 4.47266 16.8359 4.74609C17.0547 5.01953 17.0273 5.42969 16.7539 5.67578L8.79688 12.3477C8.98828 12.7578 9.125 13.2227 9.125 13.6875C9.125 15.3828 7.73047 16.75 6.0625 16.75C4.36719 16.75 3 15.3828 3 13.6875C3 12.0195 4.36719 10.625 6.0625 10.625C6.77344 10.625 7.45703 10.8984 7.97656 11.3086L9.83594 9.75L7.97656 8.21875C7.45703 8.62891 6.77344 8.875 6.0625 8.875C4.36719 8.875 3 7.50781 3 5.8125C3 4.14453 4.36719 2.75 6.0625 2.75ZM4.3125 5.8125C4.3125 6.79688 5.07812 7.5625 6.0625 7.5625C7.01953 7.5625 7.8125 6.79688 7.8125 5.8125C7.8125 4.85547 7.01953 4.0625 6.0625 4.0625C5.07812 4.0625 4.3125 4.85547 4.3125 5.8125ZM6.0625 11.9375C5.07812 11.9375 4.3125 12.7305 4.3125 13.6875C4.3125 14.6719 5.07812 15.4375 6.0625 15.4375C7.01953 15.4375 7.8125 14.6719 7.8125 13.6875C7.8125 12.7305 7.01953 11.9375 6.0625 11.9375Z"
                fill="#CBCBCD"
              />
            </svg>
          </template>
          <span>{{ t("editor.make_shorter") }}</span>
        </t-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import TButton from "@/ui/TButton/TButton.vue";
  import useAiAssistant from "@/composables/text-generator/useAiAssistant";
  import { ref } from "vue";
  import { useI18n } from "vue-i18n";
  import AIIcon from "@/icons/AIIcon.vue";

  const { t } = useI18n();
  const { improve, rephrase, makeLonger, makeShorter, loading } =
    useAiAssistant();
  const showMenu = ref(false);

  const props = defineProps<{ right?: boolean; text: any; canvas: any }>();
  const emit = defineEmits(["enrich"]);

  async function improveText() {
    // emit("enrich");
    await improve(props.text);
    props.canvas.value.renderAll();
  }

  async function rephraseText() {
    await rephrase(props.text);
    props.canvas.value.renderAll();
  }

  async function makeLongerText() {
    await makeLonger(props.text);
    props.canvas.value.renderAll();
  }

  async function makeShorterText() {
    await makeShorter(props.text);
    props.canvas.value.renderAll();
  }
</script>

<style scoped lang="scss">
  .move-buttons-overlay {
    position: absolute;
    padding: 1rem;
    top: 0;
    width: 120px;
    left: -150px;
    height: 130px;
    z-index: 998;
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 2px 3px rgba(44, 61, 70, 0.15);
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
</style>
