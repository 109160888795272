<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.41797 7.125H7.8125C8.27734 7.125 8.6875 7.53516 8.6875 8C8.6875 8.49219 8.27734 8.875 7.8125 8.875H4.3125C3.82031 8.875 3.4375 8.49219 3.4375 8V4.5C3.4375 4.03516 3.82031 3.625 4.3125 3.625C4.77734 3.625 5.1875 4.03516 5.1875 4.5V5.92188L5.65234 5.42969C8.05859 3.05078 11.9141 3.05078 14.3203 5.42969C16.6992 7.83594 16.6992 11.6914 14.3203 14.0977C11.9141 16.4766 8.05859 16.4766 5.65234 14.0977C5.32422 13.7422 5.32422 13.1953 5.65234 12.8672C6.00781 12.5117 6.55469 12.5117 6.88281 12.8672C8.60547 14.5625 11.3672 14.5625 13.0898 12.8672C14.7852 11.1445 14.7852 8.38281 13.0898 6.66016C11.3672 4.96484 8.60547 4.96484 6.88281 6.66016L6.41797 7.125Z"
      fill="currentColor"
    />
  </svg>
</template>
