import { fabric } from "fabric";
import useCoords from "./useCoords";

export default (canvas: Ref<fabric.Canvas>, bgImg: Ref<fabric.Image>) => {
  const cropStore = useCropStore();

  const { getCornerPositions, getLeftPositionOnBg, getTopPositionOnBg } =
    useCoords(bgImg);

  function movingAngle(cropper: fabric.Rect, dx: number = 0, dy: number = 0) {
    const angle =
      (bgImg.value.angle ? bgImg.value.angle : 0) - cropStore.rotate;

    if (angle === 0) {
      movingAngle0(cropper, dx, dy);
      return;
    } else if (angle > 0) {
      movingAnglePlus(cropper, dx, dy);
    } else {
      movingAngleMinus(cropper, dx, dy);
    }
  }

  function movingAngle0(cropper: fabric.Rect, dx: number = 0, dy: number = 0) {
    const bgBounding = bgImg.value.getBoundingRect(true, true);
    const bgBeforeTop = bgImg.value.top || 0;
    const bgBeforeLeft = bgImg.value.left || 0;
    const cropTop = (cropper.top || 0) - (cropper.height || 0) / 2;
    const cropBottom = cropTop + (cropper.height || 0);
    const cropLeft = (cropper.left || 0) - (cropper.width || 0) / 2;
    const cropRight = cropLeft + (cropper.width || 0);
    const bgLeft = (bgImg.value.left || 0) - dx;
    const bgTop = (bgImg.value.top || 0) - dy;
    if (
      cropTop > bgTop - bgBounding.height / 2 &&
      bgTop + bgBounding.height / 2 > cropBottom
    ) {
      bgImg.value.top = bgTop;
    } else if (cropTop <= bgTop - bgBounding.height / 2) {
      bgImg.value.top = cropTop + bgBounding.height / 2;
    } else if (bgTop + bgBounding.height / 2 <= cropBottom) {
      bgImg.value.top = cropBottom - bgBounding.height / 2;
    }
    if (
      cropLeft > bgLeft - bgBounding.width / 2 &&
      bgLeft + bgBounding.width / 2 > cropRight
    ) {
      bgImg.value.left = bgLeft;
    } else if (cropLeft <= bgLeft - bgBounding.width / 2) {
      bgImg.value.left = cropLeft + bgBounding.width / 2;
    } else if (bgLeft + bgBounding.width / 2 <= cropRight) {
      bgImg.value.left = cropRight - bgBounding.width / 2;
    }

    moveOtherObjects(
      (bgImg.value.left || 0) - bgBeforeLeft,
      (bgImg.value.top || 0) - bgBeforeTop
    );
  }

  function movingAnglePlus(
    cropper: fabric.Rect,
    dx: number = 0,
    dy: number = 0
  ) {
    const bgBounding = bgImg.value.getBoundingRect(true, true);
    const bgBeforeTop = bgImg.value.top || 0;
    const bgBeforeLeft = bgImg.value.left || 0;
    const cropTop = (cropper.top || 0) - (cropper.height || 0) / 2;
    const cropBottom = cropTop + (cropper.height || 0);
    const cropLeft = (cropper.left || 0) - (cropper.width || 0) / 2;
    const cropRight = cropLeft + (cropper.width || 0);
    //@ts-expect-error
    const newLeft = bgImg.value.left - dx;
    //@ts-expect-error
    const newTop = bgImg.value.top - dy;
    const { dlx, dly, dtx, dty } = getCornerPositions(cropper);
    const { dt: dtr } = getTopPositionOnBg(cropRight + dx);
    const { dl: dlt } = getLeftPositionOnBg(cropTop + dy);
    const { db: dbl } = getTopPositionOnBg(cropLeft + dx);
    const { dr: drb } = getLeftPositionOnBg(cropBottom + dy);
    let dontNaturallyMove = false;

    if (cropTop - dtr <= newTop - bgBounding.height / 2) {
      if (cropRight + dlx >= newLeft + bgBounding.width / 2) {
        bgImg.value.left = cropRight + dlx - bgBounding.width / 2;
        bgImg.value.top = cropBottom + dly - bgBounding.height / 2;
        bgImg.value.setCoords();
        dontNaturallyMove = true;
        return;
      }
      if (cropTop - dty <= newTop - bgBounding.height / 2) {
        bgImg.value.left = cropLeft - dtx + bgBounding.width / 2;
        bgImg.value.top = cropTop - dty + bgBounding.height / 2;
        bgImg.value.setCoords();
        dontNaturallyMove = true;
        return;
      }
      bgImg.value.top = cropTop - dtr + bgBounding.height / 2;
      bgImg.value.left = newLeft;
      bgImg.value.setCoords();
      dontNaturallyMove = true;
    }
    if (cropLeft - dlt <= newLeft - bgBounding.width / 2) {
      if (cropTop - dty <= newTop - bgBounding.height / 2) {
        bgImg.value.left = cropLeft - dtx + bgBounding.width / 2;
        bgImg.value.top = cropTop - dty + bgBounding.height / 2;
        bgImg.value.setCoords();
        dontNaturallyMove = true;
        return;
      }
      if (cropLeft - dlx <= newLeft - bgBounding.width / 2) {
        bgImg.value.left = cropLeft - dlx + bgBounding.width / 2;
        bgImg.value.top = cropTop - dly + bgBounding.height / 2;
        bgImg.value.setCoords();
        dontNaturallyMove = true;
        return;
      }
      bgImg.value.top = newTop;
      bgImg.value.left = cropLeft - dlt + bgBounding.width / 2;
      bgImg.value.setCoords();
      dontNaturallyMove = true;
    }
    if (cropBottom + dbl >= newTop + bgBounding.height / 2) {
      if (cropLeft - dlx <= newLeft - bgBounding.width / 2) {
        bgImg.value.left = cropLeft - dlx + bgBounding.width / 2;
        bgImg.value.top = cropTop - dly + bgBounding.height / 2;
        bgImg.value.setCoords();
        dontNaturallyMove = true;
        return;
      }
      if (cropBottom + dty >= newTop + bgBounding.height / 2) {
        bgImg.value.left = cropRight + dtx - bgBounding.width / 2;
        bgImg.value.top = cropBottom + dty - bgBounding.height / 2;
        bgImg.value.setCoords();
        dontNaturallyMove = true;
        return;
      }
      bgImg.value.top = cropBottom + dbl - bgBounding.height / 2;
      bgImg.value.left = newLeft;
      bgImg.value.setCoords();
      dontNaturallyMove = true;
    }
    if (cropRight + drb >= newLeft + bgBounding.width / 2) {
      if (cropBottom + dty >= newTop + bgBounding.height / 2) {
        bgImg.value.left = cropRight + dtx - bgBounding.width / 2;
        bgImg.value.top = cropBottom + dty - bgBounding.height / 2;
        bgImg.value.setCoords();
        dontNaturallyMove = true;
        return;
      }
      if (cropRight + dlx >= newLeft + bgBounding.width / 2) {
        bgImg.value.left = cropRight + dlx - bgBounding.width / 2;
        bgImg.value.top = cropBottom + dly - bgBounding.height / 2;
        bgImg.value.setCoords();
        dontNaturallyMove = true;
        return;
      }
      bgImg.value.top = newTop;
      bgImg.value.left = cropRight + drb - bgBounding.width / 2;
      bgImg.value.setCoords();
      dontNaturallyMove = true;
    }
    if (!dontNaturallyMove) {
      bgImg.value.top = newTop;
      bgImg.value.left = newLeft;
    }

    moveOtherObjects(
      (bgImg.value.left || 0) - bgBeforeLeft,
      (bgImg.value.top || 0) - bgBeforeTop
    );
  }

  function movingAngleMinus(
    cropper: fabric.Rect,
    dx: number = 0,
    dy: number = 0
  ) {
    const bgBounding = bgImg.value.getBoundingRect(true, true);
    const bgBeforeTop = bgImg.value.top || 0;
    const bgBeforeLeft = bgImg.value.left || 0;
    const cropTop = (cropper.top || 0) - (cropper.height || 0) / 2;
    const cropBottom = cropTop + (cropper.height || 0);
    const cropLeft = (cropper.left || 0) - (cropper.width || 0) / 2;
    const cropRight = cropLeft + (cropper.width || 0);
    //@ts-expect-error
    const newLeft = bgImg.value.left - dx;
    //@ts-expect-error
    const newTop = bgImg.value.top - dy;
    const { dlx, dly, dtx, dty } = getCornerPositions(cropper);
    const { dl: dlb } = getLeftPositionOnBg(cropBottom + dy);
    const { dr: drt } = getLeftPositionOnBg(cropTop + dy);
    const { dt: dtl } = getTopPositionOnBg(cropLeft + dx);
    const { db: dbr } = getTopPositionOnBg(cropRight + dx);
    let dontNaturallyMove = false;

    if (cropTop - dtl <= newTop - bgBounding.height / 2) {
      if (cropLeft - dlx <= newLeft - bgBounding.width / 2) {
        bgImg.value.left = cropLeft - dlx + bgBounding.width / 2;
        bgImg.value.top = cropBottom + dly - bgBounding.height / 2;
        bgImg.value.setCoords();
        dontNaturallyMove = true;
        return;
      }
      if (cropTop - dty <= newTop - bgBounding.height / 2) {
        bgImg.value.left = cropRight + dtx - bgBounding.width / 2;
        bgImg.value.top = cropTop - dty + bgBounding.height / 2;
        bgImg.value.setCoords();
        dontNaturallyMove = true;
        return;
      }
      bgImg.value.top = cropTop - dtl + bgBounding.height / 2;
      bgImg.value.left = newLeft;
      bgImg.value.setCoords();
      dontNaturallyMove = true;
    }
    if (cropRight + drt >= newLeft + bgBounding.width / 2) {
      if (cropTop - dty <= newTop - bgBounding.height / 2) {
        bgImg.value.left = cropRight + dtx - bgBounding.width / 2;
        bgImg.value.top = cropTop - dty + bgBounding.height / 2;
        bgImg.value.setCoords();
        dontNaturallyMove = true;
        return;
      }
      if (cropRight + dlx >= newLeft + bgBounding.width / 2) {
        bgImg.value.left = cropRight + dlx - bgBounding.width / 2;
        bgImg.value.top = cropTop - dly + bgBounding.height / 2;
        bgImg.value.setCoords();
        dontNaturallyMove = true;
        return;
      }
      bgImg.value.top = newTop;
      bgImg.value.left = cropRight + drt - bgBounding.width / 2;
      bgImg.value.setCoords();
      dontNaturallyMove = true;
    }
    if (cropBottom + dbr >= newTop + bgBounding.height / 2) {
      if (cropRight + dlx >= newLeft + bgBounding.width / 2) {
        bgImg.value.left = cropRight + dlx - bgBounding.width / 2;
        bgImg.value.top = cropTop - dly + bgBounding.height / 2;
        bgImg.value.setCoords();
        dontNaturallyMove = true;
        return;
      }
      if (cropBottom + dty >= newTop + bgBounding.height / 2) {
        bgImg.value.left = cropLeft - dtx + bgBounding.width / 2;
        bgImg.value.top = cropBottom + dty - bgBounding.height / 2;
        bgImg.value.setCoords();
        dontNaturallyMove = true;
        return;
      }
      bgImg.value.top = cropBottom + dbr - bgBounding.height / 2;
      bgImg.value.left = newLeft;
      bgImg.value.setCoords();
      dontNaturallyMove = true;
    }
    if (cropLeft - dlb <= newLeft - bgBounding.width / 2) {
      if (cropBottom + dty >= newTop + bgBounding.height / 2) {
        bgImg.value.left = cropLeft - dtx + bgBounding.width / 2;
        bgImg.value.top = cropBottom + dty - bgBounding.height / 2;
        bgImg.value.setCoords();
        dontNaturallyMove = true;
        return;
      }
      if (cropLeft - dlx <= newLeft - bgBounding.width / 2) {
        bgImg.value.left = cropLeft - dlx + bgBounding.width / 2;
        bgImg.value.top = cropBottom + dly - bgBounding.height / 2;
        bgImg.value.setCoords();
        dontNaturallyMove = true;
        return;
      }
      bgImg.value.top = newTop;
      bgImg.value.left = cropLeft - dlb + bgBounding.width / 2;
      bgImg.value.setCoords();
      dontNaturallyMove = true;
    }
    if (!dontNaturallyMove) {
      bgImg.value.top = newTop;
      bgImg.value.left = newLeft;
    }

    moveOtherObjects(
      (bgImg.value.left || 0) - bgBeforeLeft,
      (bgImg.value.top || 0) - bgBeforeTop
    );
  }

  function moveOtherObjects(dx: number, dy: number) {
    const objects = canvas.value
      .getObjects()
      //@ts-expect-error
      .filter((obj) => obj._ableToOrder === true);
    objects.forEach((object: fabric.Object) => {
      object.left = (object.left || 0) + dx;
      object.top = (object.top || 0) + dy;
      object.setCoords();
    });
  }

  return {
    movingAngle,
    moveOtherObjects,
  };
};
