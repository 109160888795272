import { fabric } from "fabric";
import useGetBgSize from "./useGetBgSize";

export default (bgImg: Ref<fabric.Image>) => {
  const cropStore = useCropStore();

  function getCornerPositions(cropper: fabric.Rect) {
    const angle = (bgImg.value.angle || 0) - cropStore.rotate;
    const { bgHeight } = useGetBgSize(bgImg);
    const cropHight = cropper.height ? cropper.height : 0;
    const cropWidth = cropper.width ? cropper.width : 0;

    const sinAngle = Math.sin(fabric.util.degreesToRadians(Math.abs(angle)));
    const cosAngle = Math.cos(fabric.util.degreesToRadians(Math.abs(angle)));

    const x = cropHight * cosAngle;
    const y = cropWidth * sinAngle;
    const dlx = x * sinAngle;
    const dly = (bgHeight - x) * cosAngle;
    const dty = y * cosAngle;
    const dtx = (bgHeight - y) * sinAngle;
    return { dlx, dtx, dty, dly };
  }

  function getLeftPositionOnBg(top: number) {
    const bgBoundingLeft = bgImg.value.getBoundingRect(true, true).left;
    const bgBoundingWidth = bgImg.value.getBoundingRect(true, true).width;
    let dl = 0;
    let dr = bgImg.value.width || 0;
    let ll = bgBoundingLeft;
    let lr = bgBoundingLeft + bgBoundingWidth;

    const angle = (bgImg.value.angle || 0) - cropStore.rotate;
    if (angle === 0) {
      return { dl, dr, ll, lr };
    }
    const { bgWidth, bgHeight } = useGetBgSize(bgImg);
    const bgBoundingHeight = bgImg.value.getBoundingRect(true, true).height;
    const bgBoundingTop = bgImg.value.getBoundingRect(true, true).top;

    const sinAngle = Math.sin(fabric.util.degreesToRadians(Math.abs(angle)));
    const cosAngle = Math.cos(fabric.util.degreesToRadians(Math.abs(angle)));
    const topCos = (top - bgBoundingTop) / (cosAngle || 1);
    const topSin = (top - bgBoundingTop) / (sinAngle || 1);
    const restTopCos =
      (bgBoundingHeight - top + bgBoundingTop) / (cosAngle || 1);
    const restTopSin =
      (bgBoundingHeight - top + bgBoundingTop) / (sinAngle || 1);

    const ltx =
      angle > 0
        ? bgHeight * cosAngle + bgBoundingTop
        : bgWidth * sinAngle + bgBoundingTop;
    const rtx =
      angle > 0
        ? bgWidth * sinAngle + bgBoundingTop
        : bgHeight * cosAngle + bgBoundingTop;
    if (top < ltx) {
      const x = angle > 0 ? topCos : topSin;
      dl = angle > 0 ? (bgHeight - x) * sinAngle : (bgWidth - x) * cosAngle;
      ll = dl + bgBoundingLeft;
    } else {
      const x = angle > 0 ? restTopSin : restTopCos;
      dl = angle > 0 ? (bgWidth - x) * cosAngle : (bgHeight - x) * sinAngle;
      ll = dl + bgBoundingLeft;
    }

    if (top < rtx) {
      const x = angle > 0 ? topSin : topCos;
      dr = angle > 0 ? (bgWidth - x) * cosAngle : (bgHeight - x) * sinAngle;
      lr = bgBoundingWidth - dr + bgBoundingLeft;
    } else {
      const x = angle > 0 ? restTopCos : restTopSin;
      dr = angle > 0 ? (bgHeight - x) * sinAngle : (bgWidth - x) * cosAngle;
      lr = bgBoundingWidth - dr + bgBoundingLeft;
    }
    return { dl, dr, ll, lr };
  }

  function getTopPositionOnBg(left: number) {
    const bgBoundingTop = bgImg.value.getBoundingRect(true, true).top;
    const bgBoundingHeight = bgImg.value.getBoundingRect(true, true).height;
    let dt = 0;
    let db = bgImg.value.height || 0;
    let tt = bgBoundingTop;
    let tb = bgBoundingTop + bgBoundingHeight;
    const angle = (bgImg.value.angle || 0) - cropStore.rotate;
    if (angle === 0) {
      return { dt, db, tt, tb };
    }
    const { bgWidth, bgHeight } = useGetBgSize(bgImg);
    const bgBoundingWidth = bgImg.value.getBoundingRect(true, true).width;
    const bgBoundingLeft = bgImg.value.getBoundingRect(true, true).left;

    const sinAngle = Math.sin(fabric.util.degreesToRadians(Math.abs(angle)));
    const cosAngle = Math.cos(fabric.util.degreesToRadians(Math.abs(angle)));
    const leftCos = (left - bgBoundingLeft) / (cosAngle || 1);
    const leftSin = (left - bgBoundingLeft) / (sinAngle || 1);
    const restLeftCos =
      (bgBoundingWidth - left + bgBoundingLeft) / (cosAngle || 1);
    const restLeftSin =
      (bgBoundingWidth - left + bgBoundingLeft) / (sinAngle || 1);

    const ty =
      angle > 0
        ? bgHeight * sinAngle + bgBoundingLeft
        : bgWidth * cosAngle + bgBoundingLeft;
    const by =
      angle > 0
        ? bgWidth * cosAngle + bgBoundingLeft
        : bgHeight * sinAngle + bgBoundingLeft;
    if (left < ty) {
      const x = angle > 0 ? leftSin : leftCos;
      dt = angle > 0 ? (bgHeight - x) * cosAngle : (bgWidth - x) * sinAngle;
      tt = dt + bgBoundingTop;
    } else {
      const x = angle > 0 ? restLeftCos : restLeftSin;
      dt = angle > 0 ? (bgWidth - x) * sinAngle : (bgHeight - x) * cosAngle;
      tt = dt + bgBoundingTop;
    }

    if (left < by) {
      const x = angle > 0 ? leftCos : leftSin;
      db = angle > 0 ? (bgWidth - x) * sinAngle : (bgHeight - x) * cosAngle;
      tb = bgBoundingHeight - db + bgBoundingTop;
    } else {
      const x = angle > 0 ? restLeftSin : restLeftCos;
      db = angle > 0 ? (bgHeight - x) * cosAngle : (bgWidth - x) * sinAngle;
      tb = bgBoundingHeight - db + bgBoundingTop;
    }
    return { dt, db, tt, tb };
  }

  return {
    getCornerPositions,
    getLeftPositionOnBg,
    getTopPositionOnBg,
  };
};
