<template>
  <div
    class="t-t-slider-v2 flex w-full items-center justify-between gap-2"
    :class="{
      'dark-theme': dark,
    }"
  >
    <vue-slider
      v-model="localVal"
      :min="min"
      :max="max"
      :width="width"
      :height="4"
      :dot-size="12"
      :duration="0.3"
    ></vue-slider>
    <t-input-number
      v-if="input"
      v-model="localVal"
      :controls="false"
      :min="min"
      :max="max"
      :unit="unit"
      :width="25"
    ></t-input-number>
  </div>
</template>
<script lang="ts" setup>
  import VueSlider from "vue-slider-component";
  import TInputNumber from "../TInputNumber/TInputNumber.vue";
  import { computed } from "vue";

  const props = defineProps({
    modelValue: {
      type: Number,
      required: true,
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    unit: {
      type: String,
      default: "%",
    },
    xs: {
      type: Boolean,
      default: false,
    },
    input: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 147,
    },
  });

  const emit = defineEmits(["update:modelValue"]);

  const localVal = computed({
    get() {
      if (props.modelValue > props.max) {
        return props.max;
      }
      return props.modelValue;
    },

    set: function (val: number) {
      emit("update:modelValue", val);
    },
  });
</script>
<style lang="scss">
  @import "./TSlider.scss";
</style>
