<template>
  <div
    class="group"
    :class="{
      'flex items-center justify-between': flex,
    }"
    @mouseover="hovered = true"
    @mouseleave="hovered = false"
  >
    <t-label v-if="label" :for="uuid" :disabled="disabled">{{ label }}</t-label>
    <div
      class="relative"
      :class="{
        'h-8 w-8 rounded-md border border-solid border-transparent bg-gray-600 transition duration-300 focus:!border-transparent focus:bg-gray-700 focus:outline focus:outline-2 focus:outline-accent-1 disabled:!cursor-not-allowed disabled:!cursor-not-allowed disabled:!border-transparent disabled:!bg-gray-600 group-hover:border-gray-400':
          noInput,
      }"
    >
      <div
        class="absolute left-[7px] top-[7px] h-[18px] w-[18px] rounded-[3px] border border-solid border-gray-900/[.2]"
        :class="{
          '!left-[6px] !top-[6px]': noInput,
        }"
        :style="{ backgroundColor: modelValue.length > 2 ? modelValue : '' }"
        data-cy="color-dot"
        @click="openColorPicker"
      >
        <svg
          v-if="modelValue === 'transparent'"
          :class="{
            'opacity-40': disabled,
          }"
          width="18"
          height="18"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
        >
          <path
            d="M0 3C0 1.34315 1.34315 0 3 0H17C18.6569 0 20 1.34315 20 3V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17V3Z"
            fill="#D0D0D0"
          />
          <path
            d="M0 3C0 1.34315 1.34315 0 3 0H17C18.6569 0 20 1.34315 20 3V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17V3Z"
            fill="url(#pattern0)"
            fill-opacity="0.8"
            style="mix-blend-mode: darken"
          />
          <path
            d="M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H17C18.3807 0.5 19.5 1.61929 19.5 3V17C19.5 18.3807 18.3807 19.5 17 19.5H3C1.61929 19.5 0.5 18.3807 0.5 17V3Z"
            stroke="white"
            stroke-opacity="0.2"
          />
          <defs>
            <pattern
              id="pattern0"
              patternContentUnits="objectBoundingBox"
              width="1"
              height="1"
            >
              <use xlink:href="#image0_7_657" transform="scale(0.015625)" />
            </pattern>
            <image
              id="image0_7_657"
              width="64"
              height="64"
              xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAAAAXNSR0IArs4c6QAAAONJREFUeF7t20EOhEAIRFG4/6F7DvEnYeFzryQIv6pBd2behOu9dPvsbog+k+NLgArQAqmJcw9iAAhSgZKB3IJkkAySQTJ4CiE+gA8oBeg0mH3Ai084P89HhqwEqIA209ICsQdjAeaZIgaAYKxBDMCAYy8fXwAIgiAIcoJpJEYGI4VjB3YrbC9gL2AvkCB43cM5PgZgAAZgQFnNZAhdGykQBEEQBEEQDBmgAm2glM/z+QUYisYUGoldO7kY32IEAzCg6RgIRgjFAsw+AgRBMNYgBmCAT2TCYfoPPz/HCqQCX1eBHzHnv7C7WhBSAAAAAElFTkSuQmCC"
            />
          </defs>
        </svg>
        <svg
          v-else-if="modelValue.length <= 2 || !modelValue.startsWith('#')"
          width="18"
          height="18"
          fill="none"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.5 3C0.5 1.61929 1.61929 0.5 3 0.5H17C18.3807 0.5 19.5 1.61929 19.5 3V17C19.5 18.3807 18.3807 19.5 17 19.5H3C1.61929 19.5 0.5 18.3807 0.5 17V3Z"
            class="stroke-gray-400"
          />
          <path
            d="M13.9609 5.48438C14.2422 5.17969 14.7344 5.17969 15.0156 5.48438C15.3203 5.76562 15.3203 6.25781 15.0156 6.53906L11.0547 10.5L15.0156 14.4844C15.3203 14.7656 15.3203 15.2578 15.0156 15.5391C14.7344 15.8438 14.2422 15.8438 13.9609 15.5391L10 11.5781L6.01562 15.5391C5.73438 15.8438 5.24219 15.8438 4.96094 15.5391C4.65625 15.2578 4.65625 14.7656 4.96094 14.4844L8.92188 10.5L4.96094 6.53906C4.65625 6.25781 4.65625 5.76562 4.96094 5.48438C5.24219 5.17969 5.73438 5.17969 6.01562 5.48438L10 9.44531L13.9609 5.48438Z"
            class="fill-gray-400"
          />
        </svg>
      </div>
      <input
        v-if="!noInput"
        :id="uuid"
        type="text"
        :disabled="disabled"
        class="inline-block w-auto rounded-md border border-solid border-transparent bg-gray-600 py-[7px] pl-[34px] pr-2.5 font-inter text-xs font-medium leading-[16px] text-gray-50 transition duration-300 placeholder:text-gray-100 focus:!border-transparent focus:bg-gray-700 focus:!text-gray-10 focus:outline focus:outline-2 focus:outline-accent-1 disabled:!cursor-not-allowed disabled:!cursor-not-allowed disabled:!border-transparent disabled:!bg-gray-600 disabled:!text-gray-200 group-hover:border-gray-400"
        :class="{
          'max-w-[50px]': short,
          'max-w-[96px]': medium,
          'indent-[-8px] uppercase':
            modelValue.startsWith('#') && modelValue.length > 1,
        }"
        :placeholder="nonePlaceholder"
        :value="modelValue"
        size="11"
        maxlength="11"
        @input="changeColor"
        @blur="blurEmpty"
      />
    </div>
  </div>
</template>
<script lang="ts" setup>
  import { v4 as uuidv4 } from "uuid";
  import TLabel from "../TLabel/TLabel.vue";
  import { ref } from "vue";

  defineProps({
    modelValue: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      default: "",
    },
    noBorderRight: {
      type: Boolean,
      default: false,
    },
    short: {
      type: Boolean,
      default: false,
    },
    nonePlaceholder: {
      type: String,
      default: "none",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    noInput: {
      type: Boolean,
      default: false,
    },
    flex: {
      type: Boolean,
      default: false,
    },
    medium: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(["update:modelValue", "open-picker"]);

  const uuid = uuidv4();
  const hovered = ref(false);

  function checkColorFormat(color: string) {
    if (!color.startsWith("t") && color !== "" && !color.startsWith("#")) {
      return "#" + color;
    }

    if (color.startsWith("#t")) {
      return color.replace("#t", "t");
    }

    return color;
  }

  function changeColor(e: any) {
    e.target.value = checkColorFormat(e.target.value);
    if (e.target.value !== "") {
      emit("update:modelValue", e.target.value);
    }
  }

  function blurEmpty(e: any) {
    if (e.target.value === "") {
      emit("update:modelValue", e.target.value);
    }
  }

  function openColorPicker() {
    emit("open-picker");
  }
</script>
