import { createApp } from "vue";
import { createPinia } from "pinia";

import { i18n } from "./i18n-setup";

import FloatingVue from "floating-vue";
import "floating-vue/dist/style.css";
import "@/css/all.scss";
import "vue-slider-component/theme/default.css";

import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import PerfectScrollbar from "vue3-perfect-scrollbar";

import App from "@/App.vue";
import mitt from "mitt";

import IPluginOptions from "types/PluginOptions";

declare global {
  interface Window {
    $event: {
      emit(key: string, payload?: unknown): void;
      on(key: string, handler: unknown): void;
      off(key: string, handler?: unknown): void;
    };
  }
}
const mode = import.meta.env.MODE;

function installApp({ mode }: { mode: string }) {
  const pinia = createPinia();
  const app = createApp(App);
  app.config.globalProperties["$event"] = emitter;

  app.use(i18n);
  app.use(pinia);
  app.use(FloatingVue);
  app.use(PerfectScrollbar, {
    options: {
      wheelPropagation: false,
    },
  });
  app.mount("#app");
}

const emitter = mitt();
window.$event = emitter;
if (import.meta.env.MODE === "development") {
  const optionsModule = await import("../../local-options/local-topol-options");

  const options = optionsModule.default();

  installApp({ mode: mode });

  (window as any).stateStore = useStateStore();

  useStateStore().setInitialLoader(options, "localhost:5173", "development");
} else if (import.meta.env.MODE === "testing") {
  const options: IPluginOptions = {
    authorize: {
      apiKey: import.meta.env.VITE_APP_EDITOR_KEY,
      entityId: "user",
    },
    callbacks: {},
  };

  installApp({ mode: mode });

  (window as any).stateStore = useStateStore();

  useStateStore().setInitialLoader(options, "localhost:5173", "testing");
} else {
  installApp({ mode: mode });
  // const lastLoadJson: unknown = null;

  // //@ts-expect-error
  // window.Ecomail = {
  //   start(options: IPluginOptions) {
  //     installApp({ mode: mode });

  //     useStateStore()
  //       .setInitialLoader(
  //         options,
  //         document.referrer.split("/")[2],
  //         "production"
  //       )
  //       .then(() => {
  //         if (lastLoadJson) {
  //           //@ts-expect-error
  //           Ecomail.load(lastLoadJson);
  //         }
  //       })
  //       .catch((e) => {
  //         console.error(e);
  //         return false;
  //       });
  //   },
  // };
}
