import { fabric } from "fabric";
import getRelativeAngle from "@/utils/getRelativeAngle";
import { ISnapObjectX, ISnapObjectY } from "types/Snap/Snap";

export default () => {
  function getd(objAngle: number, distance: number) {
    const roundDistance = Math.round(distance * 1000) / 1000;
    const angel = getRelativeAngle(objAngle);
    const dx =
      roundDistance / (Math.cos(fabric.util.degreesToRadians(angel)) || 1);
    const dy =
      roundDistance / (Math.sin(fabric.util.degreesToRadians(angel)) || 1);
    return { dx, dy };
  }

  function getdtop(obj: fabric.Object, snap: ISnapObjectX, polarity: number) {
    const dleft =
      (obj.left || 0) -
      (snap.x + (obj.getBoundingRect(true, true).width / 2) * polarity);
    return {
      x:
        dleft *
        Math.tan(
          fabric.util.degreesToRadians(getRelativeAngle(obj.angle || 0))
        ),
      y:
        dleft /
        (Math.tan(
          fabric.util.degreesToRadians(getRelativeAngle(obj.angle || 0))
        ) || 1),
    };
  }

  function getdleft(obj: fabric.Object, snap: ISnapObjectY, polarity: number) {
    const dtop =
      (obj.top || 0) -
      (snap.y + (obj.getBoundingRect(true, true).height / 2) * polarity);
    return {
      x:
        dtop /
        (Math.tan(
          fabric.util.degreesToRadians(getRelativeAngle(obj.angle || 0))
        ) || 1),
      y:
        dtop *
        Math.tan(
          fabric.util.degreesToRadians(getRelativeAngle(obj.angle || 0))
        ),
    };
  }

  function getdw(
    objAngle: number,
    objDiagAngle: number,
    dx: number,
    y: boolean = false
  ) {
    let dwa = 0;
    let dwb = 0;
    let dwc = 0;
    const angleQuadrant = Math.floor(objAngle / 90);
    const directionQ = !y ? 0 : 1;
    const delta = getRelativeAngle(objAngle);
    let dd = 0;
    let alfa = objDiagAngle - fabric.util.degreesToRadians(delta);
    if (angleQuadrant % 2 !== directionQ) {
      alfa = Math.PI / 2 - objDiagAngle - fabric.util.degreesToRadians(delta);
    }
    dd = dx / Math.cos(alfa);
    dwa = dd * Math.cos(objDiagAngle);
    if (angleQuadrant % 2 === directionQ) {
      dwb = dx / (Math.cos(fabric.util.degreesToRadians(delta)) || 1);
      const dh = dx / (Math.sin(fabric.util.degreesToRadians(delta)) || 1);
      dwc = dh / (Math.tan(objDiagAngle) || 1);
    } else {
      dwb = dx / (Math.sin(fabric.util.degreesToRadians(delta)) || 1);
      const dh = dx / (Math.cos(fabric.util.degreesToRadians(delta)) || 1);
      dwc = dh / (Math.tan(objDiagAngle) || 1);
    }

    return { dwa, dwb, dwc };
  }

  function getdy(
    objAngle: number,
    objDiagAngle: number,
    dx: number,
    y: boolean = false
  ) {
    const angleQuadrant = Math.floor(objAngle / 90);
    const directionQ = !y ? 0 : 1;
    const delta = getRelativeAngle(objAngle);
    let alfa = objDiagAngle - fabric.util.degreesToRadians(delta);
    let beta = objDiagAngle + fabric.util.degreesToRadians(delta);
    if (angleQuadrant % 2 !== directionQ) {
      alfa = Math.PI / 2 - objDiagAngle - fabric.util.degreesToRadians(delta);
      beta = objDiagAngle + (Math.PI / 2 - fabric.util.degreesToRadians(delta));
    }
    const dya = dx * Math.tan(alfa);
    const dyb = dx * Math.tan(beta);
    return { dya, dyb };
  }

  function getWidthFromRight(
    obj: fabric.Object,
    snap: ISnapObjectX,
    corner: "tl" | "tr" | "bl" | "br"
  ) {
    const divisor = (obj.width || 0) * (obj.scaleX || 0);
    const dw = getdw(
      obj.angle || 0,
      Math.atan(((obj.height || 0) * (obj.scaleY || 0)) / (divisor || 1)),
      snap.distance
    );
    let newWidth = (obj.width || 0) * (obj.scaleX || 0);
    const newWa = newWidth - dw.dwa * snap.direction;
    const newWb = newWidth - dw.dwb * snap.direction;
    const newWc = newWidth - dw.dwc * snap.direction;

    if ((obj.angle || 0) < 90) {
      switch (corner) {
        case "bl":
          newWidth = newWa;
          break;
        case "tl":
          newWidth = newWb;
          break;
        case "br":
          newWidth = newWc;
          break;
      }
    } else if ((obj.angle || 0) < 180) {
      switch (corner) {
        case "br":
          newWidth = newWa;
          break;
        case "tr":
          newWidth = newWb;
          break;
        case "bl":
          newWidth = newWc;
          break;
      }
    } else if ((obj.angle || 0) < 270) {
      switch (corner) {
        case "tr":
          newWidth = newWa;
          break;
        case "br":
          newWidth = newWb;
          break;
        case "tl":
          newWidth = newWc;
          break;
      }
    } else {
      switch (corner) {
        case "tl":
          newWidth = newWa;
          break;
        case "bl":
          newWidth = newWb;
          break;
        case "tr":
          newWidth = newWc;
          break;
      }
    }

    return newWidth;
  }

  function getTopFromRight(
    obj: fabric.Object,
    corner: "tl" | "tr" | "bl" | "br",
    dx: number
  ) {
    const divisor = (obj.width || 0) * (obj.scaleX || 0);
    const dw = getdy(
      obj.angle || 0,
      Math.atan(((obj.height || 0) * (obj.scaleY || 0)) / (divisor || 1)),
      dx
    );
    let newTop = obj.top || 0;
    const newTa = newTop + dw.dya;
    const newTb = newTop - dw.dyb;
    const newTc = newTop + dw.dyb;

    if ((obj.angle || 0) < 90) {
      switch (corner) {
        case "bl":
          newTop = newTa;
          break;
        case "tl":
          newTop = newTb;
          break;
        case "br":
          newTop = newTb;
          break;
      }
    } else if ((obj.angle || 0) < 180) {
      switch (corner) {
        case "br":
          newTop = newTa;
          break;
        case "bl":
          newTop = newTc;
          break;
        case "tr":
          newTop = newTc;
          break;
      }
    } else if ((obj.angle || 0) < 270) {
      switch (corner) {
        case "tr":
          newTop = newTa;
          break;
        case "br":
          newTop = newTb;
          break;
        case "tl":
          newTop = newTb;
          break;
      }
    } else {
      switch (corner) {
        case "tl":
          newTop = newTa;
          break;
        case "tr":
          newTop = newTc;
          break;
        case "bl":
          newTop = newTc;
          break;
      }
    }

    return newTop;
  }

  function getWidthFromLeft(
    obj: fabric.Object,
    snap: ISnapObjectX,
    corner: "tl" | "tr" | "bl" | "br"
  ) {
    const divisor = (obj.width || 0) * (obj.scaleX || 0);
    const dw = getdw(
      obj.angle || 0,
      Math.atan(((obj.height || 0) * (obj.scaleY || 0)) / (divisor || 1)),
      snap.distance
    );
    let newWidth = (obj.width || 0) * (obj.scaleX || 0);
    const newWa = newWidth + dw.dwa * snap.direction;
    const newWb = newWidth + dw.dwb * snap.direction;
    const newWc = newWidth + dw.dwc * snap.direction;

    if ((obj.angle || 0) < 90) {
      switch (corner) {
        case "tr":
          newWidth = newWa;
          break;
        case "br":
          newWidth = newWb;
          break;
        case "tl":
          newWidth = newWc;
          break;
      }
    } else if ((obj.angle || 0) < 180) {
      switch (corner) {
        case "tl":
          newWidth = newWa;
          break;
        case "bl":
          newWidth = newWb;
          break;
        case "tr":
          newWidth = newWc;
          break;
      }
    } else if ((obj.angle || 0) < 270) {
      switch (corner) {
        case "bl":
          newWidth = newWa;
          break;
        case "tl":
          newWidth = newWb;
          break;
        case "br":
          newWidth = newWc;
          break;
      }
    } else {
      switch (corner) {
        case "br":
          newWidth = newWa;
          break;
        case "tr":
          newWidth = newWb;
          break;
        case "bl":
          newWidth = newWc;
          break;
      }
    }

    return newWidth;
  }

  function getTopFromLeft(
    obj: fabric.Object,
    corner: "tl" | "tr" | "bl" | "br",
    dx: number
  ) {
    const divisor = (obj.width || 0) * (obj.scaleX || 0);
    const dw = getdy(
      obj.angle || 0,
      Math.atan(((obj.height || 0) * (obj.scaleY || 0)) / (divisor || 1)),
      dx
    );
    let newTop = obj.top || 0;
    const newTa = newTop + dw.dya;
    const newTb = newTop - dw.dyb;
    const newTc = newTop + dw.dyb;

    if ((obj.angle || 0) < 90) {
      switch (corner) {
        case "tr":
          newTop = newTa;
          break;
        case "br":
          newTop = newTb;
          break;
        case "tl":
          newTop = newTb;
          break;
      }
    } else if ((obj.angle || 0) < 180) {
      switch (corner) {
        case "tl":
          newTop = newTa;
          break;
        case "bl":
          newTop = newTc;
          break;
        case "tr":
          newTop = newTc;
          break;
      }
    } else if ((obj.angle || 0) < 270) {
      switch (corner) {
        case "bl":
          newTop = newTa;
          break;
        case "tl":
          newTop = newTb;
          break;
        case "br":
          newTop = newTb;
          break;
      }
    } else {
      switch (corner) {
        case "br":
          newTop = newTa;
          break;
        case "tr":
          newTop = newTc;
          break;
        case "bl":
          newTop = newTc;
          break;
      }
    }

    return newTop;
  }

  function getWidthFromTop(
    obj: fabric.Object,
    snap: ISnapObjectY,
    corner: "tl" | "tr" | "bl" | "br"
  ) {
    const divisor = (obj.width || 0) * (obj.scaleX || 0);
    const dw = getdw(
      obj.angle || 0,
      Math.atan(((obj.height || 0) * (obj.scaleY || 0)) / (divisor || 1)),
      snap.distance,
      true
    );
    let newWidth = (obj.width || 0) * (obj.scaleX || 0);
    const newWa = newWidth + dw.dwa * snap.direction;
    const newWb = newWidth + dw.dwb * snap.direction;
    const newWc = newWidth + dw.dwc * snap.direction;

    if ((obj.angle || 0) < 90) {
      switch (corner) {
        case "br":
          newWidth = newWa;
          break;
        case "tr":
          newWidth = newWb;
          break;
        case "bl":
          newWidth = newWc;
          break;
      }
    } else if ((obj.angle || 0) < 180) {
      switch (corner) {
        case "tr":
          newWidth = newWa;
          break;
        case "br":
          newWidth = newWb;
          break;
        case "tl":
          newWidth = newWc;
          break;
      }
    } else if ((obj.angle || 0) < 270) {
      switch (corner) {
        case "tl":
          newWidth = newWa;
          break;
        case "bl":
          newWidth = newWb;
          break;
        case "tr":
          newWidth = newWc;
          break;
      }
    } else {
      switch (corner) {
        case "bl":
          newWidth = newWa;
          break;
        case "tl":
          newWidth = newWb;
          break;
        case "br":
          newWidth = newWc;
          break;
      }
    }

    return newWidth;
  }

  function getLeftFromTop(
    obj: fabric.Object,
    corner: "tl" | "tr" | "bl" | "br",
    dx: number
  ) {
    const divisor = (obj.width || 0) * (obj.scaleX || 0);
    const dw = getdy(
      obj.angle || 0,
      Math.atan(((obj.height || 0) * (obj.scaleY || 0)) / (divisor || 1)),
      dx,
      true
    );
    let newLeft = obj.left || 0;
    const newLa = newLeft - dw.dya;
    const newLb = newLeft - dw.dyb;
    const newLc = newLeft + dw.dyb;

    if ((obj.angle || 0) < 90) {
      switch (corner) {
        case "br":
          newLeft = newLa;
          break;
        case "tr":
          newLeft = newLb;
          break;
        case "bl":
          newLeft = newLb;
          break;
      }
    } else if ((obj.angle || 0) < 180) {
      switch (corner) {
        case "tr":
          newLeft = newLa;
          break;
        case "br":
          newLeft = newLc;
          break;
        case "tl":
          newLeft = newLc;
          break;
      }
    } else if ((obj.angle || 0) < 270) {
      switch (corner) {
        case "tl":
          newLeft = newLa;
          break;
        case "bl":
          newLeft = newLb;
          break;
        case "tr":
          newLeft = newLb;
          break;
      }
    } else {
      switch (corner) {
        case "bl":
          newLeft = newLa;
          break;
        case "tl":
          newLeft = newLc;
          break;
        case "br":
          newLeft = newLc;
          break;
      }
    }

    return newLeft;
  }

  function getWidthFromBottom(
    obj: fabric.Object,
    snap: ISnapObjectY,
    corner: "tl" | "tr" | "bl" | "br"
  ) {
    const divisor = (obj.width || 0) * (obj.scaleX || 0);
    const dw = getdw(
      obj.angle || 0,
      Math.atan(((obj.height || 0) * (obj.scaleY || 0)) / (divisor || 1)),
      snap.distance,
      true
    );
    let newWidth = (obj.width || 0) * (obj.scaleX || 0);
    const newWa = newWidth - dw.dwa * snap.direction;
    const newWb = newWidth - dw.dwb * snap.direction;
    const newWc = newWidth - dw.dwc * snap.direction;

    if ((obj.angle || 0) < 90) {
      switch (corner) {
        case "tl":
          newWidth = newWa;
          break;
        case "bl":
          newWidth = newWb;
          break;
        case "tr":
          newWidth = newWc;
          break;
      }
    } else if ((obj.angle || 0) < 180) {
      switch (corner) {
        case "bl":
          newWidth = newWa;
          break;
        case "tl":
          newWidth = newWb;
          break;
        case "br":
          newWidth = newWc;
          break;
      }
    } else if ((obj.angle || 0) < 270) {
      switch (corner) {
        case "br":
          newWidth = newWa;
          break;
        case "tr":
          newWidth = newWb;
          break;
        case "bl":
          newWidth = newWc;
          break;
      }
    } else {
      switch (corner) {
        case "tr":
          newWidth = newWa;
          break;
        case "br":
          newWidth = newWb;
          break;
        case "tl":
          newWidth = newWc;
          break;
      }
    }

    return newWidth;
  }

  function getLeftFromBottom(
    obj: fabric.Object,
    corner: "tl" | "tr" | "bl" | "br",
    dx: number
  ) {
    const divisor = (obj.width || 0) * (obj.scaleX || 0);
    const dw = getdy(
      obj.angle || 0,
      Math.atan(((obj.height || 0) * (obj.scaleY || 0)) / (divisor || 1)),
      dx,
      true
    );
    let newLeft = obj.left || 0;
    const newLa = newLeft - dw.dya;
    const newLb = newLeft - dw.dyb;
    const newLc = newLeft + dw.dyb;

    if ((obj.angle || 0) < 90) {
      switch (corner) {
        case "tl":
          newLeft = newLa;
          break;
        case "bl":
          newLeft = newLb;
          break;
        case "tr":
          newLeft = newLb;
          break;
      }
    } else if ((obj.angle || 0) < 180) {
      switch (corner) {
        case "bl":
          newLeft = newLa;
          break;
        case "tl":
          newLeft = newLc;
          break;
        case "br":
          newLeft = newLc;
          break;
      }
    } else if ((obj.angle || 0) < 270) {
      switch (corner) {
        case "br":
          newLeft = newLa;
          break;
        case "tr":
          newLeft = newLb;
          break;
        case "bl":
          newLeft = newLb;
          break;
      }
    } else {
      switch (corner) {
        case "tr":
          newLeft = newLa;
          break;
        case "br":
          newLeft = newLc;
          break;
        case "tl":
          newLeft = newLc;
          break;
      }
    }

    return newLeft;
  }

  return {
    getd,
    getdtop,
    getdleft,
    getdw,
    getWidthFromRight,
    getTopFromRight,
    getWidthFromLeft,
    getTopFromLeft,
    getWidthFromTop,
    getLeftFromTop,
    getWidthFromBottom,
    getLeftFromBottom,
  };
};
