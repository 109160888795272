import { fabric } from "fabric";

import useCoords from "./useCrop/useCoords";
import useRecalculateMask from "./useCrop/useRecalculateMask";
import useGetScale from "./useCrop/useGetScale";
import useMoving from "./useCrop/useMoving";
import useScaling from "./useCrop/useScaling";
import useGetBgSize from "./useCrop/useGetBgSize";

export default (props: {
  canvas: Ref<fabric.Canvas>;
  bgImg: any;
  cropRect: any;
  cropMask: Ref<fabric.Rect>;
  cropLeftFirstThird: Ref<fabric.Line>;
  cropLeftSecondThird: Ref<fabric.Line>;
  cropTopFirstThird: Ref<fabric.Line>;
  cropTopSecondThird: Ref<fabric.Line>;
}) => {
  const cropStore = useCropStore();
  const { getTopPositionOnBg, getLeftPositionOnBg } = useCoords(props.bgImg);
  const { recalculateMask } = useRecalculateMask(props);
  const { getScaleOfBg, getDiagOnBg } = useGetScale(
    props.bgImg,
    props.cropRect
  );
  const { movingAngle, moveOtherObjects } = useMoving(
    props.canvas,
    props.bgImg
  );
  const { scaling } = useScaling(props.canvas, props.bgImg);

  function lockRatio(val: boolean) {
    props.cropRect.value.setControlsVisibility({
      mb: !val,
      ml: !val,
      mr: !val,
      mt: !val,
    });
  }

  function resetCrop() {
    props.bgImg.value.angle = 0;
    if (props.bgImg.value.width && props.bgImg.value.height) {
      props.cropRect.value.set({
        width: props.bgImg.value.width,
        height: props.bgImg.value.height,
        left: props.bgImg.value.width / 2,
        top: props.bgImg.value.height / 2,
        ratio: 0,
        customRatio: false,
        customRatioX: 1,
        customRatioY: 1,
        dimension:
          props.bgImg.value.width < props.bgImg.value.height
            ? "portrait"
            : "landscape",
      });
      props.cropRect.value.left = props.bgImg.value.width / 2;
      props.cropRect.value.top = props.bgImg.value.height / 2;
      props.cropRect.value.height = props.bgImg.value.height;
      props.cropRect.value.width = props.bgImg.value.width;
      props.bgImg.value.top = props.bgImg.value.height / 2;
      props.bgImg.value.left = props.bgImg.value.width / 2;
      cropStore.setCropperSize(
        props.bgImg.value.width,
        props.bgImg.value.height
      );
    }
    cropStore.rotate = 0;
    recalculateMask();
    props.canvas.value.renderAll();
    window.$event.emit("cropUpdated");
  }

  function showCropper() {
    props.cropRect.value.set({
      selectable: true,
      stroke: "#ccc",
      hoverCursor: "move",
    });
    props.bgImg.value.clipPath = undefined;
    props.canvas.value.getObjects().map(function (o: any) {
      if (o.id !== "cropRect") {
        o.set("selectable", false);
      }
      if (o.id && o.id.includes("cropMask")) {
        o.set("opacity", 1);
      }
    });
    props.cropRect.value.on("deselected", () => {
      if (cropStore.isCropping) {
        props.canvas.value.setActiveObject(props.cropRect.value);
        props.canvas.value.renderAll();
      }
    });
    props.canvas.value.setActiveObject(props.cropRect.value);
    props.cropRect.value.bringToFront();
    props.canvas.value.renderAll();
    window.$event.emit("cropUpdated");
  }

  function hideCropper() {
    props.cropRect.value.set({
      selectable: false,
      stroke: "transparent",
      hoverCursor: "default",
    });
    props.canvas.value.getObjects().map(function (o: any) {
      if (o.id && o.id.includes("cropMask")) {
        o.set("opacity", 0);
      } else if (!o._disalowSelecting) {
        o.set("selectable", true);
      }
    });
    props.canvas.value.discardActiveObject();
    props.canvas.value.renderAll();
  }

  function crop() {
    const clipPath = new fabric.Rect({
      width: props.cropRect.value.width,
      height: props.cropRect.value.height,
      left: props.cropRect.value.left - props.cropRect.value.width / 2,
      top: props.cropRect.value.top - props.cropRect.value.height / 2,
      absolutePositioned: true,
    });
    props.bgImg.value.clipPath = clipPath;
    cropStore.isCropping = false;
    hideCropper();
    props.canvas.value.renderAll();
    window.$event.emit("recalculateCanvasMask");
  }

  function rotate90(angel: any) {
    const beforeTop = props.bgImg.value.top;
    const beforeLeft = props.bgImg.value.left;
    const isCenter =
      Math.round(beforeTop * 1000) ===
        Math.round(
          (props.bgImg.value.getBoundingRect(true, true).height / 2) * 1000
        ) &&
      Math.round(beforeLeft * 1000) ===
        Math.round(
          (props.bgImg.value.getBoundingRect(true, true).width / 2) * 1000
        );
    const diff = angel.actual - angel.history;
    props.bgImg.value.angle = props.bgImg.value.angle + diff;
    const width = props.cropRect.value.width;
    const height = props.cropRect.value.height;
    props.cropRect.value.width = height;
    props.cropRect.value.height = width;
    cropStore.setCropperSize(height, width);

    props.cropRect.value.setCoords();
    if (isCenter) {
      props.bgImg.value.top =
        props.bgImg.value.getBoundingRect(true, true).height / 2;
      props.bgImg.value.left =
        props.bgImg.value.getBoundingRect(true, true).width / 2;
      moveOtherObjects(
        props.bgImg.value.left - beforeLeft,
        props.bgImg.value.top - beforeTop
      );
    }
    props.cropRect.value.dimension =
      props.cropRect.value.dimension === "landscape" ? "portrait" : "landscape";
    props.cropRect.value.setCoords();
    movingCropRect(props.cropRect.value, 0, 0);
    recalculateMask();
    window.$event.emit("recalculateCanvasMask");
    window.$event.emit("cropUpdated");
  }

  function rotateBg(angle: number) {
    const beforeTop = props.bgImg.value.top || 0;
    const beforeLeft = props.bgImg.value.left || 0;
    const isCenter =
      Math.round(beforeTop * 1000) ===
        Math.round(
          (props.bgImg.value.getBoundingRect(true, true).height / 2) * 1000
        ) &&
      Math.round(beforeLeft * 1000) ===
        Math.round(
          (props.bgImg.value.getBoundingRect(true, true).width / 2) * 1000
        );
    props.bgImg.value.angle = angle;
    const scale = getScaleOfBg(angle - cropStore.rotate);
    const newWidth = cropStore.cropperSize.width * (1 / (scale || 1));
    const newHeight = cropStore.cropperSize.height * (1 / (scale || 1));
    props.cropRect.value.width = newWidth;
    props.cropRect.value.height = newHeight;
    if (isCenter) {
      props.bgImg.value.top =
        props.bgImg.value.getBoundingRect(true, true).height / 2;
      props.bgImg.value.left =
        props.bgImg.value.getBoundingRect(true, true).width / 2;
      moveOtherObjects(
        props.bgImg.value.left - beforeLeft,
        props.bgImg.value.top - beforeTop
      );
    } else {
      // dodělat "obrácení" pozice
    }
    props.cropRect.value.setCoords();
    movingCropRect(props.cropRect.value, 0, 0);
    recalculateMask();
    props.canvas.value.renderAll();
    window.$event.emit("recalculateCanvasMask");
    window.$event.emit("cropUpdated");
  }

  function movingCropRect(cropper: fabric.Rect, dx: number, dy: number) {
    cropper.left = props.bgImg.value.getBoundingRect(true, true).width / 2;
    cropper.top = props.bgImg.value.getBoundingRect(true, true).height / 2;
    movingAngle(cropper, dx, dy);
    cropper.setCoords();
    recalculateMask();
    props.canvas.value.renderAll();
    window.$event.emit("recalculateCanvasMask");
  }

  function scalingCropRect(
    cropper: fabric.Rect,
    corner: "tl" | "tr" | "bl" | "br" | "mt" | "mb" | "ml" | "mr"
  ) {
    scaling(cropper, corner);
    cropper.setCoords();
    recalculateMask();
    props.canvas.value.renderAll();
    window.$event.emit("recalculateCanvasMask");
    window.$event.emit("cropUpdated");
  }

  function setCropWidth(val: number) {
    if (cropStore.lockRatio) {
      const { bgWidth, bgHeight } = useGetBgSize(props.bgImg);
      const bgLandRatio =
        bgWidth > bgHeight ? bgWidth / bgHeight : bgHeight / bgWidth;
      const crRatio = props.cropRect.value.ratio || bgLandRatio;
      const ratio =
        props.cropRect.value.dimension === "landscape"
          ? 1 / (crRatio || 1)
          : crRatio;
      const { newDiagH, newDiagW } = getDiagOnBg(
        props.bgImg.value.angle - cropStore.rotate
      );
      const diag = Math.min(newDiagH, newDiagW);
      const maxW = diag * Math.cos(Math.atan(ratio));
      if (val >= maxW) {
        props.cropRect.value.width = maxW;
      } else {
        props.cropRect.value.width = val;
      }
      props.cropRect.value.height = props.cropRect.value.width * ratio;
      cropStore.setCropperSize(
        props.cropRect.value.width,
        props.cropRect.value.height
      );
      movingCropRect(props.cropRect.value, 0, 0);
      props.cropRect.value.setCoords();
      recalculateMask();
      props.canvas.value.renderAll();
      window.$event.emit("cropUpdated");
      return;
    } else {
      const cropTop =
        props.cropRect.value.top - props.cropRect.value.height / 2;
      const cropBottom =
        props.cropRect.value.top + props.cropRect.value.height / 2;
      const { ll: tll, lr: tlr } = getLeftPositionOnBg(cropTop);
      const { ll: bll, lr: blr } = getLeftPositionOnBg(cropBottom);
      const ll = Math.max(tll, bll);
      const lr = Math.min(tlr, blr);
      const width = lr - ll;
      if (val >= width) {
        props.cropRect.value.width = width;
        cropStore.setCropperSize(width, props.cropRect.value.height);
        props.cropRect.value.left = lr - props.cropRect.value.width / 2;
        props.cropRect.value.setCoords();
        recalculateMask();
        props.canvas.value.renderAll();
        window.$event.emit("cropUpdated");
        return;
      }

      props.cropRect.value.width = val;
      cropStore.setCropperSize(val, props.cropRect.value.height);

      if (props.cropRect.value.left + val / 2 > lr) {
        props.cropRect.value.left = lr - val / 2;
      }
      recalculateMask();
      props.cropRect.value.setCoords();
      props.canvas.value.renderAll();
      window.$event.emit("cropUpdated");
    }
  }

  function setCropHeight(val: number) {
    if (cropStore.lockRatio) {
      const { bgWidth, bgHeight } = useGetBgSize(props.bgImg);
      const bgLandRatio =
        bgWidth > bgHeight ? bgWidth / bgHeight : bgHeight / bgWidth;
      const crRatio = props.cropRect.value.ratio || bgLandRatio;
      const ratio =
        props.cropRect.value.dimension === "landscape"
          ? 1 / (crRatio || 1)
          : crRatio;
      const { newDiagH, newDiagW } = getDiagOnBg(
        props.bgImg.value.angle - cropStore.rotate
      );
      const diag = Math.min(newDiagH, newDiagW);
      const maxH = diag * Math.sin(Math.atan(ratio));
      if (val >= maxH) {
        props.cropRect.value.height = maxH;
      } else {
        props.cropRect.value.height = val;
      }
      props.cropRect.value.width = props.cropRect.value.height / (ratio || 1);
      cropStore.setCropperSize(
        props.cropRect.value.width,
        props.cropRect.value.height
      );
      movingCropRect(props.cropRect.value, 0, 0);
      props.cropRect.value.setCoords();
      recalculateMask();
      props.canvas.value.renderAll();
      window.$event.emit("cropUpdated");
      return;
    } else {
      const cropLeft =
        props.cropRect.value.left - props.cropRect.value.width / 2;
      const cropRight =
        props.cropRect.value.left + props.cropRect.value.width / 2;
      const { tt: ttl, tb: tbl } = getTopPositionOnBg(cropLeft);
      const { tt: ttr, tb: tbr } = getTopPositionOnBg(cropRight);
      const tt = Math.max(ttl, ttr);
      const tb = Math.min(tbl, tbr);
      const height = tb - tt;
      if (val >= height) {
        props.cropRect.value.height = height;
        cropStore.setCropperSize(props.cropRect.value.width, height);
        props.cropRect.value.top = tb - props.cropRect.value.height / 2;
        props.cropRect.value.setCoords();
        recalculateMask();
        props.canvas.value.renderAll();
        window.$event.emit("cropUpdated");
        return;
      }

      props.cropRect.value.height = val;
      cropStore.setCropperSize(props.cropRect.value.width, val);

      if (props.cropRect.value.top + props.cropRect.value.height / 2 > tb) {
        props.cropRect.value.top = tb - props.cropRect.value.height / 2;
      }
      props.cropRect.value.setCoords();
      recalculateMask();
      props.canvas.value.renderAll();
      window.$event.emit("cropUpdated");
    }
  }

  function setCropRatio(
    val: number | string,
    custom = false,
    xy?: { x: number; y: number }
  ) {
    if (typeof val === "string") {
      if (val === "free") {
        props.cropRect.value.ratio = val;
        cropStore.lockRatio = false;
        lockRatio(false);
        return;
      }
      return;
    } else if (!cropStore.lockRatio) {
      cropStore.lockRatio = true;
      lockRatio(true);
    }
    if (val !== 0 && val < 1) {
      val = 1 / val;
    }
    props.cropRect.value.ratio = val;
    props.cropRect.value.customRatio = custom;
    if (xy) {
      props.cropRect.value.customRatioX = xy.x;
      props.cropRect.value.customRatioY = xy.y;
    }
    const bgRatio =
      props.bgImg.value.width > props.bgImg.value.height
        ? props.bgImg.value.width / props.bgImg.value.height
        : props.bgImg.value.height / props.bgImg.value.width;
    if (val === 0) {
      val = bgRatio;
    }
    const ratio =
      props.cropRect.value.dimension !== "landscape" ? 1 / (val || 1) : val;
    const newSize = props.cropRect.value.width * (1 / (ratio || 1));
    props.cropRect.value.height = newSize;
    cropStore.setCropperSize(props.cropRect.value.width, newSize);
    rotateBg(props.bgImg.value.angle);
    window.$event.emit("cropUpdated");
  }

  function setDimension(value: string) {
    props.cropRect.value.dimension = value;
    if (value === "landscape") {
      const height = props.cropRect.value.height;
      const width = props.cropRect.value.width;
      props.cropRect.value.height = width;
      props.cropRect.value.width = height;
      setCropWidth(height);
    } else {
      const height = props.cropRect.value.height;
      const width = props.cropRect.value.width;
      props.cropRect.value.height = width;
      props.cropRect.value.width = height;
      setCropHeight(width);
    }

    props.cropRect.value.setCoords();
    movingCropRect(props.cropRect.value, 0, 0);
    recalculateMask();
    window.$event.emit("recalculateCanvasMask");
    window.$event.emit("cropUpdated");
  }

  return {
    crop: {
      lockRatio,
      resetCrop,
      showCropper,
      hideCropper,
      crop,
      rotate90,
      rotateBg,
      movingCropRect,
      scalingCropRect,
      setCropHeight,
      setCropWidth,
      setCropRatio,
      setDimension,
    },
  };
};
