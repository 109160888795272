<template>
  <t-dropdown
    ref="dropdownSizeRef"
    class="dropdown-size"
    with-input
    input-type="number"
    :selected="selectedSize"
    :label="label"
    :flex="flex"
    @input-changed="sizeChanged"
  >
    <template #content>
      <ul>
        <VueCustomScrollbar
          style="position: relative; margin: auto"
          :style="{ height: calculatedHeightSizes + 'px' }"
          :settings="{ wheelPropagation: false }"
        >
          <li
            v-for="(size, k) in sizes"
            :key="k"
            style="padding: 4px 0; display: flex; justify-content: flex-start"
            @click="sizeChanged(size)"
          >
            <p
              v-if="typeof size === 'number'"
              style="width: 120px; padding-left: 12px"
            >
              {{ size }}
            </p>
            <p v-else style="width: 120px; padding-left: 12px">
              {{ size }}
            </p>
          </li>
        </VueCustomScrollbar>
      </ul>
    </template>
  </t-dropdown>
</template>

<script setup lang="ts">
  import TDropdown from "@/ui/TDropdown/TDropdown.vue";
  import { PerfectScrollbar as VueCustomScrollbar } from "vue3-perfect-scrollbar";

  defineProps<{ selectedSize: number; label?: string; flex?: boolean }>();
  const emit = defineEmits(["size-changed"]);

  const fontStore = useFontStore();

  const dropdownSizeRef = ref();

  const sizes = computed(() => {
    return fontStore.fontSizes;
  });

  const calculatedHeightSizes = computed(() => {
    const height = sizes.value.length * 40;

    if (height > 240) {
      return 240;
    }

    return height;
  });

  const sizeChanged = (size: number | string) => {
    emit("size-changed", Number(size));
    dropdownSizeRef.value.opened = false;
  };
</script>
