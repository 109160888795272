export default (
  spikeCount: number,
  outerRadius: number,
  innerRadius: number
) => {
  var cx = outerRadius;
  var cy = outerRadius;
  var sweep = Math.PI / spikeCount;
  var points = [];
  var angle = 0;

  for (var i = 0; i < spikeCount; i++) {
    var x = cx + Math.cos(angle) * outerRadius;
    var y = cy + Math.sin(angle) * outerRadius;
    points.push({
      x: spikeCount % 2 === 0 ? x : y,
      y: spikeCount % 2 === 0 ? y : x,
    });
    angle += sweep;

    x = cx + Math.cos(angle) * innerRadius;
    y = cy + Math.sin(angle) * innerRadius;
    points.push({
      x: spikeCount % 2 === 0 ? x : y,
      y: spikeCount % 2 === 0 ? y : x,
    });
    angle += sweep;
  }
  return points;
};
