<template>
  <div class="flex items-center gap-3">
    <t-label
      v-if="label"
      :for="uuid"
      :disabled="disabled"
      no-margin
      :hovered="hovered || active"
      >{{ label }}</t-label
    >
    <t-button
      :id="uuid"
      :class="{
        '!h-[32px]': lg,
        '!outline !outline-2 !outline-accent-1': active,
      }"
      @click="toggle"
    >
      <template #icon>
        <svg
          class="transition duration-300 h-3"
          :class="{
            'translate-x-0 translate-y-0 rotate-180 skew-x-0 skew-y-0 scale-100 transform':
              active,
          }"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 512"
        >
          <path
            d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"
            fill="currentColor"
          />
        </svg>
      </template>
    </t-button>
  </div>
</template>
<script lang="ts" setup>
import { v4 as uuidv4 } from "uuid";
import TLabel from "../TLabel/TLabel.vue";
import { ref, computed } from "vue";
import TButton from "../TButton/TButton.vue";

const props = defineProps({
  label: {
    type: String,
    default: "",
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  lg: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const uuid = uuidv4();
const hovered = ref(false);

const active = computed(() => props.modelValue);

function toggle() {
  if (props.disabled === false) {
    emit("update:modelValue", !props.modelValue);
  }
}
</script>
<style lang="scss">
// @import "./TInputToggle.scss";
</style>
