<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5547 7.125L13.0898 6.66016C11.3672 4.96484 8.60547 4.96484 6.88281 6.66016C5.1875 8.38281 5.1875 11.1445 6.88281 12.8672C8.60547 14.5625 11.3672 14.5625 13.0898 12.8672C13.418 12.5117 13.9648 12.5117 14.3203 12.8672C14.6484 13.1953 14.6484 13.7422 14.3203 14.0977C11.9141 16.4766 8.05859 16.4766 5.65234 14.0977C3.27344 11.6914 3.27344 7.83594 5.65234 5.42969C8.05859 3.05078 11.9141 3.05078 14.3203 5.42969L14.8125 5.92188V4.5C14.8125 4.03516 15.1953 3.625 15.6875 3.625C16.1523 3.625 16.5625 4.03516 16.5625 4.5V8C16.5625 8.49219 16.1523 8.875 15.6875 8.875H12.1875C11.6953 8.875 11.3125 8.49219 11.3125 8C11.3125 7.53516 11.6953 7.125 12.1875 7.125H13.5547Z"
      fill="currentColor"
    />
  </svg>
</template>
