<template>
  <button
    v-tooltip="{ content: tooltip }"
    type="button"
    role="button"
    class="t-group-button box-border inline-flex h-[--group-button-height] min-w-[--group-button-width-s] cursor-pointer items-center justify-center border-2 border-solid border-transparent bg-gray-500 py-[--group-button-padding] font-inter text-xs font-medium text-gray-100 transition duration-300 first:rounded-l-md last:rounded-r-md hover:bg-gray-400 hover:text-gray-50 focus:border-accent-1 focus:bg-gray-600 focus:text-gray-10 disabled:!cursor-not-allowed disabled:!border-transparent disabled:!bg-gray-600 disabled:!text-gray-200"
    :class="{
      '!border-accent-1 !bg-gray-800 !text-gray-10': active,
      'w-[--group-button-width-s]': small && !$slots.text,
      'w-[--group-button-width-m]': medium && !$slots.text,
      'w-[--group-button-width-l]': large && !$slots.text,
      'w-[130px]': xl && !$slots.text,
      'px-1.5': $slots.text,
    }"
    :disabled="disabled"
    @click="btnClicked"
  >
    <slot></slot>
    <span v-if="$slots.text" class="pl-1">
      <slot name="text"></slot>
    </span>
  </button>
</template>

<script lang="ts" setup>
  const props = defineProps({
    active: {
      type: Boolean,
      default: false,
    },

    toggle: {
      type: Boolean,
      default: false,
    },

    data: {
      type: String,
      default: "",
    },

    flat: {
      type: Boolean,
      default: false,
    },

    small: {
      type: Boolean,
      default: false,
    },

    medium: {
      type: Boolean,
      default: false,
    },

    large: {
      type: Boolean,
      default: false,
    },

    xl: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    tooltip: {
      type: String,
      required: false,
      default: "",
    },
  });

  const emit = defineEmits(["button-clicked"]);

  function btnClicked() {
    if (!props.disabled) {
      if (props.toggle && props.active) {
        emit("button-clicked", "");
      } else {
        emit("button-clicked", props.data);
      }
    }
  }
</script>

<style lang="scss">
  .t-group-button {
    --group-button-height: 32px;
    --group-button-width-s: 32px;
    --group-button-width-m: 44px;
    --group-button-width-l: 60px;
    --group-button-padding: 6px;
  }
</style>
